var render = function render(){var _vm=this,_c=_vm._self._c;return _c('draggable',{attrs:{"disabled":!_vm.allows_crud,"tag":"b-tbody","handle":".handle"},model:{value:(_vm.draggableList),callback:function ($$v) {_vm.draggableList=$$v},expression:"draggableList"}},[(_vm.draggableList.length > 0)?_vm._l((_vm.draggableList),function(evaluation){return _c('b-tr',{key:evaluation.id},[(_vm.allows_crud)?_c('b-td',{staticClass:"text-center handle",class:{ grabbable: _vm.allows_crud }},[(_vm.allows_crud && _vm.draggableList.length > 1)?_c('b-icon',{attrs:{"icon":"arrows-expand","scale":"1.2"}}):_vm._e()],1):_vm._e(),_c('b-td',{staticClass:"text-center"},[(
            evaluation.evaluation != null &&
            _vm.institution &&
            _vm.institution.kl_assessment &&
            _vm.user &&
            ((!_vm.user.groups.includes(6) && !_vm.user.groups.includes(7)) ||
              _vm.user.is_superuser)
          )?_c('span',{staticClass:"div-href",on:{"click":function($event){return _vm.redirectToEvaluation(evaluation.evaluation)}}},[_vm._v(" "+_vm._s(evaluation.name)+" ")]):_c('span',[_vm._v(" "+_vm._s(evaluation.name)+" ")])]),(
          _vm.institution &&
          _vm.institution.internal_use_id != 'duoc_uc' &&
          _vm.institution.kl_assessment
        )?_c('b-td',[_vm._l((_vm.filterTestsByEvaluation(evaluation.evaluation)),function(test){return _c('div',{key:test.id,staticClass:"text-left ml-1"},[_vm._v(" -"),(
              _vm.user &&
              ((!_vm.user.groups.includes(6) && !_vm.user.groups.includes(7)) ||
                _vm.user.is_superuser)
            )?_c('span',{staticClass:"div-href",on:{"click":function($event){return _vm.redirectToEvaluation(evaluation.evaluation, test.id)}}},[_vm._v(" "+_vm._s(test.title)+" ")]):_c('span',[_vm._v(" "+_vm._s(test.title)+" ")])])}),(_vm.filterTestsByEvaluation(evaluation.evaluation).length == 0)?_c('div',{staticClass:"text-center"},[_vm._v(" N/A ")]):_vm._e()],2):_vm._e(),_c('b-td',[_c('div',{staticClass:"rich-text-content px-2 description-evaluation",domProps:{"innerHTML":_vm._s(evaluation.description)}})]),_c('b-td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.epm_evaluation_types.find((x) => x.id == evaluation.type) ? _vm.epm_evaluation_types.find((x) => x.id == evaluation.type).value : "N/A"))]),_c('b-td',{staticClass:"text-center"},[(evaluation.type == 5)?_c('span',[_vm._v("-")]):(evaluation.type == 4)?_c('span',[_vm._v("-")]):_c('span',[_vm._v(" "+_vm._s(evaluation.weighing)+"% ")])]),(_vm.institution && _vm.institution.show_evaluation_criteria_macro)?_c('b-td',{class:evaluation.evaluation_criterias_macro.length > 0
            ? 'text-justify'
            : 'text-center'},[(evaluation.evaluation_criterias_macro.length > 0)?[_vm._l((_vm.evaluationCriteriasMacrosFilter),function(evaluation_criteria){return [(
                evaluation.evaluation_criterias_macro.includes(
                  evaluation_criteria.id
                )
              )?_c('span',{key:evaluation_criteria.id},[_c('span',{staticStyle:{"position":"absolute"}},[_vm._v(" "+_vm._s(evaluation_criteria.order)+" ")]),_c('SentenceContainer',{staticStyle:{"padding-left":"5px","padding-top":"2px"},attrs:{"Sentence":evaluation_criteria,"boundedTextContainer":false}})],1):_vm._e()]})]:[_vm._v(" N/A ")]],2):_vm._e(),_c('b-td',_vm._l((_vm.getMatterTimeAllocation(
            evaluation.id,
            true
          )),function(study_environment,index){return _c('div',{key:'evaluation' + evaluation.id + 'index' + index},[_c('div',[_c('strong',[_vm._v(_vm._s(study_environment.name))])]),_vm._l((study_environment.time_hours),function(time_allocation,index2){return _c('div',{key:'evaluation' +
              evaluation.id +
              'index' +
              index +
              'index2' +
              index2},[_c('div',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(time_allocation.name)+" "),_c('b',[_vm._v(_vm._s(time_allocation.hours)+" Hrs")])])])})],2)}),0),_c('b-td',_vm._l((_vm.getMatterTimeAllocation(
            evaluation.id,
            false
          )),function(study_environment,index){return _c('div',{key:'evaluation' + evaluation.id + 'index' + index},[_c('div',[_c('strong',[_vm._v(_vm._s(study_environment.name))])]),_vm._l((study_environment.time_hours),function(time_allocation,index2){return _c('div',{key:'-evaluation' +
              evaluation.id +
              '-index' +
              index +
              '-index2' +
              index2},[_c('div',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(time_allocation.name)+" "),_c('b',[_vm._v(_vm._s(time_allocation.hours)+" Hrs")])])])})],2)}),0),_c('b-td',{class:_vm.evaluationCriteriasMicros.filter((x) =>
            evaluation.evaluation_criterias_micro.includes(x.id)
          ).length > 0
            ? 'text-justify'
            : 'text-center'},[(
            _vm.evaluationCriteriasMicros.filter((x) =>
              evaluation.evaluation_criterias_micro.includes(x.id)
            ).length > 0
          )?[_vm._l((_vm.filterECMicro),function(study_unit){return [_c('div',{key:study_unit.id,class:{
                'evaluation-criteria-container':
                  _vm.institution && _vm.institution.internal_use_id == 'duoc_uc',
              }},[(
                  study_unit.options.filter((x) =>
                    evaluation.evaluation_criterias_micro.includes(x.id)
                  ).length > 0
                )?_c('strong',{key:study_unit.id,staticClass:"mr-2"},[_vm._v(_vm._s(study_unit.name)+" ")]):_vm._e(),_c('div',{key:'ec-container' + study_unit.id,staticClass:"ec-badge-container"},_vm._l((study_unit.options),function(evaluation_criteria){return _c('div',{key:study_unit.id + ':' + evaluation_criteria.id},[(
                      evaluation.evaluation_criterias_micro.includes(
                        evaluation_criteria.id
                      )
                    )?_c('div',{key:'div' + study_unit.id + ':' + evaluation_criteria.id,staticClass:"text-center"},[_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                        `${evaluation_criteria.full_sentence}`
                      ),expression:"\n                        `${evaluation_criteria.full_sentence}`\n                      ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"ec-micro-badge",attrs:{"id":`ec-badge-${evaluation.id}-${study_unit.id}-${evaluation_criteria.id}`}},[_vm._v(" "+_vm._s(study_unit.competence_unit_order != 999 ? study_unit.competence_unit_order + "." : "")+_vm._s(study_unit.order)+"."+_vm._s(evaluation_criteria.order)+" ")])],1):_vm._e()])}),0)])]})]:[_c('span',[_vm._v(" N/A ")])]],2),(_vm.allows_crud)?_c('b-td',{staticClass:"td-btn-wrapper"},[_c('ButtonWrapper',{attrs:{"item_id":evaluation.id},scopedSlots:_vm._u([{key:"btn-slot",fn:function(){return [(_vm.allows_crud)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive.top",value:(
                'Editar Recursos Bibliográficos'
              ),expression:"\n                'Editar Recursos Bibliográficos'\n              ",modifiers:{"v-secondary":true,"noninteractive":true,"top":true}}],attrs:{"size":"sm","fill":"white","variant":"none"},on:{"click":function($event){return _vm.$bvModal.show(
                  `m-bibliographic-resource-modal-${evaluation.id}`
                )}}},[_c('b-icon',{attrs:{"icon":"book"}})],1):_vm._e(),(_vm.allows_crud)?_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive.top",value:(
                'Editar Actividad/Evaluación'
              ),expression:"\n                'Editar Actividad/Evaluación'\n              ",modifiers:{"v-secondary":true,"noninteractive":true,"top":true}}],attrs:{"fill":"white"},on:{"click":function($event){return _vm.$bvModal.show(`edit-matter-evaluation-modal-${evaluation.id}`)}}}):_vm._e(),_c('button-delete',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive.top",value:(
                'Eliminar Actividad/Evaluación'
              ),expression:"\n                'Eliminar Actividad/Evaluación'\n              ",modifiers:{"v-secondary":true,"noninteractive":true,"top":true}}],on:{"click":function($event){return _vm.deleteEvaluation(evaluation.id, evaluation.type)}}})]},proxy:true}],null,true)}),_c('b-modal',{attrs:{"id":`m-bibliographic-resource-modal-${evaluation.id}`,"title":"Editar Recursos Bibliográficos","size":"xl","hide-footer":""}},[_c('MEvaluationBibliographicResource',{attrs:{"m_evaluation_id":evaluation.id,"matter_id":_vm.matter_id,"allows_crud":_vm.allows_crud}})],1),_c('b-modal',{attrs:{"id":`edit-matter-evaluation-modal-${evaluation.id}`,"title":"Editar Actividad/Evaluación","size":"xl","hide-footer":""}},[_c('MatterProgramEvaluationForm',{attrs:{"matter_id":_vm.matter_id,"Evaluation":evaluation,"EvaluationSituations":_vm.evaluation_situations,"evaluation_list":_vm.filterMatterEvaluations,"EvaluativeAgentTypes":_vm.evaluative_agent_types,"MatEvaStuEnvTimAll":_vm.mat_eva_stu_env_tim_all},on:{"updated":_vm.updatedEvaluation,"created_support_resource":_vm.createdSupportResource}})],1)],1):_vm._e()],1)}):_c('b-tr',[_c('b-td',{staticClass:"text-center",attrs:{"colspan":"8"}},[_vm._v("No hay actividades o evaluaciones para mostrar.")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }