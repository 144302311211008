<template>
  <div>
    <div class="d-flex mx-2 mb-2 container-select">
      <div class="text-selector align-self-center mr-2">
        Aprendizaje Esperado:
      </div>
      <b-form-select
        v-if="manual_assessment_type_id == null"
        disabled
        size="sm"
      >
      </b-form-select>
      <!-- competence -->
      <v-select
        v-if="manual_assessment_type_id == 1"
        :options="filter_competences"
        v-model="assessment_id"
        :reduce="(filter_competences) => filter_competences.assessment"
        @input="changeAssessment()"
        track-by="assessment"
        class="w-100 v-select-class"
        label="full_sentence"
      >
      </v-select>
      <!-- study_unit -->
      <v-select
        v-else-if="manual_assessment_type_id == 2"
        :options="filter_study_units"
        v-model="assessment_id"
        :reduce="(filter_study_units) => filter_study_units.assessment"
        @input="changeAssessment()"
        track-by="assessment"
        class="w-100 v-select-class"
        label="label"
      >
      </v-select>
      <!-- evaluation_criteria_micro -->
      <v-select
        v-else-if="manual_assessment_type_id == 3"
        :options="filter_evaluation_criterias_micro"
        v-model="assessment_id"
        :reduce="
          (filter_evaluation_criterias_micro) =>
            filter_evaluation_criterias_micro.assessment
        "
        @input="changeAssessment()"
        track-by="assessment"
        class="w-100 v-select-class"
        label="full_sentence"
      >
      </v-select>
      <!-- competence_unit -->
      <v-select
        v-else-if="manual_assessment_type_id == 4"
        :options="temp_competence_units"
        v-model="assessment_id"
        :reduce="(temp_competence_units) => temp_competence_units.assessment"
        @input="changeAssessment()"
        track-by="assessment"
        class="w-100 v-select-class"
        label="label"
      >
      </v-select>
    </div>
  </div>
</template>

<script>
import "vue-multiselect/dist/vue-multiselect.min.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "AssessmentRangeSelector",
  components: {
    vSelect,
  },
  props: {
    manual_assessment_type_id: {
      type: Number,
      required: true,
    },
    egress_profile_matter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      assessment_id: null,
    };
  },
  computed: {
    ...mapGetters({
      competences: names.COMPETENCES,
      profile_competences: names.PROFILE_COMPETENCES,
      competence_units: names.COMPETENCE_UNITS,
      studyUnits: names.STUDY_UNITS,
      evaluation_criterias_micro: names.EVALUATION_CRITERIAS,
    }),
    // manualAssessmentType() {
    //   let list = [
    //     {
    //       value: 1,
    //       label: `${this.$getVisibleNames(
    //         "mesh.competence",
    //         false,
    //         "Competencia"
    //       )}`,
    //       order: 1,
    //     },
    //     {
    //       value: 2,
    //       label: `${this.$getVisibleNames(
    //         "teaching.ramicro",
    //         false,
    //         "RA Micro"
    //       )}`,
    //       order: 3,
    //     },
    //     {
    //       value: 3,
    //       label: `${this.$getVisibleNames(
    //         "teaching.evaluationcriteriamicro",
    //         false,
    //         "Indicador de Logro"
    //       )}`,
    //       order: 4,
    //     },
    //   ];
    //   if (this.institution && this.institution.internal_use_id == "ciisa_uss")
    //     list.push({
    //       value: 4,
    //       label: `${this.$getVisibleNames(
    //         "teaching.tempcompetenceunit",
    //         false,
    //         "Unidad de competencia"
    //       )}`,
    //       order: 2,
    //     });
    //   list.sort((a, b) => {
    //     if (a.order > b.order) return 1;
    //     if (a.order < b.order) return -1;
    //     return 0;
    //   });
    //   return list;
    // },
    filter_profile_competences() {
      if (!this.egress_profile_matter) return [];
      return this.profile_competences.filter(
        (x) => x.egress_profile == this.egress_profile_matter.egress_profile
      );
    },
    filter_competences() {
      if (!this.egress_profile_matter) return [];
      let competence_ids = this.filter_profile_competences.map(
        (x) => x.competence
      );
      return this.competences.filter((x) => competence_ids.includes(x.id));
    },
    filter_study_units() {
      if (!this.egress_profile_matter) return [];
      return this.studyUnits
        .filter((x) => x.matter == this.egress_profile_matter.matter)
        .map((x) => {
          let competence_unit_order =
            (
              this.temp_competence_units.find(
                (a) => a.id == x.temp_competence_unit
              ) || {}
            ).order || "";
          if (competence_unit_order != "") competence_unit_order += ".";
          return {
            ...x,
            label: competence_unit_order + x.order + " " + x.full_sentence,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
    },
    filter_evaluation_criterias_micro() {
      if (!this.egress_profile_matter) return [];
      let study_units_ids = this.filter_study_units.map((x) => x.id);
      return this.evaluation_criterias_micro.filter((x) =>
        study_units_ids.includes(x.study_unit)
      );
    },

    temp_competence_units() {
      if (!this.egress_profile_matter) return [];
      return this.competence_units
        .filter((x) => x.matter == this.egress_profile_matter.matter)
        .map((x) => {
          return { ...x, label: x.order + ". " + x.full_sentence };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
    },
  },
  methods: {
    changeAssessment() {
      if (this.assessment_id != null)
        this.$emit("change_assessment", this.assessment_id);
    },
  },
  mounted() {
    if (
      this.manual_assessment_type_id == 4 &&
      this.temp_competence_units.length > 0
    ) {
      this.assessment_id = this.temp_competence_units[0].assessment;
      this.changeAssessment();
    } else if (
      this.manual_assessment_type_id == 2 &&
      this.filter_study_units.length > 0
    ) {
      this.assessment_id = this.filter_study_units[0].assessment;
      this.changeAssessment();
    }
  },
  created() {},
};
</script>

<style scoped>
.container-select {
  background-color: var(--kl-menu-color);
  border-radius: 3px;
  padding: 7px;
  color: white;
  align-items: center;
}
.container-select >>> .v-select-class {
  color: black;
}
.text-selector {
  width: 18%;
} 
.v-select-class >>> .vs__selected-options {
  /* Quita el espacio en blanco debajo del texto cuando se selecciona una opcion */
  flex-wrap: nowrap;
}
</style>
