<template>
  <div :style="containerStyle">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ContainerCell",
  props: {
    overflow_x: {
      type: String,
      default: null, // "auto" o "hidden"
    },
    overflow_y: {
      type: String,
      default: null, // "auto" o "hidden"
    },
    max_height: {
      type: String,
      default: "200px",
    },
  },
  data() {
    return {};
  },
  computed: {
    containerStyle() {
      return {
        ...(this.overflow_x && { overflowX: this.overflow_x }), // Agregar overflowX si se define
        ...(this.overflow_y && { overflowY: this.overflow_y }), // Agregar overflowY si se define
        maxHeight: this.max_height,
      };
    },
  },
  methods: {},
};
</script>

<style scoped>
</style>