
<template>
  <div>
    <div>
      <div class="descriptor-check-container mt-1">
        <!-- evaluatee_view -->
        <div
          v-if="user_id != null && evaluatee_view"
          :class="{
            'container-check-rubric':
              is_published && score_selected_evaluator == true,
          }"
          class="descriptor-checkbox"
        >
          <div
            v-if="
              coevaluator_id == null &&
              countRubricScoreEvaluator.length > 0 &&
              is_published
            "
          >
            <b-badge
              class="mr-2"
              variant="secondary"
              v-b-tooltip.v-secondary.top.noninteractive="
                `${
                  rubric.evaluatee_can_see_coevaluator
                    ? countRubricScoreEvaluator.length > 1
                      ? 'Coevaluadores: ' + getUsersRubricScoreEvaluatos
                      : 'Coevaluador: ' + getUsersRubricScoreEvaluatos
                    : ''
                }`
              "
              >{{ countRubricScoreEvaluator.length }}
            </b-badge>
          </div>
          <b-icon
            v-if="
              score_selected_evaluator == true &&
              is_published &&
              ((institution && institution.internal_use_id != 'duoc_uc') ||
                (evaluative_agent &&
                  !(
                    [
                      'duoc_coevaluacion_evaluative_agent',
                      'duoc_autoevaluacion_evaluative_agent',
                    ].includes(evaluative_agent.internal_use_id) ||
                    $equals(evaluative_agent.name, 'Coevaluación') ||
                    $equals(evaluative_agent.name, 'Autoevaluación')
                  )))
            "
            icon="check-square"
            variant="success"
            scale="1.2"
            class="mr-3"
            v-b-tooltip.v-secondary.left.noninteractive="
              `Respuesta del Docente`
            "
          ></b-icon>
          <b-checkbox
            v-if="
              coevaluator_id != null &&
              (test.student_can_auto_evaluate ||
                (evaluative_agent &&
                  (['duoc_coevaluacion_evaluative_agent'].includes(
                    evaluative_agent.internal_use_id
                  ) ||
                    $equals(evaluative_agent.name, 'Coevaluación'))))
            "
            v-model="score_selected_evaluatee"
            :disabled="
              score_selected_evaluatee == true ||
              !is_running_test ||
              finished_test ||
              is_published
            "
            @change="saveRubricScore"
          ></b-checkbox>
        </div>
        <!-- evaluator_view -->
        <div
          v-if="evaluator_view && user_id != null"
          class="descriptor-checkbox"
        >
          <div
            v-if="
              coevaluator_id == null && countRubricScoreEvaluator.length > 0
            "
          >
            <b-badge
              class="mr-2"
              variant="secondary"
              v-b-tooltip.v-secondary.top.noninteractive="
                `${
                  countRubricScoreEvaluator.length > 1
                    ? 'Coevaluadores: ' + getUsersRubricScoreEvaluatos
                    : 'Coevaluador: ' + getUsersRubricScoreEvaluatos
                }`
              "
              >{{ countRubricScoreEvaluator.length }}
            </b-badge>
          </div>
          <div
            :class="{
              'container-check-rubric':
                (test.student_can_auto_evaluate ||
                  (evaluative_agent &&
                    (['duoc_coevaluacion_evaluative_agent'].includes(
                      evaluative_agent.internal_use_id
                    ) ||
                      $equals(evaluative_agent.name, 'Coevaluación')))) &&
                score_selected_evaluatee == true,
            }"
          >
            <b-icon
              v-if="score_selected_evaluatee == true"
              icon="check-square"
              variant="success"
              scale="1.2"
              class="mr-3"
              v-b-tooltip.v-secondary.left.noninteractive="
                `
                ${
                  evaluative_agent &&
                  (['duoc_coevaluacion_evaluative_agent'].includes(
                    evaluative_agent.internal_use_id
                  ) ||
                    $equals(evaluative_agent.name, 'Coevaluación'))
                    ? 'Respuesta del Coevaluador'
                    : 'Respuesta del Evaluado'
                }`
              "
            ></b-icon>
            <b-checkbox
              v-if="
                (institution && institution.internal_use_id != 'duoc_uc') ||
                (evaluative_agent &&
                  !(
                    [
                      'duoc_coevaluacion_evaluative_agent',
                      'duoc_autoevaluacion_evaluative_agent',
                    ].includes(evaluative_agent.internal_use_id) ||
                    $equals(evaluative_agent.name, 'Coevaluación') ||
                    $equals(evaluative_agent.name, 'Autoevaluación')
                  ))
              "
              class="checkbox-check-rubric"
              v-model="score_selected_evaluator"
              :disabled="
                score_selected_evaluator == true || !closedEvaluationScore
              "
              @change="saveRubricScore"
            ></b-checkbox>
          </div>
        </div>
      </div>
      <!-- preview_evaluation -->
      <div
        v-if="
          preview_evaluation &&
          (test.student_can_auto_evaluate ||
            (evaluative_agent &&
              (['duoc_coevaluacion_evaluative_agent'].includes(
                evaluative_agent.internal_use_id
              ) ||
                $equals(evaluative_agent.name, 'Coevaluación')))) &&
          !evaluatee_view &&
          !evaluator_view
        "
      >
        <b-checkbox
          class="checkbox-check-rubric"
          :disabled="score_selected_preview_evaluation"
          v-model="score_selected_preview_evaluation"
          @change="previewEvaluationChange"
        ></b-checkbox>
      </div>
      <div
        class="descriptor-div"
        v-if="rubric.allow_criteria_performance_level_input"
      >
        <template v-if="allows_crud">
          <div
            v-b-tooltip.v-secondary="'Editar Descripción'"
            class="rich-text-content mb-1"
            :class="{
              'cell-form-description-with-content':
                rubric_description.title.length > 0,
              'cell-form-description': rubric_description.title.length == 0,
            }"
            v-html="rubric_description.title"
            @click="openModalRubricPerformanceLevel"
          ></div>
          <b-modal
            :id="`edit-cell-description-modal-${rubric_description.id}-${container_instrument_id}`"
            title="Editar Descripción de la Evaluación"
            size="lg"
            hide-footer
            no-enforce-focus
            @hide="hideModaltoMatterProgram"
          >
            <NewRichTextEditor
              :Object="rubric_description"
              :Text="rubric_description.title"
              @save="patchRubricDescriptorCell"
              @close="
                $bvModal.hide(
                  `edit-cell-description-modal-${rubric_description.id}-${container_instrument_id}`
                )
              "
            ></NewRichTextEditor>
          </b-modal>
        </template>
        <template v-else>
          <div
            class="rubric-descriptor-cell rich-text-content"
            v-html="rubric_description.title"
          ></div
        ></template>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId, toast } from "@/utils/utils";

import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "RubricDescriptorCell",
  components: {
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
  },
  props: {
    rubric: {
      type: Object,
      required: true,
    },
    container_instrument_id: {
      type: String,
    },
    rubric_criteria_id: {
      type: Number,
      required: true,
    },
    rubric_achievement_id: {
      type: Number,
      required: true,
    },
    RubricDescriptor: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          title: "",
          description: "",
          rubric_criteria: this.rubric_criteria_id,
          rubric_performance_level: this.rubric_achievement_id,
        };
      },
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    evaluatee_view: {
      type: Boolean,
      default: false,
    },
    is_running_test: {
      type: Boolean,
      default: false,
    },
    finished_test: {
      type: Boolean,
      default: false,
    },
    is_published: {
      type: Boolean,
      default: false,
    },
    preview_evaluation: {
      type: Boolean,
    },
    evaluator_view: {
      type: Boolean,
      default: false,
    },
    user_id: {
      type: Number,
      required: false,
    },
    coevaluator_id: {
      type: Number,
      required: false,
    },
    // para restringir que el evaluador pueda colocar notas fuera del periodo actual con respecto al de la sección.
    closedEvaluationScore: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rubric_description: { ...this.RubricDescriptor },
      score_selected_evaluator: false,
      score_selected_evaluatee: false,
      score_selected_preview_evaluation: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      users: names.USERS,
      tests: names.NEW_TESTS,
      rubricDescriptors: names.NEW_RUBRIC_DESCRIPTORS,
      EvaRubCriPerLevScore: names.EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVELS,
      EvaRubCriPerLevEvaluators:
        names.EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATORS,
      // evaluatee_rubrics: names.EVALUATEE_RUBRICS,
      evaluative_agents: names.NEW_TEST_TYPES,
      rubricCriterias: names.NEW_RUBRIC_CRITERIAS,
      evaluations: names.NEW_EVALUATIONS,
      egress_profile_matters: names.EGRESS_PROFILES_MATTERS,
      sections: names.SECTIONS,
    }),
    rubricCriteria() {
      return this.rubricCriterias.find((x) => x.id == this.rubric_criteria_id);
    },
    // evaluateeRubric() {
    //   if (this.evaluatee_view && this.user && this.rubric)
    //     return this.evaluatee_rubrics.find(
    //       (x) => x.evaluatee == this.user.id && x.rubric == this.rubric.id
    //     );
    //   return null;
    // },
    test() {
      return this.tests.find((x) => x.id == this.rubric.test);
    },
    evaluation() {
      if (!this.test) return [];
      return this.evaluations.find((x) => x.id == this.test.evaluation);
    },
    section() {
      if (this.evaluation && this.evaluation.scope == 1)
        return this.sections.find((x) => x.id == this.evaluation.scope_id);
      else return null;
    },
    egressProfileMatter() {
      if (
        this.evaluation &&
        this.evaluation.scope == 2 &&
        this.evaluation.scope_id != null
      ) {
        return this.egress_profile_matters.find(
          (x) => x.id == this.evaluation.scope_id
        );
      }
      if (
        this.evaluation &&
        this.evaluation.scope == 1 &&
        this.section &&
        this.section.egress_profile_matter != null
      ) {
        return this.egress_profile_matters.find(
          (x) => x.id == this.section.egress_profile_matter
        );
      }
      return null;
    },
    evaluative_agent() {
      if (!this.test) return null;
      return this.evaluative_agents.find(
        (x) => this.test.evaluative_agent == x.id
      );
    },
    rubricDescriptor() {
      return this.rubricDescriptors.find(
        (x) =>
          x.rubric_criteria == this.rubric_criteria_id &&
          x.rubric_performance_level == this.rubric_achievement_id
      );
    },
    rubricScore() {
      if (!this.user_id) return null;
      return this.EvaRubCriPerLevScore.find(
        (x) =>
          x.rubric_criteria == this.rubric_criteria_id &&
          x.evaluatee == this.user_id
      );
    },
    rubricScoreEvaluator() {
      if (!this.coevaluator_id || !this.rubricScore) return null;
      return this.EvaRubCriPerLevEvaluators.find(
        (x) =>
          x.evaluatee_rubric_criteria_performance_level ==
            this.rubricScore.id && x.evaluator == this.coevaluator_id
      );
    },
    countRubricScoreEvaluator() {
      if (!this.rubricScore) return [];
      return this.EvaRubCriPerLevEvaluators.filter(
        (x) =>
          x.evaluatee_rubric_criteria_performance_level ==
            this.rubricScore.id &&
          x.rubric_performance_level == this.rubric_achievement_id
      );
    },
    getUsersRubricScoreEvaluatos() {
      return this.users
        .filter((u) =>
          this.countRubricScoreEvaluator.map((x) => x.evaluator).includes(u.id)
        )
        .map((x) =>
          x.user_data
            ? x.user_data.identifier + " " + x.first_name + " " + x.last_name
            : x.first_name + " " + x.last_name
        )
        .join(", ");
    },
  },
  methods: {
    openModalRubricPerformanceLevel() {
      this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
      this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);

      if (this.rubric_description) {
        this.$nextTick(() => {
          this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
            rubric_criteria_id: this.rubric_description.rubric_criteria,
            rubric_achievement_id:
              this.rubric_description.rubric_performance_level,
            extra_label: `Descripción del  ${this.$getVisibleNames(
              "evaluations2.rubricperformancelevel",
              false,
              "Niveles de Logro"
            )}`,
            intelligence_option: 8,
            sub_option: 1,
          });
          this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
            visible_intelligence_options: [8],
            disabled_selected_rubric_criteria: true,
            disabled_selected_rubric_achievement: true,
          });
        });
        this.$bvModal.show(
          `edit-cell-description-modal-${this.rubric_description.id}-${this.container_instrument_id}`
        );
      }
    },
    hideModaltoMatterProgram() {
      this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
      this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);
      this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
        visible_intelligence_options: [7],
      });

      if (
        this.evaluation &&
        this.evaluation.scope == 3 &&
        this.evaluation.scope_id != null
      ) {
        this.$nextTick(() => {
          this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
            matter_id: this.evaluation.scope_id,
            extra_context: "Para la Evaluación",
            extra_label: "",
            show_message_evaluation_view: true,
            intelligence_option: 7,
          });
        });
      }
      if (
        this.egressProfileMatter &&
        this.evaluation &&
        (this.evaluation.scope == 2 || this.evaluation.scope == 1) &&
        this.evaluation.scope_id != null
      ) {
        this.$nextTick(() => {
          this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
            matter_id: this.egressProfileMatter.matter,
            extra_context: "Para la Evaluación",
            show_message_evaluation_view: true,
            intelligence_option: 7,
          });
        });
      }
    },
    patchRubricDescriptorCell(object, text) {
      this.rubric_description.title = text;
      this.saveRubricDescriptor();
    },
    saveRubricDescriptor() {
      if (isNaN(this.rubric_description.id)) this.createRubricDescriptor();
      else this.patchRubricDescriptor();
    },
    createRubricDescriptor() {
      this.$store
        .dispatch(names.POST_NEW_RUBRIC_DESCRIPTOR, this.rubric_description)
        .then((response) => {
          this.rubric_description = response;
          toast("Descriptor creado.");
        });
    },
    patchRubricDescriptor() {
      this.$store
        .dispatch(names.PATCH_NEW_RUBRIC_DESCRIPTOR, {
          rubric_descriptor_id: this.rubric_description.id,
          item: {
            title: this.rubric_description.title,
          },
        })
        .then(() => {
          toast("Descriptor actualizado.");
        });
    },
    checkSelectedEvaluatee() {
      if (
        this.rubricScore &&
        this.rubricScoreEvaluator &&
        this.user_id &&
        (this.evaluatee_view || this.evaluator_view)
      ) {
        if (
          this.rubricScoreEvaluator.rubric_performance_level ==
            this.rubric_achievement_id &&
          this.rubricScore.evaluatee == this.user_id &&
          this.rubricScoreEvaluator.evaluator == this.coevaluator_id
        ) {
          this.score_selected_evaluatee = true;
          return;
        }
      }
      this.score_selected_evaluatee = false;
    },
    checkSelectedEvaluator() {
      if (
        this.rubricScore &&
        this.user_id &&
        (this.evaluatee_view || this.evaluator_view)
      ) {
        if (
          this.rubricScore.rubric_performance_level ==
            this.rubric_achievement_id &&
          this.rubricScore.evaluatee == this.user_id
        ) {
          this.score_selected_evaluator = true;
          return;
        }
      }
      this.score_selected_evaluator = false;
    },
    checkSelectedPreviewEvaluation() {
      if (
        this.rubricCriteria &&
        !this.evaluatee_view &&
        !this.evaluator_view &&
        this.preview_evaluation
      ) {
        if (
          this.rubric_achievement_id ==
          this.rubricCriteria.preview_evaluation_achievement_id
        )
          this.score_selected_preview_evaluation = true;
        else this.score_selected_evaluatee = false;
      }
    },
    previewEvaluationChange() {
      this.rubricCriteria.preview_evaluation_achievement_id =
        this.rubric_achievement_id;
      this.$emit("resetRubricDescriptorCell");
    },
    saveRubricScore() {
      if (
        (this.closedEvaluationScore && this.evaluator_view) ||
        (this.is_running_test && this.evaluatee_view && !this.finished_test)
      ) {
        if (this.rubricScore) {
          if (this.evaluator_view) {
            const payload = {
              evaluatee_rubric_criteria_performance_level_id:
                this.rubricScore.id,
              item: {
                rubric_performance_level: this.rubric_achievement_id,
              },
            };
            this.patchRubricScore(payload);
          } else if (this.evaluatee_view) {
            if (!this.rubricScoreEvaluator)
              this.createdRubricScoreEvaluator({
                evaluator: this.coevaluator_id,
                evaluatee_rubric_criteria_performance_level:
                  this.rubricScore.id,
                rubric_performance_level: this.rubric_achievement_id,
              });
            else
              this.patchRubricScoreEvaluator({
                evaluatee_rubric_criteria_performance_level_evaluator_id:
                  this.rubricScoreEvaluator.id,
                item: {
                  rubric_performance_level: this.rubric_achievement_id,
                },
              });
          }
        } else {
          let rub_cri_per_lev_score = null;
          if (this.evaluator_view)
            rub_cri_per_lev_score = {
              rubric_criteria: this.rubric_criteria_id,
              rubric_performance_level: this.rubric_achievement_id,
              evaluatee: this.user_id,
            };
          else if (this.evaluatee_view)
            rub_cri_per_lev_score = {
              rubric_criteria: this.rubric_criteria_id,
              evaluatee: this.user_id,
            };
          if (rub_cri_per_lev_score != null)
            this.createdRubricScore(rub_cri_per_lev_score);
        }
      }
    },
    createdRubricScore(rub_cri_per_lev_score) {
      this.$store
        .dispatch(
          names.POST_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL,
          rub_cri_per_lev_score
        )
        .then((response) => {
          toast("Puntaje actualizado.");
          if (this.evaluator_view) this.$emit("created_score", response);
          if (this.evaluatee_view)
            this.createdRubricScoreEvaluator({
              evaluator: this.coevaluator_id,
              evaluatee_rubric_criteria_performance_level: response.id,
              rubric_performance_level: this.rubric_achievement_id,
            });
        });
    },
    patchRubricScore(payload) {
      this.$store
        .dispatch(
          names.PATCH_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL,
          payload
        )
        .then((response) => {
          toast("Puntaje actualizado.");
          this.$emit("updated_score", response);
        });
    },
    createdRubricScoreEvaluator(rub_cri_per_lev_score) {
      this.$store
        .dispatch(
          names.POST_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATOR,
          rub_cri_per_lev_score
        )
        .then((response) => {
          toast("Puntaje actualizado.");
          this.$emit("created_score", response);
        });
    },
    patchRubricScoreEvaluator(payload) {
      this.$store
        .dispatch(
          names.PATCH_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATOR,
          payload
        )
        .then((response) => {
          toast("Puntaje actualizado.");
          this.$emit("updated_score", response);
        });
    },
  },
  watch: {
    rubricScore() {
      this.checkSelectedEvaluator();
    },
    rubricScoreEvaluator() {
      this.checkSelectedEvaluatee();
    },
    rubricCriteria() {
      this.checkSelectedPreviewEvaluation();
    },
    rubricDescriptor(rubric_descriptor) {
      if (
        rubric_descriptor != null &&
        !isNaN(rubric_descriptor.id) &&
        isNaN(this.rubric_description.id)
      ) {
        this.rubric_description = rubric_descriptor;
      }
    },
  },
  mounted() {
    this.checkSelectedEvaluator();
    this.checkSelectedEvaluatee();
    this.checkSelectedPreviewEvaluation();
    if (
      this.rubric &&
      this.rubric.allow_criteria_performance_level_input &&
      this.rubricDescriptor
    )
      this.rubric_description = this.rubricDescriptor;
  },
  created() {
    // if (this.rubric.allow_criteria_performance_level_input) {
    //   this.$store
    //     .dispatch(names.FETCH_NEW_RUBRIC_DESCRIPTORS_BY, {
    //       rubric_criteria_id: this.rubric_criteria_id,
    //       rubric_achievement_id: this.rubric_achievement_id,
    //     })
    //     .then((response) => {
    //       if (response) {
    //         this.rubric_description = response;
    //       }
    //     });
    // }
  },
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>

<style scoped>
.descriptor-check-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
}
.checkbox-check-rubric {
  justify-content: right;
}
.container-check-rubric {
  display: flex;
  align-items: center;
  justify-content: right;
  min-width: 60px;
}
.rubric-descriptor-cell {
  padding-top: 0px;
  padding-left: 3px;
  overflow: auto;
  max-height: 250px;
}
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}
.cell-form-description {
  width: 95%;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.875rem;
  min-height: 100px;
  max-height: 250px;
  overflow-y: auto;
  padding: 0.25rem 0.25rem;
  line-height: 1.5;
}
.cell-form-description-with-content {
  width: 95%;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.875rem;
  min-height: 250px;
  max-height: 250px;
  overflow-y: auto;
  padding: 0.25rem 0.25rem;
  line-height: 1.5;
}
.descriptor-div {
  display: flex;
  justify-content: center;
}
@media print {
  .rubric-descriptor-cell {
    overflow: hidden !important;
    max-height: 100% !important;
  }
}
@media (max-width: 1100px) {
  .rubric-descriptor-cell {
    max-height: 213px;
    overflow: auto;
  }
}
@media (max-width: 750px) {
  .rubric-descriptor-cell {
    max-height: 413px;
  }
  .cell-form-description {
    max-height: 500px;
    overflow: auto;
  }
}
</style>

