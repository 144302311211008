var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-buttons-report mb-1"},[_c('div',{staticClass:"align-content-end"}),_c('div',[_c('div',{staticClass:"noprint"},[_c('div',{staticClass:"d-flex ml-auto"},[(
              _vm.assessment_range_report && _vm.assessment_range_report.assessments
            )?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"ml-auto mr-0 d-flex"},[_c('strong',{staticClass:"mr-2 align-content-center",staticStyle:{"font-size":"10pt"}},[_vm._v(" FORMATIVAS ")]),_c('b-form-checkbox',{staticClass:"align-content-center",attrs:{"switch":""},model:{value:(_vm.assessment_switches_type),callback:function ($$v) {_vm.assessment_switches_type=$$v},expression:"assessment_switches_type"}},[_c('strong',{staticStyle:{"font-size":"10pt"}},[_vm._v("SUMATIVAS")])])],1)]):_vm._e(),_c('ButtonDownload',{class:`ml-1`,attrs:{"tooltip_text":"Reporte XLSX","click_button":_vm.DownloadExcel}})],1)])])]),(_vm.is_loading)?_c('b-skeleton-table',{attrs:{"rows":9,"columns":10,"table-props":{ bordered: true, striped: true }}}):_vm._e(),_c('div',{staticClass:"table-wrapper"},[(
        !_vm.is_loading &&
        _vm.assessment_range_report &&
        _vm.assessment_range_report.assessments &&
        _vm.assessment_range_report.evaluatees
      )?_c('b-table-simple',{staticClass:"table-assesment",attrs:{"bordered":"","no-border-collapse":"","small":"","responsive":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"text-center align-middle primary-header",attrs:{"colspan":8 + _vm.assessment_range_report.assessments.length}},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('span',{staticClass:"align-content-center"},[_vm._v(" REPORTE DE ASSESSMENT POR ASIGNATURA DE PERFIL DE EGRESO ")]),(_vm.displayList.length > 0)?_c('VisibleColumns',{class:`ml-1`,attrs:{"id_button":`col-display-1-${_vm.main_assessment}-${_vm.section_ids.join(
                  '-'
                )}`,"tooltip_text":"Visualizar columnas"},scopedSlots:_vm._u([{key:"popover-content",fn:function(){return [(_vm.assessment_range_report != null)?[_vm._l((_vm.displayList),function(display){return [_c('div',{key:'index' + display.index + 'id' + display.id},[_c('div',{staticClass:"noprint"},[_c('b-form-checkbox',{attrs:{"id":`column-check-${display.index}`,"name":"checkbox-1"},model:{value:(display.visible),callback:function ($$v) {_vm.$set(display, "visible", $$v)},expression:"display.visible"}},[_vm._v(" "+_vm._s(display.title)+" ")])],1)])]})]:_vm._e()]},proxy:true}],null,false,733444062)}):_vm._e()],1)])],1),_c('b-tr',[(
              _vm.displayList.some((x) => _vm.$equals(x.title, 'N°') && x.visible)
            )?_c('b-th',{staticClass:"text-center align-middle secondary-header"},[_vm._v(" N° ")]):_vm._e(),(
              _vm.displayList.some(
                (x) => _vm.$equals(x.title, 'APELLIDO') && x.visible
              )
            )?_c('b-th',{staticClass:"text-center align-middle secondary-header"},[_vm._v(" APELLIDO ")]):_vm._e(),(
              _vm.displayList.some((x) => _vm.$equals(x.title, 'NOMBRE') && x.visible)
            )?_c('b-th',{staticClass:"text-center align-middle secondary-header"},[_vm._v(" NOMBRE ")]):_vm._e(),(
              _vm.displayList.some(
                (x) => _vm.$equals(x.title, 'IDENTIFICADOR') && x.visible
              )
            )?_c('b-th',{staticClass:"text-center align-middle secondary-header"},[_vm._v(" IDENTIFICADOR ")]):_vm._e(),(
              _vm.displayList.some(
                (x) => _vm.$equals(x.title, 'AÑO DE INGRESO') && x.visible
              )
            )?_c('b-th',{staticClass:"text-center align-middle secondary-header"},[_vm._v(" AÑO DE INGRESO ")]):_vm._e(),(
              _vm.displayList.some(
                (x) =>
                  _vm.$equals(
                    x.title,
                    _vm.$getVisibleNames(
                      'mesh.egressprofile',
                      false,
                      'Perfil de Egreso'
                    ).toUpperCase()
                  ) && x.visible
              )
            )?_c('b-th',{staticClass:"text-center align-middle secondary-header"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofile", false, "Perfil de Egreso" ).toUpperCase())+" ")]):_vm._e(),(
              _vm.displayList.some(
                (x) =>
                  _vm.$equals(
                    x.title,
                    _vm.$getVisibleNames(
                      'teaching.section',
                      false,
                      'Sección'
                    ).toUpperCase()
                  ) && x.visible
              ) && _vm.section_ids.length > 1
            )?_c('b-th',{staticClass:"text-center align-middle secondary-header"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "teaching.section", false, "Sección" ).toUpperCase())+" ")]):_vm._e(),(
              _vm.displayList.some(
                (x) => _vm.$equals(x.title, 'NOTA') && x.visible
              ) && _vm.grade == true
            )?_c('b-th',{staticClass:"text-center align-middle secondary-header"},[_vm._v(" NOTA ")]):_vm._e(),_vm._l((_vm.assessment_range_report.assessments),function(assessment_label){return [(
                _vm.displayList.some(
                  (x) =>
                    _vm.$equals(
                      `${x.id} ${x.title}`,
                      `${
                        assessment_label.id
                      } ${assessment_label.assessment_type_name.toUpperCase()} ${
                        assessment_label.order
                      }`
                    ) && x.visible
                )
              )?_c('b-th',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                assessment_label.full_sentence
              ),expression:"\n                assessment_label.full_sentence\n              ",modifiers:{"v-secondary":true,"noninteractive":true}}],key:`header-assessment-${assessment_label.id}`,staticClass:"text-center align-middle secondary-header"},[_vm._v(" "+_vm._s(assessment_label.assessment_type_short_name.toUpperCase())+" "+_vm._s(assessment_label.order)+" "),(assessment_label.weighing)?_c('span',[_vm._v(" "+_vm._s(assessment_label.weighing ? assessment_label.weighing : "0")+"% ")]):_vm._e(),_vm._v(" (%) ")]):_vm._e()]})],2)],1),_c('b-tbody',_vm._l((_vm.assessment_range_report.evaluatees),function(evaluatee_assessment,index){return _c('b-tr',{key:`row-${evaluatee_assessment.id}-${evaluatee_assessment.section_id}-${index}`},[(
              _vm.displayList.some((x) => _vm.$equals(x.title, 'N°') && x.visible)
            )?_c('b-td',{staticClass:"text-center aling-middle"},[_vm._v(" "+_vm._s(index + 1)+" ")]):_vm._e(),(
              _vm.displayList.some(
                (x) => _vm.$equals(x.title, 'APELLIDO') && x.visible
              )
            )?_c('b-td',{staticClass:"text-left aling-middle"},[_vm._v(" "+_vm._s(evaluatee_assessment.last_name))]):_vm._e(),(
              _vm.displayList.some((x) => _vm.$equals(x.title, 'NOMBRE') && x.visible)
            )?_c('b-td',{staticClass:"text-left aling-middle"},[_vm._v(" "+_vm._s(evaluatee_assessment.first_name))]):_vm._e(),(
              _vm.displayList.some(
                (x) => _vm.$equals(x.title, 'IDENTIFICADOR') && x.visible
              )
            )?_c('b-td',{staticClass:"text-center aling-middle"},[_vm._v(" "+_vm._s(evaluatee_assessment.identifier)+" ")]):_vm._e(),(
              _vm.displayList.some(
                (x) => _vm.$equals(x.title, 'AÑO DE INGRESO') && x.visible
              )
            )?_c('b-td',{staticClass:"text-center aling-middle"},[_vm._v(" "+_vm._s(evaluatee_assessment.cohort))]):_vm._e(),(
              _vm.displayList.some(
                (x) =>
                  _vm.$equals(
                    x.title,
                    _vm.$getVisibleNames(
                      'mesh.egressprofile',
                      false,
                      'Perfil de Egreso'
                    ).toUpperCase()
                  ) && x.visible
              )
            )?_c('b-td',{staticClass:"text-left aling-middle"},[_vm._v(" "+_vm._s(evaluatee_assessment.egress_profile)+" ")]):_vm._e(),(
              _vm.displayList.some(
                (x) =>
                  _vm.$equals(
                    x.title,
                    _vm.$getVisibleNames(
                      'teaching.section',
                      false,
                      'Sección'
                    ).toUpperCase()
                  ) && x.visible
              ) && _vm.section_ids.length > 1
            )?_c('b-td',{staticClass:"text-left aling-middle"},[_vm._v(" "+_vm._s(evaluatee_assessment.section_name)+" ")]):_vm._e(),(
              _vm.displayList.some(
                (x) => _vm.$equals(x.title, 'NOTA') && x.visible
              ) && _vm.grade == true
            )?_c('b-td',{staticClass:"aling-middle"},[_c('div',[_vm._v(" "+_vm._s(evaluatee_assessment.assessments.find( (assessment) => assessment.assessment_type_name == "Asignatura" ).section_grade || "-")+" ")])]):_vm._e(),_vm._l((evaluatee_assessment.assessments),function(assessment,index2){return [(
                _vm.displayList.some(
                  (x) =>
                    _vm.$equals(
                      `${x.id} ${x.title}`,
                      `${
                        assessment.assessment_id
                      } ${assessment.assessment_type_name.toUpperCase()} ${
                        assessment.assessment_order
                      }`
                    ) && x.visible
                )
              )?[(_vm.assessment_switches_type)?[_c('b-td',{key:`row-assessment-sumative-${evaluatee_assessment.id}-${assessment.id}-${index2}`,style:(`background-color:${assessment.summative_assessment_color} !important`)},[(assessment.summative_assessment >= 0)?_c('span',[_vm._v(" "+_vm._s(_vm._f("Round")(assessment.summative_assessment))+" ")]):_c('span',[_vm._v(" "+_vm._s(assessment.summative_assessment))])])]:[_c('b-td',{key:`row-assessment-formative-${evaluatee_assessment.id}-${assessment.id}-${index2}`,style:(`background-color:${assessment.formative_assessment_color} !important`)},[(assessment.formative_assessment >= 0)?_c('span',[_vm._v(" "+_vm._s(_vm._f("Round")(assessment.formative_assessment))+" ")]):_c('span',[_vm._v(" "+_vm._s(assessment.formative_assessment))])])]]:_vm._e()]})],2)}),1)],1):_vm._e()],1),(_vm.assessment_range_report && _vm.assessment_range_report.assessments)?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"ml-auto mr-0 d-flex"},[_c('strong',{staticClass:"mr-2 align-content-center",staticStyle:{"font-size":"10pt"}},[_vm._v(" FORMATIVAS ")]),_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.assessment_switches_type),callback:function ($$v) {_vm.assessment_switches_type=$$v},expression:"assessment_switches_type"}},[_c('strong',{staticStyle:{"font-size":"10pt"}},[_vm._v("SUMATIVAS")])])],1)]):_vm._e(),_c('div',{staticClass:"table-wrapper"},[(
        _vm.assessment_range_report &&
        _vm.assessment_range_report.assessments &&
        _vm.assessment_range_report.evaluatees &&
        _vm.assessment_range_report.achievement_ranges
      )?_c('b-table-simple',{staticClass:"table-assesment-range-table",attrs:{"bordered":"","no-border-collapse":"","small":"","responsive":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"primary-header",attrs:{"colspan":_vm.assessment_range_report.assessments.length + 2}},[_vm._v("RANGOS DE LOGROS ")])],1),_c('b-tr',[_c('b-th',{staticClass:"secondary-header"},[_vm._v("NOMBRE")]),_c('b-th',{staticClass:"secondary-header range-column"},[_vm._v("RANGO (%)")]),_vm._l((_vm.assessment_range_report.assessments),function(assessment_label){return _c('b-th',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
              assessment_label.full_sentence
            ),expression:"\n              assessment_label.full_sentence\n            ",modifiers:{"v-secondary":true,"noninteractive":true}}],key:`header-assessment-${assessment_label.id}`,staticClass:"text-center align-middle secondary-header"},[_vm._v(" "+_vm._s(assessment_label.assessment_type_short_name.toUpperCase())+" "+_vm._s(assessment_label.order)+" "),(assessment_label.weighing)?_c('span',[_vm._v(" "+_vm._s(assessment_label.weighing)+"% ")]):_vm._e()])})],2)],1),_c('b-tbody',_vm._l((_vm.assessment_range_report.achievement_ranges),function(achievement_range){return _c('b-tr',{key:`row-${achievement_range.id}`},[_c('b-td',{staticClass:"text-center aling-middle"},[_vm._v(_vm._s(achievement_range.name))]),_c('b-td',{staticClass:"text-center aling-middle",style:(`background-color:${achievement_range.color} !important`)},[_c('div',[_vm._v(" "+_vm._s(achievement_range.start_achievement)+" "),(achievement_range.include_start_achievement)?_c('span',[_vm._v("≤")]):_c('span',[_vm._v("<")]),_vm._v(" x "),(achievement_range.include_finish_achievement)?_c('span',[_vm._v("≤")]):_c('span',[_vm._v("<")]),_vm._v(" "+_vm._s(achievement_range.finish_achievement)+" ")])]),_vm._l((_vm.assessment_range_report.assessments),function(assessment_range){return _c('b-td',{key:`row-${achievement_range.id}columns-${assessment_range.id}`},[_vm._v(" "+_vm._s(_vm.countAchievementRange( achievement_range.id, assessment_range.achievement_ranges ))+" ")])})],2)}),1)],1):_vm._e()],1),(
      _vm.main_assessment != null &&
      !_vm.is_loading &&
      _vm.assessment_range_report &&
      _vm.assessment_range_report.assessments &&
      _vm.assessment_range_report.evaluatees
    )?_c('TeacherInfoTable',{attrs:{"sections_ids":_vm.section_ids}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }