<template>
  <div>
    <AchievementRangeGroupSelector
      v-if="egress_profile_matter"
      :egress_profile_id="egress_profile_matter.egress_profile"
      @selected_range_group="selectedRangeGroup"
    ></AchievementRangeGroupSelector>
    <b-tabs content-class="mt-3" v-model="tab_index">
      <b-tab
        v-if="institution && institution.internal_use_id != 'duoc_uc'"
        :title="`${$getVisibleNames(
          'mesh.egressprofilematter',
          false,
          'Asignatura'
        )}`"
      >
        <AssessmentRangeTable
          v-if="egress_profile_matter && show_assessment_range_table"
          :key="selected_achievement_range_group"
          :section_ids="sections_ids"
          :main_assessment="egress_profile_matter.assessment"
          :achievement_range_group="selected_achievement_range_group"
          :grade="true"
          :campuses_="campuses_"
          :egress_profiles_="egress_profiles_"
          :levels_="levels_"
          :matters_="matters_"
          :periods_="periods_"
          :sections_="sections_"
        ></AssessmentRangeTable
      ></b-tab>
      <b-tab
        v-else-if="
          institution && institution.internal_use_id == 'duoc_uc' && matter
        "
        :title="`${$getVisibleNames(
          'mesh.egressprofilematter',
          false,
          'Asignatura'
        )}`"
      >
        <AssessmentRangeTable
          v-if="show_assessment_range_table"
          :key="selected_achievement_range_group"
          :section_ids="sections_ids"
          :main_assessment="matter.assessment"
          :achievement_range_group="selected_achievement_range_group"
          :grade="true"
          :campuses_="campuses_"
          :egress_profiles_="egress_profiles_"
          :levels_="levels_"
          :matters_="matters_"
          :periods_="periods_"
          :sections_="sections_"
        ></AssessmentRangeTable>
      </b-tab>
      <b-tab
        v-if="institution && institution.internal_use_id == 'ciisa_uss'"
        :title="
          $getVisibleNames(
            'teaching.tempcompetenceunit',
            false,
            'Unidad de competencia'
          )
        "
      >
        <!-- UDC == 4 == manual_assessment_type_id -->
        <AssessmentRangeSelector
          :manual_assessment_type_id="4"
          :egress_profile_matter="egress_profile_matter"
          @change_assessment="changeAssessment4"
        ></AssessmentRangeSelector>
        <AssessmentRangeTable
          v-if="assessment_id_4 != null && show_assessment_range_table"
          :key="`${key_assessment_range_table_4}-${selected_achievement_range_group}`"
          :section_ids="sections_ids"
          :main_assessment="assessment_id_4"
          :achievement_range_group="selected_achievement_range_group"
          :campuses_="campuses_"
          :egress_profiles_="egress_profiles_"
          :levels_="levels_"
          :matters_="matters_"
          :periods_="periods_"
          :sections_="sections_"
        ></AssessmentRangeTable>
      </b-tab>
      <b-tab :title="$getVisibleNames('teaching.ramicro', false, 'RA MICRO')">
        <!-- UDC == 2 == manual_assessment_type_id -->
        <AssessmentRangeSelector
          :manual_assessment_type_id="2"
          :egress_profile_matter="egress_profile_matter"
          @change_assessment="changeAssessment2"
        ></AssessmentRangeSelector>
        <AssessmentRangeTable
          v-if="assessment_id_2 != null && show_assessment_range_table"
          :key="`${key_assessment_range_table_2}-${selected_achievement_range_group}`"
          :section_ids="sections_ids"
          :main_assessment="assessment_id_2"
          :achievement_range_group="selected_achievement_range_group"
          :campuses_="campuses_"
          :egress_profiles_="egress_profiles_"
          :levels_="levels_"
          :matters_="matters_"
          :periods_="periods_"
          :sections_="sections_"
        ></AssessmentRangeTable>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "AssessmentRangeSection",
  components: {
    AssessmentRangeTable: () =>
      import(
        "@/components/reports/TeachingReport/AssessmentRangeSection/AssessmentRangeTable.vue"
      ),
    AchievementRangeGroupSelector: () =>
      import(
        "@/components/reports/TeachingReport/AchievementRangeGroupSelector.vue"
      ),
    AssessmentRangeSelector: () =>
      import(
        "@/components/reports/TeachingReport/AssessmentRangeSection/AssessmentRangeSelector.vue"
      ),
  },
  props: {
    sections_ids: {
      type: Array,
      required: true,
    },
    egress_profile_matter: {
      type: Object,
      required: true,
    },
    campuses_: {
      type: Array,
    },
    egress_profiles_: {
      type: Array,
    },
    levels_: {
      type: Array,
    },
    matters_: {
      type: Array,
    },
    periods_: {
      type: Array,
    },
    sections_: {
      type: Array,
    },
  },
  data() {
    return {
      key_assessment_range_table_2: 0,
      key_assessment_range_table_4: 0,
      assessment_id_2: null,
      assessment_id_4: null,
      tab_index: 0,
      selected_achievement_range_group: null,
      show_assessment_range_table: false,
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      matters: names.MATTERS,
      users: names.USERS,
      sections: names.SECTIONS,
    }),
    sectionList() {
      return this.sections.filter((x) => this.sections_ids.includes(x.id));
    },
    matter() {
      if (
        this.egress_profile_matter &&
        this.egress_profile_matter.matter != null
      )
        return this.matters.find(
          (x) => x.id == this.egress_profile_matter.matter
        );
      else return null;
    },
  },
  methods: {
    getProfessorIds() {
      const professorIds = this.sectionList.flatMap(
        (section) => section.professors
      );
      return [...new Set(professorIds)];
    },
    selectedRangeGroup(achievement_range_group_id) {
      this.selected_achievement_range_group = achievement_range_group_id;
      this.show_assessment_range_table = true;
    },
    changeAssessment4(assessment_id) {
      this.assessment_id_4 = assessment_id;
      this.key_assessment_range_table_4 += 1;
    },
    changeAssessment2(assessment_id) {
      this.assessment_id_2 = assessment_id;
      this.key_assessment_range_table_2 += 1;
    },
  },
  watch: {},
  created() {
    if (
      this.egress_profile_matter &&
      this.egress_profile_matter.egress_profile &&
      this.egress_profile_matter.matter
    ) {
      this.$store.dispatch(names.FETCH_PROFILE_COMPETENCES, {
        egress_profile_id: this.egress_profile_matter.egress_profile,
      });
      this.$store.dispatch(
        names.FETCH_COMPETENCES,
        this.egress_profile_matter.egress_profile
      );
      this.$store.dispatch(
        names.FETCH_STUDY_UNITS,
        this.egress_profile_matter.matter
      );
      this.$store.dispatch(names.FETCH_EVALUATION_CRITERIAS, {
        matter__id: this.egress_profile_matter.matter,
      });
      if (this.institution && this.institution.internal_use_id == "ciisa_uss")
        this.$store.dispatch(names.FETCH_COMPETENCE_UNITS, {
          matter__id: this.egress_profile_matter.matter,
        });
    }
    const professorIds = this.getProfessorIds();
    if (professorIds.length > 0) {
      this.$store.dispatch(names.FETCH_ALT_USERS, {
        user_ids: professorIds,
      });
    }
  },
};
</script>

<style scoped>
.primary-header {
  background-color: var(--kl-menu-color) !important;
  color: white;
  align-content: center;
}
@media print {
  /* Oculta los tabs al momento de imprimir */
  /* .tabs >>> ul {
    display: none !important;
  } */
}
</style>

