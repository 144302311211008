<template>
  <div>
    <b-table-simple bordered small>
      <b-thead>
        <b-tr>
          <b-th class="th-class" :colspan="2">{{
            $getVisibleNames(
              "manual.indicador_de_logro",
              true,
              "Indicadores de Logros"
            ).toUpperCase()
          }}</b-th>
          <b-th class="th-class" :colspan="3">
            <span>
              {{
                $getVisibleNames(
                  "manual.dimension",
                  false,
                  "Dimensión"
                ).toUpperCase()
              }}
            </span>
            <button-add
              v-if="allows_crud && rubric && !rubric.is_locked"
              class="m-0 ml-2 p-0 text-white"
              v-b-tooltip.v-secondary.top.noninteractive="
                `Crear ${$getVisibleNames(
                  'manual.dimension',
                  false,
                  'Dimensión'
                )}`
              "
              @click="openModalDimensionCreate()"
              size="sm"
            >
            </button-add>
          </b-th>
        </b-tr>
        <b-tr>
          <b-th class="th-class">N°</b-th>
          <b-th class="th-class">ENUNCIADO</b-th>
          <b-th class="th-class">CANTIDAD</b-th>
          <b-th class="th-class">{{
            this.$getVisibleNames(
              "mesh.cognitivelevel",
              false,
              "Nivel Cognitivo"
            ).toUpperCase()
          }}</b-th>
          <b-th class="th-class">N° DE ORDEN</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template
          v-for="evaluation_criteria in evaluationCriteriasMicroSort.filter(
            (x) => evaluation_criteria_micro_ids.includes(x.id)
          )"
        >
          <b-tr
            v-if="
              rubricCriteriaList.filter(
                (x) =>
                  x.evaluation_criteria_micro == evaluation_criteria.id ||
                  x.evaluation_criterias_micro.includes(evaluation_criteria.id)
              ).length == 0
            "
            :key="evaluation_criteria.id + '-' + test.id"
          >
            <b-td class="text-center">
              <div class="d-flex">
                <span v-if="evaluation_criteria.competence_unit_order != 999">
                  {{ evaluation_criteria.competence_unit_order }}.
                </span>
                <span v-if="evaluation_criteria.study_unit_order != 999">
                  {{ evaluation_criteria.study_unit_order }}.
                </span>
                {{ evaluation_criteria.order }}
              </div>
            </b-td>
            <b-td>
              <SentenceContainer
                :unique_id="
                  'evaluation_criteria:' +
                  evaluation_criteria.id +
                  'test:' +
                  test.id
                "
                :Sentence="evaluation_criteria"
              ></SentenceContainer>
            </b-td>
            <b-td class="text-center">0</b-td>
            <b-td class="text-center">N/A</b-td>
          </b-tr>
          <b-tr
            v-for="(rubric_criteria, index) in rubricCriteriaList.filter(
              (x) =>
                x.evaluation_criteria_micro == evaluation_criteria.id ||
                x.evaluation_criterias_micro.includes(evaluation_criteria.id)
            )"
            :key="
              index +
              '-' +
              evaluation_criteria.id +
              '-' +
              rubric_criteria.id +
              '-' +
              test.id
            "
          >
            <b-td
              v-if="index == 0"
              :rowspan="
                rubricCriteriaList.filter(
                  (x) =>
                    x.evaluation_criteria_micro == evaluation_criteria.id ||
                    x.evaluation_criterias_micro.includes(
                      evaluation_criteria.id
                    )
                ).length
              "
              class="text-center align-middle"
            >
              <div class="d-flex">
                <span v-if="evaluation_criteria.competence_unit_order != 999">
                  {{ evaluation_criteria.competence_unit_order }}.
                </span>
                <span v-if="evaluation_criteria.study_unit_order != 999">
                  {{ evaluation_criteria.study_unit_order }}.
                </span>
                {{ evaluation_criteria.order }}
              </div>
            </b-td>
            <b-td
              v-if="index == 0"
              :rowspan="
                rubricCriteriaList.filter(
                  (x) =>
                    x.evaluation_criteria_micro == evaluation_criteria.id ||
                    x.evaluation_criterias_micro.includes(
                      evaluation_criteria.id
                    )
                ).length
              "
              class="align-middle"
            >
              <SentenceContainer
                :unique_id="
                  'evaluation_criteria:' +
                  evaluation_criteria.id +
                  'test:' +
                  test.id
                "
                :Sentence="evaluation_criteria"
              ></SentenceContainer>
            </b-td>
            <b-td
              v-if="index == 0"
              :rowspan="
                rubricCriteriaList.filter(
                  (x) =>
                    x.evaluation_criteria_micro == evaluation_criteria.id ||
                    x.evaluation_criterias_micro.includes(
                      evaluation_criteria.id
                    )
                ).length
              "
              class="text-center align-middle"
            >
              {{
                rubricCriteriaList.filter(
                  (x) =>
                    x.evaluation_criteria_micro == evaluation_criteria.id ||
                    x.evaluation_criterias_micro.includes(
                      evaluation_criteria.id
                    )
                ).length
              }}
            </b-td>
            <b-td
              v-if="rubric_criteria.cognitive_level"
              class="text-center align-middle"
              :style="{
                'background-color':
                  rubric_criteria.cognitive_level.color == '' ||
                  $equals(rubric_criteria.cognitive_level.color, '#FFFFFF')
                    ? '#ebebeb'
                    : rubric_criteria.cognitive_level.color + ' !important',
                color: $lightOrDark(
                  rubric_criteria.cognitive_level.color == '' ||
                    $equals(rubric_criteria.cognitive_level.color, '#FFFFFF')
                    ? '#ebebeb'
                    : rubric_criteria.cognitive_level.color
                )
                  ? '#2b2a2a !important'
                  : 'white !important',
              }"
            >
              {{ rubric_criteria.cognitive_level.level }}
            </b-td>
            <td v-else class="text-center align-middle">N/A</td>
            <b-td
              class="text-right align-middle cursor-help"
              v-b-tooltip.v-secondary.noninteractive="
                rubric_criteria.full_sentence
              "
            >
              {{ rubric_criteria.order }}
              <button-edit
                v-if="allows_crud && rubric && !rubric.is_locked"
                class="m-0 mb-1 p-0"
                v-b-tooltip.top.noninteractive.v-secondary="
                  `Editar ${$getVisibleNames(
                    'manual.dimension',
                    false,
                    'Dimensión'
                  )}`
                "
                @click="
                  openModalDimensionEdit(
                    rubric_criteria.id,
                    evaluation_criteria.id
                  )
                "
              ></button-edit>
              <b-modal
                :id="`modal-update-rubric-instance-1-${test.id}-${rubric_criteria.id}-${evaluation_criteria.id}`"
                :title="
                  'Modificar ' +
                  $getVisibleNames('manual.dimension', false, 'Dimensión')
                "
                hide-footer
                size="xl"
                no-enforce-focus
                @hide="hideModalDimension"
              >
                <RubricCriteriaForm
                  :rubric_id="rubric.id"
                  :RubricCriteria="rubric_criteria"
                  :order="rubric_criteria.order"
                  :evaluation_criteria_micro_ids="evaluation_criteria_micro_ids"
                  :show_delete_button="true"
                  @updated="
                    $bvModal.hide(
                      `modal-update-rubric-instance-1-${test.id}-${rubric_criteria.id}-${evaluation_criteria.id}`
                    )
                  "
                  @deleted="
                    $bvModal.hide(
                      `modal-update-rubric-instance-1-${test.id}-${rubric_criteria.id}-${evaluation_criteria.id}`
                    )
                  "
                ></RubricCriteriaForm>
              </b-modal>
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
    <!-- b-modal -->
    <b-modal
      :id="`modal-created-rubric-criteria-1-${test.id}`"
      :title="`Crear ${$getVisibleNames(
        'manual.dimension',
        false,
        'Dimensión'
      )}`"
      size="xl"
      hide-footer
      no-enforce-focus
      @hide="hideModalDimension"
    >
      <RubricCriteriaForm
        v-if="rubric"
        :rubric_id="rubric.id"
        :evaluation_criteria_micro_ids="evaluation_criteria_micro_ids"
        :order="
          rubricCriteriaList.length > 0
            ? rubricCriteriaList[rubricCriteriaList.length - 1].order + 1
            : 1
        "
        @created="$bvModal.hide(`modal-created-rubric-criteria-1-${test.id}`)"
      ></RubricCriteriaForm>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "NewEvaluationCriterialDimensionsST",
  components: {
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    RubricCriteriaForm: () =>
      import("@/components/new-evaluations/Rubrics/RubricCriteriaForm"),
  },
  props: {
    allows_crud: {
      type: Boolean,
      default: false,
    },
    test: {
      type: Object,
      required: true,
    },
    rubric_id: {
      type: Number,
      required: true,
    },
    evaluation_criteria_micro_ids: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      taxonomy: names.TAXONOMY,
      studyUnits: names.STUDY_UNITS,
      competence_units: names.COMPETENCE_UNITS,
      evaluation_criterias_micro: names.EVALUATION_CRITERIAS,
      rubrics: names.NEW_RUBRIC_INSTRUMENTS,
      rubricCriterias: names.NEW_RUBRIC_CRITERIAS,
      evaluations: names.NEW_EVALUATIONS,
      egress_profile_matters: names.EGRESS_PROFILES_MATTERS,
      sections: names.SECTIONS,
    }),
    evaluation() {
      if (!this.test) return null;
      return this.evaluations.find((x) => x.id == this.test.evaluation);
    },
    section() {
      if (this.evaluation && this.evaluation.scope == 1)
        return this.sections.find((x) => x.id == this.evaluation.scope_id);
      else return null;
    },
    egressProfileMatter() {
      if (
        this.evaluation &&
        this.evaluation.scope == 2 &&
        this.evaluation.scope_id != null
      ) {
        return this.egress_profile_matters.find(
          (x) => x.id == this.evaluation.scope_id
        );
      }
      if (
        this.evaluation &&
        this.evaluation.scope == 1 &&
        this.section &&
        this.section.egress_profile_matter != null
      ) {
        return this.egress_profile_matters.find(
          (x) => x.id == this.section.egress_profile_matter
        );
      }
      return null;
    },
    rubric() {
      return this.rubrics.find((x) => x.id == this.rubric_id);
    },
    rubricCriteriaList() {
      if (!this.rubric) return [];
      return this.rubricCriterias
        .filter((x) => x.rubric == this.rubric.id)
        .sort(function (a, b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        })
        .map((x) => ({
          ...x,
          cognitive_level: this.getCognitiveLevel(x.verb),
        }));
    },
    evaluationCriteriasMicro() {
      return this.evaluation_criterias_micro.filter((x) =>
        this.evaluation_criteria_micro_ids.includes(x.id)
      );
    },
    filterStudyUnits() {
      const study_units_ids = [
        ...new Set(this.evaluationCriteriasMicro.map((x) => x.study_unit)),
      ];
      return this.studyUnits.filter((x) => study_units_ids.includes(x.id));
    },
    filterCompetenceUnits() {
      const competence_unit_ids = [
        ...new Set(this.filterStudyUnits.map((x) => x.temp_competence_unit)),
      ];
      return this.competence_units.filter((x) =>
        competence_unit_ids.includes(x.id)
      );
    },
    evaluationCriteriasMicroSort() {
      return this.evaluationCriteriasMicro
        .map((x) => {
          const study_unit = this.filterStudyUnits.find(
            (s) => x.study_unit == s.id
          );
          const study_unit_order =
            study_unit && study_unit.order >= 0 ? study_unit.order : 999;
          let competence_unit = null;
          if (study_unit)
            competence_unit = this.filterCompetenceUnits.find(
              (c) => c.id == study_unit.temp_competence_unit
            );
          const competence_unit_order =
            competence_unit && competence_unit.order >= 0
              ? competence_unit.order
              : 999;
          return {
            ...x,
            study_unit_order: study_unit_order,
            competence_unit_order: competence_unit_order,
          };
        })
        .sort(function (a, b) {
          if (a.competence_unit_order > b.competence_unit_order) return 1;
          if (a.competence_unit_order < b.competence_unit_order) return -1;
          if (a.study_unit_order > b.study_unit_order) return 1;
          if (a.study_unit_order < b.study_unit_order) return -1;
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
  },
  methods: {
    // getStudyUnitOrder(study_unit_id) {
    //   const study_unit = this.studyUnits.find((x) => x.id == study_unit_id);
    //   return study_unit ? study_unit.order + "." : null;
    // },
    getCognitiveLevel(verb_id) {
      if (verb_id == null) return verb_id;
      const cognitive_level_obj = this.taxonomy.cognitive_levels.find(
        (cognitive_level) =>
          cognitive_level.verbs.some((verb) => verb.id == verb_id)
      );
      if (cognitive_level_obj) {
        const { name, description, level, cognitive_level, color } =
          cognitive_level_obj;
        return { name, description, level, cognitive_level, color };
      } else return null;
    },
    insertStoreOpenAiDimension() {
      this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
      this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);

      this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
        visible_intelligence_options: [8],
        disabled_selected_intelligence_option: true,
      });
    },
    openModalDimensionCreate() {
      this.insertStoreOpenAiDimension();
      this.$nextTick(() => {
        this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
          available_evaluation_criteria_micro:
            this.evaluation_criteria_micro_ids,
          extra_label: `${this.$getVisibleNames(
            "manual.dimension",
            false,
            "Dimensión"
          )}`,
          intelligence_option: 8,
          sub_option: 2,
        });
      });
      this.$bvModal.show(`modal-created-rubric-criteria-1-${this.test.id}`);
    },
    openModalDimensionEdit(rubric_criteria_id, evaluation_criteria_id) {
      this.insertStoreOpenAiDimension();
      this.$nextTick(() => {
        this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
          available_evaluation_criteria_micro:
            this.evaluation_criteria_micro_ids,
          extra_label: `${this.$getVisibleNames(
            "manual.dimension",
            false,
            "Dimensión"
          )}`,
          intelligence_option: 8,
          sub_option: 2,
        });
      });
      this.$bvModal.show(
        `modal-update-rubric-instance-1-${this.test.id}-${rubric_criteria_id}-${evaluation_criteria_id}`
      );
    },
    hideModalDimension() {
      this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
      this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);
      this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
        visible_intelligence_options: [7],
      });

      if (
        this.evaluation &&
        this.evaluation.scope == 3 &&
        this.evaluation.scope_id != null
      ) {
        this.$nextTick(() => {
          this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
            matter_id: this.evaluation.scope_id,
            extra_context: "Para la Evaluación",
            extra_label: "",
            show_message_evaluation_view: true,
            intelligence_option: 7,
          });
        });
      }
      if (
        this.egressProfileMatter &&
        this.evaluation &&
        (this.evaluation.scope == 2 || this.evaluation.scope == 1) &&
        this.evaluation.scope_id != null
      ) {
        this.$nextTick(() => {
          this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
            matter_id: this.egressProfileMatter.matter,
            extra_context: "Para la Evaluación",
            show_message_evaluation_view: true,
            intelligence_option: 7,
          });
        });
      }
    },
  },
  created() {},
};
</script>

<style scoped>
.th-class {
  background-color: var(--kl-menu-color) !important;
  color: var(--secondary-font-color);
  font-size: var(--secondary-font-size);
  text-align: center;
}
.cursor-help {
  cursor: help;
}
</style>

