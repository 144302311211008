<template>
  <div>
    <h4 v-if="isNaN(career.id) && show_title">
      Crear {{ $getVisibleNames("mesh.career", false, "Programa") }}
      <!-- Programa -->
    </h4>
    <h4 v-else-if="show_title">
      Editar {{ $getVisibleNames("mesh.career", false, "Programa") }}
      <!-- Programa -->
    </h4>
    <div>
      <b-form-group
        label="Nombre"
        label-for="name-input"
        label-cols="0"
        label-cols-sm="3"
      >
        <b-form-input
          id="name-input"
          name="name-input"
          v-model="$v.career.name.$model"
          :state="validateState('name') && !SameCareer"
          aria-describedby="input-name-live-feedback"
        ></b-form-input>

        <b-form-invalid-feedback id="input-name-live-feedback">
          <div v-if="career.name.length < 3">
            Este campo es obligatorio y debe tener al menos 3 caracteres.
          </div>
          <div v-if="SameCareer">
            Ya se cuenta con una
            {{ $getVisibleNames("manual.programa", false, "Programa") }} con
            este nombre.
          </div></b-form-invalid-feedback
        >
        <b-tooltip
          :target="`name-input`"
          variant="secondary"
          placement="right"
          :noninteractive="true"
          v-if="SameCareer"
        >
          <div v-if="SameCareer">
            {{ $getVisibleNames("manual.programa", false, "Programa")
            }}{{ filterSameCareer.length > 0 ? "s" : "" }} Existente{{
              filterSameCareer.length > 0 ? "s" : ""
            }}:
            <div v-for="Career in filterSameCareer" :key="Career.id">
              <p>
                {{ Career.name }}
              </p>
            </div>
          </div>
        </b-tooltip>
      </b-form-group>
      <b-form-group
        :label="`${$getVisibleNames(
          'mesh.egressprofiletype',
          false,
          'Tipo de Programa'
        )}`"
        label-for="type-select"
        label-cols="0"
        label-cols-sm="3"
      >
        <b-form-select
          id="type-select"
          name="type-select"
          v-model="$v.career.egress_profile_type.$model"
          :options="profileTypes"
          text-field="name"
          value-field="id"
          :state="validateState('egress_profile_type')"
          aria-describedby="select-type-live-feedback"
        >
        </b-form-select>
        <b-form-invalid-feedback id="select-type-live-feedback"
          >Este campo es obligatorio.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        :label="$getVisibleNames('mesh.faculty', false, 'Facultad')"
        label-for="faculty-input"
        label-cols="0"
        label-cols-sm="3"
      >
        <b-form-select
          id="faculty-input"
          name="faculty-input"
          v-model="$v.career.faculty.$model"
          :options="filterFacultiesTransversal"
          text-field="name"
          value-field="id"
          :state="validateState('faculty')"
          aria-describedby="input-faculty-live-feedback"
        ></b-form-select>

        <b-form-invalid-feedback id="input-faculty-live-feedback"
          >Este campo es obligatorio.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        v-if="grandeGroupsList.length > 1"
        :label="
          $getVisibleNames(
            'evaluations2.graderangegroup',
            false,
            'Grupo de Rangos de Notas'
          )
        "
        label-for="chosen_grade_range_group-input"
        label-cols="0"
        label-cols-sm="3"
      >
        <div class="d-flex">
          <div class="d-block w-100">
            <b-form-select
              id="chosen_grade_range_group-input"
              name="chosen_grade_range_group-input"
              v-model="$v.career.chosen_grade_range_group.$model"
              :options="grandeGroupsList"
              text-field="name"
              value-field="id"
              :state="validateState('chosen_grade_range_group')"
              aria-describedby="input-chosen_grade_range_group-live-feedback"
            >
              <template v-slot:first>
                <option :value="null" selected>
                  -- Seleccione un
                  {{
                    $getVisibleNames(
                      "evaluations2.graderangegroup",
                      false,
                      "Grupo de Rangos de Notas"
                    )
                  }}
                  --
                </option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback
              id="input-chosen_grade_range_group-live-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </div>
          <div>
            <b-button
              v-if="career.chosen_grade_range_group != null"
              size="sm"
              class="ml-1 p-0"
              variant="none"
              v-b-tooltip.top.noninteractive.v-secondary="
                `Previsualizar ${$getVisibleNames(
                  'evaluations2.graderange',
                  true,
                  'Rangos de Notas'
                )}`
              "
              @click="
                $bvModal.show(`modal-view-evaluations2.graderange-${career.id}`)
              "
            >
              <b-icon class="btn-actions" icon="eye" scale="1"></b-icon>
            </b-button>
            <span
              v-else
              v-b-tooltip.top.noninteractive.v-secondary="
                `Seleccione un ${$getVisibleNames(
                  'evaluations2.graderangegroup',
                  false,
                  'Grupo de Rangos de Notas'
                )} para poder previsualizar.`
              "
            >
              <b-button size="sm" class="ml-1 p-0" variant="none" disabled>
                <b-icon class="btn-actions" icon="eye" scale="1"></b-icon>
              </b-button>
            </span>
          </div>
        </div>
      </b-form-group>
      <b-form-group
        v-if="achievementGroupsList.length > 1"
        :label="
          $getVisibleNames(
            'assessment2.achievementrangegroup',
            false,
            'Grupo de Rangos de Logro'
          )
        "
        label-for="chosen_achievement_range_group-input"
        label-cols="0"
        label-cols-sm="3"
      >
        <div class="d-flex">
          <div class="d-block w-100">
            <b-form-select
              id="chosen_achievement_range_group-input"
              name="chosen_achievement_range_group-input"
              v-model="$v.career.chosen_achievement_range_group.$model"
              :options="achievementGroupsList"
              text-field="name"
              value-field="id"
              :state="validateState('chosen_achievement_range_group')"
              aria-describedby="input-chosen_achievement_range_group-live-feedback"
            >
              <template v-slot:first>
                <option :value="null" selected>
                  -- Seleccione un
                  {{
                    $getVisibleNames(
                      "assessment2.achievementrangegroup",
                      false,
                      "Grupo de Rangos de Logro"
                    )
                  }}
                  --
                </option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback
              id="input-chosen_achievement_range_group-live-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </div>
          <div>
            <b-button
              v-if="career.chosen_achievement_range_group != null"
              size="sm"
              class="ml-1 p-0"
              variant="none"
              v-b-tooltip.top.noninteractive.v-secondary="
                `Previsualizar ${$getVisibleNames(
                  'assessment2.achievementrange',
                  true,
                  'Rangos de Logro'
                )}`
              "
              @click="
                $bvModal.show(
                  `modal-view-assessment2.achievementrange-${career.id}`
                )
              "
            >
              <b-icon class="btn-actions" icon="eye" scale="1"></b-icon>
            </b-button>
            <span
              v-else
              v-b-tooltip.top.noninteractive.v-secondary="
                `Seleccione un ${$getVisibleNames(
                  'assessment2.achievementrangegroup',
                  false,
                  'Grupo de Rangos de Logro'
                )} para poder previsualizar.`
              "
            >
              <b-button size="sm" class="ml-1 p-0" variant="none" disabled>
                <b-icon class="btn-actions" icon="eye" scale="1"></b-icon>
              </b-button>
            </span>
          </div>
        </div>
      </b-form-group>
      <b-form-group
        v-if="
          (institution && institution.internal_use_id == 'duoc_uc') ||
          $debug_change_duoc
        "
        label-cols="0"
        label-cols-sm="3"
        label="Carrera Genérica"
        label-for="input-generic_career"
        class="label my-2"
      >
        <v-select
          id="input-generic_career"
          v-model="$v.career.generic_career.$model"
          :options="genericCareers"
          :reduce="(genericCareers) => genericCareers.id"
          :placeholder="`${
            genericCareers.length > 0
              ? String(
                  'Seleccione una ' +
                    $getVisibleNames(
                      'mesh.genericcareer',
                      false,
                      'Carrera Génerica'
                    ) +
                    '.'
                )
              : String(
                  'No cuenta con ' +
                    $getVisibleNames(
                      'mesh.genericcareer',
                      true,
                      'Carreras Génericas'
                    ) +
                    ' creadas.'
                )
          }`"
          :dropdown-should-open="dropdownShouldOpenGenericCareer"
          :state="validateState('generic_career')"
          label="name"
          track-by="id"
          size="sm"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No se encontraron resultados para: "<em>{{ search }}</em
              >"
            </template>
          </template>
        </v-select>
      </b-form-group>

      <div class="row">
        <div
          v-if="show_delete_button && !isNaN(career.id)"
          class="col"
          style="text-align: left"
        >
          <b-button
            class="mr-1"
            size="sm"
            variant="danger"
            @click="deleteCareer"
            >Eliminar</b-button
          >
        </div>
        <div v-if="show_save_button" class="col" style="text-align: right">
          <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
        </div>
      </div>
      <b-modal
        v-if="career.chosen_grade_range_group != null"
        :id="`modal-view-evaluations2.graderange-${career.id}`"
        :title="`${
          (
            grade_ranges_groups.find(
              (x) => x.id == career.chosen_grade_range_group
            ) || {}
          ).name || ''
        }`"
        size="lg"
        hide-footer
      >
        <GradeTableRanges
          :grade_ranges="grade_ranges"
          :grade_ranges_groups="grade_ranges_groups"
          :grade_range_group_id="career.chosen_grade_range_group"
        ></GradeTableRanges>
      </b-modal>
      <b-modal
        v-if="career.chosen_achievement_range_group != null"
        :id="`modal-view-assessment2.achievementrange-${career.id}`"
        :title="`${
          (
            achievement_ranges_groups.find(
              (x) => x.id == career.chosen_achievement_range_group
            ) || {}
          ).name || ''
        }`"
        size="lg"
        hide-footer
      >
        <AchievementTableRanges
          :achievement_ranges="achievement_ranges"
          :achievement_ranges_groups="achievement_ranges_groups"
          :achievement_range_group_id="career.chosen_achievement_range_group"
        ></AchievementTableRanges>
      </b-modal>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required, minLength, numeric } from "vuelidate/lib/validators";
import { toast } from "@/utils/utils";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "CareerForm",
  mixins: [validationMixin],
  components: {
    vSelect,
    GradeTableRanges: () =>
      import("@/components/new-dashboard/GradeTableRanges"),
    AchievementTableRanges: () =>
      import("@/components/new-dashboard/AchievementTableRanges"),
  },
  props: {
    Career: {
      type: Object,
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    show_title: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      career: this.Career
        ? {
            id: this.Career.id,
            name: this.Career.name,
            egress_profile_type: this.Career.egress_profile_type,
            faculty: this.Career.faculty,
            chosen_grade_range_group: this.Career.chosen_grade_range_group,
            chosen_achievement_range_group:
              this.Career.chosen_achievement_range_group,
            generic_career: this.Career ? this.Career.generic_career : null,
          }
        : {
            id: generateUniqueId(),
            name: "",
            egress_profile_type: null,
            faculty: generateUniqueId(),
            chosen_grade_range_group: null,
            chosen_achievement_range_group: null,
          },
      grade_ranges_groups: [],
      grade_ranges: [],
      achievement_ranges_groups: [],
      achievement_ranges: [],
    };
  },
  validations: {
    career: {
      faculty: {
        required,
        numeric,
      },
      name: {
        required,
        minLength: minLength(3),
      },
      egress_profile_type: {
        required,
      },
      chosen_grade_range_group: {},
      chosen_achievement_range_group: {},
      generic_career: {},
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      faculties: names.FACULTIES,
      careers: names.CAREERS,
      profileTypes: names.PROFILE_TYPES,
      genericCareers: names.GENERIC_CAREERS,
    }),
    filterFacultiesTransversal() {
      if (!isNaN(this.career.id)) {
        return this.faculties.filter(
          (x) => !x.duoc_has_transversal_matters || x.id == this.career.faculty
        );
      } else {
        return this.faculties;
      }
    },
    SameCareer() {
      if (!this.career) return false;
      if (!this.career.name) return false;
      const SameCareer = this.careers.filter(
        (career) =>
          career.name
            .normalize("NFD")
            .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
            .normalize()
            .trim()
            .toLowerCase() ==
          this.career.name
            .normalize("NFD")
            .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
            .normalize()
            .trim()
            .toLowerCase()
      );
      if (
        (SameCareer.length > 0 && isNaN(this.career.id)) ||
        (SameCareer.length > 0 &&
          !SameCareer.find((career) => career.id == this.career.id))
      )
        return true;
      return false;
    },
    filterSameCareer() {
      if (!this.career) return [];
      if (!this.career.name) return [];
      const SameCareer = this.careers.filter(
        (career) =>
          career.name
            .normalize("NFD")
            .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
            .normalize()
            .trim()
            .toLowerCase() ==
          this.career.name
            .normalize("NFD")
            .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
            .normalize()
            .trim()
            .toLowerCase()
      );
      if (
        (SameCareer.length > 0 && isNaN(this.career.id)) ||
        (SameCareer.length > 0 &&
          !SameCareer.find((career) => career.id == this.career.id))
      )
        return SameCareer;
      return [];
    },
    grandeGroupsIds() {
      return [...new Set(this.grade_ranges.map((x) => x.grade_range_group))];
    },
    grandeGroupsList() {
      return this.grade_ranges_groups.filter((x) =>
        this.grandeGroupsIds.includes(x.id)
      );
    },
    achievementGroupsIds() {
      return [
        ...new Set(
          this.achievement_ranges.map((x) => x.achievement_range_group)
        ),
      ];
    },
    achievementGroupsList() {
      return this.achievement_ranges_groups.filter((x) =>
        this.achievementGroupsIds.includes(x.id)
      );
    },
  },
  methods: {
    validateState(key) {
      const { $dirty, $error } = this.$v.career[key];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.career.$touch();
      if (this.$v.career.$anyError) {
        return;
      }
      if (!this.SameCareer) {
        if (isNaN(this.career.id)) this.createCareer();
        else this.updateCareer();
      }
    },
    dropdownShouldOpenGenericCareer(VueSelect) {
      if (this.genericCareers.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    createCareer() {
      this.$store.dispatch(names.POST_CAREER, this.career).then((response) => {
        toast(
          String(this.$getVisibleNames("mesh.career", false, "Programa")) +
            " creado."
        );
        this.$store.dispatch(names.FETCH_CURRENT_POSITION, this.user.id);
        this.$emit("created", response);
      });
    },
    updateCareer() {
      this.$store
        .dispatch(names.UPDATE_CAREER, this.career)
        .then((response) => {
          toast(
            String(this.$getVisibleNames("mesh.career", false, "Programa")) +
              " actualizado."
          );
          this.$emit("updated", response);
        });
    },
    deleteCareer() {
      this.$swal({
        title: "¿Está seguro de que desea eliminar?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_CAREER, this.career.id);
          this.$emit("deleted", this.career);
        }
      });
    },
    getGradeRange() {
      this.$restful.Get("/evaluations2/grade-range-group/").then((response) => {
        this.grade_ranges_groups = response;
      });
      this.$restful.Get("/evaluations2/grade-range/").then((response) => {
        this.grade_ranges = response;
        const grade_ranges_groups = [
          ...new Set(response.map((x) => x.grade_range_group)),
        ];
        if (grade_ranges_groups.length == 1)
          this.career.chosen_grade_range_group = grade_ranges_groups[0];
      });
    },
    getAchievementRange() {
      this.$restful
        .Get("/assessment2/achievement-range-group/?all_data=True")
        .then((response) => {
          this.achievement_ranges_groups = response.results;
        });
      this.$restful
        .Get("/assessment2/achievement-range/?all_data=True")
        .then((response) => {
          this.achievement_ranges = response.results;
          const achievement_ranges_groups = [
            ...new Set(response.results.map((x) => x.achievement_range_group)),
          ];
          if (achievement_ranges_groups.length == 1)
            this.career.chosen_achievement_range_group =
              achievement_ranges_groups[0];
        });
    },
  },
  watch: {},
  created() {
    this.$store.dispatch(names.FETCH_CAREERS);
    this.$store.dispatch(names.FETCH_GENERIC_CAREERS);
    this.getGradeRange();
    this.getAchievementRange();
  },
  mounted() {},
};
</script>

<style>
</style>