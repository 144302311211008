<template>
  <div class="b-table-container">
    <div class="flex-container">
      <div class="search noprint" v-if="search_filter">
        <b-input-group size="sm">
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            id="search-form"
            type="search"
            v-model="input_search"
            v-b-tooltip.v-secondary.noninteractive="
              `Presione Enter Para Agregar un Filtro`
            "
            placeholder="Buscar..."
            @keyup.enter="addSearchTag"
          >
          </b-form-input>
          <b-input-group-append>
            <b-button
              class="mr-2"
              :disabled="!input_search"
              @click="addSearchTag"
              >Buscar</b-button
            >
          </b-input-group-append>
          <b-input-group-append>
            <b-button :disabled="!input_search" @click="input_search = ''"
              >Limpiar</b-button
            >
          </b-input-group-append>
        </b-input-group>
        <div class="tag-container mt-2">
          <div
            class="style-tag"
            v-for="(tag, index) in multiple_search"
            :key="index"
          >
            <div class="d-flex">
              <div class="align-self-center">
                {{ tag }}
              </div>
              <b-button
                class="p-0"
                variant="none"
                v-b-tooltip.v-secondary.noninteractive="`Eliminar Filtro`"
                @click="deleteTag(index)"
              >
                <b-icon font-scale="1" style="color: white" icon="x"></b-icon>
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="columns-btn noprint">
        <div class="column-selector noprint" v-if="columns_display">
          <div class="d-flex">
            <b-button
              :id="`col-display-1-${display_id}`"
              class="col-display-1"
              variant="none"
              size="sm"
              >Visualizar columnas</b-button
            >
            <slot name="btn-download"></slot>
          </div>
          <b-popover
            :id="`columns-popover-1-${display_id}`"
            :target="`col-display-1-${display_id}`"
            placement="bottom"
            class="col-popover-1"
            triggers="click blur"
          >
            <div
              class="column-check noprint"
              v-for="(field, index) in fields"
              :key="index"
            >
              <b-form-checkbox
                v-if="field.label != ''"
                :disabled="
                  (dynamicFields.length == 1 && field.display_column) ||
                  (dynamicFields[0].key == 'selected' &&
                    dynamicFields.length == 2 &&
                    field.display_column) ||
                  (dynamicFields[dynamicFields.length - 1].key == 'actions' &&
                    dynamicFields.length == 2 &&
                    field.display_column)
                "
                :id="`column-check-${index}`"
                v-model="field.display_column"
                name="checkbox-1"
              >
                {{ field.label }}
              </b-form-checkbox>
            </div>
          </b-popover>
        </div>
        <div
          class="column-selector noprint"
          v-if="search_filter && view_filter_by"
        >
          <b-button
            :id="`col-display-2-${display_id}`"
            class="col-display-2"
            variant="none"
            size="sm"
            >Filtrar por</b-button
          >
          <b-popover
            :id="`columns-popover-2-${display_id}`"
            :target="`col-display-2-${display_id}`"
            placement="bottom"
            custom-class="filteron-popover"
            bottom
            triggers="click blur"
          >
            <b-form-group
              v-model="sortDirection"
              label-size="sm"
              description="Deseleccionar todos para filtrar por toda la información de la tabla."
              class="mb-0 filter-by-column noprint"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-checkbox-group
                v-model="filterOn"
                :aria-describedby="ariaDescribedby"
                class="mt-1"
              >
                <div v-for="(field, index) in fields" :key="index">
                  <b-form-checkbox
                    v-if="field.label != ''"
                    :value="field.key"
                    class="check-filteron"
                    >{{ field.label }}</b-form-checkbox
                  >
                </div>
              </b-form-checkbox-group>
            </b-form-group>
          </b-popover>
        </div>
      </div>
    </div>
    <b-table
      id="generic-table"
      :busy="busy"
      class="generic-table"
      thead-class="b-card-style text-center"
      tfoot-class="custom-footer"
      :items="filterItem"
      :filter-included-fields="filterOn"
      :filter-function="filterCustom"
      :fields="dynamicFields"
      show-empty
      small
      bordered
      borderless
      responsive
      striped
      :selectable="selection_mode"
      :select-mode="select_mode"
      @row-selected="slotSelected"
      empty-text="No hay datos para mostrar."
      empty-filtered-text="No hay datos que coincidan con su búsqueda."
      @sort-changed="handleSort"
      primary-key="id"
      :no-local-sorting="false"
    >
      <template #head()="{ label, field: { key, sortable } }">
        <div class="custom-th">
          {{ label }}
          <template v-if="sortable">
            <b-img
              v-if="sort_by !== key"
              :src="sortIconNeutral"
              class="sort-arrow"
            ></b-img>
            <b-img
              v-else-if="sort_desc"
              :src="sortIconAsc"
              class="sort-arrow"
            ></b-img>
            <b-img v-else :src="sortIconDesc" class="sort-arrow"></b-img>
          </template>
        </div>
      </template>
      <template #table-busy>
        <div class="text-center">
          <b-spinner></b-spinner>
        </div>
      </template>
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
      <template v-if="show_pagination" v-slot:custom-foot="row">
        <div class="footer-caption noprint" colspan="0">
          <i
            >Mostrando
            {{ row.items.length }}
            de {{ items.count }} filas en total.</i
          >
        </div>
      </template>
    </b-table>

    <b-row class="custom-row noprint">
      <b-col sm="5" md="6" class="per-page-col">
        <b-form-group
          label="Por página:"
          label-for="per-page-input"
          label-align-sm="right"
          label-size="sm"
          class="per-page-container"
        >
          <b-form-input
            id="per-page-input"
            v-model="per_page"
            size="sm"
            type="number"
            min="0"
            :max="items.count"
            @input="fetchData(1)"
            v-b-tooltip.v-secondary.noninteractive="
              'Cantidad de elementos que se van a mostrar por página.'
            "
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="7" md="6" class="my-1 noprint" v-if="show_pagination">
        <b-pagination
          :key="current_page_key"
          v-model="current_page"
          :total-rows="items.count"
          :per-page="per_page"
          :disabled="per_page == items.count"
          @change="(value) => fetchData(value)"
          align="fill"
          size="sm"
          class="my-0"
          no-title
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { generateUniqueId } from "@/utils/utils";

export default {
  name: "NewGenericBTable",
  components: {},
  props: {
    fields: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Number,
      default: 10,
    },
    url: {
      type: String,
      required: true,
    },
    // Si se llegara a necesitar un prefiltro que este fuera del caso del djangoFilter.
    // como ejemplo se podria agregar 'type=1&order=2'
    pre_filter: {
      type: String,
      default: "",
    },
    // ["Estudiante","Docente"]
    pre_multiple_search: {
      type: Array,
      default() {
        return [];
      },
    },
    show_pagination: {
      type: Boolean,
      default: true,
    },
    search_filter: {
      type: Boolean,
      default: true,
    },
    columns_display: {
      type: Boolean,
      default: true,
    },
    selection_mode: {
      type: Boolean,
      default: false,
    },
    //select_mode: single, multi, range
    select_mode: {
      type: String,
      default: "single",
    },
    // pre_page para definir la pag actual al cargar el componente.
    pre_current_page: { type: Number, default: 1 },
    view_filter_by: {
      type: Boolean,
      default: false,
    },
    filterCustom: {
      type: Function,
    },
    sortDesc: {},
    sortBy: {},
    Busy: {
      default: true,
    },
    display_id: {
      default: generateUniqueId(),
    },
  },
  data() {
    return {
      per_page: this.pagination ? this.pagination : 10,
      items: { count: 0, next: null, previous: null, results: [] },
      busy: this.Busy,
      current_page: 1,
      current_page_key: 0,
      total_rows: 1,
      columns_selected: this.fields,
      table_items: [],
      multiple_search: [...this.pre_multiple_search],
      input_search: "",
      filterOn: [],
      sort_desc: this.sortDesc,
      sort_by: this.sortBy,
      sortIconNeutral:
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
      sortIconDesc:
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
      sortIconAsc:
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
    };
  },
  computed: {
    filterItem() {
      return this.items.results;
    },
    dynamicFields() {
      let fields = [];
      if (this.selection_mode) {
        fields.push({
          key: "selected",
          label: "Seleccionar",
          thStyle: { width: "1%" },
        });
      }

      fields = this.columns_selected.filter((field) => field.display_column);
      return fields;
    },
  },
  methods: {
    slotSelected(rows) {
      this.$emit("selected", rows);
    },
    fetchGenericBTable() {
      let partial_url = "";
      if (this.pre_multiple_search.length > 0)
        partial_url = "&search=" + this.pre_multiple_search.join(",");
      this.$restful
        .Get(
          `${this.url}?${this.pre_filter}&page=${this.pre_current_page}${partial_url}`
        )
        .then((response) => {
          this.current_page = this.pre_current_page;
          this.items = response;
          this.table_items = response.results;
          this.total_rows = response.results.length;
          this.busy = false;
          this.$emit("actual_items", response.results);
          this.current_page_key++;
        });
    },
    fetchData(current_page = 1) {
      this.busy = true;
      this.current_page = current_page;
      let partial_url = "";
      if (this.multiple_search.length > 0)
        partial_url = "&search=" + this.multiple_search.join(",");
      let ordering = this.sort_by
        ? `&ordering=${this.sort_desc ? "-" : ""}${this.sort_by}`
        : "";
      if (this.per_page == "" || this.per_page == null)
        return { count: 0, next: null, previous: null, results: [] };
      if (this.pre_filter != "") {
        this.$restful
          .Get(
            `${this.url}?${
              this.pre_filter
            }&page=${current_page}&page_size=${parseInt(
              this.per_page
            )}${partial_url}${ordering}`
          )
          .then((response) => {
            this.items = response;
            this.table_items = response.results;
            this.total_rows = response.results.length;
            this.busy = false;
            this.$emit("actual_items", response.results);
            this.$emit("current_page", current_page);
            this.$emit("current_tag", this.multiple_search);
          });
      } else {
        this.$restful
          .Get(
            `${this.url}?page=${current_page}&page_size=${parseInt(
              this.per_page
            )}${partial_url}${ordering}`
          )
          .then((response) => {
            this.items = response;
            this.table_items = response.results;
            this.total_rows = response.results.length;
            this.busy = false;
            this.$emit("actual_items", response.results);
            this.$emit("current_page", current_page);
            this.$emit("current_tag", this.multiple_search);
          });
      }
    },
    addSearchTag() {
      if (this.input_search != "") this.multiple_search.push(this.input_search);
      this.input_search = "";
      this.current_page = 1;
      this.fetchData();
    },
    deleteTag(index) {
      this.multiple_search.splice(index, 1);
      this.current_page = 1;
      this.fetchData();
    },
    handleSort({ sortBy, sortDesc }) {
      this.sort_by = sortBy;
      this.sort_desc = sortDesc;
      this.fetchData(1); // Refrescar los datos con la nueva ordenación
    },
  },
  watch: {
    pagination: function (n, o) {
      if (n == o || n < 0 || n > 20) return;
      this.per_page = n;
    },
    pre_multiple_search() {
      if (this.pre_multiple_search.length > 0) this.fetchData();
    },
    filterOn: function (n, o) {
      if (n == o) return;
      this.$emit("emitChangeFilterOn", n);
    },
  },
  created() {
    this.fetchGenericBTable();
    // Escuchar los eventos de sort de la tabla
    this.$on("sort-changed", this.handleSort);
  },
};
</script>

<style scoped>
.tag-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
}
.style-tag {
  background-color: var(--primary-color);
  color: white;
  max-width: fit-content;
  border-radius: 3px;
  margin: 0.1em 0.1em;
  padding: 1px 4px;
  flex-grow: 1;
  font-size: var(--thirth-font-size);
  /* text-align: center; */
}
.flex-container {
  display: flex;
  margin-bottom: 1em;
}
.search {
  margin: 0;
  width: 100%;
}
.col-display-1,
.col-display-2 {
  min-width: 154px;
  margin-left: 2em;
  background-color: var(--kl-primary-button-color) !important;
  color: #fff;
}
.col-display-1:hover,
.col-display-2:hover {
  background-color: var(--kl-primary-button-hover-color) !important;
  transition: all 0.3s;
}
.col-popover-1 {
  z-index: 1010 !important;
}
.column-selector {
  width: 100%;
  margin-left: 0em;
}
.column-check {
  text-align: left;
}
.filter-by-column {
  width: 100%;
}
.filter-on-check {
  width: 300px;
}
.filteron-popover {
  width: 170px;
}
.custom-control-inline {
  margin-right: 0;
}
.custom-th {
  display: flex;
  justify-content: center;
}
.custom-row {
  margin-bottom: 1rem;
}
.b-table-container {
  margin: 1rem;
}
.b-table-container >>> .b-card-style {
  background: var(--primary-color);
}
#per-page-input {
  margin-top: -1%;
  max-width: 80px;
  margin-left: 2em;
}
.per-page-container {
  display: flex;
}
.per-page-col {
  margin-top: 0.4rem;
  max-width: 320px;
}
.per-page-input-append {
  margin-top: 8%;
  max-width: 300px;
  font-size: 11pt;
}
.footer-caption {
  color: var(--secondary-color);
  font-size: 11pt;
  padding-top: 0;
  float: left;
  transform: translateX(10%);
  margin-right: -200px;
}
.b-table-container >>> .custom-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100% !important;
}
.columns-btn {
  display: flex;
  justify-content: space-around;
  margin-left: auto;
}
.popover {
  z-index: 10 !important;
}
.sort-arrow {
  width: 0.65rem;
  height: 1rem;
  transform: translate(20%, 20%);
}
#search-form {
  min-height: 2rem;
}
@media (max-width: 991px) {
  .flex-container {
    display: block;
  }
  .columns-btn {
    margin-top: 0.7em;
    display: flex;
    justify-content: space-evenly;
  }
  .column-selector {
    margin-left: 0;
    width: 50%;
  }
  .col-display-1,
  .col-display-2 {
    margin: 0;
    width: 98%;
  }
  .col-display-1 {
    margin-right: 1% !important;
  }
  .col-display-2 {
    margin-left: 1% !important;
  }
  .filteron-popover {
    width: 180px !important;
    margin: 0;
  }
  .filter-by-column {
    width: auto !important;
  }
  i {
    margin-right: auto;
  }
}
/* Css para ocultar imagen de sorting por defecto en b-table */
.b-table-container >>> .table.b-table > thead > tr > [aria-sort="none"],
.b-table-container >>> .table.b-table > thead > tr > [aria-sort="ascending"],
.b-table-container >>> .table.b-table > thead > tr > [aria-sort="descending"] {
  background-image: none !important;
}
</style>