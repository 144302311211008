<template>
  <div>
    <!-- -------------------------Evaluaciones "Mis Clases" Profesor------------------------- -->
    <Card
      v-if="user.groups.includes(6)"
      class="dashboard-card"
      :variant_seventh="true"
    >
      <template v-slot:title>
        <div class="container-title-my-classes">
          <div>
            <b-icon class="card-header-icon" icon="journal-text"></b-icon>
            Mis Clases
          </div>
        </div>
      </template>
      <template v-slot:content>
        <template v-if="institution && institution.kl_assessment">
          <div
            v-if="
              sections_teacher.length != 0 ||
              optionsPeriodCurrentSectionTeacher.length > 1 ||
              optionsCareerCurrentSectionTeacher.length > 1 ||
              optionsCampuCurrentSectionTeacher.length > 1 ||
              optionsMatterCurrentSectionTeacher.length > 1
            "
            class="container-select mb-2 noprint"
          >
            <div>
              <b-form-select
                id="input-campus"
                class="select-form"
                v-model="select_campus_current_section_teacher"
                :options="optionsCampuCurrentSectionTeacher"
                @change="getSectionsTeacher()"
              ></b-form-select>
            </div>
            <div>
              <b-form-select
                id="input-career"
                class="select-form"
                v-model="select_career_current_section_teacher"
                :options="optionsCareerCurrentSectionTeacher"
                @change="getSectionsTeacher()"
              ></b-form-select>
            </div>
            <div>
              <b-form-select
                id="input-matter"
                class="select-form"
                v-model="select_matter_current_section_teacher"
                :options="optionsMatterCurrentSectionTeacher"
                @change="getSectionsTeacher()"
              ></b-form-select>
            </div>
            <div>
              <b-form-select
                id="input-period"
                class="select-form"
                v-model="select_period_current_section_teacher"
                :options="optionsPeriodCurrentSectionTeacher"
                @change="getSectionsTeacher()"
              ></b-form-select>
            </div>
          </div>
          <template
            v-if="user.groups.includes(6) && sections_teacher.length > 0"
          >
            <b-table-simple
              bordered
              borderless
              small
              hover
              sticky-header="400px"
              class="border-table"
            >
              <b-thead>
                <b-tr>
                  <b-th class="primary-cell-my-classes" style="width: 14%">{{
                    $getVisibleNames("mesh.campus", false, "Sede")
                  }}</b-th>
                  <b-th class="primary-cell-my-classes" style="width: 25%">{{
                    $getVisibleNames("mesh.career", false, "Programa")
                  }}</b-th>
                  <b-th class="primary-cell-my-classes" style="width: 25%">{{
                    $getVisibleNames(
                      "mesh.egressprofilematter",
                      false,
                      "Asignatura"
                    )
                  }}</b-th>
                  <b-th class="primary-cell-my-classes" style="width: 15%">{{
                    $getVisibleNames("teaching.section", false, "Sección")
                  }}</b-th>
                  <b-th class="primary-cell-my-classes" style="width: 10%">{{
                    $getVisibleNames("mesh.period", false, "Periodo")
                  }}</b-th>
                  <b-th class="primary-cell-my-classes" style="width: 10%">
                    Docentes
                  </b-th>
                  <b-th
                    class="primary-cell-my-classes noprint"
                    style="width: 5%"
                    >Opciones</b-th
                  >
                </b-tr>
              </b-thead>

              <b-tbody>
                <template v-for="section in sections_teacher">
                  <b-tr
                    v-for="sectionMatter in section.sections"
                    :key="
                      'period' +
                      section.period_id +
                      'section' +
                      sectionMatter.id
                    "
                  >
                    <!-- Nombre del Campus -->
                    <b-td class="secondary-cell-my-classes text-left">
                      {{ sectionMatter.campus_name }}
                    </b-td>
                    <!-- Nombre de la Carrera -->
                    <b-td class="secondary-cell-my-classes text-left">
                      {{ sectionMatter.career_name }}
                    </b-td>
                    <!-- Nombre de la Asignatura -->
                    <b-td class="secondary-cell-my-classes">
                      <div class="d-flex container-matter-my-classes">
                        <div
                          v-if="sectionMatter.egress_profile_matter_id != null"
                          class="text-left div-href-test"
                          v-b-tooltip.v-secondary.right.noninteractive="
                            `${
                              institution &&
                              institution.internal_use_id == 'duoc_uc'
                                ? 'Ir al PDA Docente'
                                : 'Ir al ' +
                                  $getVisibleNames(
                                    'manual.matter_program',
                                    false,
                                    'Programa de Asignatura'
                                  )
                            }`
                          "
                          @click="
                            redirectToPDA(
                              sectionMatter.egress_profile_matter_id
                            )
                          "
                        >
                          {{ sectionMatter.matter_code }} -
                          {{ sectionMatter.matter_name }}
                        </div>
                        <div v-else class="mr-0">
                          {{ sectionMatter.matter_code }} -
                          {{ sectionMatter.matter_name }}
                        </div>
                      </div>
                    </b-td>
                    <!-- Nombre de la Sección -->
                    <b-td class="secondary-cell-my-classes text-left">
                      {{ sectionMatter.name }}
                    </b-td>
                    <!-- Nombre del Periodo -->
                    <b-td class="secondary-cell-my-classes text-left">
                      {{ section.period_name }}
                    </b-td>
                    <!-- Docentes -->
                    <b-td class="secondary-cell-my-classes text-left">
                      {{ sectionMatter.professors.join(", ") }}
                    </b-td>
                    <!-- Opciones -->
                    <b-td
                      class="secondary-cell-my-classes noprint"
                      style="text-align: -webkit-center"
                    >
                      <!-- Botón 3 puntos que abre popover -->
                      <div>
                        <ButtonWrapper :item_id="sectionMatter.id">
                          <template v-slot:btn-slot>
                            <div class="container-btn-three-dots">
                              <b-button
                                variant="secondary"
                                @click="redirectToSection(sectionMatter.id)"
                                class="btn-popover-my-classes mb-2 w-100"
                              >
                                Notas de la
                                {{
                                  $getVisibleNames(
                                    "teaching.section",
                                    false,
                                    "Sección"
                                  )
                                }}</b-button
                              >
                              <b-button
                                v-if="
                                  institution &&
                                  (institution.internal_use_id == 'ciisa_uss' ||
                                    institution.internal_use_id == 'duoc_uc')
                                "
                                variant="secondary"
                                class="btn-popover-my-classes mb-2 w-100"
                                @click="
                                  redirectToAssessmentStudent(sectionMatter.id)
                                "
                                >Assessment por Estudiante
                              </b-button>
                              <b-button
                                variant="secondary"
                                @click="
                                  redirectToEvaluationTeacher(sectionMatter.id)
                                "
                                class="btn-popover-my-classes mb-2 w-100"
                              >
                                {{
                                  $getVisibleNames(
                                    "evaluations2.evaluation",
                                    true,
                                    "Evaluaciones"
                                  )
                                }}
                                de la
                                {{
                                  $getVisibleNames(
                                    "teaching.section",
                                    false,
                                    "Sección"
                                  )
                                }}</b-button
                              >
                              <b-button
                                v-if="
                                  institution &&
                                  institution.kl_assessment &&
                                  (institution.internal_use_id == 'ciisa_uss' ||
                                    institution.internal_use_id == 'duoc_uc')
                                "
                                variant="secondary"
                                @click="
                                  redirectToAssessmentRange(sectionMatter.id)
                                "
                                class="btn-popover-my-classes w-100"
                              >
                                Reporte Assessment
                              </b-button>
                            </div>
                          </template>
                        </ButtonWrapper>
                        <!-- <b-button
                          variant="secondary"
                          size="sm"
                          :id="`popover-my-classes-teacher-${sectionMatter.id}`"
                          class="btn-three-dots"
                        >
                          <b-icon icon="three-dots"> </b-icon>
                        </b-button> -->
                      </div>
                    </b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </template>
          <template
            v-if="
              user.groups.includes(6) &&
              sections_teacher.filter((x) => x.sections.length > 0).length == 0
            "
          >
            <div class="text-left">
              No hay
              {{ $getVisibleNames("teaching.section", true, "Secciones") }}
              para mostrar
            </div>
          </template>
        </template>
        <template v-else>
          <div class="text-left">
            <span>Este módulo no está habilitado para su institución</span>
          </div>
        </template>
      </template>
    </Card>
  </div>
</template>

<script>
// import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "CurrentSectionTeacher",
  components: {
    Card: () => import("@/components/users/Card"),
    ButtonWrapper: () => import("@/components/reusable/ButtonWrapper"),
  },
  data() {
    return {
      first_fetch_current_section_teacher: true,
      select_period_current_section_teacher: null,
      select_career_current_section_teacher: null,
      select_campus_current_section_teacher: null,
      select_matter_current_section_teacher: null,
      sections_teacher: [],
      optionsPeriodCurrentSectionTeacher: [],
      optionsCareerCurrentSectionTeacher: [],
      optionsCampuCurrentSectionTeacher: [],
      optionsMatterCurrentSectionTeacher: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
    }),
  },
  methods: {
    redirectToPDA(egress_profile_matter_id) {
      if (this.institution && this.institution.internal_use_id == "duoc_uc")
        this.$router.push({
          name: "LearningSuitcaseTeacherView",
          params: {
            egress_profile_matter_id: egress_profile_matter_id,
          },
        });
      else {
        this.$router.push({
          name: "MatterProgramView",
          params: {
            matter_id: egress_profile_matter_id,
          },
        });
      }
    },
    optionsCareersCurrentSection(careersFromApi) {
      this.optionsCareerCurrentSectionTeacher = [
        {
          value: null,
          text: `Filtrar por ${this.$getVisibleNames(
            "mesh.career",
            false,
            "Carrera"
          )}`,
        },
      ];
      // Crear un Map para almacenar carreras únicas
      const uniqueCareers = new Map();
      // Iterar sobre los datos de la API y llenar el Map
      careersFromApi.forEach((x) => {
        x.sections.forEach((y) => {
          if (y.career_name && !uniqueCareers.has(y.career_name)) {
            uniqueCareers.set(y.career_name, y.career_id);
          }
        });
      });
      // Convertir el Map en un array de opciones y añadirlo a optionsPeriodCurrentSectionTeacher
      uniqueCareers.forEach((id, name) => {
        this.optionsCareerCurrentSectionTeacher.push({
          value: id,
          text: name,
        });
      });
    },
    optionsPeriodCurrentSection(periodsFromApi) {
      this.optionsPeriodCurrentSectionTeacher = [
        {
          value: null,
          text: `Filtrar por ${this.$getVisibleNames(
            "mesh.period",
            false,
            "Período"
          )}`,
        },
      ];
      const uniquePeriods = new Map();

      periodsFromApi.forEach((x) => {
        if (!uniquePeriods.has(x.period_name)) {
          uniquePeriods.set(x.period_name, x.period_id);
        }
      });
      uniquePeriods.forEach((id, name) => {
        this.optionsPeriodCurrentSectionTeacher.push({
          value: id,
          text: name,
        });
      });
    },
    optionsCampusCurrentSection(campusFromApi) {
      this.optionsCampuCurrentSectionTeacher = [
        {
          value: null,
          text: `Filtrar por ${this.$getVisibleNames(
            "mesh.campus",
            false,
            "Sede"
          )}`,
        },
      ];
      const uniqueCampus = new Map();
      campusFromApi.forEach((x) => {
        x.sections.forEach((y) => {
          if (y.campus_name && !uniqueCampus.has(y.campus_name)) {
            uniqueCampus.set(y.campus_name, y.campus_id);
          }
        });
      });
      uniqueCampus.forEach((id, name) => {
        this.optionsCampuCurrentSectionTeacher.push({
          value: id,
          text: name,
        });
      });
    },
    optionsMatterCurrentSection(mattersFromApi) {
      this.optionsMatterCurrentSectionTeacher = [
        {
          value: null,
          text: `Filtrar por ${this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          )}`,
        },
      ];
      const uniqueMatter = new Map();
      mattersFromApi.forEach((x) => {
        x.sections.forEach((y) => {
          if (y.matter_name && !uniqueMatter.has(y.matter_name)) {
            uniqueMatter.set(y.matter_name, y.matter_id);
          }
        });
      });
      uniqueMatter.forEach((id, name) => {
        this.optionsMatterCurrentSectionTeacher.push({
          value: id,
          text: name,
        });
      });
    },
    redirectToSection(id) {
      this.$router.push({
        name: "NewFullGradingView",
        params: {
          section_id: id,
        },
      });
    },
    redirectToEvaluationTeacher(id) {
      this.$router.push({
        name: "EvaluatorEvaluationsListView",
        params: {
          section_id: id,
        },
      });
    },
    redirectToAssessmentStudent(id) {
      this.$router.push({
        name: "AssessmentStudentsView",
        params: {
          section_id: id,
        },
      });
    },
    redirectToAssessmentRange(id) {
      this.$router.push({
        name: "AssessmentRangeSectionView",
        params: {
          section_id: id,
        },
      });
    },
    getSectionsTeacher() {
      if (
        this.user &&
        this.user.groups.includes(6) &&
        this.institution &&
        this.institution.kl_assessment
      ) {
        let arr = [];

        if (this.select_campus_current_section_teacher != null)
          arr.push("campus=" + this.select_campus_current_section_teacher);
        if (this.select_career_current_section_teacher != null)
          arr.push("career=" + this.select_career_current_section_teacher);
        if (this.select_period_current_section_teacher != null)
          arr.push("period=" + this.select_period_current_section_teacher);
        if (this.select_matter_current_section_teacher != null)
          arr.push("matter=" + this.select_matter_current_section_teacher);

        let partial_url = "";
        if (arr.length > 0) partial_url = "?" + arr.join("&");

        this.$restful
          .Get(`/evaluations2/dashboard_teacher_sections/${partial_url}`)
          .then((response) => {
            this.sections_teacher = response.filter((x) => x.is_current_period);
            if (this.first_fetch_current_section_teacher) {
              this.optionsPeriodCurrentSection(this.sections_teacher);
              this.optionsCareersCurrentSection(this.sections_teacher);
              this.optionsCampusCurrentSection(this.sections_teacher);
              this.optionsMatterCurrentSection(this.sections_teacher);
              this.first_fetch_current_section_teacher = false;
            }
          });
      }
    },
  },
  mounted() {
    this.getSectionsTeacher();
  },
  watch: {
    user() {
      this.getSectionsTeacher();
    },
    institution() {
      this.getSectionsTeacher();
    },
  },
  created() {
    this.$hasPermissions();
  },
};
</script>

<style scoped>
.container-title-my-classes {
  display: flex;
  justify-content: space-between;
}
.dashboard-card {
  display: block;
  border-radius: 7px;
  margin-top: 5px;
  margin-bottom: 15px;
  box-shadow: 0px 1px 8px -1px #9d9d9d;
  /* box-shadow: 0px 1px 5px 0px #9d9d9d; */
  transition: all 0.5s ease-out;
}
.card-header-icon {
  margin-top: 2px;
  margin-right: 0.1em;
}
.border-table {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.primary-cell-my-classes {
  text-align: left;
  vertical-align: middle;
  background: #eed9b8 !important;
  color: black !important;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.secondary-cell-my-classes {
  text-align: center;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
.div-href-test {
  color: #007bff;
}
.div-href-test:hover {
  color: #0057b1 !important;
  text-decoration: underline;
  cursor: pointer;
}
.container-btn-three-dots {
  max-width: 250px;
}
.btn-three-dots {
  border-radius: 50%;
  padding: 2.5px 3px;
}
.btn-popover-my-classes {
  padding: 0.1rem 0.3rem;
  font-size: 11pt;
  border-radius: 4px;
}
.container-select {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.select-form {
  min-width: 270px;
  font-size: var(--secondary-font-size);
  margin: 3px;
}
@media (max-width: 768px) {
  .container-select div {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .select-form {
    margin: 3px;
  }
}
@media (max-width: 576px) {
  .container-select div {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .select-form {
    margin: 3px;
  }
}
@media print {
  .secondary-cell-my-classes {
    background-color: transparent !important;
  }
}
</style>