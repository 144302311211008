
export default [
    {
        path: '/reports/form/',
        name: 'ReportsView',
        component: () =>
            import("@/components/reports/ReportsView"),
    },
    {
        path: '/reports/students/',
        name: 'StudentsReportsView',
        component: () =>
            import("@/components/reports/StudentsReports"),
    },
    {
        path: '/reports/assessment-students/:section_id/',
        name: 'AssessmentStudentsView',
        component: () =>
            import("@/components/reports/TeachingReport/AssessmentStudentsView"),
    },
    {
        path: '/reports/assessment-range/:section_id/',
        name: 'AssessmentRangeSectionView',
        component: () =>
            import("@/components/reports/TeachingReport/AssessmentRangeSection/AssessmentRangeSectionView"),
    },
    {
        path: '/reports/assessment-student/:section_id/',
        name: 'AssessmentStudentView',
        component: () =>
            import("@/components/reports/TeachingReport/AssessmentStudentView"),
    },
    {
        path: '/reports/',
        name: 'GeneralReport',
        component: () =>
            import("@/components/reports/TeachingReport/GeneralReport"),
    },
];
