<template>
  <div v-if="achievement_ranges.length > 0">
    <template
      v-if="moreThanOneAchievementRange && achievement_range_group_id == null"
    >
      <div class="th-table-ranges p-1">
        <b-form-group
          label-cols-sm="2"
          label="Rango Seleccionado:"
          label-for="input-range_group"
          class="mt-2 mr-2 ml-2"
        >
          <b-form-select
            id="input-range_group"
            :options="achievementGroupsList"
            v-model="selected_range_group"
            value-field="id"
            text-field="name"
            class=""
          >
          </b-form-select>
        </b-form-group>
      </div>
    </template>
    <b-table-simple
      v-if="achievementRangeList.length > 0"
      bordered
      hover
      class="table-ranges"
    >
      <b-thead>
        <b-tr>
          <b-th class="th-table-ranges" style="width: 40%">
            {{
              $getVisibleNames(
                "assessment2.achievementrange",
                false,
                "Rangos de Logro"
              ).toUpperCase()
            }}</b-th
          >
          <b-th class="th-table-ranges"> COLOR</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="range in achievementRangeList" :key="range.id">
          <b-td class="td-range-tables">{{ range.name.toUpperCase() }} </b-td>
          <b-td
            :style="{
              'background-color': range.color + ' !important',
            }"
          >
            <div>
              <strong>
                {{ range.start_achievement }}%
                <span v-if="range.include_start_achievement">&le;</span>
                <span v-else>&lt;</span>
                x
                <span v-if="range.include_finish_achievement">&le;</span>
                <span v-else>&lt;</span>
                {{ range.finish_achievement }}%
              </strong>
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
// import * as names from "@/store/names";
// import { mapGetters } from "vuex";
export default {
  name: "AchievementTableRanges",
  components: {},
  props: {
    achievement_ranges: {
      type: Array,
    },
    achievement_ranges_groups: {
      type: Array,
    },
    achievement_range_group_id: {
      type: [Number, null],
      default: null,
    },
  },
  data() {
    return {
      selected_range_group: this.achievement_range_group_id,
    };
  },
  computed: {
    achievementGroupsIds() {
      return [
        ...new Set(
          this.achievement_ranges.map((x) => x.achievement_range_group)
        ),
      ];
    },
    achievementGroupsList() {
      return this.achievement_ranges_groups.filter((x) =>
        this.achievementGroupsIds.includes(x.id)
      );
    },
    moreThanOneAchievementRange() {
      if (this.achievement_range_group_id != null) return false;
      else {
        if (this.achievementGroupsIds.length > 1) return true;
        else return false;
      }
    },
    achievementRangeList() {
      if (this.achievement_range_group_id != null) {
        return this.achievement_ranges
          .filter(
            (x) => x.achievement_range_group == this.achievement_range_group_id
          )
          .sort(function (a, b) {
            if (a.finish_achievement < b.finish_achievement) return 1;
            if (a.finish_achievement > b.finish_achievement) return -1;
            if (a.start_achievement < b.start_achievement) return 1;
            if (a.start_achievement > b.start_achievement) return -1;
            return 0;
          });
      } else {
        return this.achievement_ranges
          .filter((x) => x.achievement_range_group == this.selected_range_group)
          .sort(function (a, b) {
            if (a.finish_achievement < b.finish_achievement) return 1;
            if (a.finish_achievement > b.finish_achievement) return -1;
            if (a.start_achievement < b.start_achievement) return 1;
            if (a.start_achievement > b.start_achievement) return -1;
            return 0;
          });
      }
    },
  },
  methods: {},
  mounted() {
    if (this.achievement_range_group_id == null) {
      this.selected_range_group = this.achievementGroupsIds[0];
    }
  },
  watch: {
    selected_range_group(value) {
      if (value != null) this.$emit("selected_range_group", value);
    },
  },
  created() {},
};
</script>

<style scoped>
.table-ranges {
  font-size: var(--secondary-font-size);
}
.th-table-ranges {
  background: var(--kl-menu-color) !important;
  color: white;
  font-weight: bold;
}
.td-range-tables {
  font-weight: bold;
}
</style>