<template>
  <div>
    <div class="float-center table-title">
      <strong>
        {{ $getVisibleNames("mesh.egressprofilematter", true, "Asignaturas") }}
        <InfoTooltip
          :tooltip_text="`Estas ${$getVisibleNames(
            'mesh.egressprofilematter',
            true,
            'Asignaturas'
          )} son las específicas de los perfiles de egreso que pueden ser únicas o compartidas.`"
        >
        </InfoTooltip>
      </strong>
      <b-form-group
        :label="
          $getVisibleNames('mesh.egressprofile', false, 'Perfil de Egreso')
        "
        label-for="input-egress-profile-id"
        label-cols-sm="3"
        label-align-sm="center"
        label-size="sm"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-select
            id="input-egress-profile-id"
            v-model="selected_egress_profile_id"
            :options="filterEgressProfiles()"
            value-field="id"
            text-field="professional_title"
            aria-describedby="input-egress-profile-id-feedback"
            :state="selected_egress_profile_id != null"
            class="w-75"
            @change="() => (egress_profile_id = selected_egress_profile_id)"
            :disabled="filterEgressProfiles().length == 0"
          >
            <template #first>
              <b-form-select-option :value="null">{{
                filterEgressProfiles().length > 0
                  ? String(
                      "-- Seleccione un " +
                        $getVisibleNames(
                          "mesh.egressprofile",
                          false,
                          "Perfil de Egreso"
                        ) +
                        " --"
                    )
                  : String(
                      "No hay " +
                        $getVisibleNames(
                          "mesh.egressprofile",
                          true,
                          "Perfiles De Egreso"
                        ) +
                        " asociados a su usuario."
                    )
              }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-input-group>
        <b-form-invalid-feedback id="input-egress-profile-id-feedback"
          >Seleccione un
          {{
            $getVisibleNames("mesh.egressprofile", false, "Perfil de Egreso")
          }}.</b-form-invalid-feedback
        >
      </b-form-group>
    </div>
    <div class="float-left">
      <b-button
        v-if="allows_crud && user.is_superuser"
        size="sm"
        class="mt-3 mr-1 noprint"
        variant="secondary"
        @click="$bvModal.show(`new-matter-modal`)"
        ><b-icon-plus></b-icon-plus>Agregar
        {{
          $getVisibleNames("mesh.egressprofilematter", false, "Asignatura")
        }}</b-button
      >
      <!-- <b-button
        v-if="selected_egress_profile_id != null"
        variant="secondary"
        size="sm"
        class="mt-3 ml-1 mr-2 noprint"
        :disabled="disabled_button_excel"
        @click="DownloadExcel()"
      >
        {{ $getVisibleNames("mesh.egressprofilematter", false, "Asignatura") }}
        XLSX
      </b-button> -->
    </div>
    <div v-if="selected_egress_profile_id">
      <GenericBTable
        :items="profileMatterFilter"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="input_search"
        :filterCustom="filterCustom"
        :filter-included-fields="filterOn"
        :show_pagination="true"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :busy="isBusy"
        :display_id="'profile-matter'"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
        @emitChangeSearchField="changeInputSearch"
      >
        <template #btn-download>
          <ButtonDownload
            v-if="selected_egress_profile_id != null"
            :tooltip_text="`${$getVisibleNames(
              'mesh.egressprofilematter',
              false,
              'Asignatura'
            )} XLSX`"
            :click_button="DownloadExcel"
            :disabled_button="disabled_button_excel"
            :class="`ml-1 mr-2 `"
          ></ButtonDownload>
        </template>
        <template #cell(name)="row">
          <b-link
            :to="{
              name: 'MatterProgramView',
              params: {
                matter_id: row.item.id,
              },
            }"
          >
            {{ getMatterName(row.item.matter).toUpperCase() }}
          </b-link>
        </template>
        <template #cell(egress_profile_id)="row">
          <span
            v-if="getEgressProfile(row.item.egress_profile)"
            v-b-tooltip.v-secondary="
              getEgressProfile(row.item.egress_profile).professional_title
            "
            >{{
              getEgressProfile(row.item.egress_profile).professional_title
            }}</span
          >
        </template>
        <template #cell(weeks)="row">
          <span v-if="row.item.use_matter_values">{{
            (matters.find((x) => x.id == row.item.matter) || {}).weeks || 0
          }}</span>
          <span v-else>{{ row.item.weeks }}</span>
        </template>
        <template #cell(credits)="row">
          <span v-if="row.item.use_matter_values">{{
            (matters.find((x) => x.id == row.item.matter) || {}).credits || 0
          }}</span>
          <span v-else>{{ row.item.credits }}</span>
        </template>
        <template #cell(institutional_credits)="row">
          <span v-if="row.item.use_matter_values">{{
            (matters.find((x) => x.id == row.item.matter) || {})
              .institutional_credits || 0
          }}</span>
          <span v-else>{{ row.item.institutional_credits }}</span>
        </template>
        <template #cell(actions)="row">
          <!-- <div v-if="!allows_crud">
            <b-button
              size="sm"
              variant="none"
              v-b-tooltip.v-secondary.auto.noninteractive="
                `Ir al ${$getVisibleNames(
                  'manual.matter_program',
                  false,
                  'Programa de la Asignatura'
                )}`
              "
              @click="redirectToMatter(row.item.id)"
              ><b-icon icon="eye" scale="1"></b-icon
            ></b-button>
          </div> -->
          <div v-if="allows_crud">
            <ButtonWrapper :item_id="row.item.id">
              <template v-slot:btn-slot>
                <b-button
                  v-if="
                    allows_crud &&
                    !row.item.is_closed &&
                    ((user_position &&
                      [1, 2].includes(user_position.position) &&
                      [1, 2].includes(user.groups[0])) ||
                      user.is_superuser ||
                      $hasObjectPermission(
                        'mesh.faculty',
                        'change',
                        getFacultyId(row.item.career)
                      ) ||
                      $hasObjectPermission(
                        'mesh.career',
                        'change',
                        row.item.career
                      ))
                  "
                  size="sm"
                  @click="fetch_history(row)"
                  v-b-tooltip.v-secondary.auto.noninteractive="'Versiones'"
                >
                  <b-badge variant="light">{{
                    row.item.versions_count
                  }}</b-badge>
                </b-button>
                <b-button
                  size="sm"
                  variant="none"
                  v-b-tooltip.v-secondary.auto.noninteractive="
                    `Ir al ${$getVisibleNames(
                      'manual.matter_program',
                      false,
                      'Programa de la Asignatura'
                    )}`
                  "
                  @click="redirectToMatter(row.item.id)"
                  ><b-icon icon="eye" scale="1"></b-icon
                ></b-button>
                <button-edit
                  v-can="'teaching.change_matter'"
                  v-if="
                    allows_crud &&
                    !row.item.is_closed &&
                    ((user_position &&
                      [1, 2].includes(user_position.position) &&
                      [1, 2].includes(user.groups[0])) ||
                      user.is_superuser ||
                      $hasObjectPermission(
                        'mesh.faculty',
                        'change',
                        getFacultyId(row.item.career)
                      ) ||
                      $hasObjectPermission(
                        'mesh.career',
                        'change',
                        row.item.career
                      ))
                  "
                  @click="$bvModal.show(`modal-edit-matter-${row.item.id}`)"
                  v-b-tooltip.v-secondary.auto.noninteractive="{
                    customClass: 'custom-tooltip',
                  }"
                  :title="`Editar ${$getVisibleNames(
                    'mesh.egressprofilematter',
                    false,
                    'Asignatura'
                  )}`"
                ></button-edit>

                <b-button
                  v-if="allows_crud && !row.item.is_closed && user.is_superuser"
                  size="sm"
                  variant="none"
                  v-b-tooltip.v-secondary.auto.noninteractive="{
                    customClass: 'custom-tooltip',
                  }"
                  :title="`Eliminar ${$getVisibleNames(
                    'mesh.egressprofilematter',
                    false,
                    'Asignatura'
                  )}`"
                  @click="deleteMatter(row.item.id)"
                  ><b-icon icon="trash" scale="1"></b-icon
                ></b-button>
              </template>
            </ButtonWrapper>
            <b-modal
              :id="`modal-edit-matter-${row.item.id}`"
              hide-footer
              :title="`Editar ${$getVisibleNames(
                'mesh.egressprofilematter',
                false,
                'Asignatura'
              )}`"
              size="xl"
            >
              <MatterForm
                :Matter="row.item"
                :egress_profile_id="row.item.egress_profile"
                :show_delete_button="false"
                @updated="updateMatter"
              ></MatterForm>
            </b-modal>
          </div>
        </template>
        <template #row-details="row">
          <b-card v-if="allows_crud">
            <b-table
              thead-class="th-style"
              borderless
              :items="filterHistory(row.item.id)"
              :fields="fields_history"
            >
              <template #cell(date_time)="cell">
                {{ cell.item.date_time | FormatToDateTime }}
              </template>
              <template #cell(comment)="cell">
                <div class="row">
                  <div class="col">
                    <b-form-textarea
                      id="input-comments"
                      v-model="cell.item.comment"
                      size="sm"
                    ></b-form-textarea>
                  </div>
                  <b-button
                    v-can="'reversion.change_revision'"
                    class="edit-button"
                    size="sm"
                    variant="none"
                    title="Guardar comentario"
                    @click="
                      saveComment(cell.item.revision_id, cell.item.comment)
                    "
                  >
                    <b-icon-box-arrow-in-down
                      class="h4 mb-0"
                    ></b-icon-box-arrow-in-down>
                  </b-button>
                </div>
              </template>
              <template #cell(active)="cell">
                <div v-if="ActiveMatter(cell.item.revision_id, row.item)">
                  <b-icon
                    icon="check-circle-fill"
                    scale="1"
                    variant="primary"
                  ></b-icon>
                </div>
                <div v-else>
                  <b-icon icon="x-circle-fill" scale="1"></b-icon>
                </div>
              </template>
              <template #cell(actions)="cell">
                <b-button
                  size="sm"
                  @click="
                    info(
                      filterHistoryData(cell.item.id),
                      cell.item,
                      $event.target
                    )
                  "
                  class="mr-1"
                >
                  Ver versión
                </b-button>
              </template>
            </b-table>
          </b-card>
        </template>
      </GenericBTable>
      <b-button
        size="sm"
        class="mt-3 mr-1"
        variant="secondary"
        @click="$bvModal.show(`base-matter-modal`)"
        >Mostrar
        {{
          $getVisibleNames("teaching.matter", true, "Asignaturas Base")
        }}</b-button
      >
    </div>
    <b-modal :id="`base-matter-modal`" hide-footer size="xl">
      <template #modal-title>
        <div class="float-center mt-2 mb-2 table-title">
          <strong>
            {{
              $getVisibleNames(
                "teaching.matter",
                true,
                "Asignaturas Base"
              ).toUpperCase()
            }}
          </strong>
          <InfoTooltip
            :tooltip_text="`Estas son las ${$getVisibleNames(
              'teaching.matter',
              true,
              'Asignaturas Base'
            )} que pueden ser utilizadas en varios Perfiles de Egreso, replicando sus resultados de aprendizaje.`"
          >
          </InfoTooltip>
        </div>
      </template>
      <div v-if="allows_crud" class="float-left">
        <!-- <b-button
          size="sm"
          class="mt-2 mr-1"
          variant="secondary"
          @click="$bvModal.show(`new-old-matter-modal`)"
          ><b-icon-plus></b-icon-plus>Agregar
          {{
            $getVisibleNames("teaching.matter", false, "Asignatura Base")
          }}</b-button
        > -->
      </div>
      <GenericBTable
        :items="matterFiltered"
        :fields="fields_old_matter"
        :filterCustom="filterCustom2"
        :filter="input_search"
        :sort-by.sync="sortBy2"
        :sort-desc.sync="sortDesc"
        :show_pagination="true"
        :search_filter="true"
        :columns_display="true"
        :allows_crud="allows_crud"
        :display_id="'base-matter'"
        @emitChangeSearchField="changeInputSearch"
      >
        <!-- <template #cell(egress_profiles)="row">
          <b-button
            :id="`popover-button-egress-profiles-${row.item.id}`"
            size="sm"
            style="max-height: 30px"
          >
            <b-badge variant="light">{{
              egressProfilesMatter(row.item.id).length
            }}</b-badge>
          </b-button>
          <b-popover
            :target="`popover-button-egress-profiles-${row.item.id}`"
            triggers="focus"
          >
            <template #title>
              <div class="text-center">
                {{
                  $getVisibleNames(
                    "mesh.egressprofile",
                    true,
                    "Perfiles De Egreso"
                  )
                }}
              </div>
            </template>
            <div
              class="campus-container"
              v-if="egressProfilesMatter(row.item.id).length > 0"
            >
              <div
                v-for="item in egressProfilesMatter(row.item.id)"
                :key="item.id"
                class="campus-div"
              >
                <b-button
                  size="sm"
                  variant="none"
                  v-b-tooltip.v-secondary.noninteractive="
                    `Ir a ${$getVisibleNames(
                      'mesh.egressprofile',
                      false,
                      'Perfil de Egreso'
                    )}`
                  "
                  @click="redirectToEgressProfile(item.egress_profile)"
                  >{{
                    getEgressProfile(item.egress_profile).professional_title
                  }}</b-button
                >
              </div>
            </div>
            <div v-else>No está afiliado a ningún perfil de egreso.</div>
          </b-popover>
        </template> -->
        <template #cell(study_units)="row">
          <b-button
            :id="`popover-button-matter-${row.item.id}`"
            size="sm"
            style="max-height: 30px"
          >
            <b-badge variant="light">{{ row.item.study_units.length }}</b-badge>
          </b-button>
          <b-popover
            :target="`popover-button-matter-${row.item.id}`"
            placement="lefttop"
            triggers="focus"
          >
            <template #title>Unidades de estudio</template>
            <div
              class="campus-container"
              v-if="row.item.study_units.length > 0"
            >
              <div
                v-for="item in getStudyUnitsName(row.item.study_units)"
                :key="item.id"
                class="campus-div"
              >
                {{ item.name || item.full_sentence }}
              </div>
            </div>
            <div v-else>No cuenta con unidades de estudio</div>
          </b-popover>
        </template>
        <template #cell(actions)="row">
          <button-edit
            v-can="'teaching.change_matter'"
            v-if="
              allows_crud &&
              ((user_position &&
                [1, 2].includes(user_position.position) &&
                [1, 2].includes(user.groups[0])) ||
                user.is_superuser)
            "
            @click="MatterNameModal(row.item)"
            v-b-tooltip.v-secondary.auto.noninteractive="{
              customClass: 'custom-tooltip',
            }"
            :title="`Editar ${$getVisibleNames(
              'teaching.matter',
              false,
              'Asignatura Base'
            )}`"
          ></button-edit>
          <b-modal
            :id="`modal-matter-name-${row.item.id}`"
            hide-footer
            :title="`Editar ${$getVisibleNames(
              'teaching.matter',
              false,
              'Asignatura Base'
            )}`"
            size="lg"
          >
            <OldMatterForm
              :OldMatter="row.item"
              @updated="$bvModal.hide(`modal-matter-name-${row.item.id}`)"
              :show_delete_button="
                allows_crud &&
                ((user_position &&
                  [1, 2].includes(user_position.position) &&
                  [1, 2].includes(user.groups[0])) ||
                  user.is_superuser)
              "
              @delete="removeOldMatter"
            >
            </OldMatterForm>
          </b-modal>
        </template> </GenericBTable
    ></b-modal>

    <b-modal :id="infoModal.id" size="xl" hide-footer @hide="resetInfoModal">
      <template v-if="infoModal.title" #modal-title>
        Fecha de modificación:
        {{ infoModal.title.date_time | FormatToDateTime }}</template
      >
      <div v-if="infoModal.content">
        <MatterProgram
          :egress_profile_matter_id="infoModal.content.id"
          :allows_crud="false"
        ></MatterProgram>
      </div>
      <div>
        <b-button
          size="sm"
          variant="danger"
          v-can="'reversion.delete_revision'"
          @click="deleteChanges(infoModal.title, infoModal.content)"
        >
          Eliminar versión
        </b-button>
        <b-button
          size="sm"
          class="float-right"
          @click="$bvModal.hide('info-modal-matter')"
        >
          Cancelar
        </b-button>
        <b-button
          size="sm"
          variant="primary"
          class="float-right mr-5"
          v-can="'reversion.change_revision'"
          @click="applyChanges(infoModal.title, infoModal.content)"
        >
          Aplicar versión
        </b-button>
      </div>
    </b-modal>
    <b-modal
      :id="`new-old-matter-modal`"
      hide-footer
      :title="`Crear ${$getVisibleNames(
        'teaching.matter',
        false,
        'Asignatura Base'
      )}`"
      size="lg"
    >
      <OldMatterForm @created="$bvModal.hide(`new-old-matter-modal`)">
      </OldMatterForm>
    </b-modal>
    <b-modal
      v-if="allows_crud"
      id="new-matter-modal"
      :title="`Crear ${$getVisibleNames(
        'mesh.egressprofilematter',
        false,
        'Asignatura'
      )}`"
      size="xl"
      hide-footer
    >
      <b-form-group
        :label="
          $getVisibleNames('mesh.egressprofile', false, 'Perfil de Egreso')
        "
        label-for="sort-by-select"
        label-cols-sm="2"
        label-align-sm="left"
        label-size="sm"
        class="ml-5 mb-4"
        v-slot="{ ariaDescribedby }"
      >
        <b-input-group size="sm">
          <b-form-select
            id="sort-by-select"
            v-model="egress_profile_id"
            :options="filterEgressProfiles()"
            value-field="id"
            @change="forceRerender"
            text-field="professional_title"
            :aria-describedby="ariaDescribedby"
            class="w-75"
            :disabled="filterEgressProfiles().length == 0"
          >
            <template #first>
              <b-form-select-option :value="null" disabled>{{
                filterEgressProfiles().length > 0
                  ? String(
                      "-- Seleccione un " +
                        $getVisibleNames(
                          "mesh.egressprofile",
                          false,
                          "Perfil de Egreso"
                        ) +
                        " --"
                    )
                  : String(
                      "No hay " +
                        $getVisibleNames(
                          "mesh.egressprofile",
                          true,
                          "Perfiles De Egreso"
                        ) +
                        " asociados a su usuario."
                    )
              }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-input-group>
      </b-form-group>
      <MatterForm
        v-if="egress_profile_id"
        :key="componentKey"
        :egress_profile_id="egress_profile_id"
        @created="createMatter"
        @close_modal="createMatter"
      ></MatterForm>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";
export default {
  name: "MatterList",
  components: {
    MatterProgram: () =>
      import("@/components/teaching/MatterProgram/MatterProgram"),
    MatterForm: () => import("@/components/mesh/Matrix2/MatterForm"),
    OldMatterForm: () => import("@/components/mesh/Matrix2/OldMatterForm"),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
    InfoTooltip: () => import("@/components/reusable/InfoTooltip"),
    ButtonWrapper: () => import("@/components/reusable/ButtonWrapper"),
    ButtonDownload: () =>
      import("@/components/reusable/Buttons/ButtonDownload"),
  },
  props: {
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields_history: [
        {
          key: "date_time",
          label: "Fecha de modificación",
          sortable: true,
        },
        {
          key: "user_id",
          label: "Usuario",
          sortable: true,
          formatter: (value) => {
            return this.getUserName(value);
          },
        },
        {
          key: "comment",
          label: "Comentario",
          sortable: true,
        },
        {
          key: "active",
          label: "Aplicado",
          sortable: true,
          sortDirection: "desc",
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: "actions",
          label: "Vista",
          thClass: "noprint",
          tdClass: "noprint",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 1000,
      pageOptions: [5, 10, 15, { value: 1000, text: "Mostrar Todos" }],
      sortBy: "",
      sortBy2: "name",
      sortDesc: false,
      sortDirection: "asc",
      filterOn: [],
      infoModal: {
        id: "info-modal-matter",
        title: null,
        content: null,
      },
      egress_profile_id: null,
      selected_egress_profile_id: null,
      // cycle_id: null,
      matter_history: [],
      history_data: [],
      componentKey: 0,
      input_search: "",
      disabled_button_excel: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      user_position: names.USER_POSITION,
      users: names.USERS,
      regimes: names.REGIMES,
      careers: names.CAREERS,
      egress_profiles: names.EGRESS_PROFILES,
      profile_matter: names.EGRESS_PROFILES_MATTERS,
      profile_types: names.PROFILE_TYPES,
      matters: names.MATTERS,
      studyUnits: names.STUDY_UNITS,
      cycles: names.CYCLES,
      formationAreas: names.FORMATION_AREAS,
    }),
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    profileMatterFilter() {
      if (this.selected_egress_profile_id == null) return [];
      return this.profile_matter.filter(
        (x) => x.egress_profile == this.selected_egress_profile_id
      );
    },
    matterFiltered() {
      if (this.selected_egress_profile_id == null) return [];
      let list = [];
      this.profileMatterFilter.forEach((x) => {
        list.push(this.matters.find((matter) => matter.id == x.matter));
      });
      return list;
    },
    fields_old_matter() {
      return [
        {
          key: "name",
          label: this.$getVisibleNames(
            "teaching.matter",
            true,
            "Asignaturas Base"
          ),
          sortable: true,
          class: "text-center",
          display_column: true,
        },
        {
          key: "code",
          label: "Código",
          sortable: true,
          class: "text-center",
          display_column: true,
        },
        {
          key: "weeks",
          label: "Semanas",
          sortable: true,
          display_column: true,
        },
        {
          key: "study_units",
          label: "Unidades de estudio",
          sortable: true,
          class: "text-center",
          display_column: true,
        },
        // {
        //   key: "egress_profiles",
        //   label: "Perfiles de Egreso",
        //   sortable: true,
        //   class: "text-center",
        //   display_column: true,
        // },
        {
          key: "actions",
          label: "",
          display_column: true,
          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
    fields() {
      return [
        {
          key: "name",
          label: this.$getVisibleNames(
            "mesh.egressprofilematter",
            true,
            "Asignaturas"
          ),
          sortable: true,
          tdClass: "text-left",
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "code",
          label: "Código",
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
        },
        {
          key: "order",
          label: "Orden",
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-center",
        },
        {
          key: "regime",
          label: this.$getVisibleNames("mesh.periodtype", false, "Régimen"),
          sortable: true,
          tdClass: "text-center",
          formatter: (value) => {
            return this.getRegimenName(value);
          },
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "formation_area",
          label: String(
            this.$getVisibleNames(
              "mesh.formationarea",
              false,
              "Área De Formación"
            )
          ),
          sortable: true,
          formatter: (value) => {
            return value ? this.formationAreaName(value) : "N/A";
          },
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
        },
        {
          key: "plan_level",
          label: "Nivel del Plan",
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-center",
        },
        {
          key: "weeks",
          label: "Semanas",
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-center",
        },
        {
          key: "credits",
          label: this.$getVisibleNames(
            "manual.credits",
            true,
            "Créditos SCT Totales"
          ),
          display_column: true,
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
        },

        (this.institution &&
          (this.institution.id == 7 ||
            this.institution.internal_use_id == "duoc_uc")) ||
        this.$debug_change_unab ||
        this.$debug_change_duoc
          ? {
              key: "institutional_credits",
              label: this.$getVisibleNames(
                "manual.institutional_credits",
                true,
                "Créditos Institucionales"
              ),
              display_column: true,
              sortable: true,
              thStyle: { background: "var(--primary-color) !important" },
            }
          : { key: "", label: "" },
        // {
        //   key: "week_theoric_hours",
        //   label: "Módulos de Cátedras Semanales",
        //   display_column: false,
        //   formatter: (value) => {
        //     return value ? parseFloat(value.toFixed(3)) : value;
        //   },
        //   sortable: true,
        //   thStyle: { background: "var(--primary-color) !important" },
        // },
        // {
        //   key: "week_lab_hours",
        //   label: "Módulos de Laboratorios Semanales",
        //   display_column: false,
        //   formatter: (value) => {
        //     return value ? parseFloat(value.toFixed(3)) : value;
        //   },
        //   sortable: true,
        //   thStyle: { background: "var(--primary-color) !important" },
        // },
        // {
        //   key: "week_ground_hours",
        //   label: "Módulos de Terreno Semanales",
        //   display_column: false,
        //   formatter: (value) => {
        //     return value ? parseFloat(value.toFixed(3)) : value;
        //   },
        //   sortable: true,
        //   thStyle: { background: "var(--primary-color) !important" },
        // },
        // {
        //   key: "week_assistantship_hours",
        //   label: "Módulos de Ayudantía Semanales",
        //   display_column: false,
        //   formatter: (value) => {
        //     return value ? parseFloat(value.toFixed(3)) : value;
        //   },
        //   sortable: true,
        //   thStyle: { background: "var(--primary-color) !important" },
        // },
        // {
        //   key: "week_autonomous_hours",
        //   label: "Horas Autónomas Semanales",
        //   display_column: false,
        //   formatter: (value) => {
        //     return value ? parseFloat(value.toFixed(3)) : value;
        //   },
        //   sortable: true,
        //   thStyle: { background: "var(--primary-color) !important" },
        // },
        // {
        //   key: "total_autonomous_hours",
        //   label: "Horas Autónomas Semestrales",
        //   display_column: false,
        //   formatter: (value) => {
        //     return value ? parseFloat(value.toFixed(3)) : value;
        //   },
        //   sortable: true,
        //   thStyle: { background: "var(--primary-color) !important" },
        // },
        {
          key: "egress_profile_id",
          label: "Perfil",
          sortable: false,
          display_column: false,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
        },
        {
          key: "egress_profile",
          label: "Tipo de Perfil",
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          formatter: (value) => {
            return value ? this.getEgressProfileType(value).name : "N/A";
          },
        },
        {
          key: "actions",
          label: "",
          display_column: true,
          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
  },
  methods: {
    removeOldMatter(old_matter) {
      this.$bvModal.hide(`modal-matter-name-${old_matter.id}`);
    },
    filterCustom(row) {
      if (
        this.$filtered(this.getMatterName(row.matter), this.input_search) ||
        this.$filtered(this.getRegimenName(row.regime), this.input_search) ||
        this.$filtered(
          this.formationAreaName(row.formation_area),
          this.input_search
        ) ||
        this.$filtered(
          this.getEgressProfile(row.egress_profile).professional_title,
          this.input_search
        ) ||
        this.$filtered(
          this.getEgressProfileType(row.egress_profile).name,
          this.input_search
        ) ||
        this.$filtered(row.code, this.input_search) ||
        this.$filtered(row.plan_level, this.input_search) ||
        this.$filtered(row.weeks, this.input_search) ||
        this.$filtered(row.order, this.input_search)
      ) {
        return true;
      } else {
        false;
      }
    },
    filterCustom2(row) {
      if (
        this.$filtered(row.name, this.input_search) ||
        this.$filtered(row.description, this.input_search)
      ) {
        return true;
      } else {
        false;
      }
    },
    getFacultyId(career_id) {
      const career = this.careers.find((x) => x.id == career_id);
      return career ? career.faculty : null;
    },
    egressProfilesMatter(matter_id) {
      const list = this.profile_matter.filter((x) => x.matter == matter_id);
      return list ? list : [];
    },
    redirectToEgressProfile(id) {
      // let route_data = this.$router.resolve({
      this.$router.push({
        name: "EgressProfileView",
        params: { egress_profile_id: id },
      });
      // window.open(route_data.href, "_blank");
    },
    getStudyUnitsName(study_units) {
      let list = [];
      study_units.forEach((x) => {
        const instance = this.studyUnits.find((element) => element.id == x);
        if (instance) list.push(instance);
      });
      return list;
    },
    MatterNameModal(row_item) {
      this.$root.$emit("bv::show::modal", `modal-matter-name-${row_item.id}`);
    },
    // SaveOldMatter(old_matter) {
    //   if (isNaN(old_matter.id)) {
    //     this.$store.dispatch(names.POST_MATTER, old_matter).then(() => {
    //       toast(
    //         this.$getVisibleNames("teaching.matter", false, "Asignatura Base") +
    //           " creada correctamente."
    //       );
    //       this.$bvModal.hide(`new-old-matter-modal`);
    //     });
    //   } else {
    //     this.$store
    //       .dispatch(names.PATCH_MATTER, {
    //         matter_id: old_matter.id,
    //         item: {
    //           name: old_matter.name,
    //           description: old_matter.description,
    //           code: old_matter.code,
    //           plan_level: old_matter.plan_level,
    //           formation_area: old_matter.formation_area,
    //           matter_types: old_matter.matter_types,
    //           matter_contributions: old_matter.matter_contributions,
    //           modalities: old_matter.modalities,
    //           weeks: old_matter.weeks,
    //         },
    //       })
    //       .then(() => {
    //         toast("Cambios guardados correctamente.");
    //         this.$bvModal.hide(`modal-matter-name-${old_matter.id}`);
    //       });
    //   }
    // },
    ActiveMatter(history_data_single_id, egress_profile_matter) {
      let history_data = this.filterHistoryData(history_data_single_id);
      if (history_data)
        if (
          history_data.order == egress_profile_matter.order &&
          history_data.plan_level == egress_profile_matter.plan_level &&
          history_data.weeks == egress_profile_matter.weeks &&
          history_data.egress_profile_id ==
            egress_profile_matter.egress_profile &&
          history_data.matter_id == egress_profile_matter.matter &&
          history_data.regime_id == egress_profile_matter.regime &&
          history_data.formation_area_id ==
            egress_profile_matter.formation_area &&
          JSON.stringify(history_data.prerequisites) ===
            JSON.stringify(egress_profile_matter.prerequisites) &&
          JSON.stringify(history_data.matter_types) ===
            JSON.stringify(egress_profile_matter.matter_types) &&
          JSON.stringify(history_data.matter_contributions) ===
            JSON.stringify(egress_profile_matter.matter_contributions) &&
          JSON.stringify(history_data.campuses) ===
            JSON.stringify(egress_profile_matter.campuses) &&
          JSON.stringify(history_data.competences) ===
            JSON.stringify(egress_profile_matter.competences)
        )
          return true;
      return false;
    },
    info(data, history, button) {
      this.infoModal.title = history;
      this.infoModal.content = data;
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    filterHistoryData(id) {
      const data = this.history_data.find((item) => item.history_id == id);
      return data ? data.data : null;
    },
    filterHistory(id) {
      const data = this.matter_history.find((item) => item.matter == id);
      return data ? data.data : null;
    },
    getRegimenName(id) {
      const data = this.regimes.find((item) => item.id == id);
      return data ? data.name : "";
    },
    getMatterName(matter_id) {
      if (!matter_id) return "";
      let matter = this.matters.find((x) => x.id == matter_id);
      return matter ? matter.name : "";
    },
    formationAreaName(id) {
      const data = this.formationAreas.find((item) => item.id == id);
      return data ? data.name : "";
    },
    resetInfoModal() {
      this.infoModal.title = null;
      this.infoModal.content = null;
    },
    getUserName(id) {
      const user = this.users.find((user) => user.id == id);
      return user ? user.first_name + " " + user.last_name : "-";
    },
    getEgressProfile(id) {
      const egress_profile = this.egress_profiles.find((x) => x.id == id);
      return egress_profile ? egress_profile : "No se encontro el perfil.";
    },
    getEgressProfileType(id) {
      const egress_profile = this.egress_profiles.find((x) => x.id == id);
      const profileType = this.profile_types.find(
        (x) => x.id == egress_profile.egress_profile_type
      );
      return profileType ? profileType : "No se encontro el tipo de perfil.";
    },
    forceRerender() {
      this.componentKey += 1;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    saveComment(revision_id, comment) {
      this.$restful.Get(
        `/common/revision_set_comment/?id=${revision_id}&comment=${comment}`
      );
    },
    createMatter(matter) {
      this.$bvModal.hide(`new-matter-modal`);
      this.resetTable(matter, true);
    },
    resetTable(object, created) {
      this.isBusy = true;
      const index_history_list = this.matter_history.findIndex(
        (x) => x.matter == object.id
      );
      if (index_history_list != -1) {
        this.matter_history.splice(index_history_list, 1);
        this.$restful
          .Get(
            `/mesh/egress_profile_matter_history_metadata_single/?id=${object.id}`
          )
          .then((history_list) => {
            const user_ids = [
              ...new Set(history_list.data.map((x) => x.user_id)),
            ];
            this.$store.dispatch(names.FETCH_ALT_USERS, {
              user_ids: user_ids,
            });
            this.matter_history.push({
              data: history_list.data,
              matter: object.id,
            });
            this.$restful
              .Get(`/common/history_data_single/?id=${history_list.data[0].id}`)
              .then((data_matter) => {
                this.history_data.push({
                  data: data_matter.data,
                  history_id: history_list.data[0].id,
                });
              });
          });
        // }
      } else if (created) {
        this.$restful
          .Get(
            `/mesh/egress_profile_matter_history_metadata_single/?id=${object.id}`
          )
          .then((history_list) => {
            const user_ids = [
              ...new Set(history_list.data.map((x) => x.user_id)),
            ];
            this.$store.dispatch(names.FETCH_ALT_USERS, {
              user_ids: user_ids,
            });
            this.matter_history.push({
              data: history_list.data,
              matter: object.id,
            });
            this.$restful
              .Get(`/common/history_data_single/?id=${history_list.data[0].id}`)
              .then((data_matter) => {
                this.history_data.push({
                  data: data_matter.data,
                  history_id: history_list.data[0].id,
                });
              });
          });
      }
      this.isBusy = false;
    },
    updateMatter(matter) {
      this.$bvModal.hide(`modal-edit-matter-${matter.id}`);
      this.resetTable(matter, false);
    },
    toggleDetails(row) {
      if (row.detailsShowing) {
        row.toggleDetails();
      } else {
        this.profileMatterFilter.forEach((item) => {
          this.$set(item, "_showDetails", false);
        });
        this.$nextTick(() => {
          row.toggleDetails();
        });
      }
    },
    fetch_history(row) {
      this.toggleDetails(row);
      if (
        this.matter_history.filter(
          (history_matter) => history_matter.matter == row.item.id
        ).length == 0
      ) {
        this.$restful
          .Get(
            `/mesh/egress_profile_matter_history_metadata_single/?id=${row.item.id}`
          )
          .then((history) => {
            const user_ids = [...new Set(history.data.map((x) => x.user_id))];
            this.$store.dispatch(names.FETCH_ALT_USERS, {
              user_ids: user_ids,
            });
            this.matter_history.push({
              data: history.data,
              matter: row.item.id,
            });
            history.data.forEach((data) => {
              this.$restful
                .Get(`/common/history_data_single/?id=${data.id}`)
                .then((data_single_matter) => {
                  this.history_data.push({
                    data: data_single_matter.data,
                    history_id: data.id,
                  });
                });
            });
          });
      }
    },
    applyChanges(history_data, history_details) {
      this.$swal
        .fire({
          title: "¿Seguro que quiere aplicar esta versión? ",
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Guardar",
        })
        .then((result) => {
          if (result.value) {
            this.isBusy = true;
            const payload = {
              matter_id: history_details.id,
              item: {
                code: history_details.code,
                order: history_details.order,
                plan_level: history_details.plan_level,
                weeks: history_details.weeks,
                color: history_details.color,
                regime: history_details.regime_id
                  ? history_details.regime_id
                  : null,
                formation_area: history_details.formation_area_id
                  ? history_details.formation_area_id
                  : null,
                egress_profile: history_details.egress_profile_id
                  ? history_details.egress_profile_id
                  : null,
                formation_line: history_details.formation_line_id
                  ? history_details.formation_line_id
                  : null,
                matter_type: history_details.matter_type_id
                  ? history_details.matter_type_id
                  : null,
                matter_contribution: history_details.matter_contribution_id
                  ? history_details.matter_contribution_id
                  : null,
                prerequisites: history_details.prerequisites,
                competences: history_details.competences,
                campuses: history_details.campuses,
              },
            };
            this.$bvModal.hide(this.infoModal.id);
            //TODO: Se hace con el $restful para capturar el evento del catch
            this.$restful
              .Patch(
                `/mesh/egress-profile-matter/${payload.matter_id}/`,
                payload.item
              )
              .then((response) => {
                this.$swal.fire("Versión actualizada!", "", "success");
                const index_history_list = this.matter_history.findIndex(
                  (x) => x.matter == response.id
                );
                if (index_history_list != -1) {
                  this.matter_history.splice(index_history_list, 1);
                  this.$restful
                    .Get(
                      `/mesh/egress_profile_matter_history_metadata_single/?id=${response.id}`
                    )
                    .then((history_list) => {
                      const user_ids = [
                        ...new Set(history_list.data.map((x) => x.user_id)),
                      ];
                      this.$store.dispatch(names.FETCH_ALT_USERS, {
                        user_ids: user_ids,
                      });
                      history_list.data[0].comment = `Revertido a la versión anterior, guardada el: ${this.$moment(
                        history_data.date_time
                      ).format("DD/MM/YYYY HH:mm")}`;
                      this.saveComment(
                        history_list.data[0].revision_id,
                        history_list.data[0].comment
                      );
                      this.matter_history.push({
                        data: history_list.data,
                        matter: response.id,
                      });
                      this.$restful
                        .Get(
                          `/common/history_data_single/?id=${history_list.data[0].id}`
                        )
                        .then((data_matter) => {
                          this.history_data.push({
                            data: data_matter.data,
                            history_id: history_list.data[0].id,
                          });
                        });
                    });
                }
                // }
              })
              .catch((err) => {
                if (err.response) {
                  this.$swal.fire(
                    "No se aplicó el cambio",
                    `"${err.response.request.responseText}"`,
                    "warning"
                  );
                  // console.log("response", err.response.request);
                }
              });
            this.isBusy = false;
          }
        });
    },
    deleteChanges(history_data_modal, history_details) {
      this.$swal
        .fire({
          title: "¿Seguro que quiere eliminar esta versión? ",
          text: "Este cambio es irreversible.",
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Eliminar",
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire("Versión eliminada!", "", "success");
            this.isBusy = true;
            this.$bvModal.hide(this.infoModal.id);
            const index = this.matter_history.findIndex(
              (x) => x.matter == history_details.id
            );
            if (index != -1) {
              const list_index = this.matter_history[index].data.findIndex(
                (x) => x.id == history_data_modal.id
              );
              if (list_index != -1) {
                const list_history_index = this.history_data.findIndex(
                  (x) => x.history_id == history_data_modal.id
                );
                this.matter_history[index].data.splice(list_index, 1);
                if (list_history_index != -1) {
                  this.history_data.splice(list_history_index, 1);
                  this.$restful.Get(
                    `/common/single_history_delete/?id=${history_data_modal.id}`
                  );
                }
              }
            }
            this.isBusy = false;
          }
        });
    },
    fetchInitial(egress_profile_id) {
      this.$store.dispatch(names.FETCH_EGRESS_PROFILES_MATTERS, {
        egress_profile_id: egress_profile_id,
      });
      this.$store.dispatch(names.FETCH_MATTERS, {
        egress_profile_id: egress_profile_id,
      });
      this.$store.dispatch(names.FETCH_STUDY_UNITS_BY_CICLE_AND_PROFILE, {
        egress_profile_id: egress_profile_id,
      });
      this.isBusy = false;
    },
    redirectToMatter(id) {
      // let route_data = this.$router.resolve({
      this.$router.push({
        name: "MatterProgramView",
        params: { matter_id: id },
      });
      // window.open(route_data.href, "_blank");
    },
    deleteMatter(id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.egressprofilematter",
          false,
          "Asignatura"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_EGRESS_PROFILE_MATTER, id)
            .then(() => {
              toast(
                this.$getVisibleNames(
                  "mesh.egressprofilematter",
                  false,
                  "Asignatura"
                ) + " eliminada."
              );
            });
        }
        // }
      });
    },
    filterEgressProfiles() {
      // if (!this.cycle_id)
      return this.egress_profiles;
      // let cycle = this.cycles.find((x) => x.id == this.cycle_id);
      // let filter_egress_profiles = this.egress_profiles.filter((x) =>
      //   cycle.egress_profiles.includes(x.id)
      // );
      // return filter_egress_profiles ? filter_egress_profiles : [];
    },
    changeInputSearch(input_search) {
      this.input_search = input_search;
    },
    DownloadExcel() {
      if (this.selected_egress_profile_id != null) {
        this.disabled_button_excel = true;
        this.$restful
          .Get(
            `/mesh/excel_report_egress_profile_matter_repository_excel_report/?egress_profile=${this.selected_egress_profile_id}&search_term=${this.input_search}`
          )
          .then((response) => {
            // URL + `/teaching/word_report_egress_profile_output/?egress_profile=${this.egress_profile_id}`,
            if (response && response.file_url) {
              this.disabled_button_excel = false;
              window.open(response.file_url, "_blank");
            }
          });
      }
    },
  },
  mounted() {
    this.isBusy = true;
    this.$store.dispatch(names.FETCH_PROFILE_TYPES);
    this.$store.dispatch(names.FETCH_EGRESS_PROFILES).then((response) => {
      if (response.length > 0) {
        this.selected_egress_profile_id = response[0].id;
        this.egress_profile_id = response[0].id;
        this.fetchInitial(response[0].id);
      }
      this.egress_profile_id = null;
    });
    this.totalRows = this.profileMatterFilter.length;
  },
  created() {},
  watch: {
    selected_egress_profile_id: function () {
      if (this.selected_egress_profile_id) {
        this.fetchInitial(this.selected_egress_profile_id);
      }
    },
  },
};
</script>
<style>
</style>