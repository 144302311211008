<template>
  <fab
    class="noprint"
    :position="position"
    :bg-color="background_color"
    :actions="fab_actions"
    title="Navegar a"
    tooltip="Navegar a"
    icon-size="small"
    position-type="fixed"
    @goToPDA_DUOC="goToPDA_DUOC"
    @goToPA_DUOC="goToPA_DUOC"
    @goToMatterProgram="goToMatterProgram"
    @goToAssessmentStudent="goToAssessmentStudent"
    @goToEvaluateeEvaluation="goToEvaluateeEvaluation"
    @goToAssessmentStudents="goToAssessmentStudents"
    @goToEvaluatorEvaluations="goToEvaluatorEvaluations"
    @goToReportAssessment="goToReportAssessment"
    @goToFullGrading="goToFullGrading"
  >
  </fab>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "NewDashboardCustomFab",
  components: {
    fab: () => import("vue-fab"),
  },
  props: {
    section_id: {
      type: Number,
      required: true,
    },
    show_teacher_views: {
      type: Boolean,
      default: false,
    },
    show_assessment_students: {
      type: Boolean,
      default: true,
    },
    show_evaluator_evaluations: {
      type: Boolean,
      default: true,
    },
    show_report_assessment: {
      type: Boolean,
      default: true,
    },
    show_full_grading: {
      type: Boolean,
      default: true,
    },
    show_student_views: {
      type: Boolean,
      default: false,
    },
    show_assessment_student: {
      type: Boolean,
      default: true,
    },
    show_evaluatee_evaluations: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      background_color: "var(--header-color)",
      position: "bottom-right",
      fab_actions: [],
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      sections: names.SECTIONS,
      egress_profile_matters: names.EGRESS_PROFILES_MATTERS,
    }),
    section() {
      if (!this.section_id) return null;
      return this.sections.find((x) => x.id == this.section_id);
    },
    egress_profile_matter() {
      if (this.section && this.section.egress_profile_matter != null)
        return this.egress_profile_matters.find(
          (x) => x.id == this.section.egress_profile_matter
        );
      else return null;
    },
  },
  methods: {
    goToMatterProgram() {
      if (this.egress_profile_matter)
        this.$router.push({
          name: "MatterProgramView",
          params: { matter_id: this.egress_profile_matter.id },
        });
    },
    goToPDA_DUOC() {
      if (this.egress_profile_matter)
        this.$router.push({
          name: "LearningSuitcaseTeacherView",
          params: {
            egress_profile_matter_id: this.egress_profile_matter.id,
          },
        });
    },
    goToPA_DUOC() {
      if (this.egress_profile_matter)
        this.$router.push({
          name: "LearningSuitcaseStudentView",
          params: {
            egress_profile_matter_id: this.egress_profile_matter.id,
          },
        });
    },
    goToAssessmentStudent() {
      this.$router.push({
        name: "AssessmentStudentView",
        params: {
          section_id: this.section_id,
        },
      });
    },
    goToEvaluateeEvaluation() {
      this.$router.push({
        name: "EvaluateeEvaluationsListView",
        params: {
          section_id: this.section_id,
        },
      });
    },
    goToAssessmentStudents() {
      this.$router.push({
        name: "AssessmentStudentsView",
        params: {
          section_id: this.section_id,
        },
      });
    },
    goToEvaluatorEvaluations() {
      this.$router.push({
        name: "EvaluatorEvaluationsListView",
        params: {
          section_id: this.section_id,
        },
      });
    },
    goToReportAssessment() {
      this.$router.push({
        name: "AssessmentRangeSectionView",
        params: {
          section_id: this.section_id,
        },
      });
    },
    goToFullGrading() {
      this.$router.push({
        name: "NewFullGradingView",
        params: {
          section_id: this.section_id,
        },
      });
    },
    initFabActions() {
      if (this.show_student_views) {
        if (
          this.show_assessment_student &&
          this.institution &&
          this.institution.internal_use_id == "ciisa_uss"
        )
          this.fab_actions.push({
            name: "goToAssessmentStudent",
            icon: "school",
            tooltip: `Assessment de la ${this.$getVisibleNames(
              "teaching.section",
              false,
              "Sección"
            )}`,
            color: "var(--secondary-color)",
          });
        if (this.show_evaluatee_evaluations)
          this.fab_actions.push({
            name: "goToEvaluateeEvaluation",
            icon: "view_list",
            tooltip: `${this.$getVisibleNames(
              "evaluations2.evaluation",
              true,
              "Evaluaciones"
            )} de la ${this.$getVisibleNames(
              "teaching.section",
              false,
              "Sección"
            )}`,
            color: "var(--secondary-color)",
          });
      }
      if (
        this.show_assessment_students &&
        this.institution &&
        (this.institution.internal_use_id == "ciisa_uss" ||
          this.institution.internal_use_id == "duoc_uc") &&
        this.show_teacher_views
      )
        this.fab_actions.push({
          name: "goToAssessmentStudents",
          icon: "school",
          tooltip: "Assessment por Estudiante",
          color: "var(--secondary-color)",
        });
      if (this.show_evaluator_evaluations && this.show_teacher_views)
        this.fab_actions.push({
          name: "goToEvaluatorEvaluations",
          icon: "view_list",
          tooltip: `${this.$getVisibleNames(
            "evaluations2.evaluation",
            true,
            "Evaluaciones"
          )} de la ${this.$getVisibleNames(
            "teaching.section",
            false,
            "Sección"
          )}`,
          color: "var(--secondary-color)",
        });
      if (
        this.show_report_assessment &&
        this.show_teacher_views &&
        this.institution &&
        this.institution.kl_assessment &&
        (this.institution.internal_use_id == "ciisa_uss" ||
          this.institution.internal_use_id == "duoc_uc")
      )
        this.fab_actions.push({
          name: "goToReportAssessment",
          icon: "assessment",
          tooltip: `Reporte de Assessment`,
          color: "var(--secondary-color)",
        });
      if (this.show_full_grading && this.show_teacher_views)
        this.fab_actions.push({
          name: "goToFullGrading",
          icon: "view_module",
          tooltip: `Notas de la ${this.$getVisibleNames(
            "teaching.section",
            false,
            "Sección"
          )}`,
          color: "var(--secondary-color)",
        });
    },
  },
  created() {
    if (this.section && this.section.egress_profile_matter != null) {
      this.$store
        .dispatch(
          names.FETCH_EGRESS_PROFILE_MATTER,
          this.section.egress_profile_matter
        )
        .then((egress_profile_matter) => {
          if (
            egress_profile_matter &&
            this.institution &&
            this.institution.internal_use_id != "duoc_uc"
          )
            this.fab_actions.push({
              name: "goToMatterProgram",
              icon: "library_books",
              tooltip: this.$getVisibleNames(
                "manual.matter_program",
                false,
                "Programa de Asignatura"
              ),
              color: "var(--secondary-color)",
            });
          if (
            egress_profile_matter &&
            this.institution &&
            this.institution.internal_use_id == "duoc_uc" &&
            this.show_teacher_views
          )
            this.fab_actions.push({
              name: "goToPDA_DUOC",
              icon: "library_books",
              tooltip: "PDA Docente",
              color: "var(--secondary-color)",
            });
          if (
            egress_profile_matter &&
            this.institution &&
            this.institution.internal_use_id == "duoc_uc" &&
            this.show_student_views
          )
            this.fab_actions.push({
              name: "goToPA_DUOC",
              icon: "library_books",
              tooltip: "PA Estudiante",
              color: "var(--secondary-color)",
            });
          this.initFabActions();
        });
    } else {
      this.initFabActions();
    }
  },
};
</script>

<style scoped>
.fab {
  background-color: var(--header-color);
}
.fab-wrapper {
  bottom: 6.5vh !important;
  right: 0.6vw !important;
}
@media only screen and (max-width: 1100px) {
  .fab-wrapper {
    right: 0.7vw !important;
  }
}
@media only screen and (max-width: 900px) {
  .fab-wrapper {
    right: 0.7vw !important;
  }
}
@media only screen and (max-width: 700px) {
  .fab-wrapper {
    right: 0.7vw !important;
  }
}
</style>