var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table-simple',{attrs:{"bordered":"","small":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"th-class",attrs:{"colspan":2}},[_vm._v(_vm._s(_vm.$getVisibleNames( "manual.indicador_de_logro", true, "Indicadores de Logros" ).toUpperCase()))]),_c('b-th',{staticClass:"th-class",attrs:{"colspan":3}},[_c('span',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.dimension", false, "Dimensión" ).toUpperCase())+" ")]),(_vm.allows_crud && _vm.rubric && !_vm.rubric.is_locked)?_c('button-add',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
              `Crear ${_vm.$getVisibleNames(
                'manual.dimension',
                false,
                'Dimensión'
              )}`
            ),expression:"\n              `Crear ${$getVisibleNames(\n                'manual.dimension',\n                false,\n                'Dimensión'\n              )}`\n            ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],staticClass:"m-0 ml-2 p-0 text-white",attrs:{"size":"sm"},on:{"click":function($event){return _vm.openModalDimensionCreate()}}}):_vm._e()],1)],1),_c('b-tr',[_c('b-th',{staticClass:"th-class"},[_vm._v("N°")]),_c('b-th',{staticClass:"th-class"},[_vm._v("ENUNCIADO")]),_c('b-th',{staticClass:"th-class"},[_vm._v("CANTIDAD")]),_c('b-th',{staticClass:"th-class"},[_vm._v(_vm._s(this.$getVisibleNames( "mesh.cognitivelevel", false, "Nivel Cognitivo" ).toUpperCase()))]),_c('b-th',{staticClass:"th-class"},[_vm._v("N° DE ORDEN")])],1)],1),_c('b-tbody',[_vm._l((_vm.evaluationCriteriasMicroSort.filter(
          (x) => _vm.evaluation_criteria_micro_ids.includes(x.id)
        )),function(evaluation_criteria){return [(
            _vm.rubricCriteriaList.filter(
              (x) =>
                x.evaluation_criteria_micro == evaluation_criteria.id ||
                x.evaluation_criterias_micro.includes(evaluation_criteria.id)
            ).length == 0
          )?_c('b-tr',{key:evaluation_criteria.id + '-' + _vm.test.id},[_c('b-td',{staticClass:"text-center"},[_c('div',{staticClass:"d-flex"},[(evaluation_criteria.competence_unit_order != 999)?_c('span',[_vm._v(" "+_vm._s(evaluation_criteria.competence_unit_order)+". ")]):_vm._e(),(evaluation_criteria.study_unit_order != 999)?_c('span',[_vm._v(" "+_vm._s(evaluation_criteria.study_unit_order)+". ")]):_vm._e(),_vm._v(" "+_vm._s(evaluation_criteria.order)+" ")])]),_c('b-td',[_c('SentenceContainer',{attrs:{"unique_id":'evaluation_criteria:' +
                evaluation_criteria.id +
                'test:' +
                _vm.test.id,"Sentence":evaluation_criteria}})],1),_c('b-td',{staticClass:"text-center"},[_vm._v("0")]),_c('b-td',{staticClass:"text-center"},[_vm._v("N/A")])],1):_vm._e(),_vm._l((_vm.rubricCriteriaList.filter(
            (x) =>
              x.evaluation_criteria_micro == evaluation_criteria.id ||
              x.evaluation_criterias_micro.includes(evaluation_criteria.id)
          )),function(rubric_criteria,index){return _c('b-tr',{key:index +
            '-' +
            evaluation_criteria.id +
            '-' +
            rubric_criteria.id +
            '-' +
            _vm.test.id},[(index == 0)?_c('b-td',{staticClass:"text-center align-middle",attrs:{"rowspan":_vm.rubricCriteriaList.filter(
                (x) =>
                  x.evaluation_criteria_micro == evaluation_criteria.id ||
                  x.evaluation_criterias_micro.includes(
                    evaluation_criteria.id
                  )
              ).length}},[_c('div',{staticClass:"d-flex"},[(evaluation_criteria.competence_unit_order != 999)?_c('span',[_vm._v(" "+_vm._s(evaluation_criteria.competence_unit_order)+". ")]):_vm._e(),(evaluation_criteria.study_unit_order != 999)?_c('span',[_vm._v(" "+_vm._s(evaluation_criteria.study_unit_order)+". ")]):_vm._e(),_vm._v(" "+_vm._s(evaluation_criteria.order)+" ")])]):_vm._e(),(index == 0)?_c('b-td',{staticClass:"align-middle",attrs:{"rowspan":_vm.rubricCriteriaList.filter(
                (x) =>
                  x.evaluation_criteria_micro == evaluation_criteria.id ||
                  x.evaluation_criterias_micro.includes(
                    evaluation_criteria.id
                  )
              ).length}},[_c('SentenceContainer',{attrs:{"unique_id":'evaluation_criteria:' +
                evaluation_criteria.id +
                'test:' +
                _vm.test.id,"Sentence":evaluation_criteria}})],1):_vm._e(),(index == 0)?_c('b-td',{staticClass:"text-center align-middle",attrs:{"rowspan":_vm.rubricCriteriaList.filter(
                (x) =>
                  x.evaluation_criteria_micro == evaluation_criteria.id ||
                  x.evaluation_criterias_micro.includes(
                    evaluation_criteria.id
                  )
              ).length}},[_vm._v(" "+_vm._s(_vm.rubricCriteriaList.filter( (x) => x.evaluation_criteria_micro == evaluation_criteria.id || x.evaluation_criterias_micro.includes( evaluation_criteria.id ) ).length)+" ")]):_vm._e(),(rubric_criteria.cognitive_level)?_c('b-td',{staticClass:"text-center align-middle",style:({
              'background-color':
                rubric_criteria.cognitive_level.color == '' ||
                _vm.$equals(rubric_criteria.cognitive_level.color, '#FFFFFF')
                  ? '#ebebeb'
                  : rubric_criteria.cognitive_level.color + ' !important',
              color: _vm.$lightOrDark(
                rubric_criteria.cognitive_level.color == '' ||
                  _vm.$equals(rubric_criteria.cognitive_level.color, '#FFFFFF')
                  ? '#ebebeb'
                  : rubric_criteria.cognitive_level.color
              )
                ? '#2b2a2a !important'
                : 'white !important',
            })},[_vm._v(" "+_vm._s(rubric_criteria.cognitive_level.level)+" ")]):_c('td',{staticClass:"text-center align-middle"},[_vm._v("N/A")]),_c('b-td',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
              rubric_criteria.full_sentence
            ),expression:"\n              rubric_criteria.full_sentence\n            ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"text-right align-middle cursor-help"},[_vm._v(" "+_vm._s(rubric_criteria.order)+" "),(_vm.allows_crud && _vm.rubric && !_vm.rubric.is_locked)?_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                `Editar ${_vm.$getVisibleNames(
                  'manual.dimension',
                  false,
                  'Dimensión'
                )}`
              ),expression:"\n                `Editar ${$getVisibleNames(\n                  'manual.dimension',\n                  false,\n                  'Dimensión'\n                )}`\n              ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"m-0 mb-1 p-0",on:{"click":function($event){return _vm.openModalDimensionEdit(
                  rubric_criteria.id,
                  evaluation_criteria.id
                )}}}):_vm._e(),_c('b-modal',{attrs:{"id":`modal-update-rubric-instance-1-${_vm.test.id}-${rubric_criteria.id}-${evaluation_criteria.id}`,"title":'Modificar ' +
                _vm.$getVisibleNames('manual.dimension', false, 'Dimensión'),"hide-footer":"","size":"xl","no-enforce-focus":""},on:{"hide":_vm.hideModalDimension}},[_c('RubricCriteriaForm',{attrs:{"rubric_id":_vm.rubric.id,"RubricCriteria":rubric_criteria,"order":rubric_criteria.order,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"show_delete_button":true},on:{"updated":function($event){return _vm.$bvModal.hide(
                    `modal-update-rubric-instance-1-${_vm.test.id}-${rubric_criteria.id}-${evaluation_criteria.id}`
                  )},"deleted":function($event){return _vm.$bvModal.hide(
                    `modal-update-rubric-instance-1-${_vm.test.id}-${rubric_criteria.id}-${evaluation_criteria.id}`
                  )}}})],1)],1)],1)})]})],2)],1),_c('b-modal',{attrs:{"id":`modal-created-rubric-criteria-1-${_vm.test.id}`,"title":`Crear ${_vm.$getVisibleNames(
      'manual.dimension',
      false,
      'Dimensión'
    )}`,"size":"xl","hide-footer":"","no-enforce-focus":""},on:{"hide":_vm.hideModalDimension}},[(_vm.rubric)?_c('RubricCriteriaForm',{attrs:{"rubric_id":_vm.rubric.id,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"order":_vm.rubricCriteriaList.length > 0
          ? _vm.rubricCriteriaList[_vm.rubricCriteriaList.length - 1].order + 1
          : 1},on:{"created":function($event){return _vm.$bvModal.hide(`modal-created-rubric-criteria-1-${_vm.test.id}`)}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }