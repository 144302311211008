<template>
  <div
    class="page-break mb-4"
    v-if="
      rubricCriterias.filter((x) => x.evaluation_criteria_micro != null)
        .length > 0 || allows_crud
    "
  >
    <div class="text-left mb-3">
      <!-- <span class="number-title-evaluative-agent">03. </span> -->
      <span class="title-evaluative-agent">Tabla de Especificaciones</span>
    </div>
    <div class="duoc-specification-table-container">
      <b-table-simple border>
        <b-thead>
          <b-th class="primary-cell text-center" style="width: 18%"
            >RESULTADO DE APRENDIZAJE</b-th
          >
          <b-th class="primary-cell" style="width: 25%"
            >INDICADOR DE LOGRO (IL)</b-th
          >
          <b-th class="primary-cell" style="width: 7%"
            >PONDERACIÓN INDICADOR DE LOGRO</b-th
          >
          <b-th class="primary-cell" style="width: 25%">
            <div>
              {{
                $getVisibleNames(
                  "manual.dimension",
                  false,
                  "Dimensión"
                ).toUpperCase()
              }}
              (IE)
              <button-add
                v-if="allows_crud && !rubric"
                :title="`Crear ${$getVisibleNames(
                  'evaluations2.rubric',
                  false,
                  'Pauta'
                ).toUpperCase()}`"
                v-b-tooltip.v-secondary.top.noninteractive="
                  `Crear ${$getVisibleNames(
                    'evaluations2.rubric',
                    false,
                    'Pauta'
                  )}`
                "
                @click="
                  $bvModal.show(
                    `modal-create-rubric-st-${instrument_id}-${container_instrument_id}-${container_instrument_id}`
                  )
                "
                style="color: white"
                size="sm"
              >
              </button-add>
            </div>
          </b-th>
          <b-th class="primary-cell" style="width: 7%"
            >PONDERACIÓN INDICADOR DE EVALUACIÓN</b-th
          >

          <b-th v-if="showLinkedQuestion" class="primary-cell" style="width: 7%"
            >N° DE PREGUNTA O ÍTEMS</b-th
          >
        </b-thead>
        <b-tbody>
          <template v-for="(study_unit, index) in studyUnits">
            <template
              v-for="(
                evaluation_criteria_micro, index2
              ) in evaluationCriteriaMicro.filter(
                (x) => x.study_unit == study_unit.id
              )"
            >
              <template
                v-if="
                  rubricCriterias.filter(
                    (x) =>
                      x.evaluation_criteria_micro ==
                      evaluation_criteria_micro.id
                  ).length > 0
                "
              >
                <b-tr
                  v-for="(rubric_criteria, index3) in rubricCriterias.filter(
                    (x) =>
                      x.evaluation_criteria_micro ==
                      evaluation_criteria_micro.id
                  )"
                  :key="
                    'study_unit' +
                    index +
                    'evaluation_criteria_micro' +
                    index2 +
                    'rubric_criteria' +
                    index3
                  "
                >
                  <b-td
                    v-if="index2 == 0 && index3 == 0"
                    :rowspan="
                      rubricCriterias.filter((x) =>
                        evaluationCriteriaMicro
                          .filter((a) => a.study_unit == study_unit.id)
                          .map((b) => b.id)
                          .includes(x.evaluation_criteria_micro)
                      ).length +
                      evaluationCriteriaMicro.filter(
                        (a) =>
                          a.study_unit == study_unit.id &&
                          rubricCriterias.filter(
                            (x) => x.evaluation_criteria_micro == a.id
                          ).length == 0
                      ).length
                    "
                    class="secundary-cell text-ra"
                    :class="{
                      'unselectable-text':
                        (user && user.groups.includes(7)) || preview_evaluation,
                    }"
                  >
                    <div class="d-flex">
                      <span> N°{{ study_unit.order }} </span>
                      <SentenceContainer
                        :unique_id="
                          'sentence-study_unit' +
                          index +
                          'evaluation_criteria_micro' +
                          index2 +
                          'rubric_criteria' +
                          index3
                        "
                        :class="{
                          'unselectable-text':
                            (user && user.groups.includes(7)) ||
                            preview_evaluation,
                        }"
                        :Sentence="study_unit"
                        :showColorCognitiveLevel="allows_crud"
                        :showCognitiveLevel="!preview_evaluation"
                        :boundedTextContainer="false"
                      ></SentenceContainer></div
                  ></b-td>
                  <b-td
                    v-if="index3 == 0"
                    :rowspan="
                      rubricCriterias.filter(
                        (x) =>
                          x.evaluation_criteria_micro ==
                          evaluation_criteria_micro.id
                      ).length
                    "
                    class="secundary-cell text-il"
                    :class="{
                      'unselectable-text':
                        (user && user.groups.includes(7)) || preview_evaluation,
                    }"
                  >
                    <div class="d-flex">
                      <span>
                        {{ study_unit.order }}.{{
                          evaluation_criteria_micro.order
                        }}
                      </span>
                      <SentenceContainer
                        :unique_id="
                          'sentence-2-study_unit' +
                          index +
                          'evaluation_criteria_micro' +
                          index2 +
                          'rubric_criteria' +
                          index3
                        "
                        :class="{
                          'unselectable-text':
                            (user && user.groups.includes(7)) ||
                            preview_evaluation,
                        }"
                        :Sentence="evaluation_criteria_micro"
                        :showColorCognitiveLevel="allows_crud"
                        :showCognitiveLevel="!preview_evaluation"
                        :boundedTextContainer="false"
                      ></SentenceContainer>
                    </div>
                  </b-td>
                  <!-- PONDERACIÓN INDICADOR DE LOGRO -->
                  <b-td
                    v-if="index3 == 0"
                    :rowspan="
                      rubricCriterias.filter(
                        (x) =>
                          x.evaluation_criteria_micro ==
                          evaluation_criteria_micro.id
                      ).length
                    "
                    class="secundary-cell ponderation-cell-indicator-evaluation"
                    :class="{
                      'unselectable-text':
                        (user && user.groups.includes(7)) || preview_evaluation,
                    }"
                  >
                    <DuocSpecificationTableInput
                      :test_id="instrument_id"
                      :evaluation_criteria_micro_id="
                        evaluation_criteria_micro.id
                      "
                      :evaluation_criteria_micro_ids="
                        evaluation_criteria_micro_ids
                      "
                      :allows_crud="false"
                    ></DuocSpecificationTableInput>
                    <!-- {{
                      getRubricCriteriaWeighing(
                        rubricCriterias.filter(
                          (x) =>
                            x.evaluation_criteria_micro ==
                            evaluation_criteria_micro.id
                        )
                      )
                    }} -->
                  </b-td>
                  <b-td class="secundary-cell">
                    <div class="d-flex">
                      <SentenceContainer
                        :unique_id="
                          'sentence-3-study_unit' +
                          index +
                          'evaluation_criteria_micro' +
                          index2 +
                          'rubric_criteria' +
                          index3
                        "
                        class="w-100"
                        :class="{
                          'unselectable-text':
                            (user && user.groups.includes(7)) ||
                            preview_evaluation,
                        }"
                        :Sentence="rubric_criteria"
                        :showColorCognitiveLevel="allows_crud"
                        :showCognitiveLevel="!preview_evaluation"
                        :boundedTextContainer="false"
                      ></SentenceContainer>
                      <div v-if="allows_crud && rubric && !rubric.is_locked">
                        <button-edit
                          :title="`Editar ${$getVisibleNames(
                            'manual.dimension',
                            false,
                            'Dimensión'
                          ).toUpperCase()}`"
                          v-b-tooltip.v-secondary.top.noninteractive="
                            `Editar ${$getVisibleNames(
                              'manual.dimension',
                              false,
                              'Dimensión'
                            )}`
                          "
                          @click="
                            openModalDimensionEdit(
                              index,
                              index2,
                              index3,
                              evaluation_criteria_micro.id
                            )
                          "
                          size="sm"
                        >
                        </button-edit>
                        <b-modal
                          :id="`modal-edit-rubric-criteria-st-${index}-${index2}-${index3}-${evaluation_criteria_micro.id}-${instrument_id}-${container_instrument_id}`"
                          :title="
                            'Modificar ' +
                            $getVisibleNames(
                              'manual.dimension',
                              false,
                              'Dimensión'
                            )
                          "
                          size="lg"
                          hide-footer
                          no-enforce-focus
                          @hide="hideModalDimension"
                        >
                          <RubricCriteriaForm
                            :rubric_id="rubric.id"
                            :selected_evaluation_criteria_micro="
                              evaluation_criteria_micro
                            "
                            :evaluation_criteria_micro_ids="
                              evaluation_criteria_micro_ids
                            "
                            :RubricCriteria="rubric_criteria"
                            :show_delete_button="true"
                            :order="rubric_criteria.order"
                            @updated="
                              $bvModal.hide(
                                `modal-edit-rubric-criteria-st-${index}-${index2}-${index3}-${evaluation_criteria_micro.id}-${instrument_id}-${container_instrument_id}`
                              )
                            "
                            @deleted="
                              $bvModal.hide(
                                `modal-edit-rubric-criteria-st-${index}-${index2}-${index3}-${evaluation_criteria_micro.id}-${instrument_id}-${container_instrument_id}`
                              )
                            "
                          ></RubricCriteriaForm>
                        </b-modal>
                      </div>
                    </div>
                    <div
                      class="d-flex"
                      v-if="
                        rubricCriterias.filter(
                          (x) =>
                            x.evaluation_criteria_micro ==
                            evaluation_criteria_micro.id
                        ).length ==
                        index3 + 1
                      "
                    >
                      <div class="mr-auto ml-auto">
                        <template
                          v-if="allows_crud && rubric && !rubric.is_locked"
                        >
                          <button-add
                            :title="`Crear ${$getVisibleNames(
                              'manual.dimension',
                              false,
                              'Dimensión'
                            ).toUpperCase()}`"
                            v-b-tooltip.v-secondary.top.noninteractive="
                              `Crear ${$getVisibleNames(
                                'manual.dimension',
                                false,
                                'Dimensión'
                              )}`
                            "
                            @click="
                              openModalDimensionCreate(
                                evaluation_criteria_micro.id
                              )
                            "
                            size="sm"
                          >
                          </button-add>
                          <b-modal
                            :id="`modal-created-rubric-criteria-st-${evaluation_criteria_micro.id}-${instrument_id}-${container_instrument_id}`"
                            :title="`Crear ${$getVisibleNames(
                              'manual.dimension',
                              false,
                              'Dimensión'
                            )}`"
                            size="lg"
                            hide-footer
                            no-enforce-focus
                            @hide="hideModalDimension"
                          >
                            <RubricCriteriaForm
                              :rubric_id="rubric.id"
                              :selected_evaluation_criteria_micro="
                                evaluation_criteria_micro
                              "
                              :evaluation_criteria_micro_ids="
                                evaluation_criteria_micro_ids
                              "
                              :order="
                                rubricCriterias.length > 0
                                  ? rubricCriterias[rubricCriterias.length - 1]
                                      .order + 1
                                  : 1
                              "
                              @created="
                                $bvModal.hide(
                                  `modal-created-rubric-criteria-st-${evaluation_criteria_micro.id}-${instrument_id}-${container_instrument_id}`
                                )
                              "
                            ></RubricCriteriaForm>
                          </b-modal>
                        </template>
                        <template v-if="allows_crud && !rubric">
                          <div
                            v-b-tooltip.v-danger.top.noninteractive="
                              `Para agregar una ${$getVisibleNames(
                                'manual.dimension',
                                false,
                                'Dimensión'
                              )} debe crear una ${$getVisibleNames(
                                'evaluations2.rubric',
                                false,
                                'Pauta'
                              )} `
                            "
                          >
                            <button-add disable="true" size="sm"> </button-add>
                          </div>
                        </template>
                      </div>
                    </div>
                  </b-td>
                  <b-td
                    class="secundary-cell ponderation-cell"
                    :class="{
                      'unselectable-text':
                        (user && user.groups.includes(7)) || preview_evaluation,
                      'text-danger': showWarningRubricCriteria(
                        evaluation_criteria_micro
                      ),
                    }"
                  >
                    <span
                      v-b-tooltip.v-danger.top.noninteractive="
                        `${
                          showWarningRubricCriteria(evaluation_criteria_micro)
                            ? 'La suma de las ponderaciones de los (IE) es mayor que la ponderación del (IL)'
                            : ''
                        }`
                      "
                      >{{ rubric_criteria.weighing }}%
                    </span>
                  </b-td>
                  <b-td
                    v-if="showLinkedQuestion"
                    class="secundary-cell ponderation-cell"
                    :class="{
                      'unselectable-text':
                        (user && user.groups.includes(7)) || preview_evaluation,
                    }"
                  >
                    <div
                      v-for="question in filterQuestionsByRubricCriteria(
                        rubric_criteria.id
                      )"
                      :key="question.id + '-' + rubric_criteria.id"
                      v-b-tooltip.v-secondary.top.noninteractive="
                        `${$htmlToPlainText(question.title)}`
                      "
                    >
                      <span v-if="question.segment_order != null"
                        >{{ question.segment_order }}.</span
                      >
                      {{ question.order }}
                    </div>

                    <div
                      v-if="
                        filterQuestionsByRubricCriteria(rubric_criteria.id)
                          .length == 0
                      "
                    >
                      N/A
                    </div>
                  </b-td>
                </b-tr>
              </template>
              <template v-else>
                <b-tr
                  :key="
                    'study_unit' + index + 'evaluation_criteria_micro' + index2
                  "
                >
                  <b-td
                    v-if="index2 == 0"
                    :rowspan="
                      rubricCriterias.filter((x) =>
                        evaluationCriteriaMicro
                          .filter((a) => a.study_unit == study_unit.id)
                          .map((b) => b.id)
                          .includes(x.evaluation_criteria_micro)
                      ).length +
                      evaluationCriteriaMicro.filter(
                        (a) =>
                          a.study_unit == study_unit.id &&
                          rubricCriterias.filter(
                            (x) => x.evaluation_criteria_micro == a.id
                          ).length == 0
                      ).length
                    "
                    class="secundary-cell text-ra"
                    :class="{
                      'unselectable-text':
                        (user && user.groups.includes(7)) || preview_evaluation,
                    }"
                  >
                    <div class="d-flex">
                      <span> N°{{ study_unit.order }} </span>
                      <SentenceContainer
                        :unique_id="
                          'sentence-study_unit' +
                          index +
                          'evaluation_criteria_micro' +
                          index2
                        "
                        :class="{
                          'unselectable-text':
                            (user && user.groups.includes(7)) ||
                            preview_evaluation,
                        }"
                        :Sentence="study_unit"
                        :showColorCognitiveLevel="allows_crud"
                        :showCognitiveLevel="!preview_evaluation"
                        :boundedTextContainer="false"
                      ></SentenceContainer>
                    </div>
                  </b-td>
                  <b-td
                    class="secundary-cell text-il"
                    :class="{
                      'unselectable-text':
                        (user && user.groups.includes(7)) || preview_evaluation,
                    }"
                  >
                    <div class="d-flex">
                      <span>
                        {{ study_unit.order }}.{{
                          evaluation_criteria_micro.order
                        }}
                      </span>
                      <SentenceContainer
                        :unique_id="
                          'sentence-study_unit' +
                          index +
                          'evaluation_criteria_micro' +
                          index2
                        "
                        :class="{
                          'unselectable-text':
                            (user && user.groups.includes(7)) ||
                            preview_evaluation,
                        }"
                        :Sentence="evaluation_criteria_micro"
                        :showColorCognitiveLevel="allows_crud"
                        :showCognitiveLevel="!preview_evaluation"
                        :boundedTextContainer="false"
                      ></SentenceContainer>
                    </div>
                  </b-td>
                  <!-- PONDERACIÓN INDICADOR DE LOGRO -->
                  <b-td
                    class="secundary-cell ponderation-cell-indicator-evaluation"
                    :class="{
                      'unselectable-text':
                        (user && user.groups.includes(7)) || preview_evaluation,
                    }"
                  >
                    <DuocSpecificationTableInput
                      :test_id="instrument_id"
                      :evaluation_criteria_micro_id="
                        evaluation_criteria_micro.id
                      "
                      :evaluation_criteria_micro_ids="
                        evaluation_criteria_micro_ids
                      "
                      :allows_crud="false"
                    ></DuocSpecificationTableInput>
                    <!-- {{
                      getRubricCriteriaWeighing(
                        rubricCriterias.filter(
                          (x) =>
                            x.evaluation_criteria_micro ==
                            evaluation_criteria_micro.id
                        )
                      )
                    }} -->
                  </b-td>
                  <b-td
                    class="secundary-cell"
                    :class="{
                      'unselectable-text':
                        (user && user.groups.includes(7)) || preview_evaluation,
                    }"
                  >
                    <div>
                      <div class="d-flex">
                        <div class="ml-auto mr-auto">
                          <p>
                            <b>N/A</b>
                          </p>
                        </div>
                      </div>
                      <div class="d-flex">
                        <div class="mr-auto ml-auto">
                          <template v-if="allows_crud && rubric">
                            <button-add
                              :title="`Crear ${$getVisibleNames(
                                'manual.dimension',
                                false,
                                'Dimensión'
                              ).toUpperCase()}`"
                              v-b-tooltip.v-secondary.top.noninteractive="
                                `Crear ${$getVisibleNames(
                                  'manual.dimension',
                                  false,
                                  'Dimensión'
                                )}`
                              "
                              @click="
                                $bvModal.show(
                                  `modal-created-rubric-criteria-st-${evaluation_criteria_micro.id}-${instrument_id}-${container_instrument_id}`
                                )
                              "
                              size="sm"
                            >
                            </button-add>
                            <b-modal
                              :id="`modal-created-rubric-criteria-st-${evaluation_criteria_micro.id}-${instrument_id}-${container_instrument_id}`"
                              :title="`Crear ${$getVisibleNames(
                                'manual.dimension',
                                false,
                                'Dimensión'
                              )}`"
                              size="lg"
                              hide-footer
                              no-enforce-focus
                            >
                              <RubricCriteriaForm
                                :rubric_id="rubric.id"
                                :selected_evaluation_criteria_micro="
                                  evaluation_criteria_micro
                                "
                                :evaluation_criteria_micro_ids="
                                  evaluation_criteria_micro_ids
                                "
                                :order="
                                  rubricCriterias.length > 0
                                    ? rubricCriterias[
                                        rubricCriterias.length - 1
                                      ].order + 1
                                    : 1
                                "
                                @created="
                                  $bvModal.hide(
                                    `modal-created-rubric-criteria-st-${evaluation_criteria_micro.id}-${instrument_id}-${container_instrument_id}`
                                  )
                                "
                              ></RubricCriteriaForm>
                            </b-modal>
                          </template>
                          <template v-if="allows_crud && !rubric">
                            <div
                              v-b-tooltip.v-danger.top.noninteractive="
                                `Para agregar una ${$getVisibleNames(
                                  'manual.dimension',
                                  false,
                                  'Dimensión'
                                )} debe crear una ${$getVisibleNames(
                                  'evaluations2.rubric',
                                  false,
                                  'Pauta'
                                )} `
                              "
                            >
                              <button-add disable="true" size="sm">
                              </button-add>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </b-td>
                  <!-- v-if="index3 == 0"
                    :rowspan="
                      rubricCriterias.filter(
                        (x) =>
                          x.evaluation_criteria_micro ==
                          evaluation_criteria_micro.id
                      ).length
                    " -->
                  <b-td
                    class="secundary-cell ponderation-cell"
                    :class="{
                      'unselectable-text':
                        (user && user.groups.includes(7)) || preview_evaluation,
                    }"
                    ><b>N/A</b>
                  </b-td>
                  <b-td
                    v-if="showLinkedQuestion"
                    class="secundary-cell ponderation-cell"
                    :class="{
                      'unselectable-text':
                        (user && user.groups.includes(7)) || preview_evaluation,
                    }"
                  >
                    N/A
                  </b-td>
                </b-tr>
              </template>
            </template>
          </template>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-td colspan="2" class="primary-cell"></b-td>
            <b-td
              class="primary-cell"
              :class="{
                'unselectable-text':
                  (user && user.groups.includes(7)) || preview_evaluation,
              }"
            >
              <strong>TOTAL</strong>
            </b-td>
            <!-- PONDERACIÓN INDICADOR DE LOGRO -->
            <b-td
              class="primary-cell"
              :title="
                totalWeighingTestEvaluationCriteriaMicros != 100
                  ? 'La ponderación total debe ser igual a 100.'
                  : ''
              "
              v-bind:style="{
                color:
                  totalWeighingTestEvaluationCriteriaMicros != 100 ? 'red' : '',
              }"
              :class="{
                'unselectable-text':
                  (user && user.groups.includes(7)) || preview_evaluation,
              }"
            >
              <!-- <strong>{{ totalWeighing }}%</strong> -->
              <strong>{{ totalWeighingTestEvaluationCriteriaMicros }}%</strong>
            </b-td>
            <b-td
              class="primary-cell"
              :title="
                totalWeighing != 100
                  ? 'La ponderación total debe ser igual a 100.'
                  : ''
              "
              v-bind:style="{ color: totalWeighing != 100 ? 'red' : '' }"
              :class="{
                'unselectable-text':
                  (user && user.groups.includes(7)) || preview_evaluation,
              }"
            >
              <strong>{{ totalWeighing }}%</strong>
            </b-td>
            <b-td v-if="showLinkedQuestion" class="primary-cell"></b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </div>
    <b-modal
      :id="`modal-create-rubric-st-${instrument_id}-${container_instrument_id}-${container_instrument_id}`"
      :title="`Crear ${$getVisibleNames(
        'evaluations2.rubric',
        false,
        'Pauta'
      )}`"
      size="lg"
      hide-footer
    >
      <NewRubricForm
        :test_id="instrument_id"
        @closeModal="
          $bvModal.hide(
            `modal-create-rubric-st-${instrument_id}-${container_instrument_id}-${container_instrument_id}`
          )
        "
      ></NewRubricForm>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "DuocSpecificationTable",
  components: {
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    RubricCriteriaForm: () =>
      import("@/components/new-evaluations/Rubrics/RubricCriteriaForm"),
    NewRubricForm: () =>
      import("@/components/new-evaluations/Rubrics/NewRubricForm"),
    DuocSpecificationTableInput: () =>
      import(
        "@/components/new-evaluations/Evaluation/SpecificationTable/DuocSpecificationTableInput"
      ),
  },
  props: {
    evaluation_criteria_micro_ids: {
      type: Array,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      required: true,
    },
    container_instrument_id: {
      type: String,
    },
    preview_evaluation: {
      type: Boolean,
      required: true,
    },
    instrument_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      evaluation_criteria_micros: names.EVALUATION_CRITERIAS,
      study_units: names.STUDY_UNITS,
      tests: names.NEW_TESTS,
      evaluations: names.NEW_EVALUATIONS,
      egress_profile_matters: names.EGRESS_PROFILES_MATTERS,
      sections: names.SECTIONS,
      test_evaluation_criteria_micros:
        names.NEW_TEST_EVALUATION_CRITERIA_MICROS,
      rubrics: names.NEW_RUBRIC_INSTRUMENTS,
      rubrics_criterias: names.NEW_RUBRIC_CRITERIAS,
      test_segments: names.NEW_TEST_SEGMENTS,
      redaction_questions: names.NEW_REDACTION_QUESTIONS,
      multiple_choice_questions: names.NEW_MULTIPLE_CHOICE_QUESTIONS,
      // filling_questions: names.NEW_FILLING_QUESTIONS,
      matching_questions: names.NEW_MATCHING_QUESTIONS,
    }),
    redactionQuestions() {
      return this.redaction_questions.filter(
        (x) => x.test == this.instrument_id
      );
    },
    multipleChoiceQuestions() {
      return this.multiple_choice_questions.filter(
        (x) => x.test == this.instrument_id
      );
    },
    // fillingQuestions() {
    //   return this.filling_questions.filter((x) => x.test == this.instrument_id);
    // },
    matchingQuestions() {
      return this.matching_questions.filter(
        (x) => x.test == this.instrument_id
      );
    },
    questionList() {
      let list = [];
      list = list.concat(
        this.redactionQuestions.map((question) => ({
          ...question,
          question_type: 1,
        }))
      );
      list = list.concat(
        this.multipleChoiceQuestions.map((question) => ({
          ...question,
          question_type: 2,
        }))
      );
      // list = list.concat(
      //   this.fillingQuestions.map((question) => ({
      //     ...question,
      //     question_type: 3,
      //   }))
      // );
      list = list.concat(
        this.matchingQuestions.map((question) => ({
          ...question,
          question_type: 4,
        }))
      );
      return list.sort((a, b) => a.order - b.order);
    },
    showLinkedQuestion() {
      if (
        this.questionList.filter((x) => x.linked_rubric_criteria != null)
          .length > 0
      )
        return true;
      else return false;
    },
    evaluationCriteriaMicro() {
      return this.evaluation_criteria_micros.filter((x) =>
        this.evaluation_criteria_micro_ids.includes(x.id)
      );
    },
    studyUnits() {
      const study_unit_ids = [
        ...new Set(this.evaluationCriteriaMicro.map((x) => x.study_unit)),
      ];
      return this.study_units.filter((x) => study_unit_ids.includes(x.id));
    },
    test() {
      return this.tests.find((x) => x.id == this.instrument_id);
    },
    evaluation() {
      if (!this.test) return null;
      return this.evaluations.find((x) => x.id == this.test.evaluation);
    },
    section() {
      if (this.evaluation && this.evaluation.scope == 1)
        return this.sections.find((x) => x.id == this.evaluation.scope_id);
      else return null;
    },
    egressProfileMatter() {
      if (
        this.evaluation &&
        this.evaluation.scope == 2 &&
        this.evaluation.scope_id != null
      ) {
        return this.egress_profile_matters.find(
          (x) => x.id == this.evaluation.scope_id
        );
      }
      if (
        this.evaluation &&
        this.evaluation.scope == 1 &&
        this.section &&
        this.section.egress_profile_matter != null
      ) {
        return this.egress_profile_matters.find(
          (x) => x.id == this.section.egress_profile_matter
        );
      }
      return null;
    },
    testEvaluationCriteriaMicros() {
      return this.test_evaluation_criteria_micros.filter(
        (x) =>
          x.test == this.instrument_id &&
          this.evaluation_criteria_micro_ids.includes(
            x.evaluation_criteria_micro
          )
      );
    },
    totalWeighingTestEvaluationCriteriaMicros() {
      return this.testEvaluationCriteriaMicros.reduce((acc, v_actual) => {
        let v_convert =
          v_actual.weighing === null || v_actual.weighing === ""
            ? 0
            : v_actual.weighing;
        return acc + (v_convert > 0 ? parseFloat(v_convert) : 0);
      }, 0);
    },
    rubric() {
      return this.rubrics.find((x) => x.test == this.instrument_id);
    },
    rubricCriterias() {
      if (this.rubric) {
        return this.rubrics_criterias.filter((x) => this.rubric.id == x.rubric);
      }
      return [];
    },
    totalWeighing() {
      let weighing = 0;
      this.rubricCriterias
        .filter((x) =>
          this.evaluationCriteriaMicro
            .map((a) => a.id)
            .includes(x.evaluation_criteria_micro)
        )
        .forEach((element) => {
          weighing += Number(element.weighing);
        });
      return weighing;
    },
  },
  methods: {
    insertStoreOpenAiDimension() {
      this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
      this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);

      this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
        visible_intelligence_options: [8],
        disabled_selected_intelligence_option: true,
      });
    },
    openModalDimensionCreate(evaluation_criteria_micro_id) {
      this.insertStoreOpenAiDimension();
      this.$nextTick(() => {
        this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
          available_evaluation_criteria_micro:
            this.evaluation_criteria_micro_ids,
          extra_label: `${this.$getVisibleNames(
            "manual.dimension",
            false,
            "Dimensión"
          )}`,
          intelligence_option: 8,
          sub_option: 2,
        });
      });
      this.$bvModal.show(
        `modal-created-rubric-criteria-st-${evaluation_criteria_micro_id}-${this.instrument_id}-${this.container_instrument_id}`
      );
    },
    openModalDimensionEdit(
      index,
      index2,
      index3,
      evaluation_criteria_micro_id
    ) {
      this.insertStoreOpenAiDimension();
      this.$nextTick(() => {
        this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
          available_evaluation_criteria_micro:
            this.evaluation_criteria_micro_ids,
          extra_label: `${this.$getVisibleNames(
            "manual.dimension",
            false,
            "Dimensión"
          )}`,
          intelligence_option: 8,
          sub_option: 2,
        });
      });
      this.$bvModal.show(
        `modal-edit-rubric-criteria-st-${index}-${index2}-${index3}-${evaluation_criteria_micro_id}-${this.instrument_id}-${this.container_instrument_id}`
      );
    },
    hideModalDimension() {
      this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
      this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);
      this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
        visible_intelligence_options: [7],
      });

      if (
        this.evaluation &&
        this.evaluation.scope == 3 &&
        this.evaluation.scope_id != null
      ) {
        this.$nextTick(() => {
          this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
            matter_id: this.evaluation.scope_id,
            extra_context: "Para la Evaluación",
            extra_label: "",
            show_message_evaluation_view: true,
            intelligence_option: 7,
          });
        });
      }
      if (
        this.egressProfileMatter &&
        this.evaluation &&
        (this.evaluation.scope == 2 || this.evaluation.scope == 1) &&
        this.evaluation.scope_id != null
      ) {
        this.$nextTick(() => {
          this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
            matter_id: this.egressProfileMatter.matter,
            extra_context: "Para la Evaluación",
            show_message_evaluation_view: true,
            intelligence_option: 7,
          });
        });
      }
    },
    getRubricCriteriaWeighing(rubric_criteras) {
      let weighing = 0;
      rubric_criteras.forEach((element) => {
        weighing += element.weighing;
      });
      if (weighing == 0) return "N/A";
      return weighing + "%";
    },
    showWarningRubricCriteria(evaluation_criteria_micro) {
      const max_weighing =
        (
          this.testEvaluationCriteriaMicros.find(
            (x) => x.evaluation_criteria_micro === evaluation_criteria_micro.id
          ) || 0
        ).weighing || 0;
      const weighing =
        this.rubricCriterias
          .filter(
            (x) => x.evaluation_criteria_micro === evaluation_criteria_micro.id
          )
          .reduce((acc, x) => acc + parseFloat(x.weighing), 0) || 0;
      if (weighing > max_weighing) return true;
      else return false;
    },
    filterQuestionsByRubricCriteria(rubric_criteria_id) {
      return this.questionList
        .filter((x) => x.linked_rubric_criteria == rubric_criteria_id)
        .map((x) => {
          return {
            ...x,
            segment_order:
              (this.test_segments.find((s) => s.id == x.segment) || {}).order ||
              null,
          };
        })
        .sort(function (a, b) {
          if (a.segment_order < b.segment_order) return -1;
          if (a.segment_order > b.segment_order) return 1;
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });
    },
  },
  created() {},
};
</script>

<style scoped>
.unselectable-text {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.text-ra,
.text-il {
  vertical-align: middle !important;
}
.number-title-evaluative-agent {
  font-size: 32px;
  color: rgb(255, 153, 0);
}
.title-evaluative-agent {
  font-size: 32px;
}
.primary-cell {
  padding: 5px;
  height: 100%;
  vertical-align: middle;
  background-color: var(--kl-menu-color) !important;
  color: white;
  font-size: 10pt;
}
.secundary-cell {
  text-align: justify;
  vertical-align: middle !important;
}
.ponderation-cell-indicator-evaluation {
  vertical-align: middle;
  text-align: center !important;
}
.ponderation-cell {
  text-align: center !important;
  vertical-align: middle;
}
.page-break {
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
}
@media (max-width: 750px) {
  .duoc-specification-table-container {
    overflow-x: auto;
  }
}
</style>