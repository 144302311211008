<template>
  <div>
    <div>
      <h4>Reportes</h4>
    </div>
    <div class="d-flex mx-2 mb-2 container-select noprint">
      <span class="w-10 align-content-center noprint">Tipo</span>
      <b-form-select
        id="selected_report_type"
        v-model="selected_report_type"
        :options="report_type_options"
        class="w-90 noprint"
      >
      </b-form-select>
    </div>
    <Filters
      :max_campuses="max_campuses"
      :max_careers="max_careers"
      :max_egress_profiles="max_egress_profiles"
      :max_sections="max_sections"
      :max_matters="max_matters"
      :max_modalities="max_modalities"
      :max_periods="max_periods"
      :max_levels="max_levels"
      :max_study_units="max_study_units"
      :max_cohorts="max_cohorts"
      :max_cycles="max_cycles"
      :load_cohorts="load_cohorts"
      :disabled="!selected_report_type"
      :required_selectors="required_selectors"
      :show_selectors="show_selectors"
      :clear_filters="clear_filters"
      @campuses="(x) => (campuses_ = x)"
      @careers="(x) => (careers_ = x)"
      @egress_profiles="(x) => (egress_profiles_ = x)"
      @levels="(x) => (levels_ = x)"
      @matters="(x) => (matters_ = x)"
      @periods="(x) => (periods_ = x)"
      @sections="(x) => (sections_ = x)"
      @study_units="(x) => (study_units_ = x)"
      @cohorts="(x) => (cohorts_ = x)"
      @cycles="(x) => (cycles_ = x)"
    >
    </Filters>
    <div class="toggle-div mt-3 noprint">
      <b-button
        class="load-report-btn"
        size="sm"
        @click="fetchReport"
        :disabled="!requirementFulfilled"
        >Cargar Reporte</b-button
      >
      <b-button
        class="load-report-btn ml-2"
        size="sm"
        @click="clear_filters = !clear_filters"
        >Limpiar Filtros</b-button
      >
    </div>
    <!-- {{ selected_report_type }} {{ chartdata }} {{ loading }} -->
    <template v-if="!selected_report_type || (!chartdata && !loading)">
      <div class="reports-default">
        <div class="reports-default-caption">
          Seguimiento de resultados de aprendizaje.
        </div>
        <img
          class="reports-default-image"
          src="https://i.postimg.cc/rw8KnBf2/Pngtree-learning-progress-character-vector-5776835.png"
          alt="Responsive image"
        />
      </div>
    </template>
    <LegendFilters
      :campuses_="campuses_"
      :careers_="careers_"
      :egress_profiles_="egress_profiles_"
      :levels_="levels_"
      :matters_="matters_"
      :periods_="periods_"
      :sections_="sections_"
      :study_units_="study_units_"
      :cohorts_="cohorts_"
      :cycles_="cycles_"
      :show_selectors="show_selectors"
    ></LegendFilters>
    <AssessmentRangeSection
      v-if="
        show_range_grades_section &&
        matters_ &&
        matters_.length == 1 &&
        sections_ &&
        sections_.length > 0
      "
      :key="report_key"
      :sections_ids="sections_.map((x) => x.id)"
      :egress_profile_matter="matters_[0]"
      :campuses_="campuses_"
      :egress_profiles_="egress_profiles_"
      :levels_="levels_"
      :matters_="matters_"
      :periods_="periods_"
      :sections_="sections_"
    ></AssessmentRangeSection>
  </div>
</template>

<script>
import * as names from "@/store/names";
// import { mapGetters } from "vuex";
export default {
  name: "GeneralReport",
  components: {
    Filters: () => import("@/components/reports/Filters"),
    LegendFilters: () =>
      import("@/components/reports/TeachingReport/LegendFilters"),
    AssessmentRangeSection: () =>
      import("@/components/reports/TeachingReport/AssessmentRangeSection/AssessmentRangeSection.vue"),
  },
  props: {},
  data() {
    return {
      selected_report_type: null,
      report_type_options: [
        {
          value: 1,
          text: "Reporte de Assessment por Asignatura de Perfil de Egreso",
        },
      ],
      max_campuses: "all",
      max_careers: "all",
      max_egress_profiles: "all",
      max_sections: "all",
      max_matters: "all",
      max_modalities: "all",
      max_periods: "all",
      max_levels: "all",
      max_study_units: "all",
      max_cohorts: "all",
      max_cycles: "all",
      campuses_: null,
      careers_: null,
      egress_profiles_: null,
      levels_: null,
      matters_: null,
      periods_: null,
      sections_: null,
      study_units_: null,
      cohorts_: null,
      cycles_: null,
      chartdata: null,
      // component_key: 0,
      report_key: 0,
      selectors: null,
      clear_filters: false,
      disable_clear_btn: false,
      required_selectors:
        "campuses careers egress_profiles matters sections levels periods study_units cohorts cycles",
      show_selectors: "",
      loading: false,
      load_cohorts: false,
      show_range_grades_section: false,
    };
  },
  computed: {
    // ...mapGetters({}),
    title() {
      let title = "Reporte";
      if (this.selected_report_type)
        return this.report_types.find(
          (x) => x.value == this.selected_report_type
        ).text;
      return title;
    },
    requirementFulfilled() {
      if (this.selected_report_type == 1) {
        if (!this.egress_profiles_ || !this.egress_profiles_.length)
          return false;
        // if (!this.periods_ || !this.periods_.length) return false;
        // if (!this.levels_ || !this.levels_.length) return false;
        // if (!this.cohorts_ || !this.cohorts_.length) return false;
        // if (!this.study_units_ || !this.study_units_.length) return false;
        if (!this.sections_ || !this.sections_.length) return false;
        if (!this.matters_ || !this.matters_.length) return false;
        return true;
      }
      // else if (this.selected_report_type == 3) {
      //   if (!this.egress_profiles_ || !this.egress_profiles_.length)
      //     return false;
      //   if (!this.levels_ || !this.levels_.length) return false;
      //   if (!this.cohorts_ || !this.cohorts_.length) return false;
      //   return true;
      // }
      return false;
    },
  },
  methods: {
    resetSettings() {
      this.max_campuses = "all";
      this.max_careers = "all";
      this.max_egress_profiles = "all";
      this.max_sections = "all";
      this.max_matters = "all";
      this.max_modalities = "all";
      this.max_periods = "all";
      this.max_levels = "all";
      this.max_study_units = "all";
      this.max_cohorts = "all";
      this.max_cycles = "all";
      this.show_range_grades_section = false;
    },
    clearFilters() {
      this.clear_filters = true;
      this.show_range_grades_section = false;
      this.chartdata = null;
    },
    fetchReport() {
      // this.loading = true;
      // this.report_key = 0;
      this.selectors = {
        selected_campuses: this.campuses_,
        selected_careers: this.careers_,
        selected_egress_profiles: this.egress_profiles_,
        selected_levels: this.levels_,
        selected_matters: this.matters_,
        selected_periods: this.periods_,
        selected_sections: this.sections_,
        selected_study_units: this.study_units_,
        selected_cohorts: this.cohorts_,
        selected_cycles: this.cycles_,
      };
      if (this.selected_report_type == 1) {
        this.loading = true;
        this.fetchRangeGradesSections();
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      }
    },
    fetchRangeGradesSections() {
      this.report_key++;
      this.show_range_grades_section = true;
      this.chartdata = [];
    },
  },
  watch: {
    selected_report_type() {
      // Ejemplo para mostrar todos los selectores como requeridos.
      // this.required_selectors = "campuses careers egress_profiles matters sections levels periods modalities";
      this.chartdata = null;
      this.resetSettings();
      if (this.selected_report_type == 1) {
        this.max_egress_profiles = 1;
        this.max_matters = 1;
        // this.max_sections = 1;
        this.max_periods = 1;
        this.max_levels = 1;
        this.show_selectors =
          "campuses egress_profiles levels periods matters sections";
        this.required_selectors =
          "campuses egress_profiles periods levels matters sections";
      }
    },
  },
  mounted() {},
  created() {
    this.$hasPermissions();
    this.$store.dispatch(names.FETCH_CAMPUSES);
    this.$store.dispatch(names.FETCH_FACULTIES);
    this.$store.dispatch(names.FETCH_CAREERS);
    this.$store.dispatch(names.FETCH_EGRESS_PROFILES);
    this.$store.dispatch(names.FETCH_MODALITIES);
    this.$store.dispatch(names.FETCH_PERIODS);
    this.$store.dispatch(names.FETCH_EGRESS_PROFILES_MATTERS);
    this.$store.dispatch(names.FETCH_CYCLES);
  },
};
</script>

<style scoped>
.reports-default-image {
  width: 30%;
  height: 30%;
}
.w-10 {
  width: 10%;
}
.w-90 {
  width: 90%;
}
</style>