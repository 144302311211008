<template>
  <td
    :style="{
      'background-color':
        context == 'evaluation' && !allows_crud
          ? gradeRangeBackground(
              getStudentScore.final_grade != null
                ? parseFloat(getStudentScore.final_grade.toFixed(2))
                : getStudentScore.manual_grade != null
                ? parseFloat(getStudentScore.manual_grade.toFixed(2))
                : getStudentScore.automated_grade != null
                ? parseFloat(getStudentScore.automated_grade.toFixed(2))
                : institution.min_grade
            ) + ' !important'
          : '',
    }"
  >
    <div v-if="context == 'test'">
      <div v-if="!allows_crud" class="d-flex">
        <div
          class="w-100"
          v-if="
            getStudentScore.start_time == null &&
            getStudentScore.manual_score == null &&
            getStudentScore.final_score == null &&
            getStudentScore.automated_score == 0
          "
        >
          <span
            v-b-tooltip.v-secondary.noninteractive.hover.right="
              'Instrumento no contestado'
            "
            >-
          </span>
        </div>
        <span
          v-else
          class="w-100"
          v-b-tooltip.v-secondary.noninteractive.hover="
            'Puntaje obtenido: ' +
            +(
              Math.round(
                Number(
                  getStudentScore.manual_score == null
                    ? getStudentScore.automated_score
                    : getStudentScore.manual_score
                ) + 'e+2'
              ) + 'e-2'
            ) +
            '/' +
            test.max_score
          "
        >
          {{
            getStudentScore.final_score != null
              ? parseFloat(getStudentScore.final_score.toFixed(2))
              : getStudentScore.manual_score != null
              ? parseFloat(getStudentScore.manual_score.toFixed(2))
              : getStudentScore.automated_score != null
              ? parseFloat(getStudentScore.automated_score.toFixed(2))
              : 0
          }}
        </span>
        <b-icon
          v-if="
            getStudentScore.final_score == null &&
            getStudentScore.manual_score == null &&
            getStudentScore.automated_score != null
          "
          class="ml-1 mt-1"
          v-b-tooltip.v-secondary.noninteractive.hover="'Puntaje automático'"
          icon="lightbulb"
        ></b-icon>
        <b-icon
          v-if="getStudentScore.start_time == null"
          v-b-tooltip.v-secondary.noninteractive.hover.right="
            'Instrumento no contestado.'
          "
          class="ml-1 mt-1"
          icon="journal-x"
        ></b-icon>
        <b-icon
          v-else
          class="ml-1 mt-1"
          v-b-tooltip.v-secondary.noninteractive.hover.right="
            'El instrumento se inicio el ' +
            $moment(getStudentScore.start_time).format('DD/MM/YYYY HH:mm')
          "
          icon="journal-check"
        ></b-icon>
      </div>
      <span v-else>
        <div>
          <b-form-input
            :ref="
              'student:' +
              student.id +
              'evaluation:' +
              evaluation.id +
              'test:' +
              test.id
            "
            v-b-tooltip.v-secondary.noninteractive.hover="
              'Puntaje máximo: ' + test.max_score
            "
            size="sm"
            :min="0"
            type="number"
            class="m-0 p-0 w-100 text-center"
            :value="
              getStudentScore.final_score != null
                ? parseFloat(getStudentScore.final_score.toFixed(2))
                : getStudentScore.manual_score != null
                ? parseFloat(getStudentScore.manual_score.toFixed(2))
                : getStudentScore.automated_score != null
                ? parseFloat(getStudentScore.automated_score.toFixed(2))
                : 0
            "
          >
            <!-- @update="patchStudentScore()" -->
          </b-form-input>
          <b-icon
            class="ml-1 mt-2 style-icon-btn"
            v-b-tooltip.v-secondary.noninteractive.hover.top="'Guardar'"
            icon="save2"
            @click="patchStudentScore()"
          ></b-icon>
          <b-icon
            class="ml-1 mt-2 style-icon-btn"
            v-b-tooltip.v-secondary.noninteractive.hover="
              'Utilizar Puntaje automático: ' +
              +(
                Math.round(Number(getStudentScore.automated_score) + 'e+2') +
                'e-2'
              )
            "
            @click="patchAutomateScore()"
            icon="arrow-repeat"
          ></b-icon>
        </div>
      </span>
    </div>
    <div v-else-if="context == 'evaluation'">
      <div v-if="!allows_crud" class="d-flex">
        <span class="w-100">
          {{
            getStudentScore.final_grade != null
              ? parseFloat(getStudentScore.final_grade.toFixed(2))
              : getStudentScore.manual_grade != null
              ? parseFloat(getStudentScore.manual_grade.toFixed(2))
              : getStudentScore.automated_grade != null
              ? parseFloat(getStudentScore.automated_grade.toFixed(2))
              : institution.min_grade
          }}
        </span>
        <b-icon
          v-if="
            getStudentScore.final_grade == null &&
            getStudentScore.manual_grade == null &&
            getStudentScore.automated_grade != null
          "
          class="ml-1 mt-1"
          v-b-tooltip.v-secondary.noninteractive.hover="'Nota automática'"
          icon="lightbulb"
        ></b-icon>
      </div>
      <span v-else>
        <div>
          <b-form-input
            :ref="'student:' + student.id + 'evaluation:' + evaluation.id"
            size="sm"
            step="0.01"
            :max="institution.max_grade"
            :min="institution.min_grade"
            type="number"
            class="m-0 p-0 w-100 text-center"
            :value="
              getStudentScore.final_grade != null
                ? parseFloat(getStudentScore.final_grade.toFixed(2))
                : getStudentScore.manual_grade != null
                ? parseFloat(getStudentScore.manual_grade.toFixed(2))
                : getStudentScore.automated_grade != null
                ? parseFloat(getStudentScore.automated_grade.toFixed(2))
                : institution.min_grade
            "
          ></b-form-input>
          <!-- @update="patchStudentScore()" -->
          <b-icon
            class="ml-1 mt-2 style-icon-btn"
            v-b-tooltip.v-secondary.noninteractive.hover.top="'Guardar'"
            icon="save2"
            @click="patchStudentScore()"
          ></b-icon>
          <b-icon
            class="ml-1 mt-2 style-icon-btn"
            v-b-tooltip.v-secondary.noninteractive.hover="
              'Utilizar Puntaje automático: ' +
              +(
                Math.round(Number(getStudentScore.automated_grade) + 'e+2') +
                'e-2'
              )
            "
            @click="patchAutomatedGrade()"
            icon="arrow-repeat"
          ></b-icon>
        </div>
      </span>
    </div>
  </td>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "NewFullGradingInputComponent",
  components: {},
  props: {
    context: {
      type: String,
      required: true,
    },
    grade_ranges: {
      type: Array,
    },
    student: {
      type: Object,
      required: true,
    },
    evaluatee_evaluations: {
      type: Array,
      required: true,
    },
    evaluation: {
      type: Object,
      required: true,
    },
    test: {
      type: Object,
    },
    allows_crud: {
      type: Boolean,
      required: true,
    },
    selected_range_group: {
      type: [Number, null],
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
    }),
    getStudentScore() {
      if (this.context == "test") {
        let index = this.evaluatee_evaluations.findIndex(
          (x) => x.evaluation == this.evaluation.id
        );
        if (index != -1) {
          let student_score = this.evaluatee_evaluations[
            index
          ].evaluatee_tests.find((x) => x.test == this.test.id);
          if (student_score) return student_score;
        }
      }
      if (this.context == "evaluation") {
        let student_score = this.evaluatee_evaluations.find(
          (x) => x.evaluation == this.evaluation.id
        );
        if (student_score) return student_score;
      }
      return {
        id: null,
        automated_grade: this.institution ? this.institution.min_grade : 0,
        final_grade: this.institution ? this.institution.min_grade : 0,
        manual_grade: this.institution ? this.institution.min_grade : 0,
        publish: false,
        automated_score: 0,
        manual_score: 0,
        final_score: 0,
        start_time: null,
        manual_score_id: null,
      };
    },
    gradeRangesList() {
      if (this.selected_range_group != null)
        return this.grade_ranges.filter(
          (x) => x.grade_range_group == this.selected_range_group
        );
      else return this.grade_ranges;
    },
  },
  methods: {
    gradeRangeBackground(grade) {
      for (let range of this.gradeRangesList) {
        if (range.include_start_grade && range.include_finish_grade) {
          if (grade >= range.start_grade && grade <= range.finish_grade) {
            return range.color;
          }
        } else if (range.include_start_grade && !range.include_finish_grade) {
          if (grade >= range.start_grade && grade < range.finish_grade) {
            return range.color;
          }
        } else if (!range.include_start_grade && range.include_finish_grade) {
          if (grade > range.start_grade && grade <= range.finish_grade) {
            return range.color;
          }
        } else {
          if (grade > range.start_grade && grade < range.finish_grade) {
            return range.color;
          }
        }
      }
      return null; // Retorna null si no encuentra ningún rango que coincida.
    },
    patchStudentScore() {
      if (this.context == "test") {
        let score = parseFloat(
          this.$refs[
            "student:" +
              this.student.id +
              "evaluation:" +
              this.evaluation.id +
              "test:" +
              this.test.id
          ].localValue
        );
        if (score >= 0) {
          if (score > this.test.max_score) score = this.test.max_score;
          if (this.getStudentScore.id == null) {
            this.$store
              .dispatch(names.POST_EVALUATEE_TEST, {
                manual_score: score,
                is_finished: false,
                evaluatee: this.student.id,
                is_corrected: true,
                test: this.test.id,
                attachments: [],
                student_attachments: [],
              })
              .then(() => {
                this.$emit("change_score");
              });
          } else {
            let payload = null;
            if (this.getStudentScore.final_score != null) {
              payload = {
                evaluatee_test_id: this.getStudentScore.id,
                item: {
                  final_score: score,
                  is_corrected: true,
                },
              };
            } else {
              payload = {
                evaluatee_test_id: this.getStudentScore.id,
                item: {
                  manual_score: score,
                  is_corrected: true,
                },
              };
            }
            if (payload != null)
              this.$store
                .dispatch(names.PATCH_EVALUATEE_TEST, payload)
                .then(() => {
                  this.$emit("change_score");
                });
          }
        }
      }
      if (this.context == "evaluation") {
        let score = parseFloat(
          this.$refs[
            "student:" + this.student.id + "evaluation:" + this.evaluation.id
          ].localValue
        );
        if (
          score >= this.institution.min_grade &&
          score <= this.institution.max_grade
        ) {
          if (this.getStudentScore.id == null) {
            this.$store
              .dispatch(names.POST_EVALUATEE_EVALUATION, {
                manual_grade: score,
                evaluatee: this.student.id,
                evaluation: this.evaluation.id,
                attachments: [],
              })
              .then(() => {
                this.$emit("change_score");
              });
          } else {
            let payload = null;
            if (this.getStudentScore.final_grade != null) {
              payload = {
                evaluatee_evaluation_id: this.getStudentScore.id,
                item: {
                  final_grade: score,
                },
              };
            } else {
              payload = {
                evaluatee_evaluation_id: this.getStudentScore.id,
                item: {
                  manual_grade: score,
                },
              };
            }
            if (payload != null)
              this.$store
                .dispatch(names.PATCH_EVALUATEE_EVALUATION, payload)
                .then(() => {
                  this.$emit("change_score");
                });
          }
        }
      }
    },
    patchAutomateScore() {
      if (this.getStudentScore.id == null) {
        this.$store
          .dispatch(names.POST_EVALUATEE_TEST, {
            manual_score: null,
            is_finished: false,
            evaluatee: this.student.id,
            is_corrected: true,
            test: this.test.id,
            attachments: [],
            student_attachments: [],
          })
          .then(() => {
            this.$emit("change_score");
          });
      } else {
        const payload = {
          evaluatee_test_id: this.getStudentScore.id,
          item: {
            manual_score: null,
            is_corrected: true,
          },
        };
        this.$store.dispatch(names.PATCH_EVALUATEE_TEST, payload).then(() => {
          this.$emit("change_score");
        });
      }
    },
    patchAutomatedGrade() {
      if (this.getStudentScore.id == null) {
        this.$store
          .dispatch(names.POST_EVALUATEE_EVALUATION, {
            manual_grade: null,
            evaluatee: this.student.id,
            evaluation: this.evaluation.id,
            attachments: [],
          })
          .then(() => {
            this.$emit("change_score");
          });
      } else {
        const payload = {
          evaluatee_evaluation_id: this.getStudentScore.id,
          item: {
            manual_grade: null,
          },
        };
        this.$store
          .dispatch(names.PATCH_EVALUATEE_EVALUATION, payload)
          .then(() => {
            this.$emit("change_score");
          });
      }
    },
  },
  created() {},
};
</script>

<style scoped>
.container-icons-inputs {
  display: flex;
  justify-content: center;
}
.style-icon-btn {
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.2s;
  border-radius: 50%;
  cursor: pointer;
}
.style-icon-btn:hover {
  background-color: #ecececa1;
  border-radius: 50%;
  transform: scale(1.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
</style>

