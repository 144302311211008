<template>
  <div>
    <template v-if="institution && !institution.kl_assessment">
      <Page404></Page404>
    </template>
    <div v-else>
      <div class="mt-5 mb-2 px-3">
        <template v-if="section && section.egress_profile_matter != null">
          <h4>
            Información de la
            {{
              $getVisibleNames("mesh.egressprofilematter", false, "Asignatura")
            }}
          </h4>
          <NewMatterPresentation
            class="mt-3"
            v-if="egress_profile_matter && matter"
            :Matter="egress_profile_matter"
            :oldMatter="matter"
          ></NewMatterPresentation>
        </template>
        <template v-else-if="section && section.matter != null">
          <h4>
            Información de la
            {{ $getVisibleNames("teaching.matter", false, "Asignatura Base") }}
          </h4>
          <NewMatterPresentation
            class="mt-3"
            v-if="matter"
            :oldMatter="matter"
          ></NewMatterPresentation>
        </template>
      </div>
      <template v-if="egress_profile_matter">
        <h4>
          Información de la
          {{ $getVisibleNames("teaching.section", false, "Sección") }}
        </h4>
        <HeaderSection
          class="pt-1 pb-4"
          :Matter="egress_profile_matter"
          :section_id="section_id"
          :allows_crud="false"
        ></HeaderSection
      ></template>
      <h3>Reporte de Assessment</h3>
      <AssessmentRangeSection
        v-if="
          egress_profile_matter &&
          matter &&
          section.id &&
          section.egress_profile_matter
        "
        :sections_ids="[section.id]"
        :egress_profile_matter="egress_profile_matter"
      >
      </AssessmentRangeSection>
      <NewDashboardCustomFab
        v-if="section_id"
        :section_id="section_id"
        :show_report_assessment="false"
        :show_teacher_views="true"
      ></NewDashboardCustomFab>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "AssessmentRangeSectionView",
  components: {
    NewMatterPresentation: () =>
      import(
        "@/components/teaching/MatterProgram/Components/NewMatterPresentation.vue"
      ),
    AssessmentRangeSection: () =>
      import("@/components/reports/TeachingReport/AssessmentRangeSection/AssessmentRangeSection.vue"),
    NewDashboardCustomFab: () =>
      import("@/components/dashboard/NewDashboardCustomFab"),
    HeaderSection: () => import("@/components/teaching/Section/HeaderSection"),
    Page404: () => import("@/components/public/Page404"),
  },
  props: {},
  data() {
    return {
      section_id: Number(this.$route.params.section_id),
      allows_crud: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      sections: names.SECTIONS,
      egress_profile_matters: names.EGRESS_PROFILES_MATTERS,
      matters: names.MATTERS,
    }),
    section() {
      return this.sections.find((x) => x.id == this.section_id);
    },
    egress_profile_matter() {
      if (this.section && this.section.egress_profile_matter != null)
        return this.egress_profile_matters.find(
          (x) => x.id == this.section.egress_profile_matter
        );
      else return null;
    },
    matter() {
      if (this.section && this.section.matter != null)
        return this.matters.find((x) => x.id == this.section.matter);
      else if (
        this.egress_profile_matter &&
        this.egress_profile_matter.matter != null
      )
        return this.matters.find(
          (x) => x.id == this.egress_profile_matter.matter
        );
      else return null;
    },
  },
  methods: {
    pushDashboard() {
      this.$swal
        .fire({
          backdrop: `rgba(0,0,0,0.99)`,
          title: "Acceso Denegado",
          text: "No tienes permiso para ver la siguiente vista",
          type: "warning",
          showConfirmButton: false,
          timer: 5000, // Tiempo del temporizador en milisegundos (5000 ms = 5 segundos)
          timerProgressBar: true,
          willClose: () => {
            this.$router.push({ name: "DashboardView" });
          },
        })
        .then(() => {
          this.$router.push({ name: "DashboardView" });
        });
    },
  },
  watch: {
    section(value) {
      if (
        value != null &&
        this.user &&
        (this.user.groups.includes(7) ||
          (this.user.groups.includes(6) &&
            !value.professors.includes(this.user.id)))
      ) {
        this.pushDashboard();
      }
    },
  },
  created() {
    this.$store
      .dispatch(names.FETCH_SECTION, this.section_id)
      .then((section) => {
        if (section.egress_profile_matter != null) {
          this.$store
            .dispatch(
              names.FETCH_EGRESS_PROFILE_MATTER,
              section.egress_profile_matter
            )
            .then((egress_profile_matter) => {
              this.$store.dispatch(
                names.FETCH_MATTER,
                egress_profile_matter.matter
              );
            });
        } else if (section.matter != null) {
          this.$store.dispatch(names.FETCH_MATTER, section.matter);
        }
      });
  },
};
</script>

<style scoped>
</style>

