<template>
  <div>
    <b-table-simple bordered no-border-collapse small responsive>
      <b-tr>
        <b-th class="primary-header" style="width: 20%">
          {{
            $getVisibleNames("teaching.section", false, "Sección").toUpperCase()
          }}
        </b-th>
        <b-th class="primary-header"> DOCENTE(S) </b-th>
      </b-tr>
      <template v-for="section in sectionList">
        <b-tr :key="section.id">
          <b-td class="text-left"> {{ section.name }} </b-td>
          <b-td class="text-left">
            {{
              getProfessorsForSection(section)
                .map((x) => `${x.first_name} ${x.last_name} (${x.email})`)
                .join(", ")
            }}
          </b-td>
        </b-tr>
      </template>
    </b-table-simple>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "TeacherInfoTable",
  components: {},
  props: {
    sections_ids: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      users: names.USERS,
      sections: names.SECTIONS,
    }),
    sectionList() {
      return this.sections.filter((x) => this.sections_ids.includes(x.id));
    },
  },
  methods: {
    getProfessorIds() {
      const professorIds = this.sectionList.flatMap(
        (section) => section.professors
      );
      return [...new Set(professorIds)];
    },
    getProfessorsForSection(section) {
      return this.users.filter((user) => section.professors.includes(user.id));
    },
  },
  created() {},
};
</script>

<style scoped>
.primary-header {
  background-color: var(--kl-menu-color) !important;
  color: white;
}
</style>

