<template>
  <div :class="['selectors-container', `selectors-${visibleSelectors}`]">
    <div class="selectors" v-if="show_campuses_">
      <TagSelector
        v-model="selected_campuses"
        :title="$getVisibleNames('mesh.campus', true, 'Sede')"
        :options="campuses"
        :max="max_campuses"
        label_field="name"
        :disabled="disabled"
        :required="required_selectors.includes('campuses')"
        :clear_filters="clear_filters"
      ></TagSelector>
    </div>
    <div class="selectors" v-if="show_careers_">
      <TagSelector
        v-model="selected_careers"
        :title="`${$getVisibleNames('mesh.career', true, 'Programa')}`"
        :options="filteredCareers"
        :max="max_careers"
        label_field="name"
        cite_field="faculty_name"
        :disabled="disabled"
        :required="required_selectors.includes('careers')"
        :clear_filters="clear_filters"
      ></TagSelector>
    </div>
    <div class="selectors" v-if="show_egress_profiles_">
      <TagSelector
        v-model="selected_egress_profiles"
        :title="
          $getVisibleNames('mesh.egressprofile', false, 'Perfil De Egreso')
        "
        :options="filteredEgressProfiles"
        :max="max_egress_profiles"
        label_field="ep_custom_label"
        cite_field="career_name"
        :disabled="disabled"
        :required="required_selectors.includes('egress_profiles')"
        :clear_filters="clear_filters"
      ></TagSelector>
    </div>
    <div class="selectors" v-if="show_levels_">
      <TagSelector
        v-model="selected_levels"
        title="Nivel"
        :options="filteredLevels"
        :max="max_levels"
        label_field="name"
        :disabled="disabled"
        :required="required_selectors.includes('levels')"
        :clear_filters="clear_filters"
      ></TagSelector>
    </div>
    <div class="selectors" v-if="show_modalities_">
      <TagSelector
        v-model="selected_modalities"
        title="Modalidad"
        :options="filteredModalities"
        :max="max_modalities"
        label_field="name"
        :disabled="disabled"
        :required="required_selectors.includes('modalities')"
        :clear_filters="clear_filters"
      ></TagSelector>
    </div>
    <div class="selectors" v-if="show_matters_">
      <TagSelector
        v-model="selected_matters"
        :title="
          $getVisibleNames('mesh.egressprofilematter', false, 'Asignatura')
        "
        :options="filteredMatters"
        :max="max_matters"
        label_field="matter_name"
        :disabled="disabled"
        :required="required_selectors.includes('matters')"
        :clear_filters="clear_filters"
      ></TagSelector>
    </div>
    <div class="selectors" v-if="show_periods_">
      <TagSelector
        v-model="selected_periods"
        :title="$getVisibleNames('mesh.period', false, 'Período')"
        :options="filteredPeriods"
        :max="max_periods"
        label_field="name"
        :disabled="disabled"
        :required="required_selectors.includes('periods')"
        :clear_filters="clear_filters"
      ></TagSelector>
    </div>
    <div class="selectors" v-if="show_sections_">
      <TagSelector
        v-model="selected_sections"
        :title="$getVisibleNames('teaching.section', false, 'Sección')"
        :options="filteredSections"
        :max="max_sections"
        label_field="name"
        :disabled="disabled"
        :required="required_selectors.includes('sections')"
        :clear_filters="clear_filters"
      ></TagSelector>
    </div>
    <div class="selectors" v-if="show_study_units_">
      <TagSelector
        v-model="selected_study_units"
        :title="$getVisibleNames('teaching.ramicro', false, 'RA Micro')"
        :options="filteredStudyUnits"
        :max="max_study_units"
        label_field="name"
        :disabled="disabled"
        :required="required_selectors.includes('study_units')"
        :clear_filters="clear_filters"
      ></TagSelector>
    </div>
    <div class="selectors" v-if="show_cohorts_">
      <TagSelector
        v-model="selected_cohorts"
        title="Año de Ingreso"
        :options="fetched_cohorts"
        :max="max_cohorts"
        label_field="cohort"
        :disabled="disabled"
        :required="required_selectors.includes('cohorts')"
        :clear_filters="clear_filters"
      ></TagSelector>
    </div>
    <div class="selectors" v-if="show_cycles_">
      <TagSelector
        v-model="selected_cycles"
        :title="$getVisibleNames('mesh.cycle', false, 'Ciclo Formativo')"
        :options="cycles"
        :max="max_cycles"
        label_field="name"
        :disabled="disabled"
        :required="required_selectors.includes('cycles')"
        :clear_filters="clear_filters"
      ></TagSelector>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  components: {
    TagSelector: () => import("./TagSelector"),
  },
  props: {
    /**
     * Define los selectores que se mostraran.
     * La opcion por defecto "all" mostrará todos los selectores disponibles.
     * @values campuses careers egress_profiles levels periods matters sections
     */
    show_selectors: {
      type: String,
      default: "campuses",
    },
    /**
     * Mostrará la opcion --TODOS-- en los selectores indicados.
     * @values campuses careers egress_profiles levels periods matters sections
     */
    show_all: {
      type: String,
      default: "campuses sections",
    },
    /**
     * Define los selectores requeridos por el usuario.
     * @values campuses careers egress_profiles levels periods matters sections
     */
    required_selectors: {
      type: String,
      default: "campuses sections",
    },
    /**
     * Limpia los filtros actuales seleccionados por el usuario.
     */
    clear_filters: {
      type: Boolean,
    },

    /**
     * @param {(Number|string)} ['all'] Define la cantidad máxima permitida para seleccionar.
     */
    max_campuses: { default: "all" },
    max_careers: { default: "all" },
    max_egress_profiles: { default: "all" },
    max_sections: { default: "all" },
    max_matters: { default: "all" },
    max_modalities: { default: "all" },
    max_periods: { default: "all" },
    max_levels: { default: "all" },
    max_study_units: { default: "all" },
    max_cohorts: { default: "all" },
    max_cycles: { default: "all" },
    load_cohorts: { default: false },
    /**
     * Desabilita el componente.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected_campuses: [],
      selected_careers: [],
      selected_egress_profiles: [],
      selected_modalities: [],
      selected_levels: [],
      selected_periods: [],
      selected_matters: [],
      selected_sections: [],
      selected_study_units: [],
      selected_cohorts: [],
      selected_cycles: [],
      show_campuses_: false,
      show_careers_: false,
      show_egress_profiles_: false,
      show_modalities_: false,
      show_levels_: false,
      show_periods_: false,
      show_matters_: false,
      show_sections_: false,
      show_study_units_: false,
      show_cohorts_: false,
      show_cycles_: false,
      cohorts: [
        { value: 2021, name: "2021" },
        { value: 2022, name: "2022" },
      ],
      /**
       * Temporal para guardar los ids de asignaturas cuyas secciones y unidades de estudio han sido descargadas.
       * Para evitar muchas peticiones repetidas.
       */
      fetched_matters_sections: [],
      fetched_matters_study_unit: [],
      fetched_cohorts: [],
    };
  },
  computed: {
    ...mapGetters({
      campuses: names.CAMPUSES,
      careers: names.CAREERS,
      egressProfiles: names.EGRESS_PROFILES,
      regimes: names.REGIMES,
      formationAreas: names.FORMATION_AREAS,
      matters: names.MATTERS,
      sections: names.SECTIONS,
      modalities: names.MODALITIES,
      studyUnits: names.STUDY_UNITS,
      egressProfilesMatters: names.EGRESS_PROFILES_MATTERS,
      periods: names.PERIODS,
      faculties: names.FACULTIES,
      cycles: names.CYCLES,
    }),
    filteredCampuses() {
      return this.campuses;
    },
    visibleSelectors() {
      return [
        this.show_campuses_,
        this.show_careers_,
        this.show_egress_profiles_,
        this.show_levels_,
        this.show_modalities_,
        this.show_matters_,
        this.show_periods_,
        this.show_sections_,
        this.show_study_units_,
        this.show_cohorts_,
        this.show_cycles_,
      ].filter(Boolean).length; // Filtra los selectores visibles
    },
    /**
     * Retorna todas las carreras con el atributo adicional 'faculty_name'.
     */
    filteredCareers() {
      let list = [];
      this.careers.forEach((career) => {
        let faculty = this.faculties.find((x) => x.id == career.faculty);
        if (faculty) {
          career["faculty_name"] = faculty.name;
        }
        list.push(career);
      });
      return list;
    },
    /**
     * Retorna los perfiles de egreso filtrados por carreras y sedes.
     */
    filteredEgressProfiles() {
      let list = [];
      const selected_campus_id = this.selected_campuses.map(
        (campus) => campus.id
      );
      this.careers.forEach((career) => {
        career.egress_profiles.forEach((egress_profile_id) => {
          let egress_profile = null;
          if (this.selected_campuses && this.selected_campuses.length > 0) {
            const campus_filtered_eps = this.egressProfiles.filter(
              (x) =>
                x.campuses.filter((element) =>
                  selected_campus_id.includes(element)
                ).length > 0
            );
            egress_profile = campus_filtered_eps.find(
              (x) => x.id == egress_profile_id
            );
          }
          let ep_career = null;
          if (egress_profile) {
            ep_career = this.careers.find((x) => x.id == egress_profile.career);
          }
          if (ep_career != null && egress_profile != null) {
            egress_profile["career_name"] = ep_career.name;
            if (egress_profile.code && egress_profile.init_date) {
              if (this.max_egress_profiles == "all") {
                egress_profile["ep_custom_label"] =
                  egress_profile.professional_title;
              }
              if (this.max_egress_profiles != "all") {
                egress_profile["ep_custom_label"] =
                  egress_profile.code +
                  " - " +
                  this.$moment(egress_profile.init_date).format("YYYY") +
                  " - " +
                  egress_profile.professional_title;
              }
            } else {
              egress_profile["ep_custom_label"] =
                egress_profile.professional_title;
            }
            list.push(egress_profile);
          }
        });
      });
      return list;
    },
    /**
     * Retorna los niveles de acuerdo a los perfiles seleccionados.
     * Por cada nivel crea un objeto con 'id' y 'name' como atributos.
     */
    filteredLevels() {
      let max_level = 0;
      this.selected_egress_profiles.forEach((ep) => {
        if (ep.semester_amount > max_level) {
          max_level = ep.semester_amount;
        }
      });
      return [...Array(max_level).keys()].map(function (i) {
        return { id: i + 1, name: i + 1 };
      });
    },
    filteredModalities() {
      return this.modalities;
    },
    filteredPeriods() {
      let period_list = this.periods;
      return period_list.sort((a, b) => (a.start_date < b.start_date ? 1 : -1));
    },
    filteredMatters() {
      let formatted_matters = [];
      let ep_matter_list = this.egressProfilesMatters.filter((ep_matter) =>
        this.selected_egress_profiles
          .map((ep) => ep.id)
          .includes(Number(ep_matter.egress_profile))
      );
      if (this.show_levels_) {
        ep_matter_list = ep_matter_list.filter((matter) =>
          this.selected_levels
            .map((level) => level.id)
            .some((level_id) => matter.plan_level == level_id)
        );
      }
      if (this.show_modalities_) {
        ep_matter_list = ep_matter_list.filter((matter) =>
          this.selected_modalities
            .map((modality) => modality.id)
            .some(
              (modality_id) =>
                !matter.modality || matter.modality == modality_id
            )
        );
      }
      ep_matter_list.forEach((ep_matter_) => {
        let new_matter = this.matters.find(
          (matter_) => ep_matter_.matter == matter_.id
        );
        if (new_matter) {
          if (this.max_matters == "all") {
            ep_matter_.matter_name = new_matter.name;
          }
          if (this.max_matters != "all") {
            ep_matter_.matter_name = ep_matter_.code + " - " + new_matter.name;
          }
          formatted_matters.push(ep_matter_);
        }
      });
      return formatted_matters;
    },
    filteredSections() {
      let list = [];
      this.selected_matters.forEach((ep_matter) => {
        ep_matter.sections.forEach((section_id) => {
          let section = this.sections.find((x) => x.id == section_id);
          if (this.selected_periods.length || this.selected_matters.length) {
            const selected_matter_id = this.selected_matters.map(
              (matter) => matter.id
            );
            const selected_period_id = this.selected_periods.map(
              (period) => period.id
            );
            list = this.sections.filter(
              (section) =>
                selected_period_id.includes(section.period) &&
                selected_matter_id.includes(section.egress_profile_matter)
            );
          } else {
            if (section) {
              list.push(section);
            }
          }
        });
      });
      return list;
    },
    filteredStudyUnits() {
      let list = [];
      this.filteredMatters.forEach((ep_matter) => {
        let study_units = this.studyUnits.filter(
          (x) => x.matter == ep_matter.matter
        );
        list.push(...study_units);
      });
      return list;
    },
  },
  methods: {},
  watch: {
    /**
     * Modifica la visivilidad de los selectores en variables internas.
     * Funciona como optimización.
     */
    show_selectors() {
      this.show_campuses_ = false;
      this.show_careers_ = false;
      this.show_egress_profiles_ = false;
      this.show_modalities_ = false;
      this.show_levels_ = false;
      this.show_periods_ = false;
      this.show_matters_ = false; // Asignaturas de Perfil de Egreso
      this.show_sections_ = false;
      this.show_study_units_ = false;
      this.show_cohorts_ = false;
      this.show_cycles_ = false;
      if (this.show_selectors.includes("campuses")) this.show_campuses_ = true;
      if (this.show_selectors.includes("careers")) this.show_careers_ = true;
      if (this.show_selectors.includes("egress_profiles"))
        this.show_egress_profiles_ = true;
      if (this.show_selectors.includes("modalities"))
        this.show_modalities_ = true;
      if (this.show_selectors.includes("levels")) this.show_levels_ = true;
      if (this.show_selectors.includes("periods")) this.show_periods_ = true;
      if (this.show_selectors.includes("matters")) this.show_matters_ = true;
      if (this.show_selectors.includes("sections")) this.show_sections_ = true;
      if (this.show_selectors.includes("study_units"))
        this.show_study_units_ = true;
      if (this.show_selectors.includes("cohorts")) this.show_cohorts_ = true;
      if (this.show_selectors.includes("cycles")) this.show_cycles_ = true;
    },
    /**
     * Observa cuando cambian las asignaturas selleccionadas.
     * Pide las secciones y unidades de las asignaturas seleccionadas si no han sido cargadas con anteriorida.
     */
    selected_matters() {
      this.$emit("matters", this.selected_matters);
      this.selected_matters.forEach((ep_matter) => {
        if (
          this.show_sections_ &&
          !this.fetched_matters_sections.find((x) => x == ep_matter.id)
        ) {
          this.$store.dispatch(names.FETCH_SECTIONS, {
            egress_profile_matter_id: ep_matter.id,
          });
          this.fetched_matters_sections.push(ep_matter.id);
        }
        if (
          this.show_study_units_ &&
          !this.fetched_matters_study_unit.find((x) => x == ep_matter.id)
        ) {
          this.$store.dispatch(names.FETCH_STUDY_UNITS, ep_matter.matter);
          this.fetched_matters_study_unit.push(ep_matter.id);
        }
      });
    },
    selected_campuses() {
      this.$emit("campuses", this.selected_campuses);
    },
    selected_careers() {
      this.$emit("careers", this.selected_careers);
    },
    selected_egress_profiles() {
      this.$emit("egress_profiles", this.selected_egress_profiles);
      if (this.show_cohorts_ && this.selected_egress_profiles.length) {
        this.$restful
          .Get(
            `/teaching/get_egress_profile_cohorts/?egress_profile=${this.selected_egress_profiles[0].id}`
          )
          .then((response) => {
            this.fetched_cohorts = response.filter(
              (item, index, self) =>
                index === self.findIndex((t) => t.cohort === item.cohort)
            );
          });
      }
    },
    selected_modalities() {
      this.$emit("modalities", this.selected_modalities);
    },
    selected_levels() {
      this.$emit("levels", this.selected_levels);
      this.selected_egress_profiles.forEach((ep) => {
        if (this.show_matters_) {
          this.$store.dispatch(names.FETCH_MATTERS, ep.id);
        }
      });
    },
    selected_periods() {
      this.$emit("periods", this.selected_periods);
    },
    selected_sections() {
      this.$emit("sections", this.selected_sections);
    },
    selected_study_units() {
      this.$emit("study_units", this.selected_study_units);
    },
    selected_cohorts() {
      this.$emit("cohorts", this.selected_cohorts);
    },
    selected_cycles() {
      this.$emit("cycles", this.selected_cycles);
    },
    clear_filters() {
      this.selected_campuses = [];
      this.selected_egress_profiles = [];
      this.selected_levels = [];
      this.selected_periods = [];
      this.selected_matters = [];
      this.selected_sections = [];
      this.selected_study_units = [];
      this.selected_cohorts = [];
      this.selected_cycles = [];
    },
    load_cohorts() {
      if (this.load_cohorts && this.selected_egress_profiles.length) {
        this.$restful
          .Get(
            `/teaching/get_egress_profile_cohorts/?egress_profile=${this.selected_egress_profiles[0].id}`
          )
          .then((response) => {
            this.fetched_cohorts = response.filter(
              (item, index, self) =>
                index === self.findIndex((t) => t.cohort === item.cohort)
            );
          });
      }
    },
  },
};
</script>

<style scoped>
.selectors-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.selectors {
  box-sizing: border-box;
}

/* Distribución automática basada en el número de selectores visibles */
/* 2 por fila (4 selectores) */
.selectors-container.selectors-4 .selectors {
  flex: 1 1 calc(50% - 20px);
  max-width: calc(50% - 20px);
}

/* 3 por fila (6 selectores) */
.selectors-container.selectors-6 .selectors {
  flex: 1 1 calc(33.33% - 20px);
  max-width: calc(33.33% - 20px);
}

/* 4 por fila (8 selectores) */
.selectors-container.selectors-8 .selectors {
  flex: 1 1 calc(25% - 20px);
  max-width: calc(25% - 20px);
}

/* Caso genérico: 2 por fila si no hay clase específica */
.selectors-container .selectors {
  flex: 1 1 calc(50% - 20px);
  max-width: calc(50% - 20px);
}
@media (max-width: 768px) {
  .selectors {
    flex: 1 1 calc(100%) !important;
    max-width: calc(100%) !important;
  }
}
</style>