var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.studyUnitsFilter.length > 0)?_c('b-table-simple',{attrs:{"small":"","responsive":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"text-align-center evaluation-header"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.criterio_de_evaluacion", true, "Criterios de Evaluación" ).toUpperCase())+" ")]),_c('b-th',{staticClass:"text-align-center evaluation-header"},[_vm._v(" INSTRUMENTOS ESCRITOS ")]),_c('b-th',{staticClass:"text-align-center evaluation-header"},[_vm._v(" INSTRUMENTOS DE OBESERVACIÓN ")]),_c('b-th',{staticClass:"text-align-center evaluation-header"},[_vm._v(" MENCIONES ")])],1)],1),_c('b-tbody',[_vm._l((_vm.studyUnitsFilter),function(study_unit){return _vm._l((_vm.ECMicroByStudyUnits(study_unit.id)),function(e_c_micro){return _c('b-tr',{key:`matter-${_vm.matter_id}-study_unit-${study_unit.id}-e_c_micro-${e_c_micro.id}`},[_c('b-td',{class:{
              'text-danger':
                _vm.getQuestionIndicators(e_c_micro.id).length +
                  _vm.getRubricCriteriaIndicators(e_c_micro.id).length ==
                0,
            }},[_c('div',{staticClass:"ml-1 mt-1"},[_c('SentenceContainer',{attrs:{"unique_id":`ecm-matter-${_vm.matter_id}-study_unit-${study_unit.id}-e_c_micro-${e_c_micro.id}`,"showColorCognitiveLevel":_vm.show_taxonomy_color,"Sentence":e_c_micro,"boundedTextContainer":_vm.short_view,"single_line":_vm.short_view},scopedSlots:_vm._u([{key:"order",fn:function(){return [_c('span',[_c('span',[(study_unit.competence_unit_order != 999)?_c('span',[_vm._v(" "+_vm._s(study_unit.competence_unit_order)+".")]):_vm._e(),_vm._v(_vm._s(study_unit.order)+"."+_vm._s(e_c_micro.order)+" ")])])]},proxy:true}],null,true)})],1)]),_c('b-td',{staticClass:"text-center align-center"},[_vm._v(" "+_vm._s(_vm.getQuestionIndicators(e_c_micro.id).length)+" ")]),_c('b-td',{staticClass:"text-center align-center"},[_vm._v(_vm._s(_vm.getRubricCriteriaIndicators(e_c_micro.id).length)+" ")]),_c('b-td',{staticClass:"text-center align-center"},[_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-danger.top.noninteractive.hover",value:(
                _vm.getQuestionIndicators(e_c_micro.id).length +
                  _vm.getRubricCriteriaIndicators(e_c_micro.id).length ==
                0
                  ? 'No está siendo mencionado en ninguna Evaluación'
                  : ''
              ),expression:"\n                getQuestionIndicators(e_c_micro.id).length +\n                  getRubricCriteriaIndicators(e_c_micro.id).length ==\n                0\n                  ? 'No está siendo mencionado en ninguna Evaluación'\n                  : ''\n              ",modifiers:{"v-danger":true,"top":true,"noninteractive":true,"hover":true}}],attrs:{"id":`e_c_micro-popover-${e_c_micro.id}`,"variant":_vm.getQuestionIndicators(e_c_micro.id).length +
                  _vm.getRubricCriteriaIndicators(e_c_micro.id).length ==
                0
                  ? 'danger'
                  : _vm.filterMentionsTest(e_c_micro.id).length > 0
                  ? 'success'
                  : _vm.filterMentionsTest(e_c_micro.id).length > 0
                  ? 'warning'
                  : 'secondary'}},[_vm._v(" "+_vm._s(_vm.getQuestionIndicators(e_c_micro.id).length + _vm.getRubricCriteriaIndicators(e_c_micro.id).length)+" ")]),(_vm.filterMentionsTest(e_c_micro.id).length > 0)?_c('b-popover',{attrs:{"target":`e_c_micro-popover-${e_c_micro.id}`,"placement":"left","triggers":"hover click","variant":"dark"}},[_c('div',[(_vm.filterMentionsTest(e_c_micro.id).length > 0)?_c('div',[(_vm.filterMentionsTest(e_c_micro.id).length > 1)?_c('strong',[_vm._v(_vm._s(_vm.$getVisibleNames( "evaluations2.test", true, "Instrumentos" ))+":")]):_vm._e(),(_vm.filterMentionsTest(e_c_micro.id).length == 1)?_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "evaluations2.test", false, "Instrumento" ))+": ")]):_vm._e()]):_vm._e(),_vm._l((_vm.filterMentionsTest(
                    e_c_micro.id
                  )),function(filter_evaluations,index){return _c('li',{key:index + 'filter_mentions_evaluations'},[_vm._v(" "+_vm._s(filter_evaluations)+" ")])})],2)]):_vm._e()],1)],1)})})],2)],1):_vm._e(),(_vm.studyUnitsFilter.length > 0)?_c('b-table-simple',[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"text-align-center evaluation-header py-2"},[_vm._v(" Total de Preguntas ")]),_c('b-th',{staticClass:"text-align-center evaluation-header py-2"},[_vm._v(" Total de Preguntas no asociadas a "+_vm._s(_vm.$getVisibleNames( "teaching.evaluationcriteriamicro", true, "Criterio de Evaluación Micro" ))+" ")]),_c('b-th',{staticClass:"text-align-center evaluation-header py-2"},[_vm._v(" % ")]),_c('b-th',{staticClass:"text-align-center evaluation-header py-2"},[_vm._v(" Total de "+_vm._s(_vm.$getVisibleNames("manual.dimension", true, "Dimensiones"))+" en instrumentos de observación ")]),_c('b-th',{staticClass:"text-align-center evaluation-header py-2"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("manual.dimension", true, "Dimensiones"))+" no asociadas a "+_vm._s(_vm.$getVisibleNames( "teaching.evaluationcriteriamicro", true, "Criterio de Evaluación Micro" ))+" ")]),_c('b-th',{staticClass:"text-align-center evaluation-header py-2"},[_vm._v(" % ")])],1)],1),_c('b-tbody',[_c('b-tr',[_c('b-td',{staticClass:"text-center p-2"},[_vm._v(_vm._s(_vm.questionList.length))]),_c('b-td',{staticClass:"text-center p-2"},[(_vm.getQuestionIndicators(null).length > 0)?_c('span',{staticClass:"helper",attrs:{"id":`questions-null-popover`}},[_c('b-badge',{staticClass:"border",attrs:{"variant":"light"}},[_vm._v(" "+_vm._s(_vm.getQuestionIndicators(null).length)+" ")])],1):_c('span',[_vm._v(" 0 ")]),(_vm.getQuestionIndicators(null).length > 0)?_c('b-popover',{attrs:{"target":`questions-null-popover`,"placement":"left","triggers":"hover","variant":"dark"}},[_c('div',[(_vm.getQuestionIndicators(null).length > 0)?_c('div',[(
                    _vm.getInstrumentsByQuestions(_vm.getQuestionIndicators(null))
                      .length > 1
                  )?_c('strong',[_vm._v(_vm._s(_vm.$getVisibleNames( "evaluations2.test", true, "Instrumentos" ))+":")]):_vm._e(),(
                    _vm.getInstrumentsByQuestions(_vm.getQuestionIndicators(null))
                      .length == 1
                  )?_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "evaluations2.test", false, "Instrumento" ))+": ")]):_vm._e()]):_vm._e(),_vm._l((_vm.getInstrumentsByQuestions(
                  _vm.getQuestionIndicators(null)
                )),function(filter_test,index){return _c('div',{key:index + 'filter_test'},[_vm._v(" "+_vm._s(filter_test.title)+": "),_c('li',[_c('strong',[_vm._v(" "+_vm._s(_vm.getQuestionIndicators(null) .filter((x) => x.test == filter_test.id) .map((x) => x.segment_order ? x.segment_order + "." + x.order : x.order ) .join(", "))+" ")])])])})],2)]):_vm._e()],1),_c('b-td',{staticClass:"text-center p-2"},[(_vm.questionList.length > 0)?[_vm._v(" "+_vm._s(_vm._f("Round")(((_vm.getQuestionIndicators(null).length / _vm.questionList.length) * 100)))+"% ")]:[_vm._v("0")]],2),_c('b-td',{staticClass:"text-center p-2"},[_vm._v(_vm._s(_vm.rubricCriteriaList.length))]),_c('b-td',{staticClass:"text-center p-2"},[(_vm.getRubricCriteriaIndicators(null).length > 0)?_c('span',{staticClass:"helper",attrs:{"id":`rubric-null-popover`}},[_c('b-badge',{staticClass:"border",attrs:{"variant":"light"}},[_vm._v(" "+_vm._s(_vm.getRubricCriteriaIndicators(null).length)+" ")])],1):_c('span',[_vm._v("0")]),(_vm.getRubricCriteriaIndicators(null).length > 0)?_c('b-popover',{attrs:{"target":`rubric-null-popover`,"placement":"left","triggers":"hover","variant":"dark"}},[_c('div',[(_vm.getRubricCriteriaIndicators(null).length > 0)?_c('div',[(
                    _vm.getInstrumentsByRubricCriteria(
                      _vm.getRubricCriteriaIndicators(null)
                    ).length > 1
                  )?_c('strong',[_vm._v(_vm._s(_vm.$getVisibleNames( "evaluations2.test", true, "Instrumentos" ))+":")]):_vm._e(),(
                    _vm.getInstrumentsByRubricCriteria(
                      _vm.getRubricCriteriaIndicators(null)
                    ).length == 1
                  )?_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "evaluations2.test", false, "Instrumento" ))+": ")]):_vm._e()]):_vm._e(),_vm._l((_vm.getInstrumentsByRubricCriteria(
                  _vm.getRubricCriteriaIndicators(null)
                )),function(filter_test,index){return _c('div',{key:index + 'filter_test-rubric'},[_vm._v(" "+_vm._s(filter_test.title)+": "),_c('li',[_c('strong',[_vm._v(" "+_vm._s(_vm.getRubricCriteriaIndicators(null) .filter((x) => _vm.rubricList.some( (r) => r.id == x.rubric && r.test == filter_test.id ) ) .map((x) => x.order) .join(", "))+" ")])])])})],2)]):_vm._e()],1),_c('b-td',{staticClass:"text-center p-2"},[(_vm.rubricCriteriaList.length > 0)?[_vm._v(" "+_vm._s(_vm._f("Round")(((_vm.getRubricCriteriaIndicators(null).length / _vm.rubricCriteriaList.length) * 100)))+"% ")]:[_vm._v(" 0 ")]],2)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }