<template>
  <b-button
    size="sm"
    variant="primary"
    :title="text_button"
    v-b-tooltip.top.v-secondary.noninteractive="
      tooltip_text ? tooltip_text : ''
    "
    @click="handleClick"
    class="noprint"
    :class="custom_class"
    :disabled="disabled_button"
  >
    <b-icon-download v-if="show_icon"></b-icon-download>
    <span v-if="text_button" class="ml-1">
      {{ text_button }}
    </span>
  </b-button>
</template>

<script>
export default {
  name: "ButtonDownload",
  props: {
    text_button: {
      type: String,
      default: "",
    },
    tooltip_text: {
      type: String,
      required: true,
    },
    click_button: {
      type: Function,
      required: false,
    },
    size: {
      type: String,
      default: "sm",
    },
    // variant: {
    //   type: String,
    //   default: "secondary",
    // },
    show_icon: {
      type: Boolean,
      default: true,
    },
    custom_class: {
      type: String,
      default: "",
    },
    disabled_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    handleClick() {
      // Si se proporciona una función de click, se ejecuta
      if (this.click_button) {
        this.click_button();
      } else {
        this.$emit("click");
      }
    },
  },
};
</script>

<style scoped>
</style>