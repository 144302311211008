<template>
  <div>
    <div
      class="d-flex"
      v-if="institution && institution.internal_use_id == 'duoc_uc'"
    >
      <div class="w-100" v-if="work_types.length > 0">
        <b-table-simple bordered>
          <b-thead>
            <b-tr>
              <b-th
                class="work-type-th text-center"
                :colspan="work_types.length"
                >TIPOS DE TRABAJO DE
                {{
                  $getVisibleNames(
                    "evaluations2.evaluation",
                    true,
                    "Evaluaciones"
                  ).toUpperCase()
                }}
                SUMATIVAS
              </b-th>
            </b-tr>
            <b-tr>
              <b-th
                v-for="work_type in work_types"
                :key="'work_type_value:' + work_type.id"
                class="work-type-th"
                >{{ work_type.value }}
              </b-th>
            </b-tr>
            <b-tr>
              <b-td
                v-for="work_type in work_types"
                :key="'work_type_id:' + work_type.id"
                class="work-type-td"
                >{{ totalWeighingByWorkType(work_type.id) }}%
              </b-td>
            </b-tr>
          </b-thead>
        </b-table-simple>
        <!-- <div>
          <div
            v-for="work_type in work_types"
            :key="'work_type:' + work_type.id"
          >
            <strong class="d-flex">
              <span class="mr-2"> {{ work_type.value }}: </span>
              <div class="ml-auto mr-0">
                {{ totalWeighingByWorkType(work_type.id) }}%
              </div>
            </strong>
          </div>
        </div> -->
      </div>
    </div>
    <b-table-simple small responsive>
      <b-thead>
        <b-tr>
          <b-th
            :colspan="allows_crud ? 10 : 9"
            style="text-align: center"
            class="pl-3 evaluation-header"
          >
            ACTIVIDADES Y EVALUACIONES
            <div class="evaluation-button-div">
              <b-button
                v-if="allows_crud"
                class="mx-1"
                size="sm"
                @click="
                  $bvModal.show(`add-matter-evaluation-modal-${matter_id}`)
                "
                >Crear Actividad o Evaluación</b-button
              >
            </div>
          </b-th>
        </b-tr>
        <b-tr>
          <b-td
            style="width: 3%"
            class="text-center"
            v-if="allows_crud && filterMatterEvaluations.length > 0"
          >
          </b-td>
          <b-td style="width: 12%" class="text-center">
            <strong> NOMBRE </strong>
          </b-td>
          <b-td
            v-if="
              institution &&
              institution.internal_use_id != 'duoc_uc' &&
              institution.kl_assessment
            "
            style="width: 10%"
            class="text-center"
          >
            <strong>
              {{
                $getVisibleNames(
                  "evaluations2.test",
                  true,
                  "Instrumentos"
                ).toUpperCase()
              }}
            </strong>
          </b-td>
          <b-td style="width: 15%" class="text-center">
            <strong> DESCRIPCIÓN </strong>
          </b-td>
          <b-td style="width: 5%" class="text-center">
            <strong> TIPO </strong>
          </b-td>
          <b-td style="width: 3%" class="text-center">
            <strong>PONDERACIÓN</strong>
          </b-td>
          <b-td style="width: 7%" class="text-center">
            <strong>HORAS DOCENCIA DIRECTA (HORAS PEDAGÓGICAS)</strong>
          </b-td>
          <b-td style="width: 7%" class="text-center">
            <strong>HORAS DE TRABAJO AUTÓNOMO (HORAS CRONOLÓGICAS)</strong>
          </b-td>
          <b-td
            colspan="1"
            style="width: 35%"
            class="text-center"
            v-if="institution && institution.show_evaluation_criteria_macro"
          >
            <strong>
              {{
                $getVisibleNames(
                  "teaching.evaluationcriteriamacro",
                  true,
                  "Criterios de Evaluación Macro"
                ).toUpperCase()
              }}</strong
            >
          </b-td>
          <b-td colspan="1" style="width: 15%" class="text-center">
            <strong>
              {{
                $getVisibleNames(
                  "teaching.evaluationcriteriamicro",
                  true,
                  "Criterios de Evaluación Micro"
                ).toUpperCase()
              }}</strong
            >
          </b-td>
          <b-td
            style="width: 2%"
            v-if="allows_crud && filterMatterEvaluations.length > 0"
          ></b-td>
        </b-tr>
      </b-thead>
      <template v-for="item in filterLearningExperience">
        <template
          v-if="
            filterMatterEvaluations.filter(
              (x) => x.learning_experience == item.id
            ).length > 0
          "
        >
          <b-thead :key="'learning-experiences-1' + item.id">
            <b-tr>
              <b-th
                style="text-align: left"
                class="pl-3 evaluation-header"
                :colspan="allows_crud ? 10 : 9"
                >{{ item.name }}
              </b-th>
            </b-tr>
          </b-thead>
          <MatterProgramEvaluationDraggable
            :key="
              key_matter_program_draggable + 'learning-experiences-2' + item.id
            "
            :allows_crud="allows_crud"
            :learning_experience="item.id"
            :matter_id="matter_id"
            :EvaluationSituations="evaluation_situations"
            :EvaluativeAgentTypes="evaluative_agent_types"
            :MatEvaStuEnvTimAll="mat_eva_stu_env_tim_all"
            @created_support_resource="createdSupportResource"
            @fetch_mat_eva_stu_env_tim_all="fetchMatEvaStuEnvTimAll"
          ></MatterProgramEvaluationDraggable>
          <!-- @delete_matter_evaluation="deleteMatterEvaluation" -->
          <!-- @update_matter_evaluations="
              () => $emit('update_matter_evaluations')
            " -->
        </template>
      </template>
      <template
        v-if="
          filterMatterEvaluations.filter(
            (x) => x.learning_experience == null && x.type != 5
          ).length > 0
        "
      >
        <b-thead
          :key="'learning-experiences-1.1'"
          v-if="filterLearningExperience.length > 0"
        >
          <b-tr>
            <b-th
              style="text-align: left"
              class="p-3 evaluation-header"
              :colspan="allows_crud ? 10 : 9"
            ></b-th>
          </b-tr>
        </b-thead>
        <MatterProgramEvaluationDraggable
          :key="key_matter_program_draggable + 'learning-experiences-2.1'"
          :allows_crud="allows_crud"
          :learning_experience="null"
          :include_transversal_evaluation="false"
          :matter_id="matter_id"
          :EvaluationSituations="evaluation_situations"
          :EvaluativeAgentTypes="evaluative_agent_types"
          :MatEvaStuEnvTimAll="mat_eva_stu_env_tim_all"
          @created_support_resource="createdSupportResource"
          @fetch_mat_eva_stu_env_tim_all="fetchMatEvaStuEnvTimAll"
        ></MatterProgramEvaluationDraggable>
        <!-- @delete_matter_evaluation="deleteMatterEvaluation" -->
        <!-- @update_matter_evaluations="() => $emit('update_matter_evaluations')" -->
      </template>
      <b-tr
        v-if="
          institution &&
          institution.internal_use_id == 'duoc_uc' &&
          filterMatterEvaluations.length > 0
        "
      >
        <b-td class="p-3 text-right" colspan="9">
          <strong>TOTAL 60% DE LA ASIGNATURA</strong>
        </b-td>
      </b-tr>
      <template
        v-if="
          filterMatterEvaluations.filter(
            (x) => x.learning_experience == null && x.type == 5
          ).length > 0
        "
      >
        <b-thead
          :key="'learning-experiences-1.2'"
          v-if="filterLearningExperience.length > 0"
        >
          <b-tr>
            <b-th
              style="text-align: left"
              class="p-3 evaluation-header"
              :colspan="allows_crud ? 10 : 9"
            ></b-th>
          </b-tr>
        </b-thead>
        <MatterProgramEvaluationDraggable
          :key="key_matter_program_draggable + 'learning-experiences-2.2'"
          :allows_crud="allows_crud"
          :learning_experience="null"
          :include_transversal_evaluation="true"
          :matter_id="matter_id"
          :EvaluationSituations="evaluation_situations"
          :EvaluativeAgentTypes="evaluative_agent_types"
          :MatEvaStuEnvTimAll="mat_eva_stu_env_tim_all"
          @created_support_resource="createdSupportResource"
          @fetch_mat_eva_stu_env_tim_all="fetchMatEvaStuEnvTimAll"
        ></MatterProgramEvaluationDraggable>
        <!-- @delete_matter_evaluation="deleteMatterEvaluation" -->
        <!-- @update_matter_evaluations="() => $emit('update_matter_evaluations')" -->
      </template>
      <b-tr
        v-if="
          institution &&
          institution.internal_use_id == 'duoc_uc' &&
          filterMatterEvaluations.find((x) => x.type == 5)
        "
      >
        <b-td class="p-3 text-right" colspan="9">
          <strong>TOTAL 40% DE LA ASIGNATURA</strong>
        </b-td>
      </b-tr>
      <b-tbody v-if="filterMatterEvaluations.length == 0">
        <b-tr>
          <b-td class="text-center" colspan="10"
            >No hay actividades o evaluaciones para mostrar.</b-td
          >
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <ILIndicatorsUsage
      v-if="
        institution &&
        institution.internal_use_id != 'duoc_uc' &&
        institution.kl_assessment
      "
      :key="evaluation_ids.join(',')"
      :matter_id="matter_id"
      :evaluations_ids="evaluation_ids"
      :fetch_evaluations="true"
      :fetch_evaluation_criteria_micro="false"
      :fetch_competence_unit="false"
      :show_taxonomy_color="true"
    ></ILIndicatorsUsage>
    <b-modal
      :id="`add-matter-evaluation-modal-${matter_id}`"
      title="Crear Actividad o Evaluación"
      size="xl"
      @hide="confirmClose"
      hide-footer
    >
      <MatterProgramEvaluationForm
        :key="key_matter_program_draggable"
        :matter_id="matter_id"
        :evaluation_list="filterMatterEvaluations"
        :EvaluationSituations="evaluation_situations"
        :EvaluativeAgentTypes="evaluative_agent_types"
        :MatEvaStuEnvTimAll="mat_eva_stu_env_tim_all"
        @created="createdEvaluation"
        @created_support_resource="createdSupportResource"
        @change_matter_evaluation_form="
          () => (change_matter_evaluation_form = true)
        "
      >
      </MatterProgramEvaluationForm>
    </b-modal>
  </div>
</template>

<script>
// import draggable from "vuedraggable";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "MatterProgramEvaluation",
  props: {
    matter_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    // draggable,
    MatterProgramEvaluationForm: () =>
      import("./MatterProgramEvaluationForm.vue"),
    MatterProgramEvaluationDraggable: () =>
      import("./MatterProgramEvaluationDraggable.vue"),
    // MEvaluationBibliographicResource: () =>
    //   import("./MEvaluationBibliographicResource.vue"),
    // SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    // ButtonWrapper: () => import("@/components/reusable/ButtonWrapper"),
    ILIndicatorsUsage: () =>
      import(
        "@/components/teaching/MatterProgram/Components/ILIndicatorsUsage"
      ),
  },
  data() {
    return {
      evaluation_situations: [],
      evaluative_agent_types: [],
      mat_eva_stu_env_tim_all: [],
      prevent_close_modal: false,
      change_matter_evaluation_form: false,
      key_matter_program_draggable: 0,
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      evaluationCriteriasMacros: names.EVALUATION_CRITERIAS_MACROS,
      evaluationCriteriasMicros: names.EVALUATION_CRITERIAS,
      studyUnits: names.STUDY_UNITS,
      learningExperiences: names.LEARNING_EXPERIENCES,
      matterEvaluations: names.MATTER_EVALUATIONS,
      evaluations: names.NEW_EVALUATIONS,
      tests: names.NEW_TESTS,
      work_types: names.TEST_WORK_TYPES,
    }),
    filterLearningExperience() {
      if (!this.matter_id) return [];
      return this.learningExperiences
        .filter((x) => x.matter == this.matter_id)
        .sort((a, b) => a.order - b.order);
    },
    filterMatterEvaluations() {
      if (!this.matter_id) return [];
      return this.matterEvaluations
        .filter((x) => x.matter == this.matter_id)
        .sort((a, b) => a.order - b.order);
    },
    filterEvaluations() {
      return this.evaluations.filter(
        (x) => x.scope == 3 && x.scope_id == this.matter_id
      );
    },
    evaluation_ids() {
      return this.filterEvaluations.map((x) => x.id);
    },
  },
  methods: {
    createdSupportResource(response) {
      this.$emit("created_support_resource", response);
    },
    confirmClose(bvModalEvt) {
      if (
        bvModalEvt.trigger === "headerclose" ||
        bvModalEvt.trigger === "event"
      ) {
        this.change_matter_evaluation_form = false;
        return;
      }
      if (!this.prevent_close_modal && this.change_matter_evaluation_form) {
        bvModalEvt.preventDefault();
        this.$bvModal
          .msgBoxConfirm(
            "¿Estás seguro de que quieres cerrar? Los datos no guardados se perderán.",
            {
              title: "Confirmación Necesaria",
              size: "md",
              buttonSize: "md",
              okVariant: "warning",
              okTitle: "Sí, cerrar",
              cancelTitle: "Cancelar",
              footerClass: "p-2",
              hideHeaderClose: true,
              centered: true,
              headerClass: "text-black",
              header: {
                title: "Confirmación Necesaria",
                class: "text-center",
              },
            }
          )
          .then((value) => {
            if (value) {
              this.prevent_close_modal = true;
              this.$bvModal.hide(bvModalEvt.componentId);
              this.$nextTick(() => {
                this.change_matter_evaluation_form = false;
                this.prevent_close_modal = false;
              });
            }
          });
      }
    },
    createdNewEvaluation(response, tests_list) {
      this.$store
        .dispatch(names.POST_NEW_EVALUATION, {
          title: response.name,
          description: response.description,
          weighing: response.weighing,
          visible_by_evaluatees: false,
          parent_evaluation: null,
          files: [],
        })
        .then((new_evaluation) => {
          this.$store.commit(
            "MUTATE_CHANGE_NEW_EVALUATION_BY_MATTER_EVALUATION",
            {
              new_evaluation_id: new_evaluation.id,
              matter_evaluation_id: response.id,
            }
          );
          this.$restful
            .Post("/evaluations2/evaluation-scope-default/", {
              scope: 3,
              scope_id: this.matter_id,
              evaluation: new_evaluation.id,
            })
            .then((evaluation_scope) => {
              this.$store.commit(
                "MUTATE_CHANGE_NEW_EVALUATION_SCOPE_BY_NEW_EVALUATION",
                evaluation_scope
              );
              response.evaluation = new_evaluation.id;
              this.$store
                .dispatch(names.PATCH_MATTER_EVALUATION, {
                  matter_evaluation_id: response.id,
                  item: { evaluation: new_evaluation.id },
                })
                .then(() => {
                  this.bulkCreateTest(tests_list, new_evaluation);
                });
              // evaluations2: sumativa 1, diagnóstica 2, formativa 3
              // evaluation_type:  diagnóstica 1, formativa 2, sumativa 3, actividad 4, transversal 5
              let evaluation_type = null;
              if (response.type == 1) evaluation_type = 2;
              else if ([2, 4].includes(response.type)) evaluation_type = 3;
              else if ([3, 5].includes(response.type)) evaluation_type = 1;
              else evaluation_type = 3;
              this.saveEvaluationTypeDefault(new_evaluation, {
                id: new_evaluation.evaluation_type_default,
                type: evaluation_type,
                evaluation: new_evaluation.id,
              });
              // this.$nextTick(() => {
              //   this.$bvModal.show(
              //     `m-bibliographic-resource-modal-${response.id}`
              //   );
              // });
            });
        });
    },
    createdEvaluation(evaluation, tmp_mat_eva_stu_env_tim_alls, tests_list) {
      evaluation.order =
        this.filterMatterEvaluations.filter(
          (x) => x.learning_experience == evaluation.learning_experience
        ).length + 1;
      this.$store
        .dispatch(names.POST_MATTER_EVALUATION, evaluation)
        .then((response) => {
          if (response.evaluation == null && response.type != 4) {
            this.createdNewEvaluation(response, tests_list);
          } else {
            if (response.evaluation != null && response.type != 4) {
              this.$store
                .dispatch(names.PATCH_NEW_EVALUATION, {
                  new_evaluation_id: response.evaluation,
                  item: {
                    title: response.name,
                    description: response.description,
                    weighing: response.weighing,
                  },
                })
                .then((new_evaluation) => {
                  this.$store.commit(
                    names.MUTATE_NEW_EVALUATION,
                    new_evaluation
                  );
                  this.bulkCreateTest(tests_list, new_evaluation);
                });
            }
            // this.$nextTick(() => {
            //   this.$bvModal.show(
            //     `m-bibliographic-resource-modal-${response.id}`
            //   );
            // });
          }
          this.createdMatStuEnvTimAlls(response, tmp_mat_eva_stu_env_tim_alls);
          this.$bvModal.hide(`add-matter-evaluation-modal-${this.matter_id}`);
        });
    },
    async createdMatStuEnvTimAlls(evaluation, tmp_mat_eva_stu_env_tim_alls) {
      for await (let tmp_map_eva of tmp_mat_eva_stu_env_tim_alls) {
        this.$restful
          .Post(`/mesh/mat-eva-stu-env-tim-all/`, {
            matter_evaluation: evaluation.id,
            study_environment: tmp_map_eva.study_environment,
            time_allocation: tmp_map_eva.time_allocation,
            hours: tmp_map_eva.hours,
          })
          .then((response) => {
            this.mat_eva_stu_env_tim_all.push(response);
          });
      }
    },
    async bulkCreateTest(test_list, new_evaluation) {
      for await (let test of test_list) {
        if (isNaN(test.id)) {
          test.evaluation = new_evaluation.id;
          test.title = new_evaluation.title;
          await this.$store
            .dispatch(names.POST_NEW_TEST, test)
            .then((new_test) => {
              this.saveTestType({
                ...new_test,
                instrument_type: test.instrument_type,
              });
            });
        } else if (!test.delete_test) {
          await this.$store
            .dispatch(names.PATCH_NEW_TEST, {
              new_test_id: test.id,
              item: {
                evaluation_situation: test.evaluation_situation,
                weighing: test.weighing,
                examiner: test.examiner,
                work_type: test.work_type,
              },
            })
            .then((new_test) => {
              this.saveTestType({
                ...new_test,
                instrument_type: test.instrument_type,
              });
            });
        } else if (test.delete_test) {
          await this.$store.dispatch(names.DELETE_NEW_TEST, test.id);
        }
      }
    },
    saveTestType(new_test) {
      if (new_test.test_type_default == null) {
        this.$restful
          .Post("/evaluations2/test-type-default/", {
            test: new_test.id,
            evaluative_agent: new_test.instrument_type.evaluative_agent,
          })
          .then((response) => {
            this.$store.commit(
              "MUTATE_CHANGE_NEW_TEST_TYPES_BY_NEW_TEST",
              response
            );
          });
      } else {
        this.$restful
          .Put(
            `/evaluations2/test-type-default/${new_test.test_type_default}/`,
            {
              test: new_test.id,
              evaluative_agent: new_test.instrument_type.evaluative_agent,
            }
          )
          .then((response) => {
            this.$store.commit(
              "MUTATE_CHANGE_NEW_TEST_TYPES_BY_NEW_TEST",
              response
            );
          });
      }
    },
    saveEvaluationTypeDefault(new_evaluation, evaluation_type) {
      if (evaluation_type) {
        if (new_evaluation.evaluation_type_default == null) {
          this.$restful
            .Post("/evaluations2/evaluation-type-default/", evaluation_type)
            .then((response) => {
              this.$store.commit(
                "MUTATE_CHANGE_NEW_EVALUATION_TYPE_BY_NEW_EVALUATION",
                response
              );
            });
        } else {
          this.$restful
            .Put(
              `/evaluations2/evaluation-type-default/${evaluation_type.id}/`,
              evaluation_type
            )
            .then((response) => {
              this.$store.commit(
                "MUTATE_CHANGE_NEW_EVALUATION_TYPE_BY_NEW_EVALUATION",
                response
              );
            });
        }
      }
    },
    updatedEvaluation(evaluation) {
      this.$store
        .dispatch(names.UPDATE_MATTER_EVALUATION, evaluation)
        .then((response) => {
          this.$bvModal.hide(`edit-matter-evaluation-modal-${response.id}`);
        });
    },
    fetchMatEvaStuEnvTimAll() {
      if (this.mat_eva_stu_env_tim_all.length == 0)
        this.$restful
          .Get(`/mesh/mat-eva-stu-env-tim-all/?matter=${this.matter_id}`)
          .then((response) => {
            this.mat_eva_stu_env_tim_all = response;
            this.key_matter_program_draggable++;
          });
      else {
        const time = setInterval(() => {
          this.$restful
            .Get(`/mesh/mat-eva-stu-env-tim-all/?matter=${this.matter_id}`)
            .then((response) => {
              this.mat_eva_stu_env_tim_all = response;
              this.key_matter_program_draggable++;
            });
          clearInterval(time);
        }, 500);
      }
    },
    fetchEvaluationSituations() {
      this.$restful.Get(`/teaching/evaluation-situation/`).then((response) => {
        this.evaluation_situations = response;
      });
    },
    fetchEvaluativeAgentTypes() {
      this.$restful.Get(`/teaching/evaluative-agent/`).then((response) => {
        this.evaluative_agent_types = response;
      });
    },
    totalWeighingByWorkType(work_type_id) {
      const evaluations = this.filterEvaluations.filter((x) => x.weighing > 0);
      const tests = this.tests.filter(
        (x) =>
          evaluations.map((e) => e.id).includes(x.evaluation) &&
          x.work_type == work_type_id
      );
      return tests.reduce((acc, obj) => {
        const evaluation = evaluations.find((x) => x.id == obj.evaluation);
        let evaluation_weighing = 0;
        if (evaluation) {
          evaluation_weighing = parseFloat(evaluation.weighing);
          if (evaluation.type == 1)
            evaluation_weighing = parseFloat(evaluation_weighing) * 0.6;
        }
        return (
          parseFloat(acc) +
          (parseFloat(obj.weighing) * parseFloat(evaluation_weighing)) / 100
        );
      }, 0);
    },
  },
  created() {
    this.$store.dispatch(names.FETCH_EPM_EVALUATION_TYPES);
    this.$store.dispatch(names.FETCH_AUTONOMOUS_TEACHING_ACTIVITIES);
    this.$store
      .dispatch(names.FETCH_MATTER_EVALUATIONS, this.matter_id)
      .then((matter_evaluations) => {
        const evaluations_ids = matter_evaluations
          .filter((x) => x.evaluation != null)
          .map((x) => x.evaluation);
        if (evaluations_ids.length > 0) {
          this.$store.dispatch(names.FETCH_NEW_EVALUATIONS, {
            evaluations_ids: evaluations_ids,
          });
          this.$store.dispatch(names.FETCH_NEW_TESTS, {
            evaluations_ids: evaluations_ids,
          });
        }
      });
    this.$store.dispatch(names.FETCH_NEW_TEST_TYPES);
    this.$store.dispatch(names.FETCH_TEST_WORK_TYPES);
    this.fetchMatEvaStuEnvTimAll();
    this.fetchEvaluativeAgentTypes();
    this.fetchEvaluationSituations();
  },
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
table th {
  vertical-align: middle;
  background-color: var(--primary-color);
  color: white;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.work-type-th {
  border: 1px solid black;
  color: black;
  padding: 3px 10px 3px 10px;
  text-align: center;
}
.work-type-td {
  text-align: center;
  padding: 3px 10px 3px 10px;
}
.evaluation-header {
  color: black !important;
  background: var(--primary-color) !important;
  border: 1px solid #000 !important;
}
.evaluation-button-div {
  float: right;
  /* padding-right: 1rem; */
}
table td {
  border: 1px solid black;
  text-align: left;
  vertical-align: middle;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
.grabbable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.ec-badge-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.ec-micro-badge {
  font-size: var(--thirth-font-size);
  text-align: center;
  width: fit-content !important;
  margin-right: 0.3em;
}
@media print {
  .ec-micro-badge {
    border: none;
  }
}
</style>