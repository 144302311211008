import { render, staticRenderFns } from "./CurrentSectionTeacher.vue?vue&type=template&id=c33ae438&scoped=true"
import script from "./CurrentSectionTeacher.vue?vue&type=script&lang=js"
export * from "./CurrentSectionTeacher.vue?vue&type=script&lang=js"
import style0 from "./CurrentSectionTeacher.vue?vue&type=style&index=0&id=c33ae438&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c33ae438",
  null
  
)

export default component.exports