<template>
  <div>
    <div
      class="d-flex mx-2 mb-2 container-select"
      v-if="show_assessment_range_list && achievement_ranges_groups.length > 1"
    >
      <div class="text-selector align-self-center mr-2">
        Seleccionar rango de logro:
      </div>
      <b-form-select
        v-model="selected_achievement_range_group"
        :options="achievementRangesGroups"
        value-field="id"
        text-field="label"
        size="sm"
      >
      </b-form-select>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
// import { mapGetters } from "vuex";
export default {
  name: "AchievementRangeGroupSelector",
  components: {},
  props: {
    egress_profile_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selected_achievement_range_group: null,
      show_assessment_range_list: true,
      achievement_ranges_groups: [],
      achievement_ranges: [],
    };
  },
  computed: {
    achievementRangesGroups() {
      return this.achievement_ranges_groups.map((x) => {
        const achievement_range = this.achievement_ranges
          .filter((g) => g.achievement_range_group == x.id)
          .map(
            (a) =>
              `${a.name} [${a.start_achievement} ${
                a.include_start_achievement ? "≤" : "<"
              } x ${a.include_finish_achievement ? "≤" : "<"} ${
                a.finish_achievement
              }]`
          )
          .join(", ");

        return {
          ...x,
          label: `${x.name}: ${achievement_range}`,
        };
      });
    },
  },
  methods: {
    getAchievementRange() {
      this.$restful
        .Get("/assessment2/achievement-range-group/?all_data=True")
        .then((response) => {
          this.achievement_ranges_groups = response.results;
        });
      this.$restful
        .Get("/assessment2/achievement-range/?all_data=True")
        .then((response) => {
          this.achievement_ranges = response.results;
        });
    },
  },
  watch: {
    selected_achievement_range_group(value) {
      if (value != null) this.$emit("selected_range_group", value);
    },
  },
  created() {
    this.getAchievementRange();
    this.$store
      .dispatch(names.FETCH_EGRESS_PROFILE, this.egress_profile_id)
      .then((egress_profile) => {
        this.$store
          .dispatch(names.FETCH_CAREER, egress_profile.career)
          .then((career) => {
            this.selected_achievement_range_group =
              career.chosen_achievement_range_group;
            if (career.chosen_achievement_range_group != null)
              this.show_assessment_range_list = false;
            // Necesario para que en AssessmentRangeSection se muestre la tabla.
            if (career.chosen_achievement_range_group == null)
              this.$emit("selected_range_group", null);
          });
      });
  },
};
</script>

<style scoped>
.container-select {
  background-color: var(--kl-menu-color) !important;
  border-radius: 3px;
  padding: 7px;
  color: white;
  align-items: center;
}
.text-selector {
  width: 18%;
}
</style>

