<template>
  <div>
    <!-- assessment_switches_type -->

    <div class="container-buttons-report mb-1">
      <div class="align-content-end"></div>
      <div>
        <div class="noprint">
          <div class="d-flex ml-auto">
            <div
              class="d-flex"
              v-if="
                assessment_range_report && assessment_range_report.assessments
              "
            >
              <div class="ml-auto mr-0 d-flex">
                <strong
                  class="mr-2 align-content-center"
                  style="font-size: 10pt"
                >
                  FORMATIVAS
                </strong>
                <b-form-checkbox
                  class="align-content-center"
                  v-model="assessment_switches_type"
                  switch
                >
                  <strong style="font-size: 10pt">SUMATIVAS</strong>
                </b-form-checkbox>
              </div>
            </div>
            <ButtonDownload
              tooltip_text="Reporte XLSX"
              :click_button="DownloadExcel"
              :class="`ml-1`"
            ></ButtonDownload>
          </div>
        </div>
      </div>
    </div>

    <!-- b-table -->
    <b-skeleton-table
      v-if="is_loading"
      :rows="9"
      :columns="10"
      :table-props="{ bordered: true, striped: true }"
    ></b-skeleton-table>
    <div class="table-wrapper">
      <b-table-simple
        bordered
        no-border-collapse
        small
        responsive
        v-if="
          !is_loading &&
          assessment_range_report &&
          assessment_range_report.assessments &&
          assessment_range_report.evaluatees
        "
        class="table-assesment"
      >
        <b-thead>
          <b-tr>
            <b-th
              class="text-center align-middle primary-header"
              :colspan="8 + assessment_range_report.assessments.length"
            >
              <div class="d-flex justify-content-center">
                <span class="align-content-center">
                  REPORTE DE ASSESSMENT POR ASIGNATURA DE PERFIL DE EGRESO
                </span>
                <VisibleColumns
                  v-if="displayList.length > 0"
                  :id_button="`col-display-1-${main_assessment}-${section_ids.join(
                    '-'
                  )}`"
                  tooltip_text="Visualizar columnas"
                  :class="`ml-1`"
                >
                  <template #popover-content>
                    <template v-if="assessment_range_report != null">
                      <template v-for="display in displayList">
                        <div :key="'index' + display.index + 'id' + display.id">
                          <div class="noprint">
                            <b-form-checkbox
                              :id="`column-check-${display.index}`"
                              name="checkbox-1"
                              v-model="display.visible"
                            >
                              {{ display.title }}
                            </b-form-checkbox>
                          </div>
                        </div>
                      </template>
                    </template>
                  </template>
                </VisibleColumns>
              </div>
            </b-th>
          </b-tr>
          <b-tr>
            <b-th
              class="text-center align-middle secondary-header"
              v-if="
                displayList.some((x) => $equals(x.title, 'N°') && x.visible)
              "
            >
              N°
            </b-th>
            <b-th
              class="text-center align-middle secondary-header"
              v-if="
                displayList.some(
                  (x) => $equals(x.title, 'APELLIDO') && x.visible
                )
              "
            >
              APELLIDO
            </b-th>
            <b-th
              class="text-center align-middle secondary-header"
              v-if="
                displayList.some((x) => $equals(x.title, 'NOMBRE') && x.visible)
              "
            >
              NOMBRE
            </b-th>
            <b-th
              class="text-center align-middle secondary-header"
              v-if="
                displayList.some(
                  (x) => $equals(x.title, 'IDENTIFICADOR') && x.visible
                )
              "
            >
              IDENTIFICADOR
            </b-th>
            <b-th
              class="text-center align-middle secondary-header"
              v-if="
                displayList.some(
                  (x) => $equals(x.title, 'AÑO DE INGRESO') && x.visible
                )
              "
            >
              AÑO DE INGRESO
            </b-th>
            <b-th
              class="text-center align-middle secondary-header"
              v-if="
                displayList.some(
                  (x) =>
                    $equals(
                      x.title,
                      $getVisibleNames(
                        'mesh.egressprofile',
                        false,
                        'Perfil de Egreso'
                      ).toUpperCase()
                    ) && x.visible
                )
              "
            >
              {{
                $getVisibleNames(
                  "mesh.egressprofile",
                  false,
                  "Perfil de Egreso"
                ).toUpperCase()
              }}
            </b-th>
            <b-th
              v-if="
                displayList.some(
                  (x) =>
                    $equals(
                      x.title,
                      $getVisibleNames(
                        'teaching.section',
                        false,
                        'Sección'
                      ).toUpperCase()
                    ) && x.visible
                ) && section_ids.length > 1
              "
              class="text-center align-middle secondary-header"
            >
              {{
                $getVisibleNames(
                  "teaching.section",
                  false,
                  "Sección"
                ).toUpperCase()
              }}
            </b-th>
            <b-th
              v-if="
                displayList.some(
                  (x) => $equals(x.title, 'NOTA') && x.visible
                ) && grade == true
              "
              class="text-center align-middle secondary-header"
            >
              NOTA
            </b-th>
            <template
              v-for="assessment_label in assessment_range_report.assessments"
            >
              <b-th
                v-if="
                  displayList.some(
                    (x) =>
                      $equals(
                        `${x.id} ${x.title}`,
                        `${
                          assessment_label.id
                        } ${assessment_label.assessment_type_name.toUpperCase()} ${
                          assessment_label.order
                        }`
                      ) && x.visible
                  )
                "
                class="text-center align-middle secondary-header"
                v-b-tooltip.v-secondary.noninteractive="
                  assessment_label.full_sentence
                "
                :key="`header-assessment-${assessment_label.id}`"
              >
                {{ assessment_label.assessment_type_short_name.toUpperCase() }}
                {{ assessment_label.order }}
                <span v-if="assessment_label.weighing">
                  {{
                    assessment_label.weighing ? assessment_label.weighing : "0"
                  }}%
                </span>
                (%)
              </b-th>
            </template>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(
              evaluatee_assessment, index
            ) in assessment_range_report.evaluatees"
            :key="`row-${evaluatee_assessment.id}-${evaluatee_assessment.section_id}-${index}`"
          >
            <!-- N° -->
            <b-td
              v-if="
                displayList.some((x) => $equals(x.title, 'N°') && x.visible)
              "
              class="text-center aling-middle"
            >
              {{ index + 1 }}
            </b-td>
            <!-- APELLIDO -->
            <b-td
              v-if="
                displayList.some(
                  (x) => $equals(x.title, 'APELLIDO') && x.visible
                )
              "
              class="text-left aling-middle"
            >
              {{ evaluatee_assessment.last_name }}</b-td
            >
            <!-- NOMBRE -->
            <b-td
              v-if="
                displayList.some((x) => $equals(x.title, 'NOMBRE') && x.visible)
              "
              class="text-left aling-middle"
            >
              {{ evaluatee_assessment.first_name }}</b-td
            >
            <!-- IDENTIFICADOR -->
            <b-td
              v-if="
                displayList.some(
                  (x) => $equals(x.title, 'IDENTIFICADOR') && x.visible
                )
              "
              class="text-center aling-middle"
            >
              {{ evaluatee_assessment.identifier }}
            </b-td>
            <!-- AÑO DE INGRESO -->
            <b-td
              v-if="
                displayList.some(
                  (x) => $equals(x.title, 'AÑO DE INGRESO') && x.visible
                )
              "
              class="text-center aling-middle"
            >
              {{ evaluatee_assessment.cohort }}</b-td
            >
            <!-- PERFIL DE EGRESO -->
            <b-td
              v-if="
                displayList.some(
                  (x) =>
                    $equals(
                      x.title,
                      $getVisibleNames(
                        'mesh.egressprofile',
                        false,
                        'Perfil de Egreso'
                      ).toUpperCase()
                    ) && x.visible
                )
              "
              class="text-left aling-middle"
            >
              {{ evaluatee_assessment.egress_profile }}
            </b-td>
            <!-- SECCIONES -->
            <b-td
              v-if="
                displayList.some(
                  (x) =>
                    $equals(
                      x.title,
                      $getVisibleNames(
                        'teaching.section',
                        false,
                        'Sección'
                      ).toUpperCase()
                    ) && x.visible
                ) && section_ids.length > 1
              "
              class="text-left aling-middle"
            >
              {{ evaluatee_assessment.section_name }}
            </b-td>
            <!-- NOTAS -->
            <b-td
              v-if="
                displayList.some(
                  (x) => $equals(x.title, 'NOTA') && x.visible
                ) && grade == true
              "
              class="aling-middle"
            >
              <div>
                {{
                  evaluatee_assessment.assessments.find(
                    (assessment) =>
                      assessment.assessment_type_name == "Asignatura"
                  ).section_grade || "-"
                }}
              </div>
            </b-td>
            <template
              v-for="(assessment, index2) in evaluatee_assessment.assessments"
            >
              <template
                v-if="
                  displayList.some(
                    (x) =>
                      $equals(
                        `${x.id} ${x.title}`,
                        `${
                          assessment.assessment_id
                        } ${assessment.assessment_type_name.toUpperCase()} ${
                          assessment.assessment_order
                        }`
                      ) && x.visible
                  )
                "
              >
                <template v-if="assessment_switches_type">
                  <b-td
                    :key="`row-assessment-sumative-${evaluatee_assessment.id}-${assessment.id}-${index2}`"
                    :style="`background-color:${assessment.summative_assessment_color} !important`"
                  >
                    <span v-if="assessment.summative_assessment >= 0">
                      {{ assessment.summative_assessment | Round }}
                    </span>
                    <span v-else> {{ assessment.summative_assessment }}</span>
                  </b-td>
                </template>
                <template v-else>
                  <b-td
                    :key="`row-assessment-formative-${evaluatee_assessment.id}-${assessment.id}-${index2}`"
                    :style="`background-color:${assessment.formative_assessment_color} !important`"
                  >
                    <span v-if="assessment.formative_assessment >= 0">
                      {{ assessment.formative_assessment | Round }}
                    </span>
                    <span v-else> {{ assessment.formative_assessment }}</span>
                  </b-td>
                </template>
              </template>
            </template>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <div
      class="d-flex"
      v-if="assessment_range_report && assessment_range_report.assessments"
    >
      <div class="ml-auto mr-0 d-flex">
        <strong class="mr-2 align-content-center" style="font-size: 10pt">
          FORMATIVAS
        </strong>
        <b-form-checkbox v-model="assessment_switches_type" switch>
          <strong style="font-size: 10pt">SUMATIVAS</strong>
        </b-form-checkbox>
      </div>
    </div>
    <div class="table-wrapper">
      <b-table-simple
        bordered
        no-border-collapse
        small
        responsive
        v-if="
          assessment_range_report &&
          assessment_range_report.assessments &&
          assessment_range_report.evaluatees &&
          assessment_range_report.achievement_ranges
        "
        class="table-assesment-range-table"
      >
        <b-thead>
          <b-tr>
            <b-th
              class="primary-header"
              :colspan="assessment_range_report.assessments.length + 2"
              >RANGOS DE LOGROS
            </b-th>
          </b-tr>
          <b-tr>
            <b-th class="secondary-header">NOMBRE</b-th>
            <b-th class="secondary-header range-column">RANGO (%)</b-th>
            <b-th
              class="text-center align-middle secondary-header"
              v-for="assessment_label in assessment_range_report.assessments"
              :key="`header-assessment-${assessment_label.id}`"
              v-b-tooltip.v-secondary.noninteractive="
                assessment_label.full_sentence
              "
            >
              {{ assessment_label.assessment_type_short_name.toUpperCase() }}
              {{ assessment_label.order }}
              <span v-if="assessment_label.weighing">
                {{ assessment_label.weighing }}%
              </span>
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="achievement_range in assessment_range_report.achievement_ranges"
            :key="`row-${achievement_range.id}`"
          >
            <b-td class="text-center aling-middle">{{
              achievement_range.name
            }}</b-td>
            <b-td
              class="text-center aling-middle"
              :style="`background-color:${achievement_range.color} !important`"
            >
              <div>
                {{ achievement_range.start_achievement }}
                <span v-if="achievement_range.include_start_achievement"
                  >&le;</span
                >
                <span v-else>&lt;</span>
                x
                <span v-if="achievement_range.include_finish_achievement"
                  >&le;</span
                >
                <span v-else>&lt;</span>
                {{ achievement_range.finish_achievement }}
              </div>
            </b-td>
            <b-td
              v-for="assessment_range in assessment_range_report.assessments"
              :key="`row-${achievement_range.id}columns-${assessment_range.id}`"
            >
              {{
                countAchievementRange(
                  achievement_range.id,
                  assessment_range.achievement_ranges
                )
              }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <TeacherInfoTable
      v-if="
        main_assessment != null &&
        !is_loading &&
        assessment_range_report &&
        assessment_range_report.assessments &&
        assessment_range_report.evaluatees
      "
      :sections_ids="section_ids"
    ></TeacherInfoTable>
  </div>
</template>

<script>
// import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "AssessmentRangeTable",
  components: {
    ButtonDownload: () =>
      import("@/components/reusable/Buttons/ButtonDownload"),
    VisibleColumns: () =>
      import("@/components/reusable/Buttons/VisibleColumns"),
    TeacherInfoTable: () =>
      import(
        "@/components/reports/TeachingReport/AssessmentRangeSection/TeacherInfoTable.vue"
      ),
  },
  props: {
    section_ids: {
      type: Array,
      required: true,
    },
    main_assessment: {
      type: Number,
      required: true,
    },
    achievement_range_group: {
      type: [Number, null],
      required: false,
      default: null,
    },
    grade: {
      type: Boolean,
      default: false,
    },
    campuses_: {
      type: Array,
    },
    egress_profiles_: {
      type: Array,
    },
    levels_: {
      type: Array,
    },
    matters_: {
      type: Array,
    },
    periods_: {
      type: Array,
    },
    sections_: {
      type: Array,
    },
  },
  data() {
    return {
      is_loading: false, // Variable para mostrar "skeletons"
      assessment_range_report: [],
      displayList: [],
      assessment_switches_type: true,
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
    }),
  },
  methods: {
    DownloadExcel() {
      this.$restful
        .Post(`assessment2/excel_report_generic_achievement_report/`, {
          campus_filters: this.campuses_
            ? this.campuses_.map((x) => x.id)
            : null,
          egress_profile_filters: this.egress_profiles_
            ? this.egress_profiles_.map((x) => x.id)
            : null,
          level_filters: this.levels_ ? this.levels_.map((x) => x.id) : null,
          period_filters: this.periods_ ? this.periods_.map((x) => x.id) : null,
          matter_filters: this.matters_ ? this.matters_.map((x) => x.id) : null,
          section_filters: this.sections_
            ? this.sections_.map((x) => x.id)
            : null,
          sections: this.section_ids,
          main_assessment: this.main_assessment,
          report_type: 1,
        })
        .then((response) => {
          if (response && response.file_url) {
            window.open(response.file_url, "_blank");
          }
        });
    },
    getAssessmentRange() {
      if (this.section_ids.length > 0 && this.main_assessment != null) {
        this.is_loading = true;
        this.displayList = [];
        this.$restful
          .Post(`/assessment2/generic_achievement_report/`, {
            sections: this.section_ids,
            main_assessment: this.main_assessment,
            achievement_range_group: this.achievement_range_group,
          })
          .then((response) => {
            this.assessment_range_report = response;
            this.completeDisplayList(response);
            this.is_loading = false;
          });
      }
    },
    completeDisplayList(response) {
      this.displayList = [
        {
          index: 0,
          id: "N°",
          title: "N°",
          visible: true,
        },
        {
          index: 1,
          id: "AÑO DE INGRESO",
          title: "AÑO DE INGRESO",
          visible: true,
        },
        {
          index: 2,
          id: "IDENTIFICADOR",
          title: "IDENTIFICADOR",
          visible: true,
        },
        {
          index: 3,
          id: "APELLIDO",
          title: "APELLIDO",
          visible: true,
        },
        {
          index: 4,
          id: "NOMBRE",
          title: "NOMBRE",
          visible: true,
        },
        {
          index: 5,
          id: this.$getVisibleNames(
            "mesh.egressprofile",
            false,
            "Perfil de Egreso"
          ).toUpperCase(),
          title: this.$getVisibleNames(
            "mesh.egressprofile",
            false,
            "Perfil de Egreso"
          ).toUpperCase(),
          visible: false,
        },
      ];
      if (this.grade == true) {
        this.displayList.push({
          index: 6,
          id: "NOTA",
          title: "NOTA",
          visible: true,
        });
      }
      if (this.section_ids.length > 1)
        this.displayList.push({
          index: 7,
          id: this.$getVisibleNames(
            "teaching.section",
            false,
            "Sección"
          ).toUpperCase(),
          title: this.$getVisibleNames(
            "teaching.section",
            false,
            "Sección"
          ).toUpperCase(),
          visible: true,
        });
      let display_index = this.displayList.length;
      if (response.assessments && response.assessments.length > 0)
        response.assessments.forEach((element) => {
          this.displayList.push({
            index: display_index,
            id: element.id,
            title: `${element.assessment_type_name.toUpperCase()} ${
              element.order != null ? element.order : ""
            }`,
            visible: true,
          });
          display_index += 1;
        });
    },
    countAchievementRange(achievement_range_id, achievement_range_list) {
      const achivement = achievement_range_list.find(
        (x) => x.achievement_range_id == achievement_range_id
      );
      if (achivement) {
        if (this.assessment_switches_type) return achivement.summative_count;
        else return achivement.formative_count;
      } else return "-";
    },
  },
  created() {
    this.getAssessmentRange();
  },
};
</script>

<style scoped>
.container-buttons-report {
  display: flex;
  justify-content: right;
}
.primary-header {
  background-color: var(--kl-menu-color) !important;
  color: white;
}
.secondary-header {
  background: #d0cece !important;
}
.col-display-1,
.col-display-2 {
  min-width: 200px;
  margin-left: 2em;
  background-color: var(--kl-primary-button-color) !important;
  color: #fff;
}
.col-display-1:hover,
.col-display-2:hover {
  background-color: var(--kl-primary-button-hover-color) !important;
  transition: all 0.3s;
}

@media (max-width: 768px) {
  .table-wrapper {
    overflow-x: auto;
  }

  .table-assesment-range-table {
    width: 100%;
    min-width: 600px;
    border-collapse: collapse; /* Evita que las celdas se sobrepongan */
  }
  .table-assesment {
    width: 100%;
    min-width: 800px;
    border-collapse: collapse; /* Evita que las celdas se sobrepongan */
  }
}
</style>

