<template>
  <div>
    <!-- ************************************** Paso 1 ******************************************* -->
    <section v-if="step == 1">
      <div class="step-grid mb-5">
        <div class="step-icon-border first-load-border mr-5">
          <b-icon-list-ul
            id="step1"
            class="step-icon first-load-icon"
          ></b-icon-list-ul>
          <p class="step-caption text-success">
            Paso 1: Detalles de la
            {{ $getVisibleNames("teaching.section", false, "Sección") }}
          </p>
        </div>
        <div class="step-icon-border mx-5">
          <b-icon-people-fill class="step-icon"></b-icon-people-fill>
          <p class="step-caption">Paso 2: Asignar Estudiante(s)</p>
        </div>
        <div class="step-icon-border ml-5">
          <b-icon-person-check-fill
            class="step-icon"
          ></b-icon-person-check-fill>
          <p class="step-caption">Paso 3: Asignar Profesor(es)</p>
        </div>
      </div>
      <span class="progress first-load"></span>
      <div class="container-group">
        <div class="group">
          <b-form-group
            label="Nombre"
            label-for="input-name"
            label-cols="3"
            label-cols-sm="3"
            class="m-0"
          >
            <b-form-input
              id="input-name"
              v-model="$v.section.name.$model"
              :state="validateState('name')"
              :disabled="!allows_crud"
              aria-describedby="input-name-feedback"
              size="sm"
            ></b-form-input>
            <b-form-invalid-feedback id="input-name-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
        <!-- ******************************** SE COMENTA SELECTOR COLOR EN SECCIONES ************************************** -->
        <!-- <div class="group">
        <b-form-group
          label="Color"
          label-for="input-color"
          label-cols="4"
          label-cols-sm="3"
          class="m-0"
        >
          <b-form-input
            id="input-color"
            v-model="$v.section.color.$model"
            :state="validateState('color')"
            :disabled="!allows_crud"
            aria-describedby="input-color-feedback"
            size="sm"
            type="color"
          ></b-form-input>
          <b-form-invalid-feedback id="input-color-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>
        </div> -->
        <!-- <div class="group">
          <b-form-group
            v-if="egressProfile"
            label="Nivel"
            label-for="select-level"
            label-cols="4"
            label-cols-sm="3"
            class="m-0"
          >
            <b-form-select
              id="select-level"
              :options="levels"
              v-model="$v.section.plan_level.$model"
              :state="validateState('plan_level')"
              :disabled="!allows_crud || !edit_level"
              aria-describedby="select-level-feedback"
              size="sm"
            ></b-form-select>
            <b-form-invalid-feedback id="select-level-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>
        </div> -->
        <div v-if="egressProfile" class="group">
          <b-form-group
            :label="
              $getVisibleNames('mesh.egressprofilematter', false, 'Asignatura')
            "
            label-for="select-egress_profile_matter"
            label-cols="3"
            label-cols-sm="3"
            class="mb-1 mr-0 ml-0"
          >
            <v-select
              id="select-egress_profile_matter"
              v-model="$v.section.egress_profile_matter.$model"
              :options="matterList"
              :reduce="(matterList) => matterList.id"
              :state="validateState('egress_profile_matter')"
              :disabled="!allows_crud || !edit_matter"
              :placeholder="`${String(
                'Seleccione una ' +
                  $getVisibleNames(
                    'teaching.matter',
                    false,
                    'Asignatura'
                  ).toLowerCase()
              )}`"
              aria-describedby="select-egress_profile_matter-feedback"
              size="md"
              label="label"
              track-by="id"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  No se encontró resultados para <em>{{ search }}</em
                  >.
                </template>
              </template>
              <template v-slot:option="option">
                {{ option.label }}
              </template>
            </v-select>
            <div
              class="div-invalid-feedback"
              v-if="$v.section.egress_profile_matter.$anyError == true"
            >
              Este campo es obligatorio.
            </div>
          </b-form-group>
        </div>
        <div v-if="!egressProfile" class="group">
          <b-form-group
            :label="$getVisibleNames('teaching.matter', false, 'Asignatura')"
            label-for="select-level"
            label-cols="3"
            label-cols-sm="3"
            class="mb-1 mr-0 ml-0"
          >
            <!-- :class="{ 'diable-search': section.matter != null }" -->
            <v-select
              v-model="$v.section.matter.$model"
              :options="sortMatters"
              :reduce="(sortMatters) => sortMatters.id"
              :state="validateState('matter')"
              :disabled="!allows_crud || !edit_matter"
              :placeholder="`${String(
                '-- Seleccione una ' +
                  $getVisibleNames(
                    'teaching.matter',
                    false,
                    'Asignatura'
                  ).toLowerCase()
              )} --`"
              label="label"
              track-by="id"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  No se encontró resultados para <em>{{ search }}</em
                  >.
                </template>
              </template>
              <template v-slot:option="option">
                {{ option.label }}
              </template>
            </v-select>
            <div
              class="div-invalid-feedback"
              v-if="$v.section.matter.$anyError == true"
            >
              Este campo es obligatorio.
            </div>
          </b-form-group>
        </div>
        <div class="group" v-if="!egressProfile">
          <b-form-group
            :label="$getVisibleNames('mesh.periodtype', false, 'Régimen')"
            label-for="select-period_type"
            label-cols="3"
            label-cols-sm="3"
            class="m-0"
          >
            <b-form-select
              id="select-period_type"
              :options="regimes"
              value-field="id"
              text-field="name"
              v-model="period_type_id"
              :disabled="!allows_crud || !edit_period"
              aria-describedby="select-period_type-feedback"
              @change="() => (section.period = null)"
              size="sm"
            ></b-form-select>
            <b-form-invalid-feedback id="select-period_type-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
        <!-- Jornada -->
        <div class="group">
          <b-form-group
            :label="$getVisibleNames('mesh.shift', false, 'Jornada')"
            label-for="select-shift"
            label-cols="3"
            label-cols-sm="3"
            class="m-0"
          >
            <b-form-select
              id="select-shift"
              :options="shifts"
              value-field="id"
              text-field="name"
              v-model="$v.section.shift.$model"
              :state="validateState('shift')"
              :disabled="!allows_crud || !edit_shift"
              aria-describedby="select-shift-feedback"
              size="sm"
            ></b-form-select>
            <b-form-invalid-feedback id="select-shift-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
        <!-- Sede -->
        <div class="group">
          <b-form-group
            :label="$getVisibleNames('mesh.campus', false, 'Sede')"
            label-for="select-campus"
            label-cols="3"
            label-cols-sm="3"
            class="m-0"
          >
            <b-form-select
              id="select-campus"
              :options="filteredCampuses"
              value-field="id"
              text-field="name"
              v-model="$v.section.campus.$model"
              :state="validateState('campus')"
              :disabled="!allows_crud || !edit_campus"
              aria-describedby="select-campus-feedback"
              size="sm"
            ></b-form-select>
            <b-form-invalid-feedback id="select-campus-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
        <!-- Periodo -->
        <div class="group">
          <b-form-group
            :label="$getVisibleNames('mesh.period', false, 'Período')"
            label-for="select-period"
            label-cols="3"
            label-cols-sm="3"
            class="m-0"
          >
            <b-form-select
              id="select-period"
              :options="periodList"
              value-field="id"
              text-field="label"
              v-model="$v.section.period.$model"
              :state="validateState('period')"
              :disabled="!allows_crud || !edit_period || periodList.length == 0"
              aria-describedby="select-period-feedback"
              size="sm"
            ></b-form-select>
            <b-form-invalid-feedback id="select-period-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
      </div>
    </section>
    <!-- ************************************** Paso 2 ******************************************* -->
    <div class="hidden-step" :class="{ 'is-shown': this.step == 2 }">
      <div class="step-grid mb-5">
        <div class="step-icon-border second-load-border-step1 mr-5 done">
          <b-icon-list-ul
            id="step1"
            class="step-icon second-load-icon-step1"
            variant="success"
          ></b-icon-list-ul>
          <p class="step-caption text-success">
            Paso 1: Detalles de la
            {{ $getVisibleNames("teaching.section", false, "Sección") }}
            <b-icon-check2-circle variant="success"></b-icon-check2-circle>
          </p>
        </div>
        <div class="step-icon-border second-load-border mx-5">
          <b-icon-people-fill
            class="step-icon second-load-icon"
          ></b-icon-people-fill>
          <p class="step-caption text-success">Paso 2: Asignar Estudiante(s)</p>
        </div>
        <div class="step-icon-border ml-5">
          <b-icon-person-check-fill
            class="step-icon"
          ></b-icon-person-check-fill>
          <p class="step-caption">Paso 3: Asignar Profesor(es)</p>
        </div>
      </div>
      <div class="progress second-load"></div>
      <div class="container-group">
        <h5>Estudiantes</h5>
        <UserListSection
          :section_id="section.id"
          :filter_by_professor="false"
          :filter_by_student="true"
          :allows_crud="allows_crud"
          @changed="slotStudentsChanged"
        ></UserListSection>
      </div>
    </div>
    <!-- ************************************** Paso 3 ******************************************* -->
    <div class="hidden-step" :class="{ 'is-shown': this.step == 3 }">
      <div class="step-grid mb-5">
        <div class="step-icon-border third-load-border-step1 mr-5 done">
          <b-icon-list-ul
            id="step1"
            class="step-icon third-load-icon-step1"
            variant="success"
          ></b-icon-list-ul>
          <p class="step-caption text-success">
            Paso 1: Detalles de la
            {{ $getVisibleNames("teaching.section", false, "Sección") }}
            <b-icon-check2-circle variant="success"></b-icon-check2-circle>
          </p>
        </div>
        <div class="step-icon-border third-load-border-step2 mx-5 done">
          <b-icon-people-fill
            class="step-icon third-load-icon-step2"
            variant="success"
          ></b-icon-people-fill>
          <p class="step-caption text-success">
            Paso 2: Asignar Estudiante(s)
            <b-icon-check2-circle variant="success"></b-icon-check2-circle>
          </p>
        </div>
        <div
          class="step-icon-border third-load-border ml-5"
          :class="[{ done: saved }, { info: !saved }]"
        >
          <b-icon-person-check-fill
            class="step-icon third-load-icon"
            :style="[
              saved
                ? { color: 'white !important' }
                : { color: 'var(--secondary-color)' },
            ]"
          ></b-icon-person-check-fill>
          <p
            class="step-caption"
            :class="[{ 'text-success': saved }, { 'text-success': !saved }]"
          >
            Paso 3: Asignar Profesor(es)<b-icon-check2-circle
              :style="[saved ? { display: 'inline' } : { display: 'none' }]"
              variant="success"
            ></b-icon-check2-circle>
          </p>
        </div>
      </div>
      <span class="progress third-load done" :class="[{ fill: saved }]"></span>
      <div class="container-group">
        <h5>Profesores</h5>
        <UserListSection
          :section_id="section.id"
          :filter_by_professor="true"
          :filter_by_student="false"
          :allows_crud="allows_crud"
          @changed="slotProfessorsChanged"
        ></UserListSection>
      </div>
    </div>
    <b-form-group
      v-if="step == 1"
      label-for="input-modification-date"
      label-cols="0"
      label-cols-sm="0"
      class="p-0 m-0 mt-2"
    >
      <ModificationDate :Information="section"></ModificationDate>
    </b-form-group>
    <div class="mb-4" style="text-align: right">
      <b-button v-if="step != 1" class="mr-1" @click.prevent="prevStep">
        Paso anterior
      </b-button>
      <b-button v-if="step != total_steps" @click.prevent="nextStep">
        Siguiente paso
      </b-button>
      <b-button
        class="mr-1"
        @click="save"
        v-can="'teaching.add_section'"
        v-if="step == 3 && allows_crud"
        >Guardar</b-button
      >
    </div>
    <div class="row" v-if="allows_crud">
      <div
        v-if="show_delete_button && !isNaN(section.id)"
        class="col"
        style="text-align: left"
      >
        <b-button
          class="mr-1"
          style="margin-top: -9%"
          size="sm"
          variant="danger"
          v-can="'teaching.delete_section'"
          @click="askForDelete"
          >Eliminar</b-button
        >
      </div>
    </div>
    <div style="text-align: right" v-if="step == 3"></div>
  </div>
</template>

<script>
import { generateUniqueId, toast } from "@/utils/utils";
import * as names from "@/store/names";
import { mapGetters, mapMutations } from "vuex";
import { validationMixin } from "vuelidate";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import {
  required,
  // minValue,
  // maxValue,
  // numeric,
} from "vuelidate/lib/validators";

export default {
  name: "SectionForm",
  mixins: [validationMixin],
  components: {
    vSelect,
    UserListSection: () =>
      import("@/components/teaching/Scheduler/UserListSection"),
    ModificationDate: () => import("@/components/reusable/ModificationDate"),
  },
  props: {
    Section: {
      type: Object,
    },
    egress_profile_id: {},
    selected_matter_id: {},
    period_id: {
      type: Number,
    },
    shift_id: {
      type: Number,
    },
    campus_id: {
      type: Number,
    },
    selected_level: {
      type: [Number, null],
      default: null,
    },
    period_type: {
      type: Number,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    show_delete_button: {
      type: Boolean,
      default: true,
    },
    edit_matter: {
      type: Boolean,
      default: true,
    },
    edit_level: {
      type: Boolean,
      default: true,
    },
    edit_period: {
      type: Boolean,
      default: true,
    },
    edit_shift: {
      type: Boolean,
      default: true,
    },
    edit_campus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      step: 1,
      total_steps: 3,
      saved: false,
      period_type_id: this.Section ? null : this.period_type,
      section: {
        id: this.Section ? this.Section.id : generateUniqueId(),
        name: this.Section ? this.Section.name : "",
        color: this.Section ? this.Section.color : "#000000",
        attention_hours: this.Section ? this.Section.attention_hours : "",
        coexistence_rules: this.Section ? this.Section.coexistence_rules : "",
        // TODO: Preguntar si se mantiene fuera el plan_level de Section.
        // plan_level: this.Section ? this.Section.plan_level : 1,
        egress_profile_matter: this.Section
          ? this.Section.egress_profile_matter
          : null,
        matter: this.Section ? this.Section.matter : this.selected_matter_id,
        period: this.Section ? this.Section.period : this.period_id,
        shift: this.Section ? this.Section.shift : this.shift_id,
        campus: this.Section ? this.Section.campus : this.campus_id,
        students: this.Section ? this.Section.students : [],
        professors: this.Section ? this.Section.professors : [],
        sessions: this.Section ? this.Section.sessions : [],
        session_dates: this.Section ? this.Section.session_dates : [],
        updated_by: this.Section ? this.Section.updated_by : null,
        update_date: this.Section ? this.Section.update_date : null,
      },
    };
  },
  validations() {
    return {
      section: {
        name: { required },
        color: {},
        // plan_level: {
        //   required,
        //   numeric,
        //   minValue: minValue(1),
        //   maxValue: maxValue(18),
        // },
        matter: {
          valid(value) {
            if (!this.egressProfile) {
              if (value) return true;
              else return false;
            } else return true;
          },
        },
        egress_profile_matter: {
          valid(value) {
            if (this.egressProfile) {
              if (value != null) return true;
              else return false;
            }
            return true;
          },
          // validMatter(value) {
          //   const matter = this.egress_profiles_matters.find(
          //     (x) => x.id == value
          //   );
          //   if (matter) {
          //     if (matter.is_closed) return true;
          //     else return false;
          //   } else return false;
          // },
        },
        period: { required },
        shift: { required },
        campus: { required },
        students: {},
        professors: {},
      },
    };
  },
  computed: {
    ...mapGetters({
      matters: names.MATTERS,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      egressProfiles: names.EGRESS_PROFILES,
      periods: names.PERIODS,
      shifts: names.SHIFTS,
      campuses: names.CAMPUSES,
      regimes: names.REGIMES,
      user: "getUser",
      institution: "getInstitution",
    }),
    egressProfile() {
      if (!this.egress_profile_id) return null;
      return this.egressProfiles.find((x) => x.id == this.egress_profile_id);
    },
    levels() {
      let list = [];
      if (!this.egressProfile) {
        for (let i = 1; i <= this.institution.max_semester_amount; i++) {
          list.push(i);
        }
        return list;
      } else {
        for (
          let index = 1;
          index <= this.egressProfile.semester_amount;
          index++
        ) {
          list.push(index);
        }
        return list;
      }
    },
    matterList() {
      let matterList = [];
      if (!this.egress_profile_id) return matterList;
      let list = this.egress_profiles_matters.filter(
        (x) => x.egress_profile == this.egress_profile_id
      );
      if (this.selected_level != null)
        list = list.filter(
          (x) =>
            x.plan_level == this.selected_level ||
            this.section.egress_profile_matter
        );
      if (list.length > 0) {
        list.forEach((x) => {
          const matter = this.matters.find((matter) => matter.id == x.matter);
          if (matter) {
            matterList.push({
              id: x.id,
              name: matter.name,
              label: matter.code + " - " + matter.name,
            });
          }
        });
      }
      return matterList;
    },
    sortMatters() {
      return this.matters
        .map((x) => ({ ...x, label: x.code + " - " + x.name }))
        .sort((a, b) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        });
    },
    periodList() {
      let periods_list = this.periods.map((period) => {
        let start_date = period.start_date;
        let end_date = period.end_date;
        if (start_date)
          start_date = this.$moment(start_date).format("DD/MM/YYYY");
        if (end_date) end_date = this.$moment(end_date).format("DD/MM/YYYY");
        return {
          ...period,
          label: `${period.name} De: ${start_date} al ${end_date}`,
        };
      });
      if (!this.period_type_id) return periods_list;
      return periods_list.filter((x) => x.period_type == this.period_type_id);
    },
    filteredCampuses() {
      if (!this.egressProfile)
        return [...this.campuses].sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
      let list = [];
      this.egressProfile.campuses.forEach((campus_id) => {
        const instance = this.campuses.find((x) => x.id == campus_id);
        if (instance) list.push(instance);
      });
      return list.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    },
  },
  methods: {
    ...mapMutations([
      "setUserSectionProfessor",
      "UpdateUserSectionProfessor",
      "DeleteUserSectionProfessor",
    ]),
    validateState(name) {
      const { $dirty, $error } = this.$v.section[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.section.$touch();
      if (this.$v.section.$anyError) {
        return;
      }
      // if (this.step == 3 && this.section.professors.length == 0) {
      //   return this.$swal(
      //     "No se han ingresado profesores",
      //     "Por favor, asegúrese de asignar profesores a la sección antes de guardar.",
      //     "warning"
      //   );
      // }
      if (isNaN(this.section.id)) this.create();
      else this.update();
      this.saved = true;
    },
    create() {
      this.$store
        .dispatch(names.POST_SECTION, this.section)
        .then((response) => {
          if (this.section.professors.find((x) => x == this.user.id)) {
            let payload = { user_id: this.user.id, section: response };
            this.setUserSectionProfessor(payload);
          }
          this.$restful.Get(
            `/teaching/fill_evaluation_users_default/?section=${response.id}`
          );
          this.$emit("created", response);
          toast(
            this.$getVisibleNames("teaching.section", false, "Sección") +
              " creada."
          );
        });
    },
    update() {
      this.$store
        .dispatch(names.UPDATE_SECTION, this.section)
        .then((response) => {
          let payload = { user_id: this.user.id, section: response };
          this.UpdateUserSectionProfessor(payload);
          this.$emit("updated", response);
          toast(
            this.$getVisibleNames("teaching.section", false, "Sección") +
              " modificada."
          );
        });
    },
    askForDelete() {
      this.$swal({
        title: "¿Está seguro de que desea eliminar la Sección?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) this.delete();
      });
    },
    delete() {
      this.$store.dispatch(names.DELETE_SECTION, this.section.id).then(() => {
        this.$emit("deleted", this.section);
        let payload = { user_id: this.user.id, section: this.section };
        this.DeleteUserSectionProfessor(payload);
        toast(
          this.$getVisibleNames("teaching.section", false, "Sección") +
            " eliminada."
        );
      });
    },

    slotStudentsChanged(students) {
      this.section.students = [];
      students.forEach((user) => {
        const index = this.section.students.findIndex((x) => x == user.id);
        if (index == -1) this.section.students.push(user.id);
      });
    },
    slotProfessorsChanged(professors) {
      this.section.professors = [];
      professors.forEach((user) => {
        const index = this.section.professors.findIndex((x) => x == user.id);
        if (index == -1) this.section.professors.push(user.id);
      });
    },
    prevStep() {
      return this.step--;
    },
    nextStep() {
      if (this.allows_crud) {
        this.$v.section.$touch();
        if (this.$v.section.$anyError) {
          return;
        }
      }
      // if (this.step == 2 && this.section.students.length == 0) {
      //   return this.$swal(
      //     "No se han ingresado estudiantes",
      //     "Por favor, asegúrese de asignar estudiantes a la sección antes de continuar.",
      //     "warning"
      //   );
      // }
      return this.step++;
    },
  },
  created() {
    if (!isNaN(this.section.id)) {
      const period = this.periods.find((x) => x.id == this.section.period);
      if (period) this.period_type_id = period.period_type;
    }
    this.saved = false;
    this.$store.dispatch(names.FETCH_SHIFTS);
    if (this.egress_profile_id) {
      this.$store.dispatch(names.FETCH_MATTERS, {
        egress_profile_id: this.egress_profile_id,
      });
      this.$store.dispatch(names.FETCH_EGRESS_PROFILES_MATTERS, {
        egress_profile_id: this.egress_profile_id,
      });
    }
  },
};
</script>

<style scoped>
/* .v-select.diable-search >>> .vs__search { */
/* display: none; */
/* } */
p {
  margin-bottom: 0px !important;
  margin-top: 9px !important;
}
.div-invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
.group {
  width: 100%;
}
.hidden-step {
  display: none;
}
.is-shown {
  display: block;
}
.step-grid {
  display: flex;
  justify-content: center;
  margin-bottom: 10% !important;
}
.step-icon-border {
  width: 50px;
  height: 50px;
  border: 3px solid var(--secondary-color);
  border-radius: 50%;
  padding: 11px 1px 1px 12px;
  z-index: 30;
  background: #fff;
}
.first-load-border {
  background: white;
  border: 3px solid #28a745;
}
.second-load-border {
  border: 3px solid #28a745;
  background: white;
}
.third-load-border {
  background: white;
}
.second-load-border-step1 {
  background: #28a745;
}
.third-load-border-step1 {
  background: #28a745;
}
.third-load-border-step2 {
  background: #28a745;
}
.first-load-icon {
  color: var(--secondary-color);
}
.second-load-icon {
  color: var(--secondary-color);
}
.third-load-icon {
  color: var(--secondary-color);
}
.second-load-icon-step1 {
  color: white !important;
}
.third-load-icon-step1 {
  color: white !important;
}
.third-load-icon-step2 {
  color: white !important;
}
.step-icon {
  font-size: 22px;
}
.step-caption {
  width: 170px;
  text-align: center;
  margin-top: 1.3em;
  transform: translateX(-42%);
}
.progress {
  border: 1px solid var(--secondary-color);
  height: 10px;
  margin-top: -12.9%;
  margin-bottom: 5%;
}
.first-load {
  border: 3px solid #28a745;
  background: #28a745;
  width: 35%;
  margin-right: 20%;
}
.second-load {
  border: 3px solid #28a745;
  background: #28a745;
  width: 50%;
  margin-right: 20%;
}
.third-load {
  border: 3px solid #28a745;
  background: #28a745;
  width: 65%;
  margin-right: 20%;
}
.fill {
  width: 100%;
}
.container-group {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  flex-wrap: wrap;
  margin-top: 7%;
}
.done {
  border: 3px solid #28a745 !important;
  background: #28a745;
  transition: 0.4s;
}
@media (max-width: 1200px) {
  .progress {
    margin-top: -16%;
    margin-bottom: 7%;
  }
  .first-load {
    width: 30%;
  }
  .third-load {
    width: 70%;
  }
}
@media (max-width: 991px) {
  .progress {
    margin-top: -20%;
    margin-bottom: 19%;
  }
  .step-icon-border {
    margin-right: 1% !important;
  }
  .step-caption {
    width: 190px;
    padding: 0 10px;
  }
  .first-load {
    width: 25%;
  }
}
</style>