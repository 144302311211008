var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"noprint"},[_c('span',{class:{ 'font-weight-bold': !_vm.requirementFulfilled }},[_vm._v(" "+_vm._s(_vm.title)+" "),(!_vm.requirementFulfilled)?[_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-secondary.noninteractive",value:(
          'Debe seleccionar al menos un elemento.'
        ),expression:"\n          'Debe seleccionar al menos un elemento.'\n        ",modifiers:{"hover":true,"v-secondary":true,"noninteractive":true}}],staticClass:"span-required",attrs:{"icon":"asterisk"}},[_vm._v("* ")])]:(_vm.required)?[_c('b-icon',{attrs:{"icon":"check","variant":"success","scale":"1.5"}}),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-secondary.noninteractive",value:(
          'Limpiar elementos seleccionados.'
        ),expression:"\n          'Limpiar elementos seleccionados.'\n        ",modifiers:{"hover":true,"v-secondary":true,"noninteractive":true}}],staticClass:"btn-x-header ml-2",on:{"click":_vm.removeAllItems}},[_c('b-icon',{staticClass:"icon-x-clear",attrs:{"icon":"x","scale":"1.4"}})],1)]:_vm._e()],2),_c('v-select',{ref:"vselect",attrs:{"options":_vm.inputOptions,"get-option-label":_vm.label_function,"label":_vm.label_field,"disabled":_vm.disabled,"required":_vm.required},on:{"option:selected":_vm.slotOptionSelected},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Nada para seleccionar. ")]},proxy:true},(_vm.cite_field)?{key:"option",fn:function(option){return [_c('b',[_vm._v(_vm._s(option[_vm.label_field]))]),_c('br'),_c('cite',{staticStyle:{"font-size":"small"}},[_vm._v(_vm._s(option[_vm.cite_field]))])]}}:null],null,true),model:{value:(_vm.current_selected),callback:function ($$v) {_vm.current_selected=$$v},expression:"current_selected"}}),_c('div',{staticClass:"container-badge text-left"},_vm._l((_vm.selected_items),function(item){return _c('b-badge',{key:item[_vm.id_field],staticClass:"badge-select",attrs:{"variant":"info","disabled":_vm.disabled}},[_c('span',{attrs:{"id":`popover_custom_label-${item.id}-${_vm.title}`}},[_vm._v(" "+_vm._s(item[_vm.label_field])+" ")]),_c('b-button',{staticClass:"round btn-x",attrs:{"variant":"none","size":"sm"},on:{"click":function($event){return _vm.slotRemoveItem(item)}}},[_c('b-icon',{attrs:{"icon":"x","scale":"0.9"}})],1),_c('b-popover',{attrs:{"target":`popover_custom_label-${item.id}-${_vm.title}`,"placement":"bottom","triggers":"hover focus","boundary":"viewport"}},[[(
              _vm.$equals(
                _vm.title,
                _vm.$getVisibleNames(
                  'mesh.egressprofile',
                  false,
                  'Perfil De Egreso'
                )
              ) && _vm.selected_items.length > 1
            )?[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$getVisibleNames( "manual.mesh.titulo_profesional", false, "Título Profesional" ))+":")]),_vm._v(" "+_vm._s(item.professional_title)+" ")]),_c('div',[_c('strong',[_vm._v("Año:")]),_vm._v(" "+_vm._s(_vm._f("FormatToYear")(item.init_date))+" ")]),(item.code)?_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$getVisibleNames( "manual.egress_profile_code", false, "Código de Perfil de Egreso" ))+": ")]),(item.code)?_c('span',[_vm._v(" "+_vm._s(item.code)+" ")]):_c('span',[_vm._v("-")])]):_vm._e()]:_vm._e(),(
              _vm.$equals(
                _vm.title,
                _vm.$getVisibleNames(
                  'mesh.egressprofilematter',
                  false,
                  'Asignatura'
                )
              ) && _vm.selected_items.length > 1
            )?[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$getVisibleNames( "mesh.egressprofilematter", false, "Asignatura" ))+":")]),_vm._v(" "+_vm._s(item.matter_name)+" ")]),_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$getVisibleNames("manual.matter_code", false, "Código"))+": ")]),(item.code)?_c('span',[_vm._v(" "+_vm._s(item.code)+" ")]):_c('span',[_vm._v("-")])])]:_vm._e()]],2)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }