<template>
  <div>
    <div class="d-flex">
      <div class="ml-auto mr-0 d-flex">
        <strong class="mr-2"> FORMATIVAS </strong>
        <b-form-checkbox v-model="assessment_switches_type" switch>
          <strong>SUMATIVAS</strong>
        </b-form-checkbox>
      </div>
    </div>
    <b-skeleton-table
      v-if="is_loading"
      :rows="5"
      :columns="6"
      :table-props="{ bordered: true, striped: true }"
    ></b-skeleton-table>
    <b-table-simple
      bordered
      small
      responsive
      v-if="!is_loading"
      class="table-assessment"
    >
      <b-thead>
        <b-tr>
          <b-th class="th-class-2" colspan="10"
            >INFORME DE RESULTADOS DE LOGROS</b-th
          >
        </b-tr>
        <b-tr>
          <b-th
            v-if="institution && institution.internal_use_id == 'ciisa_uss'"
            class="th-class"
            :colspan="1"
          >
            {{
              $getVisibleNames(
                "teaching.tempcompetenceunit",
                false,
                "Unidad de competencia"
              ).toUpperCase()
            }}
          </b-th>
          <b-th class="th-class" :colspan="1">
            {{
              $getVisibleNames(
                "teaching.ramicro",
                false,
                "RA Micro"
              ).toUpperCase()
            }}
          </b-th>
          <b-th class="th-class-2" :colspan="1">
            {{
              $getVisibleNames(
                "manual.criterio_de_evaluacion",
                false,
                "Criterio de Evaluación"
              ).toUpperCase()
            }}</b-th
          >
          <b-th class="th-class-2" :colspan="7">LOGROS</b-th>
        </b-tr>
        <b-tr>
          <b-th
            v-if="institution && institution.internal_use_id == 'ciisa_uss'"
            class="th-class"
          >
            ENUNCIADO
          </b-th>
          <b-th class="th-class" style="width: 20%"> ENUNCIADO </b-th>
          <b-th class="th-class"> ENUNCIADO </b-th>
          <b-th class="th-class">
            {{
              $getVisibleNames(
                "manual.criterio_de_evaluacion",
                false,
                "Criterio de Evaluación"
              ).toUpperCase()
            }}
          </b-th>
          <b-th
            class="th-class"
            style="min-width: 30px !important; cursor: help"
            v-b-tooltip.v-secondary.noninteractive.hover="`PONDERACIÓN`"
          >
            %
          </b-th>
          <b-th class="th-class">
            {{
              $getVisibleNames(
                "teaching.ramicro",
                false,
                "RA Micro"
              ).toUpperCase()
            }}
          </b-th>
          <b-th
            v-if="institution && institution.internal_use_id != 'duoc_uc'"
            class="th-class"
            style="min-width: 30px !important; cursor: help"
            v-b-tooltip.v-secondary.noninteractive.hover="`PONDERACIÓN`"
          >
            %
          </b-th>
          <b-th
            class="th-class"
            v-if="institution && institution.internal_use_id == 'ciisa_uss'"
          >
            {{
              $getVisibleNames(
                "teaching.tempcompetenceunit",
                false,
                "Unidad de competencia"
              ).toUpperCase()
            }}
          </b-th>
          <b-th
            v-if="institution && institution.internal_use_id == 'ciisa_uss'"
            class="th-class"
            style="min-width: 30px !important; cursor: help"
            v-b-tooltip.v-secondary.noninteractive.hover="`PONDERACIÓN`"
          >
            %
          </b-th>
          <b-th
            class="th-class"
            v-if="
              institution &&
              institution.internal_use_id == 'ciisa_uss' &&
              assessment_report &&
              assessment_report.matter
            "
          >
            {{
              $getVisibleNames(
                "mesh.egressprofilematter",
                false,
                "Asignatura"
              ).toUpperCase()
            }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template
          v-if="
            assessment_report != null &&
            (assessment_report.competence_units.length > 0 ||
              assessment_report.study_units.length > 0) &&
            ((!hidden_assessment_formative && !assessment_switches_type) ||
              (!hidden_assessment_sumative && assessment_switches_type))
          "
        >
          <template
            v-for="(
              competence_unit, index1
            ) in assessment_report.competence_units"
          >
            <template
              v-for="(study_unit, index2) in competence_unit.study_units"
            >
              <template v-if="study_unit.evaluation_criterias_micro.length > 0">
                <b-tr
                  v-for="(
                    evaluation_criteria, index3
                  ) in study_unit.evaluation_criterias_micro"
                  :key="
                    'competence_unit:' +
                    index1 +
                    'study_unit:' +
                    index2 +
                    'evaluation_criteria:' +
                    index3
                  "
                >
                  <!-- enunciado UC-->
                  <b-td
                    class="td-class"
                    :rowspan="
                      competence_unit.study_units.reduce(
                        (acc, obj) =>
                          acc + obj.evaluation_criterias_micro.length,
                        0
                      )
                    "
                    v-if="index3 == 0 && index2 == 0"
                  >
                    UC N° {{ competence_unit.order }}
                  </b-td>
                  <!-- enunciado RA-->
                  <b-td
                    class="td-class text-left"
                    :rowspan="study_unit.evaluation_criterias_micro.length"
                    v-if="index3 == 0"
                  >
                    <span>
                      <span
                        v-if="study_unit.name && study_unit.name.trim() != ''"
                        class="study-unit"
                        v-b-tooltip.v-secondary.noninteractive.hover.right="
                          study_unit.full_sentence
                        "
                      >
                        {{ study_unit.name }}
                      </span>
                      <span v-else>
                        <SentenceContainer
                          :Large="55"
                          :Sentence="study_unit"
                          :boundedTextContainer="false"
                          :showCognitiveLevel="false"
                        >
                          <template v-slot:order>
                            <span class="evaluation-criteria-order">
                              {{ competence_unit.order }}.{{ study_unit.order }}
                            </span>
                          </template>
                        </SentenceContainer>
                      </span>
                    </span>
                  </b-td>
                  <!-- enunciado IL-->
                  <b-td class="td-class text-justify pl-2">
                    <ContainerCell overflow_y="auto" max_height="130px">
                      <span>
                        <SentenceContainer
                          :Large="55"
                          :Sentence="evaluation_criteria"
                          :boundedTextContainer="false"
                          :showCognitiveLevel="false"
                        >
                          <template v-slot:order>
                            <span class="evaluation-criteria-order">
                              {{ competence_unit.order }}.{{
                                study_unit.order
                              }}.{{ evaluation_criteria.order }}
                            </span>
                          </template>
                        </SentenceContainer>
                      </span>
                    </ContainerCell>
                  </b-td>
                  <!-- logro IL -->
                  <b-td
                    class="td-class"
                    :style="`background-color: ${
                      assessment_switches_type
                        ? evaluation_criteria.summative_achievement_color
                        : evaluation_criteria.formative_achievement_color
                    } !important`"
                  >
                    <span v-if="assessment_switches_type">
                      {{ evaluation_criteria.summative_achievement | Round }}%
                    </span>
                    <span v-else>
                      {{ evaluation_criteria.formative_achievement | Round }}%
                    </span>
                  </b-td>
                  <!--ponderación IL-->
                  <b-td class="td-class">
                    <span v-if="evaluation_criteria.weighing != null">
                      {{ evaluation_criteria.weighing | Round }}%
                    </span>
                    <span v-else>N/A</span>
                  </b-td>
                  <!-- logro RA -->
                  <b-td
                    class="td-class"
                    :style="`background-color: ${
                      assessment_switches_type
                        ? study_unit.summative_achievement_color
                        : study_unit.formative_achievement_color
                    } !important`"
                    :rowspan="study_unit.evaluation_criterias_micro.length"
                    v-if="index3 == 0"
                  >
                    <span v-if="assessment_switches_type">
                      {{ study_unit.summative_achievement | Round }}%
                    </span>
                    <span v-else>
                      {{ study_unit.formative_achievement | Round }}%
                    </span>
                  </b-td>
                  <!-- ponderación RA-->
                  <b-td
                    v-if="
                      index3 == 0 &&
                      institution &&
                      institution.internal_use_id != 'duoc_uc'
                    "
                    class="td-class"
                    :rowspan="study_unit.evaluation_criterias_micro.length"
                  >
                    <span v-if="study_unit.weighing != null">
                      {{ study_unit.weighing | Round }}%
                    </span>
                    <span v-else>N/A</span>
                  </b-td>
                  <!-- logro UC -->
                  <b-td
                    class="td-class"
                    :style="`background-color: ${
                      assessment_switches_type
                        ? competence_unit.summative_achievement_color
                        : competence_unit.formative_achievement_color
                    } !important`"
                    :rowspan="
                      competence_unit.study_units.reduce(
                        (acc, obj) =>
                          acc + obj.evaluation_criterias_micro.length,
                        0
                      )
                    "
                    v-if="index3 == 0 && index2 == 0"
                  >
                    <span v-if="assessment_switches_type">
                      {{ competence_unit.summative_achievement | Round }}%
                    </span>
                    <span v-else>
                      {{ competence_unit.formative_achievement | Round }}%
                    </span>
                  </b-td>
                  <!-- ponderación UC-->
                  <b-td
                    class="td-class"
                    :rowspan="
                      competence_unit.study_units.reduce(
                        (acc, obj) =>
                          acc + obj.evaluation_criterias_micro.length,
                        0
                      )
                    "
                    v-if="index3 == 0 && index2 == 0"
                  >
                    {{ competence_unit.weighing | Round }}%
                  </b-td>
                  <!-- logro matter -->
                  <b-td
                    class="td-class"
                    :rowspan="getMatterRowspan()"
                    v-if="
                      institution &&
                      institution.internal_use_id == 'ciisa_uss' &&
                      index3 == 0 &&
                      index2 == 0 &&
                      index1 == 0 &&
                      assessment_report &&
                      assessment_report.matter
                    "
                    :style="`background-color: ${
                      assessment_switches_type
                        ? assessment_report.matter.summative_achievement_color
                        : assessment_report.matter.formative_achievement_color
                    } !important`"
                  >
                    <template v-if="assessment_report.matter">
                      <span v-if="assessment_switches_type">
                        {{
                          assessment_report.matter.summative_achievement
                            | Round
                        }}%
                      </span>
                      <span v-else>
                        {{
                          assessment_report.matter.formative_achievement
                            | Round
                        }}%
                      </span>
                    </template>
                    <template v-else>-</template>
                  </b-td>
                </b-tr>
              </template>
              <!--  evaluation_criterias_micro.length == 0-->
              <template v-else>
                <b-tr
                  :key="'competence_unit:' + index1 + 'study_unit:' + index2"
                >
                  <!-- enunciado UC -->
                  <b-td
                    class="td-class"
                    :rowspan="competence_unit.study_units.length"
                    v-if="index2 == 0"
                  >
                    UC N° {{ competence_unit.order }}
                  </b-td>
                  <!-- enunciado RA -->
                  <b-td class="td-class text-left">
                    <span>
                      <span
                        v-if="study_unit.name && study_unit.name.trim() != ''"
                        class="study-unit"
                        v-b-tooltip.v-secondary.noninteractive.hover.right="
                          study_unit.full_sentence
                        "
                      >
                        {{ study_unit.name }}
                      </span>
                      <span v-else>
                        <SentenceContainer
                          :Large="55"
                          :Sentence="study_unit"
                          :boundedTextContainer="false"
                          :showCognitiveLevel="false"
                        >
                          <template v-slot:order>
                            <span class="evaluation-criteria-order">
                              {{ competence_unit.order }}.{{ study_unit.order }}
                            </span>
                          </template>
                        </SentenceContainer>
                      </span>
                    </span>
                  </b-td>
                  <!-- enunciado IL -->
                  <b-td class="td-class"> N/A </b-td>
                  <!-- logro IL -->
                  <b-td class="td-class"> N/A </b-td>
                  <!-- ponderación IL -->
                  <b-td class="td-class"> N/A </b-td>
                  <!-- logro RA -->
                  <b-td
                    class="td-class"
                    :style="`background-color: ${
                      assessment_switches_type
                        ? study_unit.summative_achievement_color
                        : study_unit.formative_achievement_color
                    } !important`"
                  >
                    <span v-if="assessment_switches_type">
                      {{ study_unit.summative_achievement | Round }}%
                    </span>
                    <span v-else>
                      {{ study_unit.formative_achievement | Round }}%
                    </span>
                  </b-td>
                  <!-- ponderación RA -->
                  <b-td
                    class="td-class"
                    v-if="
                      institution && institution.internal_use_id != 'duoc_uc'
                    "
                  >
                    <span v-if="study_unit.weighing != null">
                      {{ study_unit.weighing | Round }}%
                    </span>
                    <span v-else>N/A</span>
                  </b-td>
                  <!-- logro UC -->
                  <b-td
                    class="td-class"
                    :style="`background-color: ${
                      assessment_switches_type
                        ? competence_unit.summative_achievement_color
                        : competence_unit.formative_achievement_color
                    } !important`"
                    :rowspan="competence_unit.study_units.length"
                    v-if="index2 == 0"
                  >
                    <span v-if="assessment_switches_type">
                      {{ competence_unit.summative_achievement | Round }}%
                    </span>
                    <span v-else>
                      {{ competence_unit.formative_achievement | Round }}%
                    </span>
                  </b-td>
                  <!-- ponderación UC -->
                  <b-td
                    class="td-class"
                    :rowspan="competence_unit.study_units.length"
                    v-if="index2 == 0"
                  >
                    {{ competence_unit.weighing | Round }}%
                  </b-td>
                  <!-- logro matter -->
                  <b-td
                    class="td-class"
                    :rowspan="getMatterRowspan()"
                    v-if="
                      institution &&
                      institution.internal_use_id == 'ciisa_uss' &&
                      index1 == 0 &&
                      index2 == 0 &&
                      assessment_report &&
                      assessment_report.matter
                    "
                    :style="`background-color: ${
                      assessment_switches_type
                        ? assessment_report.matter.summative_achievement_color
                        : assessment_report.matter.formative_achievement_color
                    } !important`"
                  >
                    <template v-if="assessment_report.matter">
                      <span v-if="assessment_switches_type">
                        {{
                          assessment_report.matter.summative_achievement
                            | Round
                        }}%
                      </span>
                      <span v-else>
                        {{
                          assessment_report.matter.formative_achievement
                            | Round
                        }}%
                      </span>
                    </template>
                    <template v-else>-</template>
                  </b-td>
                </b-tr>
              </template>
            </template>
          </template>
          <template
            v-for="(study_unit, index2) in assessment_report.study_units"
          >
            <b-tr
              v-for="(
                evaluation_criteria, index3
              ) in study_unit.evaluation_criterias_micro"
              :key="'study_unit:' + index2 + 'evaluation_criteria:' + index3"
            >
              <!-- enunciado UC-->
              <b-td
                class="td-class"
                :rowspan="study_unit.evaluation_criterias_micro.length"
                v-if="
                  institution &&
                  institution.internal_use_id == 'ciisa_uss' &&
                  index3 == 0
                "
              >
                N/A
              </b-td>

              <!-- enunciado RA-->
              <b-td
                class="td-class text-left"
                :rowspan="study_unit.evaluation_criterias_micro.length"
                v-if="index3 == 0"
              >
                <span>
                  <span
                    v-if="study_unit.name && study_unit.name.trim() != ''"
                    class="study-unit"
                    v-b-tooltip.v-secondary.noninteractive.hover.right="
                      study_unit.full_sentence
                    "
                  >
                    {{ study_unit.name }}
                  </span>
                  <span v-else>
                    <SentenceContainer
                      :Sentence="study_unit"
                      :Large="55"
                      :boundedTextContainer="false"
                      :showCognitiveLevel="false"
                    >
                      <template v-slot:order>
                        <span class="evaluation-criteria-order">
                          {{ study_unit.order }}
                        </span>
                      </template>
                    </SentenceContainer>
                  </span>
                </span>
              </b-td>
              <!-- enunciado IL-->
              <b-td class="td-class text-justify pl-2">
                <ContainerCell overflow_y="auto" max_height="130px">
                  <span class="d-flex">
                    <SentenceContainer
                      :Large="55"
                      :Sentence="evaluation_criteria"
                      :boundedTextContainer="false"
                      :showCognitiveLevel="false"
                    >
                      <template v-slot:order>
                        <span class="evaluation-criteria-order">
                          {{ study_unit.order }}.{{ evaluation_criteria.order }}
                        </span>
                      </template>
                    </SentenceContainer>
                  </span>
                </ContainerCell>
              </b-td>
              <!-- logro IL -->
              <b-td
                class="td-class"
                :style="`background-color: ${
                  assessment_switches_type
                    ? evaluation_criteria.summative_achievement_color
                    : evaluation_criteria.formative_achievement_color
                } !important`"
              >
                <span v-if="assessment_switches_type">
                  {{ evaluation_criteria.summative_achievement | Round }}%
                </span>
                <span v-else>
                  {{ evaluation_criteria.formative_achievement | Round }}%
                </span>
              </b-td>
              <!--ponderación IL-->
              <b-td class="td-class">
                <span v-if="evaluation_criteria.weighing != null">
                  {{ evaluation_criteria.weighing | Round }}%
                </span>
                <span v-else>N/A</span>
              </b-td>
              <!-- logro RA -->
              <b-td
                class="td-class"
                :style="`background-color: ${
                  assessment_switches_type
                    ? study_unit.summative_achievement_color
                    : study_unit.formative_achievement_color
                } !important`"
                :rowspan="study_unit.evaluation_criterias_micro.length"
                v-if="index3 == 0"
              >
                <span v-if="assessment_switches_type">
                  {{ study_unit.summative_achievement | Round }}%
                </span>
                <span v-else>
                  {{ study_unit.formative_achievement | Round }}%
                </span>
              </b-td>
              <!-- ponderación RA-->
              <b-td
                v-if="
                  index3 == 0 &&
                  institution &&
                  institution.internal_use_id != 'duoc_uc'
                "
                class="td-class"
                :rowspan="study_unit.evaluation_criterias_micro.length"
              >
                <span v-if="study_unit.weighing != null">
                  {{ study_unit.weighing | Round }}%
                </span>
                <span v-else>N/A</span>
              </b-td>
              <!-- logro UC -->
              <b-td
                v-if="
                  institution &&
                  institution.internal_use_id == 'ciisa_uss' &&
                  index3 == 0
                "
                class="td-class"
                :rowspan="study_unit.evaluation_criterias_micro.length"
              >
                N/A
              </b-td>
              <!-- ponderación UC-->
              <b-td
                v-if="
                  institution &&
                  institution.internal_use_id == 'ciisa_uss' &&
                  index3 == 0
                "
                class="td-class"
                :rowspan="study_unit.evaluation_criterias_micro.length"
              >
                N/A
              </b-td>
              <!-- logro matter -->
              <b-td
                class="td-class"
                :rowspan="getMatterRowspan()"
                v-if="
                  institution &&
                  institution.internal_use_id == 'ciisa_uss' &&
                  index3 == 0 &&
                  index2 == 0 &&
                  assessment_report &&
                  assessment_report.matter
                "
                :style="`background-color: ${
                  assessment_switches_type
                    ? assessment_report.matter.summative_achievement_color
                    : assessment_report.matter.formative_achievement_color
                } !important`"
              >
                <template v-if="assessment_report.matter">
                  <span v-if="assessment_switches_type">
                    {{
                      assessment_report.matter.summative_achievement | Round
                    }}%
                  </span>
                  <span v-else>
                    {{
                      assessment_report.matter.formative_achievement | Round
                    }}%
                  </span>
                </template>
                <template v-else>-</template>
              </b-td>
            </b-tr>
          </template>
        </template>
        <template v-else>
          <b-tr
            v-if="
              (!hidden_assessment_formative && !assessment_switches_type) ||
              (!hidden_assessment_sumative && assessment_switches_type)
            "
          >
            <b-td colspan="10" class="td-class"> No hay datos que mostrar</b-td>
          </b-tr>
          <b-tr
            v-else-if="
              (hidden_assessment_formative && !assessment_switches_type) ||
              (hidden_assessment_sumative && assessment_switches_type)
            "
          >
            <td colspan="10">
              <div class="progress" style="height: 1.5rem">
                <div
                  class="progress-bar progress-bar-striped progress-bar-animated bg-secondary"
                  role="progressbar"
                  style="width: 100%"
                >
                  Evaluación en Proceso...
                </div>
              </div>
            </td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
    <template v-if="assessment_report != null">
      <b-card
        v-for="competence_unit in assessment_report.competence_units"
        :key="competence_unit.id"
        no-header
        no-body
        class="text-left footer-card mt-2"
      >
        <span class="card-custom-title d-flex">
          {{
            $getVisibleNames(
              "teaching.tempcompetenceunit",
              assessment_report.competence_units.length > 1,
              ""
            ).toUpperCase()
          }}:
          <SentenceContainer
            class="evaluation-criteria-order-2 ml-1"
            :Sentence="competence_unit"
            :showCognitiveLevel="false"
          >
            <template v-slot:order>
              {{ competence_unit.order }}
            </template>
          </SentenceContainer>
        </span>
        <b-list-group flush>
          <b-list-group-item class="p-0 px-1 pb-2">
            <b-card
              v-if="competence_unit.study_units.length > 0"
              no-header
              no-body
              class="text-left footer-card mt-2"
            >
              <span class="card-custom-title">
                {{
                  $getVisibleNames(
                    "teaching.ramicro",
                    false,
                    competence_unit.study_units.length > 1
                      ? "Resultados de Aprendizaje Micro"
                      : "Resultado de Aprendizaje Micro"
                  ).toUpperCase()
                }}
              </span>
              <b-list-group flush>
                <b-list-group-item
                  v-for="item in competence_unit.study_units"
                  :key="item.id"
                >
                  <div class="d-flex">
                    <span
                      class="study-unit-name mr-1"
                      v-if="item.name && item.name.trim() != ''"
                      >{{ item.name }}:</span
                    >
                    <div class="ml-1 ra-micro-sentence">
                      <SentenceContainer
                        :Large="55"
                        class="evaluation-criteria-order-2 ml-1"
                        :Sentence="item"
                        :boundedTextContainer="false"
                        :showCognitiveLevel="false"
                      >
                        <template v-slot:order>
                          {{ competence_unit.order }}.{{ item.order }}.
                        </template>
                      </SentenceContainer>
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-list-group-item>
        </b-list-group>
      </b-card>
      <b-card
        v-if="assessment_report.study_units.length > 0"
        no-header
        no-body
        class="text-left footer-card mt-2"
      >
        <span class="card-custom-title">
          {{
            $getVisibleNames(
              "teaching.ramicro",
              false,
              assessment_report.study_units.length > 1
                ? "Resultados de Aprendizaje Micro"
                : "Resultado de Aprendizaje Micro"
            ).toUpperCase()
          }}
        </span>
        <b-list-group flush>
          <b-list-group-item
            v-for="item in assessment_report.study_units"
            :key="item.id"
          >
            <div class="d-flex">
              <span
                class="study-unit-name mr-1"
                v-if="item.name && item.name.trim() != ''"
                >{{ item.name }}:</span
              >
              <div class="ml-1 ra-micro-sentence">
                <SentenceContainer
                  :Large="55"
                  class="evaluation-criteria-order-2 ml-1"
                  :Sentence="item"
                  :boundedTextContainer="false"
                  :showCognitiveLevel="false"
                >
                  <template v-slot:order> {{ item.order }}. </template>
                </SentenceContainer>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </template>
  </div>
</template>

<script>
// import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "AssessmentStudentTable",
  components: {
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    ContainerCell: () => import("@/components/reusable/ContainerCell"),
  },
  props: {
    section_id: {
      type: Number,
      required: true,
    },
    matter: {
      type: Object,
      required: true,
    },
    student_selected: {
      type: Number,
      required: true,
    },
    achievement_range_group: {
      default: null,
    },
    hidden_assessment_formative: { type: Boolean, default: false },
    hidden_assessment_sumative: { type: Boolean, default: false },
  },
  data() {
    return {
      assessment_report: null,
      assessment_switches_type: true,
      is_loading: false, // Variable para mostrar "skeletons"
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
    }),
  },
  methods: {
    getMatterRowspan() {
      if (this.assessment_report == null) return 1;
      let rowspan = this.assessment_report.competence_units.reduce(
        (total, competence_unit) =>
          total +
          competence_unit.study_units.reduce(
            (acc2, study_unit) =>
              acc2 + study_unit.evaluation_criterias_micro.length,
            0
          ),
        0
      );
      rowspan += this.assessment_report.study_units.reduce(
        (acc, study_unit) => acc + study_unit.evaluation_criterias_micro.length,
        0
      );
      return rowspan;
    },
    fetchAssessmentReport() {
      this.is_loading = true;
      if (
        this.section_id != null &&
        this.student_selected != null &&
        this.institution
      ) {
        const achievement_range_group =
          this.achievement_range_group != null
            ? `&achievement_range_group=${this.achievement_range_group}`
            : "";
        if (this.institution.internal_use_id == "ciisa_uss") {
          this.$restful
            .Get(
              `/assessment2/evaluatee_assessments_per_matter_ipss/?evaluatee=${this.student_selected}&section=${this.section_id}${achievement_range_group}`
            )
            .then((response) => {
              this.is_loading = false;
              this.assessment_report = response;
            })
            .catch(() => {
              this.is_loading = false;
            });
        } else if (this.institution.internal_use_id == "duoc_uc") {
          this.$restful
            .Get(
              `/assessment2/evaluatee_assessments_per_matter_duoc/?evaluatee=${this.student_selected}&section=${this.section_id}${achievement_range_group}`
            )
            .then((response) => {
              this.is_loading = false;
              this.assessment_report = response;
              this.assessment_report.competence_units = [];
            })
            .catch(() => {
              this.is_loading = false;
            });
        } else this.is_loading = false;
      }
    },
  },
  created() {
    this.fetchAssessmentReport();
  },
};
</script>

<style scoped>
.table-assessment {
  border-collapse: collapse; /* Combina los bordes adyacentes */
}

.table-assessment td,
.table-assessment th {
  border: 1px solid #948b8b;
}
table thead >>> .th-class,
.th-class-2 {
  background-color: var(--kl-menu-color) !important;
  color: white;
  vertical-align: middle !important;
}
.td-class {
  vertical-align: middle !important;
}
.evaluation-criteria-order {
  font-size: 11pt;
}
.ra-micro-sentence {
  display: flex;
  font-size: 11pt;
}
.evaluation-criteria-order-2 {
  font-size: 11pt;
}
.study-unit,
.ce-macro {
  transition: ease-in-out 0.3s;
  text-decoration: underline;
  font-size: 11pt;
}
.study-unit:hover,
.ce-macro {
  color: #0d1525;
  transition: 0.2s;
  cursor: help;
}
.footer-card {
  padding: 0;
  background: var(--kl-menu-color);
}
.card-custom-title {
  padding: 0.5em;
  color: var(--secondary-font-color);
  font-weight: bold;
}
.study-unit-name {
  font-size: 11pt;
  width: 15%;
}
</style>

