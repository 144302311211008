<template>
  <div>
    <draggable
      v-model="questions"
      :disabled="!allows_crud"
      v-bind="dragOptions"
      class="list-group"
      group="question"
      handle=".handle"
    >
      <transition-group type="transition" name="flip-list">
        <template>
          <div
            class="container-question"
            v-for="question in questions"
            :key="`question-dragable-${question.id}-${question.test}-${question.question_type}`"
          >
            <NewQuestionCard
              :ref="`question-dragable-${question.id}-${question.test}-${question.question_type}`"
              :container_instrument_id="container_instrument_id"
              :allows_crud="allows_crud"
              :Question="question"
              :isLoading="isLoading"
              :evaluation_criteria_micro_ids="evaluation_criteria_micro_ids"
              :preview_evaluation="preview_evaluation"
              :checked_score="checked_score"
              :checked_weightning="checked_weightning"
              :evaluatee_view="evaluatee_view"
              :evaluator_view="evaluator_view"
              :is_running_test="is_running_test"
              :finished_test="finished_test"
              :user_id="user_id"
              :evaluation_situations="evaluation_situations"
              :closedEvaluationScore="closedEvaluationScore"
              :is_published="is_published"
            ></NewQuestionCard>
          </div>
        </template>
      </transition-group>
    </draggable>
    <div class="container-buttons mb-2">
      <b-button
        v-if="
          allows_crud &&
          test &&
          !test.question_creation_is_locked &&
          (!evaluationSituation ||
            (evaluationSituation &&
              ![
                'duoc_ejecucion_practica_test',
                'duoc_entrega_de_encargo_test',
                'duoc_presentacion_test',
              ].includes(evaluationSituation.internal_use_id) &&
              evaluationSituation.enable_questions &&
              (evaluationSituation.enable_redaction_questions ||
                evaluationSituation.enable_simple_selection_questions ||
                evaluationSituation.enable_multiple_selection_questions ||
                evaluationSituation.enable_matching_questions)))
        "
        variant="primary"
        size="sm"
        class="mt-2"
        @click="openModalCreateQuestion"
      >
        + Agregar Pregunta
        <span v-if="segment_id"
          >dentro del
          {{
            $getVisibleNames("evaluations2.testsegment", false, "Segmento")
          }}</span
        >
      </b-button>
    </div>
    <b-modal
      :id="`modal-created-question-${instrument_id}-${segment_id}-${container_instrument_id}`"
      :title="`Crear Pregunta`"
      hide-footer
      size="lg"
      no-enforce-focus
      @hide="hideModalQuestion"
    >
      <NewQuestionForm
        :test_id="instrument_id"
        :segment_id="segment_id"
        :evaluation_criteria_micro_ids="evaluation_criteria_micro_ids"
        :order="
          questions.length > 0 ? questions[questions.length - 1].order + 1 : 1
        "
        :evaluation_situations="evaluation_situations"
        @closeModal="
          $bvModal.hide(
            `modal-created-question-${instrument_id}-${segment_id}-${container_instrument_id}`
          )
        "
      ></NewQuestionForm>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "QuestionsContainer",
  components: {
    draggable,
    NewQuestionCard: () => import("./NewQuestionCard"),
    NewQuestionForm: () =>
      import(
        "@/components/new-evaluations/Evaluation/Questions/NewQuestionForm"
      ),
  },
  props: {
    instrument_id: { type: Number, required: true },
    segment_id: { type: [Number, null], default: null },
    allows_crud: { type: Boolean, required: true },
    isLoading: { type: Boolean, required: true },
    preview_evaluation: {
      type: Boolean,
      required: true,
    },
    container_instrument_id: {
      type: String,
    },
    checked_score: {
      type: Boolean,
    },
    evaluation_criteria_micro_ids: {
      type: Array,
      required: true,
    },
    checked_weightning: {
      type: Boolean,
    },
    evaluatee_view: {
      type: Boolean,
      default: false,
    },
    evaluator_view: {
      type: Boolean,
      default: false,
    },
    is_running_test: {
      type: Boolean,
      default: false,
    },
    finished_test: {
      type: Boolean,
      default: false,
    },
    // para mostrar el resultado del evaluado al profesor.
    user_id: {
      type: Number,
      required: false,
    },
    // para restringir que el evaluador pueda colocar notas fuera del periodo actual con respecto al de la sección.
    closedEvaluationScore: {
      type: Boolean,
      default: false,
    },
    evaluation_situations: {
      type: Array,
    },
    is_published: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      redaction_questions: names.NEW_REDACTION_QUESTIONS,
      multiple_choice_questions: names.NEW_MULTIPLE_CHOICE_QUESTIONS,
      filling_questions: names.NEW_FILLING_QUESTIONS,
      matching_questions: names.NEW_MATCHING_QUESTIONS,
      tests: names.NEW_TESTS,
      evaluations: names.NEW_EVALUATIONS,
      egress_profile_matters: names.EGRESS_PROFILES_MATTERS,
      sections: names.SECTIONS,
    }),
    test() {
      return this.tests.find((x) => x.id == this.instrument_id);
    },
    evaluationSituation() {
      if (!this.test) return null;
      return this.evaluation_situations.find(
        (x) => x.id == this.test.evaluation_situation
      );
    },
    evaluation() {
      if (!this.test) return null;
      return this.evaluations.find((x) => x.id == this.test.evaluation);
    },
    section() {
      if (this.evaluation && this.evaluation.scope == 1)
        return this.sections.find((x) => x.id == this.evaluation.scope_id);
      else return null;
    },
    egressProfileMatter() {
      if (
        this.evaluation &&
        this.evaluation.scope == 2 &&
        this.evaluation.scope_id != null
      ) {
        return this.egress_profile_matters.find(
          (x) => x.id == this.evaluation.scope_id
        );
      }
      if (
        this.evaluation &&
        this.evaluation.scope == 1 &&
        this.section &&
        this.section.egress_profile_matter != null
      ) {
        return this.egress_profile_matters.find(
          (x) => x.id == this.section.egress_profile_matter
        );
      }
      return null;
    },
    questions: {
      get() {
        let list = [];
        list = list.concat(
          this.redactionQuestions.map((question) => ({
            ...question,
            question_type: 1,
          }))
        );
        list = list.concat(
          this.multipleChoiceQuestions.map((question) => ({
            ...question,
            question_type: 2,
          }))
        );
        // list = list.concat(
        //   this.fillingQuestions.map((question) => ({
        //     ...question,
        //     question_type: 3,
        //   }))
        // );
        list = list.concat(
          this.matchingQuestions.map((question) => ({
            ...question,
            question_type: 4,
          }))
        );
        return list.sort((a, b) => a.order - b.order);
      },
      set(list) {
        list.forEach((element, index) => {
          if (!isNaN(element.id)) {
            if (element.question_type == 1) {
              let payload = {
                new_redaction_question_id: element.id,
                item: {
                  order: index + 1,
                  segment: this.segment_id,
                },
              };
              this.$store.dispatch(names.PATCH_NEW_REDACTION_QUESTION, payload);
            } else if (element.question_type == 2) {
              let payload = {
                new_multiple_choice_question_id: element.id,
                item: {
                  order: index + 1,
                  segment: this.segment_id,
                },
              };
              this.$store.dispatch(
                names.PATCH_NEW_MULTIPLE_CHOICE_QUESTION,
                payload
              );
            } else if (element.question_type == 3) {
              let payload = {
                new_filling_question_id: element.id,
                item: {
                  order: index + 1,
                  segment: this.segment_id,
                },
              };
              this.$store.dispatch(names.PATCH_NEW_FILLING_QUESTION, payload);
            } else if (element.question_type == 4) {
              let payload = {
                new_matching_question_id: element.id,
                item: {
                  order: index + 1,
                  segment: this.segment_id,
                },
              };
              this.$store.dispatch(names.PATCH_NEW_MATCHING_QUESTION, payload);
            }
          }
        });
      },
    },
    dragOptions() {
      return {
        animation: 0,
        group: "question",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    redactionQuestions() {
      return this.redaction_questions.filter(
        (x) => x.test == this.instrument_id && x.segment == this.segment_id
      );
    },
    multipleChoiceQuestions() {
      return this.multiple_choice_questions.filter(
        (x) => x.test == this.instrument_id && x.segment == this.segment_id
      );
    },
    fillingQuestions() {
      return this.filling_questions.filter(
        (x) => x.test == this.instrument_id && x.segment == this.segment_id
      );
    },
    matchingQuestions() {
      return this.matching_questions.filter(
        (x) => x.test == this.instrument_id && x.segment == this.segment_id
      );
    },
  },
  methods: {
    insertStoreOpenAiQuestion() {
      this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
      this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);

      this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
        visible_intelligence_options: [8],
        disabled_selected_intelligence_option: true,
      });
    },
    openModalCreateQuestion() {
      this.insertStoreOpenAiQuestion();
      if (
        this.evaluation &&
        this.evaluation.scope == 3 &&
        this.evaluation.scope_id != null
      ) {
        this.$nextTick(() => {
          this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
            matter_id: this.evaluation.scope_id,
            available_evaluation_criteria_micro:
              this.evaluation_criteria_micro_ids,
            extra_label: `Pregunta`,
            extra_context: "Para la Evaluación",
            intelligence_option: 8,
            sub_option: 3,
            show_message_evaluation_view: true,
          });
        });
      }
      if (
        this.egressProfileMatter &&
        this.evaluation &&
        (this.evaluation.scope == 2 || this.evaluation.scope == 1) &&
        this.evaluation.scope_id != null
      ) {
        this.$nextTick(() => {
          this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
            matter_id: this.egressProfileMatter.matter,
            available_evaluation_criteria_micro:
              this.evaluation_criteria_micro_ids,
            extra_label: `Pregunta`,
            extra_context: "Para la Evaluación",
            intelligence_option: 8,
            sub_option: 3,
            show_message_evaluation_view: true,
          });
        });
      }
      this.$bvModal.show(
        `modal-created-question-${this.instrument_id}-${this.segment_id}-${this.container_instrument_id}`
      );
    },
    hideModalQuestion() {
      this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
      this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);
      this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
        visible_intelligence_options: [7],
      });

      if (
        this.evaluation &&
        this.evaluation.scope == 3 &&
        this.evaluation.scope_id != null
      ) {
        this.$nextTick(() => {
          this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
            matter_id: this.evaluation.scope_id,
            extra_context: "Para la Evaluación",
            extra_label: "",
            show_message_evaluation_view: true,
            intelligence_option: 7,
          });
        });
      }
      if (
        this.egressProfileMatter &&
        this.evaluation &&
        (this.evaluation.scope == 2 || this.evaluation.scope == 1) &&
        this.evaluation.scope_id != null
      ) {
        this.$nextTick(() => {
          this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
            matter_id: this.egressProfileMatter.matter,
            extra_context: "Para la Evaluación",
            show_message_evaluation_view: true,
            intelligence_option: 7,
          });
        });
      }
    },
    scrollIntoView(payload) {
      this.$nextTick(() => {
        setTimeout(() => {
          const reft =
            this.$refs[
              `question-dragable-${payload.question.id}-${payload.question.test}-${payload.question.question_type}`
            ];
          if (reft && reft.length > 0 && reft[0].$el)
            reft[0].$el.scrollIntoView(payload.options); // Hacer scroll al elemento
        }, 500); // Retraso de 100 ms
      });
    },
  },
  created() {},
};
</script>

<style scoped>
.container-question {
  background: white;
  border-radius: 5px;
}
.container-buttons {
  display: flex;
  justify-content: center;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>

