var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.institution && _vm.institution.internal_use_id == 'duoc_uc')?_c('div',{staticClass:"d-flex"},[(_vm.work_types.length > 0)?_c('div',{staticClass:"w-100"},[_c('b-table-simple',{attrs:{"bordered":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"work-type-th text-center",attrs:{"colspan":_vm.work_types.length}},[_vm._v("TIPOS DE TRABAJO DE "+_vm._s(_vm.$getVisibleNames( "evaluations2.evaluation", true, "Evaluaciones" ).toUpperCase())+" SUMATIVAS ")])],1),_c('b-tr',_vm._l((_vm.work_types),function(work_type){return _c('b-th',{key:'work_type_value:' + work_type.id,staticClass:"work-type-th"},[_vm._v(_vm._s(work_type.value)+" ")])}),1),_c('b-tr',_vm._l((_vm.work_types),function(work_type){return _c('b-td',{key:'work_type_id:' + work_type.id,staticClass:"work-type-td"},[_vm._v(_vm._s(_vm.totalWeighingByWorkType(work_type.id))+"% ")])}),1)],1)],1)],1):_vm._e()]):_vm._e(),_c('b-table-simple',{attrs:{"small":"","responsive":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"pl-3 evaluation-header",staticStyle:{"text-align":"center"},attrs:{"colspan":_vm.allows_crud ? 10 : 9}},[_vm._v(" ACTIVIDADES Y EVALUACIONES "),_c('div',{staticClass:"evaluation-button-div"},[(_vm.allows_crud)?_c('b-button',{staticClass:"mx-1",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(`add-matter-evaluation-modal-${_vm.matter_id}`)}}},[_vm._v("Crear Actividad o Evaluación")]):_vm._e()],1)])],1),_c('b-tr',[(_vm.allows_crud && _vm.filterMatterEvaluations.length > 0)?_c('b-td',{staticClass:"text-center",staticStyle:{"width":"3%"}}):_vm._e(),_c('b-td',{staticClass:"text-center",staticStyle:{"width":"12%"}},[_c('strong',[_vm._v(" NOMBRE ")])]),(
            _vm.institution &&
            _vm.institution.internal_use_id != 'duoc_uc' &&
            _vm.institution.kl_assessment
          )?_c('b-td',{staticClass:"text-center",staticStyle:{"width":"10%"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "evaluations2.test", true, "Instrumentos" ).toUpperCase())+" ")])]):_vm._e(),_c('b-td',{staticClass:"text-center",staticStyle:{"width":"15%"}},[_c('strong',[_vm._v(" DESCRIPCIÓN ")])]),_c('b-td',{staticClass:"text-center",staticStyle:{"width":"5%"}},[_c('strong',[_vm._v(" TIPO ")])]),_c('b-td',{staticClass:"text-center",staticStyle:{"width":"3%"}},[_c('strong',[_vm._v("PONDERACIÓN")])]),_c('b-td',{staticClass:"text-center",staticStyle:{"width":"7%"}},[_c('strong',[_vm._v("HORAS DOCENCIA DIRECTA (HORAS PEDAGÓGICAS)")])]),_c('b-td',{staticClass:"text-center",staticStyle:{"width":"7%"}},[_c('strong',[_vm._v("HORAS DE TRABAJO AUTÓNOMO (HORAS CRONOLÓGICAS)")])]),(_vm.institution && _vm.institution.show_evaluation_criteria_macro)?_c('b-td',{staticClass:"text-center",staticStyle:{"width":"35%"},attrs:{"colspan":"1"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "teaching.evaluationcriteriamacro", true, "Criterios de Evaluación Macro" ).toUpperCase()))])]):_vm._e(),_c('b-td',{staticClass:"text-center",staticStyle:{"width":"15%"},attrs:{"colspan":"1"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "teaching.evaluationcriteriamicro", true, "Criterios de Evaluación Micro" ).toUpperCase()))])]),(_vm.allows_crud && _vm.filterMatterEvaluations.length > 0)?_c('b-td',{staticStyle:{"width":"2%"}}):_vm._e()],1)],1),_vm._l((_vm.filterLearningExperience),function(item){return [(
          _vm.filterMatterEvaluations.filter(
            (x) => x.learning_experience == item.id
          ).length > 0
        )?[_c('b-thead',{key:'learning-experiences-1' + item.id},[_c('b-tr',[_c('b-th',{staticClass:"pl-3 evaluation-header",staticStyle:{"text-align":"left"},attrs:{"colspan":_vm.allows_crud ? 10 : 9}},[_vm._v(_vm._s(item.name)+" ")])],1)],1),_c('MatterProgramEvaluationDraggable',{key:_vm.key_matter_program_draggable + 'learning-experiences-2' + item.id,attrs:{"allows_crud":_vm.allows_crud,"learning_experience":item.id,"matter_id":_vm.matter_id,"EvaluationSituations":_vm.evaluation_situations,"EvaluativeAgentTypes":_vm.evaluative_agent_types,"MatEvaStuEnvTimAll":_vm.mat_eva_stu_env_tim_all},on:{"created_support_resource":_vm.createdSupportResource,"fetch_mat_eva_stu_env_tim_all":_vm.fetchMatEvaStuEnvTimAll}})]:_vm._e()]}),(
        _vm.filterMatterEvaluations.filter(
          (x) => x.learning_experience == null && x.type != 5
        ).length > 0
      )?[(_vm.filterLearningExperience.length > 0)?_c('b-thead',{key:'learning-experiences-1.1'},[_c('b-tr',[_c('b-th',{staticClass:"p-3 evaluation-header",staticStyle:{"text-align":"left"},attrs:{"colspan":_vm.allows_crud ? 10 : 9}})],1)],1):_vm._e(),_c('MatterProgramEvaluationDraggable',{key:_vm.key_matter_program_draggable + 'learning-experiences-2.1',attrs:{"allows_crud":_vm.allows_crud,"learning_experience":null,"include_transversal_evaluation":false,"matter_id":_vm.matter_id,"EvaluationSituations":_vm.evaluation_situations,"EvaluativeAgentTypes":_vm.evaluative_agent_types,"MatEvaStuEnvTimAll":_vm.mat_eva_stu_env_tim_all},on:{"created_support_resource":_vm.createdSupportResource,"fetch_mat_eva_stu_env_tim_all":_vm.fetchMatEvaStuEnvTimAll}})]:_vm._e(),(
        _vm.institution &&
        _vm.institution.internal_use_id == 'duoc_uc' &&
        _vm.filterMatterEvaluations.length > 0
      )?_c('b-tr',[_c('b-td',{staticClass:"p-3 text-right",attrs:{"colspan":"9"}},[_c('strong',[_vm._v("TOTAL 60% DE LA ASIGNATURA")])])],1):_vm._e(),(
        _vm.filterMatterEvaluations.filter(
          (x) => x.learning_experience == null && x.type == 5
        ).length > 0
      )?[(_vm.filterLearningExperience.length > 0)?_c('b-thead',{key:'learning-experiences-1.2'},[_c('b-tr',[_c('b-th',{staticClass:"p-3 evaluation-header",staticStyle:{"text-align":"left"},attrs:{"colspan":_vm.allows_crud ? 10 : 9}})],1)],1):_vm._e(),_c('MatterProgramEvaluationDraggable',{key:_vm.key_matter_program_draggable + 'learning-experiences-2.2',attrs:{"allows_crud":_vm.allows_crud,"learning_experience":null,"include_transversal_evaluation":true,"matter_id":_vm.matter_id,"EvaluationSituations":_vm.evaluation_situations,"EvaluativeAgentTypes":_vm.evaluative_agent_types,"MatEvaStuEnvTimAll":_vm.mat_eva_stu_env_tim_all},on:{"created_support_resource":_vm.createdSupportResource,"fetch_mat_eva_stu_env_tim_all":_vm.fetchMatEvaStuEnvTimAll}})]:_vm._e(),(
        _vm.institution &&
        _vm.institution.internal_use_id == 'duoc_uc' &&
        _vm.filterMatterEvaluations.find((x) => x.type == 5)
      )?_c('b-tr',[_c('b-td',{staticClass:"p-3 text-right",attrs:{"colspan":"9"}},[_c('strong',[_vm._v("TOTAL 40% DE LA ASIGNATURA")])])],1):_vm._e(),(_vm.filterMatterEvaluations.length == 0)?_c('b-tbody',[_c('b-tr',[_c('b-td',{staticClass:"text-center",attrs:{"colspan":"10"}},[_vm._v("No hay actividades o evaluaciones para mostrar.")])],1)],1):_vm._e()],2),(
      _vm.institution &&
      _vm.institution.internal_use_id != 'duoc_uc' &&
      _vm.institution.kl_assessment
    )?_c('ILIndicatorsUsage',{key:_vm.evaluation_ids.join(','),attrs:{"matter_id":_vm.matter_id,"evaluations_ids":_vm.evaluation_ids,"fetch_evaluations":true,"fetch_evaluation_criteria_micro":false,"fetch_competence_unit":false,"show_taxonomy_color":true}}):_vm._e(),_c('b-modal',{attrs:{"id":`add-matter-evaluation-modal-${_vm.matter_id}`,"title":"Crear Actividad o Evaluación","size":"xl","hide-footer":""},on:{"hide":_vm.confirmClose}},[_c('MatterProgramEvaluationForm',{key:_vm.key_matter_program_draggable,attrs:{"matter_id":_vm.matter_id,"evaluation_list":_vm.filterMatterEvaluations,"EvaluationSituations":_vm.evaluation_situations,"EvaluativeAgentTypes":_vm.evaluative_agent_types,"MatEvaStuEnvTimAll":_vm.mat_eva_stu_env_tim_all},on:{"created":_vm.createdEvaluation,"created_support_resource":_vm.createdSupportResource,"change_matter_evaluation_form":() => (_vm.change_matter_evaluation_form = true)}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }