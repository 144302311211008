var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex"},[_c('ButtonDownload',{class:`ml-auto mb-2 mr-3`,attrs:{"tooltip_text":`${_vm.$getVisibleNames(
        'mesh.career',
        true,
        'Programas'
      )} XLSX`,"click_button":_vm.DownloadCareersXLS}})],1),_c('div',[_c('b-table-simple',{attrs:{"bordered":"","no-border-collapse":"","small":"","responsive":"","sticky-header":_vm.stickyHeaderSize}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"text-center align-middle primary-header"},[_vm._v(" Número de Currículum ")]),_c('b-th',{staticClass:"text-center align-middle primary-header"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.career", false, "Programa"))+" ")]),_c('b-th',{staticClass:"text-center align-middle primary-header"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.faculty", false, "Escuela"))+" ")]),_c('b-th',{staticClass:"text-center align-middle primary-header"},[_vm._v(" Entrada Vigencia ")]),_c('b-th',{staticClass:"text-center align-middle primary-header"},[_vm._v(" Nivel ")]),(_vm.institution && _vm.institution.internal_use_id == 'duoc_uc')?_c('b-th',{staticClass:"text-center align-middle primary-header"},[_vm._v(" Jornada ")]):_vm._e(),_c('b-th',{staticClass:"text-center align-middle primary-header"},[_vm._v(" Modalidad ")]),_c('b-th',{staticClass:"text-center align-middle primary-header"},[_vm._v(" Título que otorga ")]),_c('b-th',{staticClass:"text-center align-middle primary-header"},[_vm._v(" Duración ")]),(_vm.institution && _vm.institution.internal_use_id == 'duoc_uc')?_c('b-th',{staticClass:"text-center align-middle primary-header"},[_vm._v(" Área de escuela ")]):_vm._e(),(_vm.institution && _vm.institution.internal_use_id == 'duoc_uc')?_c('b-th',{staticClass:"text-center align-middle primary-header"},[_vm._v(" KF ")]):_vm._e()],1)],1),_c('b-tbody',[(_vm.careers_report == null || _vm.careers_report.length == 0)?[_c('b-tr',[_c('b-td',{attrs:{"colspan":_vm.institution && _vm.institution.internal_use_id == 'duoc_uc'
                  ? 11
                  : 8}},[_c('strong',[_vm._v(" No hay datos para mostrar ")])])],1)]:(_vm.careers_report.length > 0)?_vm._l((_vm.careers_report),function(egress_profile){return _c('b-tr',{key:egress_profile.id},[_c('b-td',{staticClass:"text-center aling-middle"},[_vm._v(_vm._s(egress_profile.egress_profile_code))]),_c('b-td',{staticClass:"text-left"},[_vm._v(_vm._s(egress_profile.career_name)+" ")]),_c('b-td',{staticClass:"text-left"},[_vm._v(_vm._s(egress_profile.faculty_name)+" ")]),_c('b-td',{staticClass:"text-center aling-middle"},[_vm._v(_vm._s(egress_profile.egress_profile_ini_date)+" ")]),_c('b-td',{staticClass:"text-center aling-middle"},[_vm._v(_vm._s(egress_profile.egress_profile_type_name)+" ")]),(_vm.institution && _vm.institution.internal_use_id == 'duoc_uc')?_c('b-td',{staticClass:"text-center aling-middle"},[_vm._v(_vm._s(egress_profile.ep_shift)+" ")]):_vm._e(),_c('b-td',{staticClass:"text-center aling-middle"},[_vm._v(_vm._s(egress_profile.ep_modality)+" ")]),_c('b-td',{staticClass:"text-left"},[_vm._v(_vm._s(egress_profile.professional_title)+" ")]),_c('b-td',{staticClass:"text-center aling-middle"},[_vm._v(_vm._s(egress_profile.semester_amount)+" ")]),(_vm.institution && _vm.institution.internal_use_id == 'duoc_uc')?_c('b-td',{staticClass:"text-center"},[_vm._v(_vm._s(egress_profile.faculty_area_name)+" ")]):_vm._e(),(_vm.institution && _vm.institution.internal_use_id == 'duoc_uc')?_c('b-td',{staticClass:"text-left"},[_vm._v(_vm._s(egress_profile.kl_column)+" ")]):_vm._e()],1)}):_vm._e()],2)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }