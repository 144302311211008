var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-center"},[(_vm.courses_wrapper_id && _vm.coursesWrapper)?_c('h4',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.coursewrapper", false, "Envoltorio de Asignaturas" ))+": "+_vm._s(_vm.coursesWrapper.name)+" ")]):_c('h4',{staticClass:"mt-4 mb-3"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("manual.matriz_2", false, "Matriz 2"))+": "+_vm._s(_vm.$getVisibleNames("mesh.egressprofilematter", true, "Asignaturas"))+" "),(_vm.career)?_c('span',[_vm._v(_vm._s(" de " + _vm.career.name)+" ")]):_vm._e()]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-flex align-items-center"},[(
            _vm.allows_crud &&
            ((_vm.user_position &&
              [1, 2, 3].includes(_vm.user_position.position) &&
              [1, 2, 3].includes(_vm.user.groups[0])) ||
              _vm.user.is_superuser ||
              _vm.$hasObjectPermission(
                'mesh.career',
                'change',
                _vm.egressProfile.career
              )) &&
            _vm.tabIndex == 0
          )?[_c('b-button',{staticClass:"mb-2 ml-3",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.$bvModal.show('new-matter-modal')}}},[_vm._v("Agregar "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofilematter", false, "Asignatura" )))])]:_vm._e(),(_vm.courses_wrapper_id == null)?_c('b-button',{staticClass:"mb-2 ml-3 noprint",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.$bvModal.show('course-wrapper-modal')}}},[_vm._v(_vm._s(_vm.$getVisibleNames( "mesh.coursewrapper", false, "Envoltorio de Asignaturas" )))]):_c('b-button',{staticClass:"mb-2 ml-3 noprint",attrs:{"variant":"secondary","size":"sm"},on:{"click":() => {
              _vm.$router.push({
                name: 'Matrix2View',
                params: {
                  egress_profile_id: _vm.egress_profile_id,
                },
              });
            }}},[_vm._v("Volver")]),(
            _vm.user &&
            _vm.user.is_superuser &&
            _vm.allows_crud &&
            _vm.courses_wrapper_id == null
          )?_c('RemoveEPMattersComponent',{attrs:{"egress_profile_id":_vm.egress_profile_id,"allows_crud":_vm.allows_crud}}):_vm._e()],2),(_vm.courses_wrapper_id == null)?[_c('div',[(
              (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
              _vm.$debug_change_duoc
            )?_c('b-button',{staticClass:"btn btn-primary mr-3 mb-1 btn-download-excel noprint",attrs:{"variant":"primary"},on:{"click":_vm.DownloadCompletedPMDXLS}},[_vm._v("COMPLETITUD PMD XLS")]):_vm._e(),_c('download-excel',{staticClass:"btn btn-primary mr-3 mb-1 btn-download-excel noprint",attrs:{"fields":_vm.jsonFields,"fetch":_vm.fetchMatterXLS,"worksheet":`REPORTE DE ${_vm.$getVisibleNames(
              'mesh.egressprofilematter',
              true,
              'Asignaturas'
            ).toUpperCase()}`,"name":`Reporte de ${_vm.$getVisibleNames(
              'mesh.egressprofilematter',
              true,
              'Asignaturas'
            )}.xls`}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofilematter", true, "Asignaturas" ).toUpperCase())+" XLS ")]),(
              (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
              _vm.$debug_change_duoc
            )?_c('b-button',{staticClass:"btn btn-primary mr-3 mb-1 btn-download-excel noprint",attrs:{"variant":"primary"},on:{"click":_vm.DownloadXLS}},[_vm._v("MAPA FORMATIVO XLS")]):_vm._e()],1)]:_vm._e()],2),_c('div',{staticClass:"container-v-select noprint"},[_c('span',{staticClass:"mt-1 ml-3 text-filter-formative-line"},[_c('strong',[_vm._v("Filtrar por "+_vm._s(_vm.$getVisibleNames( "mesh.formationline", false, "Línea De Formación" ))+":")])]),_c('v-select',{staticClass:"mr-3 ml-1 mt-2 v-select-class",staticStyle:{"width":"100%"},attrs:{"id":"select-selected_formative_lines","placeholder":`Seleccione uno o varios para filtrar.`,"options":_vm.formationLinesList,"reduce":(formationLinesList) => formationLinesList.id,"selectable":(option) => !_vm.selected_formative_lines.includes(option.id),"label":"name","track-by":"id","size":"sm","multiple":"","dropdown-should-open":_vm.dropdownShouldOpen},on:{"input":() => (_vm.visible_formation_line = true)},scopedSlots:_vm._u([{key:"no-options",fn:function({ search, searching }){return [(searching)?[_vm._v(" No se encontró resultados para "),_c('em',[_vm._v(_vm._s(search))]),_vm._v(". ")]:_vm._e()]}}]),model:{value:(_vm.selected_formative_lines),callback:function ($$v) {_vm.selected_formative_lines=$$v},expression:"selected_formative_lines"}})],1)]),_c('div',{staticClass:"table-responsive"},[_c('GenericBTable',{key:_vm.visible_formation_line,attrs:{"items":_vm.filterMatterList,"fields":_vm.fields,"filter":_vm.input_search,"filterCustom":_vm.filterCustom,"current-page":_vm.currentPage,"per-page":_vm.perPage,"pagination":50,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"busy":_vm.isBusy,"sort-direction":_vm.sortDirection,"delay_input":true,"show_pagination":true,"search_filter":true,"view_filter_by":true,"columns_display":true,"allows_crud":_vm.allows_crud &&
        ((_vm.user_position &&
          [1, 2, 3].includes(_vm.user_position.position) &&
          [1, 2, 3].includes(_vm.user.groups[0])) ||
          _vm.user.is_superuser ||
          _vm.$hasObjectPermission('mesh.career', 'change', _vm.egressProfile.career)),"display_id":'profiles-list',"sticky-header":_vm.stickyHeaderSize},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"filtered":_vm.onFiltered,"emitChangeSearchField":_vm.changeInputSearch,"emitChangeFilterOn":_vm.changeFilterOn},scopedSlots:_vm._u([{key:"cell(name)",fn:function(row){return [_c('div',{staticClass:"align-middle text-left",class:[row.item.id == _vm.last_matter ? 'new-matter-container' : '']},[_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
              `${_vm.$getVisibleNames('manual.matter_code', false, 'Código')}`
            ),expression:"\n              `${$getVisibleNames('manual.matter_code', false, 'Código')}`\n            ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"code-matter mb-1",attrs:{"variant":"none","pill":""}},[_vm._v(_vm._s(row.item.code))]),_c('div',{staticClass:"text-left ml-4"}),_c('div',{staticClass:"d-flex"},[_c('div',[_c('b-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                  `Ir al ${_vm.$getVisibleNames(
                    'manual.matter_program',
                    false,
                    'Programa de la Asignatura'
                  )}`
                ),expression:"\n                  `Ir al ${$getVisibleNames(\n                    'manual.matter_program',\n                    false,\n                    'Programa de la Asignatura'\n                  )}`\n                ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"matter-link",attrs:{"to":{
                  name: 'MatterProgramView',
                  params: { matter_id: row.item.id },
                }}},[(_vm.allows_crud)?[(row.item.is_closed)?[_c('b-icon',{attrs:{"icon":"lock","scale":"1"}})]:[_c('b-icon',{attrs:{"icon":"unlock","scale":"1"}})]]:_vm._e(),_vm._v(" "+_vm._s(row.item.name.toUpperCase())+" ")],2),(!row.item.use_matter_values)?[(row.item.modalities.length > 0)?[_c('b-badge',{staticClass:"modalities-badge",attrs:{"id":`modalities-badge-${row.item.id}`}},[_vm._v(" "+_vm._s(row.item.modalities ? row.item.modalities.length : "")+" ")]),_c('ModalitiesPopover',{attrs:{"object":row.item,"content":_vm.matterModalities(row.item.modalities),"triggers":'hover'}})]:_vm._e()]:[(
                    _vm.oldMatter(row.item) &&
                    _vm.oldMatter(row.item).modalities.length > 0
                  )?[_c('b-badge',{staticClass:"modalities-badge",attrs:{"id":`modalities-badge-${row.item.id}`}},[_vm._v(" "+_vm._s(_vm.oldMatter(row.item).modalities.length)+" ")]),_c('ModalitiesPopover',{attrs:{"object":row.item,"content":_vm.matterModalities(_vm.oldMatter(row.item).modalities),"triggers":'hover'}})]:_vm._e()],(
                  _vm.allows_crud &&
                  ((_vm.user_position &&
                    [1, 2, 3].includes(_vm.user_position.position) &&
                    [1, 2, 3].includes(_vm.user.groups[0])) ||
                    _vm.user.is_superuser ||
                    _vm.$hasObjectPermission(
                      'mesh.career',
                      'change',
                      _vm.egressProfile.career
                    ))
                )?_c('div',{staticClass:"button-div"},[_c('button-edit',{directives:[{name:"can",rawName:"v-can",value:('teaching.change_matter'),expression:"'teaching.change_matter'"},{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                    `Editar ${_vm.$getVisibleNames(
                      'mesh.egressprofilematter',
                      false,
                      'Asignatura'
                    )}`
                  ),expression:"\n                    `Editar ${$getVisibleNames(\n                      'mesh.egressprofilematter',\n                      false,\n                      'Asignatura'\n                    )}`\n                  ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.$bvModal.show(`modal-edit-matter-${row.item.id}`)}}}),(
                    ((_vm.institution && _vm.institution.show_base_ra) ||
                      _vm.$debug_change_duoc) &&
                    _vm.matterCompetenceIds(row.item).length > 0 &&
                    !_vm.isClosedOldMatter(row.item)
                  )?_c('b-button',{directives:[{name:"can",rawName:"v-can",value:('teaching.change_matter'),expression:"'teaching.change_matter'"},{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                    `Vincular ${_vm.$getVisibleNames(
                      'teaching.basera',
                      true,
                      'RA Base'
                    )} a ${row.item.name}`
                  ),expression:"\n                    `Vincular ${$getVisibleNames(\n                      'teaching.basera',\n                      true,\n                      'RA Base'\n                    )} a ${row.item.name}`\n                  ",modifiers:{"v-secondary":true,"noninteractive":true}}],attrs:{"variant":"none"},on:{"click":function($event){return _vm.$bvModal.show(`modal-copy-study-unit-${row.item.id}`)}}},[(row.item.is_integrative)?[_c('b-icon',{attrs:{"icon":"ui-checks"}})]:[_c('b-icon',{attrs:{"icon":"ui-radios"}})]],2):_vm._e(),(!_vm.isClosedOldMatter(row.item))?_c('button-add',{directives:[{name:"can",rawName:"v-can",value:('teaching.change_matter'),expression:"'teaching.change_matter'"},{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                    `Adicionar ${_vm.$getVisibleNames(
                      'teaching.ramicro',
                      false,
                      'RA Micro'
                    )}`
                  ),expression:"\n                    `Adicionar ${$getVisibleNames(\n                      'teaching.ramicro',\n                      false,\n                      'RA Micro'\n                    )}`\n                  ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.openModalStudyUnit(row.item.id)}}}):_vm._e()],1):_vm._e(),_c('b-modal',{attrs:{"id":`modal-new-study-unit-${row.item.id}`,"title":`Crear ${_vm.$getVisibleNames(
                  'teaching.ramicro',
                  false,
                  'RA Micro'
                )}`,"size":"lg","hide-footer":"","no-enforce-focus":""},on:{"hide":function($event){return _vm.hideModalRaMacro()}}},[_c('StudyUnitForm',{attrs:{"egress_profile_matter_id":row.item.id,"matter_id":row.item.matter,"show_temp_competence_unit":true},on:{"created":function($event){return _vm.$bvModal.hide(`modal-new-study-unit-${row.item.id}`)},"close_modal":function($event){return _vm.$bvModal.hide(`modal-new-study-unit-${row.item.id}`)}}})],1),_c('b-modal',{attrs:{"id":`modal-copy-study-unit-${row.item.id}`,"title":`Vincular ${_vm.$getVisibleNames(
                  'teaching.basera',
                  false,
                  'RA Base'
                )} a ${row.item.name}`,"size":"lg","hide-footer":""}},[_c('SelectedRaBase',{attrs:{"matrix2_view":true,"competences_ids":_vm.matterCompetenceIds(row.item),"egress_profile_id":_vm.egress_profile_id,"egress_profile_matter":row.item,"matter_id":row.item.matter},on:{"closeModal":function($event){return _vm.$bvModal.hide(`modal-copy-study-unit-${row.item.id}`)}}})],1),_c('b-modal',{attrs:{"id":`modal-edit-matter-${row.item.id}`,"hide-footer":"","title":`Editar ${_vm.$getVisibleNames(
                  'mesh.egressprofilematter',
                  false,
                  'Asignatura'
                )}`,"size":"xl","no-enforce-focus":""}},[_c('MatterForm',{attrs:{"Matter":row.item,"egress_profile_id":row.item.egress_profile,"show_delete_button":true},on:{"update":_vm.slotUpdatedMatter,"updated":_vm.slotUpdatedMatter}})],1)],2)])],1)]}},{key:"cell(competences)",fn:function(row){return [_c('div',{staticClass:"content-div",class:[row.item.id == _vm.last_matter ? 'new-matter-container' : '']},[_vm._l((_vm.matterCompetences(row.item)),function(competence){return _c('div',{key:`competence-${row.item.matter}-${competence.id}`},[_c('section',{staticClass:"d-flex",attrs:{"title":competence.type
                  ? _vm.competenceTypes.find((x) => x.id == competence.type).name
                  : ''}},[_c('strong',{staticClass:"mt-1 competence-order"},[_vm._v(_vm._s(competence.order)+"."+_vm._s(_vm.cycleOrder(competence.cycle)))]),_c('SentenceContainer',{staticClass:"mt-1",attrs:{"unique_id":row.item.id +
                  '-' +
                  competence.id +
                  '-' +
                  competence.full_sentence,"Sentence":competence}})],1)])}),_c('MatterCompetencesModal',{attrs:{"matter":row.item,"egressProfile":_vm.egressProfile,"open_modal":_vm.open_competence_modal == row.item.id ? true : false,"allows_crud":!row.item.is_closed
                ? _vm.allows_crud &&
                  ((_vm.user_position &&
                    [1, 2, 3].includes(_vm.user_position.position) &&
                    [1, 2, 3].includes(_vm.user.groups[0])) ||
                    _vm.user.is_superuser ||
                    _vm.$hasObjectPermission(
                      'mesh.career',
                      'change',
                      _vm.egressProfile.career
                    ))
                : false}})],2)]}},{key:"cell(temp_competence_unit)",fn:function(row){return [_c('CompetenceUnitCell',{attrs:{"profileMatter":row.item,"matter_id":row.item.matter,"allows_crud":!_vm.isClosedOldMatter(row.item)
              ? _vm.allows_crud &&
                ((_vm.user_position &&
                  [1, 2, 3].includes(_vm.user_position.position) &&
                  [1, 2, 3].includes(_vm.user.groups[0])) ||
                  _vm.user.is_superuser ||
                  _vm.$hasObjectPermission(
                    'mesh.career',
                    'change',
                    _vm.egressProfile.career
                  ))
              : false}})]}},{key:"cell(ra_macro)",fn:function(row){return [_c('div',{staticClass:"ra-macro-div",class:[row.item.id == _vm.last_matter ? 'new-matter-container' : '']},[(_vm.getRaMacro(row.item.matter))?_c('section',[_c('SentenceContainer',{attrs:{"Sentence":_vm.getRaMacro(row.item.matter)}})],1):_vm._e(),(
              !_vm.isClosedOldMatter(row.item)
                ? _vm.allows_crud &&
                  ((_vm.user_position &&
                    [1, 2, 3].includes(_vm.user_position.position) &&
                    [1, 2, 3].includes(_vm.user.groups[0])) ||
                    _vm.user.is_superuser ||
                    _vm.$hasObjectPermission(
                      'mesh.career',
                      'change',
                      _vm.egressProfile.career
                    ))
                : false
            )?_c('div',[(_vm.getRaMacro(row.item.matter))?_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary",value:(
                `Editar ${_vm.$getVisibleNames(
                  'teaching.ramacro',
                  false,
                  'RA Macro'
                )}`
              ),expression:"\n                `Editar ${$getVisibleNames(\n                  'teaching.ramacro',\n                  false,\n                  'RA Macro'\n                )}`\n              ",modifiers:{"v-secondary":true}},{name:"can",rawName:"v-can",value:('teaching.change_ramacro'),expression:"'teaching.change_ramacro'"}],on:{"click":function($event){return _vm.openModalCreate(row.item.id)}}}):_vm._e(),(!_vm.getRaMacro(row.item.matter))?_c('button-add',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary",value:(
                `Crear ${_vm.$getVisibleNames(
                  'teaching.ramacro',
                  false,
                  'RA Macro'
                )}`
              ),expression:"\n                `Crear ${$getVisibleNames(\n                  'teaching.ramacro',\n                  false,\n                  'RA Macro'\n                )}`\n              ",modifiers:{"v-secondary":true}},{name:"can",rawName:"v-can",value:('teaching.change_ramacro'),expression:"'teaching.change_ramacro'"}],on:{"click":function($event){return _vm.openModalEdit(row.item.id)}}}):_vm._e(),_c('b-modal',{attrs:{"id":`modal-new-ra-macro-${row.item.id}`,"title":`Crear ${_vm.$getVisibleNames(
                'teaching.ramacro',
                false,
                'RA Macro'
              )}.`,"size":"lg","hide-footer":"","no-enforce-focus":""},on:{"hide":_vm.hideModalRaMacro}},[_c('RAMacroForm',{attrs:{"matter_id":row.item.matter},on:{"created":function($event){return _vm.$bvModal.hide(`modal-new-ra-macro-${row.item.id}`)},"create":function($event){return _vm.$bvModal.hide(`modal-new-ra-macro-${row.item.id}`)}}})],1),_c('b-modal',{attrs:{"id":`modal-edit-ra-macro-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames(
                'teaching.ramacro',
                false,
                'RA Macro'
              )}.`,"size":"lg","hide-footer":"","no-enforce-focus":""},on:{"hide":_vm.hideModalRaMacro}},[_c('RAMacroForm',{attrs:{"matter_id":row.item.matter,"RAMacro":_vm.getRaMacro(row.item.matter)},on:{"update":function($event){return _vm.$bvModal.hide(`modal-edit-ra-macro-${row.item.id}`)},"updated":function($event){return _vm.$bvModal.hide(`modal-edit-ra-macro-${row.item.id}`)}}})],1)],1):_vm._e()])]}},{key:"cell(ra_micro)",fn:function(row){return [_c('div',{staticClass:"ra-micro-container",class:[row.item.id == _vm.last_matter ? 'new-matter-container' : '']},[(_vm.studyUnitList(row.item).length > 0)?[_vm._l((_vm.studyUnitList(row.item)),function(study_unit){return [(study_unit)?_c('StudyUnitCell',{key:`study-unit-cell-${study_unit.id}`,staticClass:"ra-micro-content",attrs:{"egress_profile_matter_id":row.item.id,"study_unit_id":study_unit.id,"matter_id":row.item.matter,"allows_crud":!_vm.isClosedOldMatter(row.item)
                    ? _vm.allows_crud &&
                      ((_vm.user_position &&
                        [1, 2, 3].includes(_vm.user_position.position) &&
                        [1, 2, 3].includes(_vm.user.groups[0])) ||
                        _vm.user.is_superuser ||
                        _vm.$hasObjectPermission(
                          'mesh.career',
                          'change',
                          _vm.egressProfile.career
                        ))
                    : false}}):_vm._e()]})]:[_vm._v(" N/A ")]],2)]}},{key:"cell(methodological_strategies)",fn:function(row){return [_c('div',{class:[row.item.id == _vm.last_matter ? 'new-matter-container' : '']},[_c('MethodologicalStrategiesCell',{attrs:{"ProfileMatter":row.item,"allows_crud":!_vm.isClosedOldMatter(row.item)
                ? _vm.allows_crud &&
                  ((_vm.user_position &&
                    [1, 2, 3].includes(_vm.user_position.position) &&
                    [1, 2, 3].includes(_vm.user.groups[0])) ||
                    _vm.user.is_superuser ||
                    _vm.$hasObjectPermission(
                      'mesh.career',
                      'change',
                      _vm.egressProfile.career
                    ))
                : false}})],1)]}}])})],1),_c('b-modal',{attrs:{"id":"new-matter-modal","title":`Crear ${_vm.$getVisibleNames(
      'mesh.egressprofilematter',
      false,
      'Asignatura'
    )}`,"size":"xl","hide-footer":""},on:{"hide":_vm.confirmClose}},[_c('MatterForm',{attrs:{"egress_profile_id":_vm.egress_profile_id,"courses_wrapper_id":_vm.courses_wrapper_id},on:{"created":_vm.slotCreatedMatter,"close_modal":_vm.slotCreatedMatter,"change_matter_form":() => (_vm.change_matter_form = true)}})],1),_c('b-modal',{attrs:{"id":"course-wrapper-modal","title":`${_vm.$getVisibleNames(
      'mesh.coursewrapper',
      false,
      'Envoltorio de Asignaturas'
    )}`,"size":"xl","hide-footer":""}},[_c('CoursesWrapperView',{attrs:{"egress_profile_id":_vm.egress_profile_id,"allows_crud":_vm.allows_crud},on:{"closeModal":function($event){return _vm.$bvModal.hide('course-wrapper-modal')}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }